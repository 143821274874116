import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Tag } from 'src/app/guru/guru.component';
import { Contest, ContestType, DurationType } from '../game/game.service';
import { PaginatedContest, SubTopicsEntityOrOwnedSubtopicsEntity, TopicsEntity } from '../../profile/User/user.service';
import { TopicService } from 'src/app/topic/topic.service';
import { GlobalContestState, UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { SubtopicService } from 'src/app/topic/subtopic/subtopic.service';
import { ContestStateService } from '../contest-state.service';
import { SubtopicRankingService } from 'src/app/Aggregation/subtopic-ranking.service';
import { ContestService } from '../contest.service';
import { user } from '../../GlobalVars/routingGlobal';
import {Clipboard} from '@angular/cdk/clipboard';
import { InviteLinkService } from '../../shared/invite-link.service';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';

import { Page } from 'src/app/media/media.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Duration } from 'moment-timezone';
import * as routingGlobals from '../../GlobalVars/routingGlobal';
import { PaginationCommand } from 'src/app/profile/Me/your-blogs/your-blogs.component';

@Component({
  selector: 'app-view-contests-container',
  templateUrl: './view-contests-container.component.html',
  styleUrls: ['./view-contests-container.component.css']
})
export class ViewContestsContainerComponent implements OnInit {

  @Output()
  selectedASubtopic = new EventEmitter<SubTopicsEntityOrOwnedSubtopicsEntity>();
  @Output()
  selectedATag = new EventEmitter<Tag>();
  @Input()
  userId = -1;

  @Input()
  globalContestStateForAPICall = '';

  public yourLink = '';
  PaginationCommand = PaginationCommand;
  GlobalContestState = GlobalContestState;
  public selectedTagValue: string;
  public searchTagValue: any;
  private _filteredTagList: Tag[];
  private _nonfilteredTagList: Tag[];

  private _filteredGameNameList: string[];
  private _nonfilteredGameNameList: string[];

  private _selectedTag: Tag = {name: '', id: -1};

  public selectedContestTypeValue: string;
  public searchContestTypeValue: any;
  private _filteredContestTypeList: ContestType[];
  private _nonfilteredContestTypeList: ContestType[];
  private _selectedContestType: ContestType = ContestType.ALL;

  public selectedDurationTypeValue: string;
  public searchDurationTypeValue: any;
  private _filteredDurationTypeList: DurationType[];
  private _nonfilteredDurationTypeList: DurationType[];
  private _selectedDurationType: DurationType = DurationType.ALL;
  isMobile: boolean;
  currentTopic: TopicsEntity;
  currentSubtopic: SubTopicsEntityOrOwnedSubtopicsEntity;
  createContestFlag: boolean;
  enterExistingContestFlag: boolean;
  endOfBlogs: boolean;
  paginatedContest: PaginatedContest;
  currentContest: Contest;
  contestTypesFinance = [ContestType.ALL, ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
  contestTypesSports = [ContestType.ALL, ContestType.MARGIN_OF_VICTORY, ContestType.TOTAL_POINTS_SCORED];
  selectedGameNameValue: string;
  private _selectedGameNameValue: string;


  constructor(private topicService: TopicService,  private subtopicRankingService: SubtopicRankingService,
              private subtopicService: SubtopicService,
              private contestService: ContestService, private clipboard: Clipboard,
              private inviteLinkService: InviteLinkService, private router: Router,
              private route: ActivatedRoute
                ) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    const contest: Contest = {
      topicReference: null,
      subtopicReference: null,
      name: '',
      games: [],
      maxUsers: 100000,
      globalContestState: GlobalContestState.NONE,
      userContestState: UserContestState.NONE,
      contestType: ContestType.UNDER_OVER,
      durationType: DurationType.ALL
    };
    this.currentContest = contest;
    this._nonfilteredContestTypeList = this.contestTypesFinance;
    this._filteredContestTypeList = this._nonfilteredContestTypeList.slice();
    this._nonfilteredDurationTypeList = [DurationType.ALL, DurationType.ONE_DAY,
       DurationType.SEVEN_DAY, DurationType.FOURTEEN_DAY, DurationType.THIRTY_DAY];
    this._filteredDurationTypeList = this._nonfilteredDurationTypeList.slice();
    this._selectedContestType = this._nonfilteredContestTypeList[0];
    this._selectedDurationType = this._nonfilteredDurationTypeList[0];
    this.selectedGameNameValue = 'ALL';
    // expect the parent component to always have topics available
    this.topicService.allTopics._embedded.topicDTOList.forEach((topic: TopicsEntity) => {
      if (topic.name === 'Finance'){
        this.selectTopic(topic);
      }
    });
    // this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
    //   if (subtopic.name === 'Stocks'){
    //     this.selectSubtopic(subtopic);
    //     // this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, subtopic, true);
    //     this.subtopicSelected();
    //   }
    // });

  }


  filterTagDropdown(e): void {

    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this._filteredTagList = this.nonFilteredTagList.slice();
      return;
    } else {
      this._filteredTagList = this.nonFilteredTagList.filter(
        (tag: Tag) => tag.name.toLowerCase().indexOf(searchString) > -1
      );

    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
    console.log('this.filteredList indropdown -------> ', this._filteredTagList);
  }

  selectGameNameValue(name: string): void {
    this.selectedGameNameValue = name;
    this._selectedTag = {id: -1, name: ''};
    this.selectGameName(this.selectedGameNameValue, true);
  }

  filterGameNameDropdown(e): void {
    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this._filteredGameNameList = this.nonFilteredGameNameList.slice();
      return;
    } else {
      this._filteredGameNameList = this.nonFilteredGameNameList.filter(
        (name: string) => name.toLowerCase().indexOf(searchString) > -1
      );
    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
    console.log('this.filteredList indropdown -------> ', this._filteredGameNameList);
  }

  selectTagValue(tag: Tag): void {
    this.selectedTagValue = tag.name;
    this.selectedGameNameValue = 'ALL';
    this.selectTag(tag, true);
  }


  filterDurationTypeDropdown(e): void {
    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this._filteredDurationTypeList = this.nonFilteredDurationTypeList.slice();
      return;
    } else {
      this._filteredDurationTypeList = this.nonFilteredDurationTypeList.filter(
        (durationType: string) => durationType.toLowerCase().indexOf(searchString) > -1
      );
    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
  }

  selectDurationTypeValue(durationType: DurationType): void {
    this.selectedDurationTypeValue = durationType;
    this._selectedDurationType = durationType;
    this.currentContest.subtopicReference = {    id: this.currentSubtopic.id,
      name: this.currentSubtopic.name};
    if (this.userId === -1){
      this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, this.currentSubtopic);
    }
    else{
      this.retreiveExistingContestsWithGlobalContestStateForUser(GlobalContestState.UPCOMING, this.currentSubtopic, this.userId);
    }
  }

  get selectedDurationType(): string{
    return this._selectedDurationType;
  }

  get selectedContestType(): ContestType{
    return this._selectedContestType;
  }

  filterContestTypeDropdown(e): void {
    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this._filteredContestTypeList = this.nonFilteredContestTypeList.slice();
      return;
    } else {
      this._filteredContestTypeList  = this.nonFilteredContestTypeList.filter(
        (contestType: ContestType) => contestType.toLowerCase().indexOf(searchString) > -1
      );
    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
  }

  selectContestTypeValue(contestType: ContestType): void {
    this.selectedContestTypeValue = contestType;
    this._selectedContestType = contestType;
    this.currentContest.subtopicReference = {    id: this.currentSubtopic.id,
      name: this.currentSubtopic.name};
    if (this.userId === -1){
      this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, this.currentSubtopic);
    }
    else{
      this.retreiveExistingContestsWithGlobalContestStateForUser(GlobalContestState.UPCOMING, this.currentSubtopic, this.userId);
    }
  }

  get filteredTagList(): Tag[]{
    return this._filteredTagList;
  }

  get nonFilteredTagList(): Tag[]{
    return this._nonfilteredTagList;
  }

  get filteredGameNameList(): string[]{
    return this._filteredGameNameList;
  }

  get nonFilteredGameNameList(): string[]{
    return this._nonfilteredGameNameList;
  }

  get filteredContestTypeList(): ContestType[]{
    return this._filteredContestTypeList;
  }

  get nonFilteredContestTypeList(): ContestType[]{
    return this._nonfilteredContestTypeList;
  }

  get filteredDurationTypeList(): string[]{
    return this._filteredDurationTypeList;
  }

  get nonFilteredDurationTypeList(): DurationType[]{
    return this._nonfilteredDurationTypeList;
  }


  selectTag(tag: Tag, makeAPICall = false): void{
    // this.guruService.currentTag = tag;
    this._selectedTag = tag;
    this.selectedATag.emit( tag);
    if (makeAPICall){
      this.currentContest.subtopicReference = {    id: this.currentSubtopic.id,
        name: this.currentSubtopic.name};
      if (this.userId === -1){
        this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, this.currentSubtopic);
      }
      else{
        this.retreiveExistingContestsWithGlobalContestStateForUser(GlobalContestState.UPCOMING, this.currentSubtopic, this.userId);
      }
    }

   }


  selectGameName(tag: string, makeAPICall = false): void{
    // TODO -> calle api with gamename filter for upcoming contests
    if (makeAPICall){
      this.currentContest.subtopicReference = {    id: this.currentSubtopic.id,
        name: this.currentSubtopic.name};
      if (this.userId === -1){
        this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, this.currentSubtopic);
      }
      else{
        this.retreiveExistingContestsWithGlobalContestStateForUser(GlobalContestState.UPCOMING, this.currentSubtopic, this.userId);
      }
    }
   }

   get selectedTag(): Tag{
    return this._selectedTag;
   }

   selectTopic(topic: TopicsEntity): void {
    this.topicService.currentTopic = topic;
    this.currentTopic = topic;
    if (topic.name.toUpperCase() === 'FINANCE'){
      this._nonfilteredContestTypeList = this.contestTypesFinance;
      this._filteredContestTypeList = this._nonfilteredContestTypeList.slice();
      this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
        if (subtopic.name.toUpperCase()  === 'STOCKS'){
          this.selectSubtopic(subtopic);
          // this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, subtopic, true);
          this.subtopicSelected();
        }
      });
    }
    if (topic.name.toUpperCase() === 'SPORTS'){
      this._nonfilteredContestTypeList = this.contestTypesSports;
      this._filteredContestTypeList = this._nonfilteredContestTypeList.slice();
      this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
        if (subtopic.name.toUpperCase()  === 'NCAAF'){
          this.selectSubtopic(subtopic);
          // this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, subtopic, true);
          this.subtopicSelected();
        }
      });
    }
    this.currentContest.topicReference = {    id:  this.topicService.currentTopic.id,
      name: this.topicService.currentTopic.name};
    this.currentTopic = topic;
  }

    retreiveExistingContestsWithGlobalContestState(globalContestState: GlobalContestState,
                                                   subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void{
      this.contestService.getContests({type: 'topicAndSubtopic',
      topicId: this.topicService.currentTopic.id, subtopicId: subtopic.id, contestType: this._selectedContestType,
      durationType: this._selectedDurationType, globalContestState: globalContestState,
      tagId: this._selectedTag.id, page: 0, atomicGameName: this.selectedGameNameValue}).subscribe((results: PaginatedContest) => {
          this.paginatedContest = results;

          this.createContestFlag = false;
          this.enterExistingContestFlag = true;
          this.subtopicRankingService.getAllTagsForSubtopicWithNameMappings(subtopic.id).subscribe(
          // this.subtopicRankingService.getAllTagsForSubtopic(subtopic.id, false, false, false, true, -1, false).subscribe(
            (result) => {
              this._nonfilteredTagList = [];
              this._nonfilteredTagList = result._embedded.tagList;
              this._filteredTagList = this.nonFilteredTagList.slice();
              this._nonfilteredContestTypeList = [ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
              this._nonfilteredDurationTypeList = [DurationType.ALL, DurationType.ONE_DAY,
                DurationType.SEVEN_DAY, DurationType.FOURTEEN_DAY, DurationType.THIRTY_DAY];
              if (this._selectedTag.id === -1){
               this._nonfilteredTagList.forEach(tag => {
                if (tag.name.toLowerCase() === subtopic.name.toLowerCase()){
                  // this.selectTag(this._filteredTagList[0]);
                  this._selectedTag = tag;
                  // this.searchTagValue = tag.name;
                  this.selectedTagValue = tag.name;
                  this.selectedATag.emit( tag);
                  this._filteredTagList = this.nonFilteredTagList.slice();

                }
              });
              }
            }
          );
      });
      this.subtopicRankingService.getAllGameNamesForSubtopicUpcomingContests(this.currentSubtopic.id).subscribe(
        (result) => {
          this._nonfilteredGameNameList = [];
          this._nonfilteredGameNameList = result;
          this._filteredGameNameList =   this._nonfilteredGameNameList.slice();
        });
  }

    retreiveExistingContestsWithGlobalContestStateForUser(globalContestState: GlobalContestState,
                                                          subtopic: SubTopicsEntityOrOwnedSubtopicsEntity,
                                                          userId: number): void{
      if (this.globalContestStateForAPICall === 'userCompetitorLive' ) {
        this.contestService.getUserContests({type: 'userCompetitorLive', subtopicId: this.currentSubtopic.id,
         contestType: this._selectedContestType, durationType: this._selectedDurationType,
         tagId: this._selectedTag.id, topicId: -1, page: 0, atomicGameName: this.selectedGameNameValue}, userId)
        .subscribe((results: PaginatedContest) => {
          this.processUserContestResults(results, subtopic, GlobalContestState.LIVE);
       });
      }
      else if (this.globalContestStateForAPICall === 'userCompetitorPast' ) {
        this.contestService.getUserContests({type: 'userCompetitorPast', subtopicId: this.currentSubtopic.id,
        contestType: this._selectedContestType, durationType: this._selectedDurationType,
         tagId: this._selectedTag.id, topicId: -1, page: 0,  atomicGameName: this.selectedGameNameValue}, userId)
        .subscribe((results: PaginatedContest) => {
          this.processUserContestResults(results, subtopic, GlobalContestState.PAST);
        });
      }
      else if (this.globalContestStateForAPICall === 'userCompetitorUpcoming' ) {
        this.contestService.getUserContests({type: 'userCompetitorUpcoming',
        contestType: this._selectedContestType, durationType: this._selectedDurationType,
        subtopicId: this.currentSubtopic.id, tagId: this._selectedTag.id, topicId: -1,
        page: 0,  atomicGameName: this.selectedGameNameValue}, userId)
        .subscribe((results: PaginatedContest) => {
          this.processUserContestResults(results, subtopic, GlobalContestState.UPCOMING);
        });
      }
  }

  private processUserContestResults(results: PaginatedContest,
                                    subtopic: SubTopicsEntityOrOwnedSubtopicsEntity,
                                    globalContestState: GlobalContestState): void {
    this.paginatedContest = results;
    this.createContestFlag = false;
    this.enterExistingContestFlag = true;
    this.currentSubtopic = subtopic;
    this.subtopicRankingService.getAllTagsForSubtopic(
      subtopic.id, false, false, true, false, this.userId, false, globalContestState).subscribe(
      (result) => {
        this._nonfilteredTagList = [];
        this._nonfilteredTagList = result._embedded.tagList;
        this._filteredTagList = this.nonFilteredTagList.slice();
        this._nonfilteredContestTypeList = [ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
        this._nonfilteredDurationTypeList = [DurationType.ALL, DurationType.ONE_DAY,
          DurationType.SEVEN_DAY, DurationType.FOURTEEN_DAY, DurationType.THIRTY_DAY];
        if (this._selectedTag.id === -1){
          this._nonfilteredTagList.forEach(tag => {
           if (tag.name.toLowerCase() === subtopic.name.toLowerCase()){
             // this.selectTag(this._filteredTagList[0]);
             this._selectedTag = tag;
             // this.searchTagValue = tag.name;
             this.selectedTagValue = tag.name;
             this.selectedATag.emit( tag);
             this._filteredTagList = this.nonFilteredTagList.slice();

           }
         });
         }

        // this._nonfilteredTagList.forEach(tag => {
        //   if (tag.name.toLowerCase() === subtopic.name.toLowerCase()) {
        //     // this.selectTag(this._filteredTagList[0]);
        //     this.selectTag(tag);
        //     this.selectedTagValue = tag.name;
        //     this._filteredTagList = this.nonFilteredTagList.slice();
        //   }
        // });
      }
    );
    this.subtopicRankingService.getAllGameNamesForSubtopicUpcomingContests(this.currentSubtopic.id).subscribe(
      (result) => {
        this._nonfilteredGameNameList = [];
        this._nonfilteredGameNameList = result;
        this._filteredGameNameList =   this._nonfilteredGameNameList.slice();
      });
  }

  copyURL(): void {
    // console.log('attempt copy to clipboard');
    // this.inviteLinkService.getYourInviteLink().subscribe(result => {
    //   console.log('result is ' + result);
    //   this.clipboard.copy( location.origin + '/signup?inviter=' + result.message);
    //   this.yourLink = location.origin + '/signup?inviter=' + result.message;
    //   console.log('copied to clipboard ONE');
    // });
    // console.log('copied to clipboard');
    this.router.navigate([routingGlobals.absShareableLink]);
  }


  subtopicSelected(): boolean{
    // tslint:disable-next-line:max-line-length
    if ( this.currentContest && this.currentContest.subtopicReference){
      return true;
    }
    return false;
  }

  selectSubtopic(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
    // this.subtopicService.currentSubtopic = subtopic;
    if (subtopic == null){
      console.log('null subtopic');
      return;
    }
    this.currentSubtopic = subtopic;
    this._selectedTag = {id: -1, name: ''};
    this.selectedGameNameValue = 'ALL';
    this.selectedASubtopic.emit(  subtopic);
    this.currentContest.subtopicReference = {    id: this.currentSubtopic.id,
      name: this.currentSubtopic.name};
    if (this.userId === -1){
      this.retreiveExistingContestsWithGlobalContestState(GlobalContestState.UPCOMING, subtopic);
    }
    else{
      this.retreiveExistingContestsWithGlobalContestStateForUser(GlobalContestState.UPCOMING, subtopic, this.userId);
    }

  }

  isSelected(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): boolean {
    if (this.currentSubtopic && this.currentSubtopic.id){
      return this.currentSubtopic.id === subtopic.id;
    }
    return false;
  }

  onPaginate(paginationCommand: PaginationCommand): void {
    let nextPage = null;
    if (paginationCommand === PaginationCommand.NEXT){
      if (this.paginatedContest.page.number + 1 === this.paginatedContest.page.totalPages){
        return;
      }
      nextPage = this.paginatedContest.page.number + 1;
    }
    else if (paginationCommand === PaginationCommand.PREVIOUS){
      if (this.paginatedContest.page.number - 1 === 0){
        return;
      }
      nextPage = this.paginatedContest.page.number - 1;
    }
    else if (paginationCommand === PaginationCommand.FIRST){
      nextPage = 0;
    }
    else if (paginationCommand === PaginationCommand.LAST){
      nextPage = this.paginatedContest.page.totalPages - 1;
    }

    if (this.globalContestStateForAPICall === 'userCompetitorLive' ) {
      this.contestService.getUserContests({type: 'userCompetitorLive',
      contestType: this._selectedContestType, durationType: this._selectedDurationType,
      subtopicId: this.currentSubtopic.id, tagId: this._selectedTag.id, topicId: -1,
      page: nextPage, atomicGameName: this.selectedGameNameValue}, this.userId)
      .subscribe((results: PaginatedContest) => {
        this.processUserContestResults(results, this.currentSubtopic, GlobalContestState.LIVE);
        if (this.paginatedContest._embedded.contestPreviewDTOList.length > 0){
          this.paginatedContest = results;
        }
        else{
          this.endOfBlogs = true;
        }
     });
    }
    else if (this.globalContestStateForAPICall === 'userCompetitorPast' ) {
      this.contestService.getUserContests({type: 'userCompetitorPast', subtopicId: this.currentSubtopic.id,
      contestType: this._selectedContestType, durationType: this._selectedDurationType,  atomicGameName: this.selectedGameNameValue,
      tagId: this._selectedTag.id, topicId: -1, page: nextPage}, this.userId)
      .subscribe((results: PaginatedContest) => {
        this.processUserContestResults(results, this.currentSubtopic, GlobalContestState.PAST);
        if (this.paginatedContest._embedded.contestPreviewDTOList.length > 0){
          this.paginatedContest = results;
        }
        else{
          this.endOfBlogs = true;
        }
      });
    }
    else if (this.globalContestStateForAPICall === 'userCompetitorUpcoming' ) {
      this.contestService.getUserContests({type: 'userCompetitorUpcoming', subtopicId: this.currentSubtopic.id,
      contestType: this._selectedContestType, durationType: this._selectedDurationType,  atomicGameName: this.selectedGameNameValue,
      tagId: this._selectedTag.id, topicId: -1, page: nextPage}, this.userId)
      .subscribe((results: PaginatedContest) => {
        this.processUserContestResults(results, this.currentSubtopic, GlobalContestState.UPCOMING);
        if (this.paginatedContest._embedded.contestPreviewDTOList.length > 0){
          this.paginatedContest = results;
        }
        else{
          this.endOfBlogs = true;
        }
      });
    }
    else if (this.globalContestStateForAPICall === 'topicAndSubtopic' ) {
      this.contestService.getContests({type: 'topicAndSubtopic',
      topicId: this.topicService.currentTopic.id, subtopicId: this.currentSubtopic.id,
      contestType: this._selectedContestType, durationType: this._selectedDurationType,  atomicGameName: this.selectedGameNameValue,
      tagId: this._selectedTag.id, globalContestState: this.GlobalContestState.UPCOMING, page: nextPage})
      .subscribe((results: PaginatedContest) => {
        this.paginatedContest = results;
        if (this.paginatedContest._embedded.contestPreviewDTOList.length > 0){
        this.createContestFlag = false;
        this.enterExistingContestFlag = true;
        // this.subtopicRankingService.getAllTagsForSubtopic(this.currentSubtopic.id, false, false, false, true, -1, false).subscribe(
        this.subtopicRankingService.getAllTagsForSubtopicWithNameMappings(this.currentSubtopic.id).subscribe(
          (result) => {
            this._nonfilteredTagList = [];
            this._nonfilteredTagList = result._embedded.tagList;
            this._filteredTagList = this.nonFilteredTagList.slice();
            this._nonfilteredContestTypeList = [ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
            this._nonfilteredDurationTypeList = [DurationType.ALL, DurationType.ONE_DAY,
              DurationType.SEVEN_DAY, DurationType.FOURTEEN_DAY, DurationType.THIRTY_DAY];
            if (this._selectedTag.id === -1){
              this._nonfilteredTagList.forEach(tag => {
               if (tag.name.toLowerCase() === this.currentSubtopic.name.toLowerCase()){
                 // this.selectTag(this._filteredTagList[0]);
                 this._selectedTag = tag;
                 // this.searchTagValue = tag.name;
                 this.selectedTagValue = tag.name;
                 this.selectedATag.emit( tag);
                 this._filteredTagList = this.nonFilteredTagList.slice();

               }
             });
             }
          }
        );
      }
        else{
          this.endOfBlogs = true;
        }
      });
      this.subtopicRankingService.getAllGameNamesForSubtopicUpcomingContests(this.currentSubtopic.id).subscribe(
        (result) => {
          this._nonfilteredGameNameList = [];
          this._nonfilteredGameNameList = result;
          this._filteredGameNameList =   this._nonfilteredGameNameList.slice();
        });
    }
    }

    get enterButtonText(): string{
      switch (this.globalContestStateForAPICall){
        case  'userCompetitorUpcoming':
          return 'View Contest';
        case 'userCompetitorPast':
          return 'View Results';
        case  'userCompetitorLive':
          return 'View Live';
        default:
          return 'Enter contest';
      }
    }

    get enterButtonHeaderText(): string{
      switch (this.globalContestStateForAPICall){
        case  'userCompetitorUpcoming':
          return 'View';
        case 'userCompetitorPast':
          return 'Result';
        case  'userCompetitorLive':
          return 'Live';
        default:
          return 'Enter';
      }
    }



    get pageData(): Page{
      if (this.paginatedContest.page){
        return this.paginatedContest.page;
      }
      return undefined;
    }
    get topics(): any{
      return this.topicService.allTopics?._embedded?.topicDTOList;
     }

     isSelectedTopic(topic: TopicsEntity): boolean {
      return this.topicService.currentTopic === topic;
    }

}
