import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ContestStateService } from 'src/app/contest/contest-state.service';
import { StockData } from 'src/app/contest/create-game/create-financial-game/create-financial-game.component';
import { GameService } from 'src/app/contest/game/game.service';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import { CreateContestService } from '../../create-contest.service';

@Component({
  selector: 'app-create-financial-contest-modal-date-selection',
  templateUrl: './create-financial-contest-modal-date-selection.component.html',
  styleUrls: ['./create-financial-contest-modal-date-selection.component.css']
})
export class CreateFinancialContestModalDateSelectionComponent implements OnInit {

 // gameTypes = [  'StraightSelection', 'MarginOfError', 'CombinedDAndWeightedMarginOfError', 'WeightedStraightSelection'];
 currentDate = new Date();
 endModel: NgbDateStruct;
 startModel: NgbDateStruct;
 today = this.calendar.getToday();
 tomorrow = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
 time = {hour: new Date().getHours() + 12, minute: 30};
 meridian = true;

 isDateSelection = false;
 isStockSelection = false;
 isNaming = false;

 fieldToBeBetOn = 'Open';

 constructor(private calendar: NgbCalendar,
             private gameService: GameService,
             private contestStateService: ContestStateService,
             private route: ActivatedRoute, private router: Router,
             private createContestService: CreateContestService) {}

nextPage(): void {
  this.createDate(this.startModel, false);
  this.createDate(this.endModel, true);
  this.router.navigate(['../' + routingGlobals.createFinancialContestModalReviewAndSubmit], {relativeTo: this.route});
}
previousPage(): void {
  this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockGameTypeSelection], {relativeTo: this.route});
}
startPage(): void {
  this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
}
 ngOnInit(): void {
 }

 toggleMeridian(): any {
   this.meridian = !this.meridian;
 }

 createDate(model: NgbDateStruct, isEndDate: boolean): Date{
   return this.createContestService.createDate(model, isEndDate);
 }

 get games(): any{
   return this.gameService.games;
 }

 get bothDatesSelected(): boolean {
  if (this.createContestService.startDate && this.createContestService.endDate){
    return true;
  }
  return false;
 }

}
