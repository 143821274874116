import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from 'src/app/Aggregation/subtopic-ranking.service';
import { Tag } from 'src/app/guru/guru.component';
import { SubtopicService } from 'src/app/topic/subtopic/subtopic.service';
import { ContestOutcome, PaginatedContestOutcome } from '../../Me/my-contest-outcomes/my-contest-outcome.service';
import { SubTopicsEntityOrOwnedSubtopicsEntity, UserService } from '../user.service';
import { UserContestOutcomesService } from './user-contest-outcomes.service';
import { PaginatedSubtopicRanking, SubtopicRanking } from '../../../contest/contest.component';
import { Time } from '@angular/common';
import { Router } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';

@Component({
  selector: 'app-user-contest-outcomes',
  templateUrl: './user-contest-outcomes.component.html',
  styleUrls: ['./user-contest-outcomes.component.css']
})
export class UserContestOutcomesComponent implements OnInit {

  @Output()
  updateSubtopicRankingPercentiles = new EventEmitter<any>();

  throttle = 0;
  distance = 2;
  page = 1;

  private _subtopic: SubTopicsEntityOrOwnedSubtopicsEntity;
  private _tag: Tag;
  private _contestType: string;
  isMobile: boolean;
  activeSubtopicRankingSet: SubtopicRanking;
  private _timeframeType: TimeAndGameDurationPeriodType;
  _userId: number;
  endOfContestOutcomes = false;

  @Input() set
  subtopic(value: SubTopicsEntityOrOwnedSubtopicsEntity){
    this._subtopic = value;
    if (!!this._userId &&
      !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get subtopic(): SubTopicsEntityOrOwnedSubtopicsEntity{
    return this._subtopic;
  }

  @Input() set
  userId(value: number){
    this._userId = value;
    if (!!this._userId && !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get userId(): number{
    return this._userId;
  }

  @Input() set
  tag(value: Tag){
    this._tag = value;
    if (!!this._userId && !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get tag(): Tag{
    return this._tag;
  }

  @Input() set
  contestType(value: string){
    this._contestType = value;
    if (!!this._userId && !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get contestType(): string{
    return this._contestType;
  }

  @Input() set
  timeframeType(value: TimeAndGameDurationPeriodType){
    this._timeframeType = value;
    if (!!this._userId &&
       !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get timeframeType(): TimeAndGameDurationPeriodType{
    return this._timeframeType;
  }

  _filteredOutcomes: ContestOutcome[] = [];

  paginatedContestOutcomesForSingleContest: PaginatedContestOutcome;

  constructor(private userContestOutcomeService: UserContestOutcomesService,
              private subtopicRankingService: SubtopicRankingService,
              private router: Router) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    if (!!this._userId && !!this._subtopic && !!this._tag && !!this._contestType){
    //  this.retrieveContestOutcomeList();
    this.retrieveSubtopicRankingList();
    }

  }

  retrieveContestOutcomeList(timeframe: string = 'YEAR'): void {
    this.endOfContestOutcomes = false;
    this.userContestOutcomeService.getContestOutcomesForUser(this._userId,
      this._subtopic.id, this._tag.id, this._contestType, timeframe)
    .subscribe((paginatedContestOutcome: PaginatedContestOutcome) => {
      this.userContestOutcomeService.paginatedContestOutcome = paginatedContestOutcome;
      this._filteredOutcomes = [];
      if (this.userContestOutcomeService.paginatedContestOutcome.page.number
        === this.userContestOutcomeService.paginatedContestOutcome.page.totalPages){
        this.endOfContestOutcomes = true;
      }
      // console.log('Filtering outcomes');
      this.contestOutcomeList.forEach((contestOutcome: ContestOutcome) => {
          // console.log('subtopic . name :' + this.subtopic.name);
          // console.log('contest outcome. subtopicname :' + contestOutcome.subtopicName);
          if (contestOutcome.subtopicName === this._subtopic.name){
            // console.log('Found match');
            this._filteredOutcomes.push(contestOutcome);
          }
        });
      // this.retrieveSubtopicRankingList();
    });
  }


  onScroll(): void {
    const nextPage = this.userContestOutcomeService.paginatedContestOutcome.page.number + 1;
    this.userContestOutcomeService.getContestOutcomesForUser(this._userId,
      this._subtopic.id, this._tag.id, this._contestType,
      this.activeSubtopicRankingSet.timeAndGameDurationPeriod,
       nextPage)
      .subscribe((newContestOutcomes: PaginatedContestOutcome) => {
        if (newContestOutcomes?._embedded?.contestOutcomeDTOList?.length > 0 &&
          this.userContestOutcomeService.paginatedContestOutcome?._embedded?.contestOutcomeDTOList?.length > 0){
          this.userContestOutcomeService.paginatedContestOutcome._embedded.contestOutcomeDTOList
          .push(...newContestOutcomes._embedded.contestOutcomeDTOList);
          this.userContestOutcomeService.paginatedContestOutcome._links = newContestOutcomes._links;
          this.userContestOutcomeService.paginatedContestOutcome.page = newContestOutcomes.page;
          this._filteredOutcomes = [];
          if (this.userContestOutcomeService.paginatedContestOutcome.page.number
            === this.userContestOutcomeService.paginatedContestOutcome.page.totalPages){
            this.endOfContestOutcomes = true;
          }
          // console.log('Filtering outcomes');
          this.contestOutcomeList.forEach((contestOutcome: ContestOutcome) => {
          // console.log('subtopic . name :' + this.subtopic.name);
          // console.log('contest outcome. subtopicname :' + contestOutcome.subtopicName);
          if (contestOutcome.subtopicName === this._subtopic.name){
            // console.log('Found match');
            this._filteredOutcomes.push(contestOutcome);
          }
        });
        }
        else{
          this.endOfContestOutcomes = true;
        }
      });
  }

  retrieveSubtopicRankingList(): void {
    this.subtopicRankingService.
    getSubtopicRankingsForUserWithContestOutcomes(this._userId,
      this._subtopic.id, this._tag.id, this._contestType, this.timeframeType)
    .subscribe((paginatedSubtopicRanking: PaginatedSubtopicRanking) => {
      this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType = paginatedSubtopicRanking;
      this.subtopicRankingService.
      getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframe(this._userId,
        this._subtopic.id, this._tag.id, this._contestType, this.timeframeType,
        // tslint:disable-next-line:max-line-length
        this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList[0].timeAndGameDurationPeriod)
      .subscribe((paginatedSubtopicRanking2: PaginatedSubtopicRanking) => {
        // tslint:disable-next-line:max-line-length
        this.activeSubtopicRankingSet = this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList[0];
        this.userContestOutcomeService.paginatedSubtopicRankingAllForTimeframe = paginatedSubtopicRanking2;
        // tslint:disable-next-line:max-line-length
        this.retrieveContestOutcomeList(this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList[0].timeAndGameDurationPeriod);
      });

    });
  }

  viewAllSubtopicRankingsForTimeframe(subtopicRankingActiveForTimeframeType: SubtopicRanking): void{
    this.activeSubtopicRankingSet = subtopicRankingActiveForTimeframeType;
    this.subtopicRankingService.
    getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframe(this._userId,
      this._subtopic.id, this._tag.id, this._contestType, subtopicRankingActiveForTimeframeType.timeAndGameDurationPeriodType,
      subtopicRankingActiveForTimeframeType.timeAndGameDurationPeriod)
    .subscribe((paginatedSubtopicRanking2: PaginatedSubtopicRanking) => {
      this.userContestOutcomeService.paginatedSubtopicRankingAllForTimeframe = paginatedSubtopicRanking2;
      this.retrieveContestOutcomeList(subtopicRankingActiveForTimeframeType.timeAndGameDurationPeriod);
      this.updateSubtopicRankingPercentiles.emit(this.activeSubtopicRankingSet.timeAndGameDurationPeriod);
    });
  }

  get subtopicRankingsAllForTimeFrame(): SubtopicRanking[] {
    if (this.userContestOutcomeService.paginatedSubtopicRankingAllForTimeframe &&
      this.userContestOutcomeService.paginatedSubtopicRankingAllForTimeframe._embedded &&
      this.userContestOutcomeService.paginatedSubtopicRankingAllForTimeframe._embedded.subtopicRankingDTOList){
        return  this.userContestOutcomeService.paginatedSubtopicRankingAllForTimeframe._embedded.subtopicRankingDTOList;
      }
    return null;
  }

  get subtopicRankingsActiveForTimeframeType(): SubtopicRanking[]{
    if (this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType &&
      this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded &&
      this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList) {
    return  this.userContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList;
    }
    return null;
  }

  get contestOutcomeList(): ContestOutcome[]{
    if (this.userContestOutcomeService.paginatedContestOutcome
      && this.userContestOutcomeService.paginatedContestOutcome._embedded
      && this.userContestOutcomeService.paginatedContestOutcome._embedded.contestOutcomeDTOList){
        return this.userContestOutcomeService.paginatedContestOutcome._embedded.contestOutcomeDTOList;
      }
    return null;
  }

  getFilteredOutcomes(): ContestOutcome[]{
    this._filteredOutcomes = [];
    console.log('Filtering outcomes');
    if (this.subtopic && this.contestOutcomeList){
      this.contestOutcomeList.forEach((contestOutcome: ContestOutcome) => {
        console.log('subtopic . name :' + this.subtopic.name);
        console.log('contest outcome. subtopicname :' + contestOutcome.subtopicName);
        if (contestOutcome.subtopicName === this.subtopic.name){
          console.log('Found match');
          this._filteredOutcomes.push(contestOutcome);
        }
      });
      return this._filteredOutcomes;
    }
    return null;
  }

  get filteredContestOutcomes(): ContestOutcome[]{
    return this._filteredOutcomes;
  }

  viewContestOutcomesForSpecificContest(contestOutcome: ContestOutcome): void {
    this.userContestOutcomeService.getContestOutcomesForContest(contestOutcome.contestId)
    .subscribe((paginatedContestOutcome: PaginatedContestOutcome) => {
      this.paginatedContestOutcomesForSingleContest = paginatedContestOutcome;
    });
  }

  get contestOutcomesForSingleContest(): ContestOutcome[] {
    if (this.paginatedContestOutcomesForSingleContest){
      return this.paginatedContestOutcomesForSingleContest._embedded.contestOutcomeDTOList;
    }
    return null;
  }

  // todo -> this function ->  (click)="viewContestOutcomesForSpecificContest(contestOutcome)"
  // should be on a page by itself if user wants to see every outcome for every user for a specific contest
  // replacing with just viewing the contest for now
  viewContest(contestId: number): void{
    if (this.userId === -1){
      this.router.navigate([routingGlobals.absViewContest, contestId]);
    }
    else{
      this.router.navigate([routingGlobals.absViewContest, contestId],  { queryParams: { user: this.userId }});
    }
  }

}
