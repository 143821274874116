import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { BlogService } from '../media/blog/blog.service';
import { UserService } from '../profile/User/user.service';
import { CommentService } from '../media/blog/comment.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:ban-types
export class BlogAndAuthorDataResolverServiceService implements Resolve<Object> {

  constructor(private blogService: BlogService, private userService: UserService,
              private commentService: CommentService) { }
  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    // const userId = +route.paramMap.get('userId');
    // const blogId = +route.paramMap.get('blogId');
    const params = this.collectRouteParams([state.root]);
    const userId = +params.userId;
    const blogId = +params.blogId;

    const blog = this.blogService.getBlog(blogId).pipe(catchError(e => of(e)));
    const user = this.userService.getUser(userId);
    const comments = this.commentService.getCommentsForBlog(blogId);

    return forkJoin([user, blog, comments]);
  }

  collectRouteParams(stack: ActivatedRouteSnapshot[]): any {
    let params = {};
    // const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
    while (stack.length > 0) {
      // tslint:disable-next-line:no-non-null-assertion
      const route = stack.pop()!;
      params = {...params, ...route.params};
      stack.push(...route.children);
    }
    return params;
  }
}
