import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { SubtopicService } from 'src/app/topic/subtopic/subtopic.service';
import { TopicService } from 'src/app/topic/topic.service';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:ban-types
export class UserSubscriptionsResolverService implements Resolve<Object> {
 constructor(private topicService: TopicService,
             private subtopicService: SubtopicService) { }
  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    // const userId = +route.paramMap.get('userId');
    // tslint:disable-next-line:no-string-literal
    const params = this.collectRouteParams([state.root]);
    const userId = +params.userId;
    // const topics = this.topicService.getUserTopics(userId);
    // const subtopics = this.subtopicService.getUserSubtopics(userId);
    const topics = this.topicService.getTopics();
    const subtopics = this.subtopicService.getSubtopics();
    // const s: UrlSegment[] = route.
    // s[0].path; // returns 'team'
    // s[0].parameters; // returns {id: 33}
    console.log('in subscription resolver');
    console.log('user id is' + userId);
    console.log('route param keys are' + route.paramMap.keys);
    return forkJoin([topics, subtopics]);
  }

  collectRouteParams(stack: ActivatedRouteSnapshot[]): any {
    let params = {};
    // const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
    while (stack.length > 0) {
      // tslint:disable-next-line:no-non-null-assertion
      const route = stack.pop()!;
      params = {...params, ...route.params};
      stack.push(...route.children);
    }
    return params;
  }
}
