import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TopicService } from '../topic/topic.service';
import { SubtopicService } from '../topic/subtopic/subtopic.service';
import { ContestStateService, PaginatedUnderOverOutcomeRanking } from './contest-state.service';
import { GlobalContestState, UserContestState } from '../GlobalVars/Enums/ContestEnums';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import { Contest, ContestType, DurationType } from './game/game.service';
import { TopicsEntity, SubTopicsEntityOrOwnedSubtopicsEntity, PaginatedContest, UserService, PaginatedSubtopic } from '../profile/User/user.service';
import { Blog, Links, Page, PaginatedBlog } from '../media/media.component';
import { BlogService } from '../media/blog/blog.service';
import { SafeHtml } from '@angular/platform-browser';
import { Me, MeService } from '../profile/Me/me.service';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from '../Aggregation/subtopic-ranking.service';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { Tag } from '../guru/guru.component';
import {Clipboard} from '@angular/cdk/clipboard';


// declare var Stripe;

@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.css']
})
export class ContestComponent implements OnInit {
  afterInit = false;
  currentTopic: TopicsEntity;
  currentSubtopic;
  createContestFlag = false;
  enterExistingContestFlag = false;
  topicRankings: PaginatedUnderOverOutcomeRanking;
  subtopicRankings: PaginatedSubtopicRanking;
  // underOverSubtopicRankings: PaginatedSubtopicRanking;
  // marginOfErrorSubtopicRankings: PaginatedSubtopicRanking;
  underOverSubtopicRankingsMap: TimeAndGameDurationPeriodTypeAndPaginatedSubtopicRankingMap[] = [];
  marginOfErrorSubtopicRankingsMap: TimeAndGameDurationPeriodTypeAndPaginatedSubtopicRankingMap[] = [];
  GlobalContestState = GlobalContestState;
  paginatedBlogs: PaginatedBlog;
  canExcecuteRankingsNow = false;
  subtopicBlogSetMap: SubtopicPaginatedBlogMap[] = [];
  newPaginatedBlog: PaginatedBlog;
  upVotesPaginatedBlog: PaginatedBlog;
  mostViewedPaginatedBlog: PaginatedBlog;
  currentBlog: Blog;
  data: string;
  private _selectedAccounts: any[];
  allAccounts: any;
  topicIsSports = false;

  // stripe;
  isMobile = false;
  startDate: Date;
  endDate: Date;
  contestType: string;
  periodType: TimeAndGameDurationPeriodType;
  currentTag: Tag = {id: -1, name: 'mike'};
  subtopicRankingsErrorMessage: string;
  topicSelectedIsSports: boolean;


  constructor(private router: Router,
              private activatedRoute: ActivatedRoute, private topicService: TopicService,
              private subtopicService: SubtopicService, private contestStateService: ContestStateService,
              private route: ActivatedRoute,
              private blogService: BlogService, private userService: UserService,
              private subtopicRankingService: SubtopicRankingService, private httpClient: HttpClient,
              private meService: MeService, private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.createDatesOnInit();
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    this.contestType =  'UNDER_OVER';

    // tslint:disable-next-line:no-shadowed-variable
    const contest: Contest = {
      topicReference: null,
      subtopicReference: null,
      name: '',
      games: [],
      maxUsers: 100000,
      globalContestState: GlobalContestState.NONE,
      userContestState: UserContestState.NONE,
      contestType: ContestType.UNDER_OVER,
      durationType: DurationType.ALL
    };
    this.contestStateService.viewNonEnteredUpcomingContest(contest);
    this.activatedRoute.data.subscribe((response: any) => {
      this.topicService.allTopics = response.topicData;
    });
    this.topicService.allTopics._embedded.topicDTOList.forEach((topic: TopicsEntity) => {
      if (topic.name === 'Finance'){
        this.selectTopic(topic);
      }
    });
  }

  isSelected(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): boolean {
    return this.subtopicService.currentSubtopic === subtopic;
  }

  get contests(): any {
    return this.contestStateService.contests;
  }

  flipContestType(contestType: string): void {
    this.contestType = contestType;
    this.getSubtopicRankings(contestType, this.periodType, this.subtopicService.currentSubtopic);
    if (contestType === 'UNDER_OVER'){
      this.contestType = 'UNDER_OVER';
      this.subtopicRankings = null;
      let foundMatch = false;
      this.underOverSubtopicRankingsMap.forEach((rankingSet) => {
        if (rankingSet.timeAndGameDurationPeriodType === this.periodType){
          this.subtopicRankings = rankingSet.subtopicRankings;
          foundMatch = true;
          return;
        }
      });
      if (!foundMatch){
        this.subtopicRankings = null;
      }
    }
    else if (contestType === 'MARGIN_OF_ERROR'){
      this.contestType = 'MARGIN_OF_ERROR';
      let foundMatch = false;
      this.subtopicRankings = null;
      this.marginOfErrorSubtopicRankingsMap.forEach((rankingSet) => {
        if (rankingSet.timeAndGameDurationPeriodType === this.periodType){
          this.subtopicRankings = rankingSet.subtopicRankings;
          foundMatch = true;
          return;
        }
      });
      if (!foundMatch){
        this.subtopicRankings = null;
      }
    }
  }

  flipPeriodType(periodType: string): void {

    if (periodType === 'MONTH'){
      this.periodType = TimeAndGameDurationPeriodType.MONTH;
      this.getSubtopicRankings(this.contestType, TimeAndGameDurationPeriodType.MONTH,  this.subtopicService.currentSubtopic);
    }
    else if (periodType === 'YEAR'){
      this.periodType = TimeAndGameDurationPeriodType.YEAR;
      this.getSubtopicRankings(this.contestType, TimeAndGameDurationPeriodType.YEAR,  this.subtopicService.currentSubtopic);
    }

    if (this.contestType === 'UNDER_OVER'){
      let foundMatch = false;
      this.underOverSubtopicRankingsMap.forEach((rankingSet) => {
        if (rankingSet.timeAndGameDurationPeriodType === this.periodType){
          this.subtopicRankings = rankingSet.subtopicRankings;
          foundMatch = true;
          return;
        }
      });
      if (!foundMatch){
        this.subtopicRankings = null;
      }
    }
    else if (this.contestType === 'MARGIN_OF_ERROR'){
      let foundMatch = false;
      this.marginOfErrorSubtopicRankingsMap.forEach((rankingSet) => {
        if (rankingSet.timeAndGameDurationPeriodType === this.periodType){
          this.subtopicRankings = rankingSet.subtopicRankings;
          foundMatch = true;
          return;
        }
      });
      if (!foundMatch){
        this.subtopicRankings = null;
      }
    }
  }



  createContest(): void {
    this.createContestFlag = true;
    this.enterExistingContestFlag = false;
    // // todo -> create shell class that isnt itselt and run this function in there
    // const modalRef = this.modalService.open(CreateContestModalComponent, { centered: true,  size: 'lg'});
    // modalRef.componentInstance.name = 'World1';
    this.router.navigate([routingGlobals.createFinancialContestModal], {relativeTo: this.route});
  }

  get  isTopicSelectedSports(): boolean {
    return this.topicService.currentTopic.name.toUpperCase() === 'SPORTS';
  }

  selectTopic(topic: TopicsEntity): void {
    this.topicService.currentTopic = topic;
    this.contestStateService.currentContest.topicReference = {    id:  this.topicService.currentTopic.id,
      name: this.topicService.currentTopic.name};
    this.currentTopic = topic;
    this.getTopicRankings(topic);
    if (this.currentTopic.name.toUpperCase() === 'SPORTS'){
      this.contestType = 'TOTAL_POINTS_SCORED';
      this.topicSelectedIsSports = true;
    }
    else{
      this.contestType = 'MARGIN_OF_ERROR';
      this.topicSelectedIsSports = false;
    }
    // this.selectSubtopic(topic.subtopics[0]);
  }

  // todo -> needs to be an event listened to from child component
  selectSubtopic(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
    this.subtopicService.currentSubtopic = subtopic;
    this.currentSubtopic = subtopic;


    this.contestStateService.currentContest.subtopicReference = {    id: this.subtopicService.currentSubtopic.id,
      name: this.subtopicService.currentSubtopic.name};
    this.currentSubtopic = subtopic;
    this.subtopicBlogSetMap.forEach(item => {
      if (item.subtopic.id === subtopic.id){
        this.paginatedBlogs = item.paginatedBlog;
      }
    });
    this.flipPeriodType('YEAR');
    // this.fetchAllBlogs();
  }

  selectTagTrigger(tag: Tag): void {
    if (this.currentTag && this.currentTag.name === tag.name && this.afterInit){
      return;
    }
    this.currentTag = tag;

    this.contestStateService.currentContest.subtopicReference = {    id: this.subtopicService.currentSubtopic.id,
      name: this.subtopicService.currentSubtopic.name};
    this.currentSubtopic =  this.subtopicService.currentSubtopic;
    this.subtopicBlogSetMap.forEach(item => {
      if (item.subtopic.id ===  this.subtopicService.currentSubtopic.id){
        this.paginatedBlogs = item.paginatedBlog;
      }
    });
    this.flipPeriodType('YEAR');
    this.fetchAllBlogsWithTagId();

  }

  selectSubtopicTrigger(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
    if (this.subtopicService.currentSubtopic && this.afterInit &&
      this.subtopicService.currentSubtopic.name === subtopic.name){
      return;
    }
    this.subtopicService.currentSubtopic = subtopic;
    this.currentSubtopic = subtopic;
    this.subtopicService.currentSubtopic = subtopic;
    if (this.currentTopic.name.toUpperCase() === 'SPORTS'){
      this.contestType = 'TOTAL_POINTS_SCORED';
      this.topicSelectedIsSports = true;
    }
    else{
      this.contestType = 'MARGIN_OF_ERROR';
      this.topicSelectedIsSports = false;
    }
    this.currentTag = {id: -1, name: this.subtopicService.currentSubtopic.name};

    this.contestStateService.currentContest.subtopicReference = {    id: this.subtopicService.currentSubtopic.id,
      name: this.subtopicService.currentSubtopic.name};
    this.currentSubtopic =  this.subtopicService.currentSubtopic;
    this.subtopicBlogSetMap.forEach(item => {
      if (item.subtopic.id ===  this.subtopicService.currentSubtopic.id){
        this.paginatedBlogs = item.paginatedBlog;
      }
    });
    this.flipPeriodType('YEAR');
    // fetch all blogs will just be called via tag trigger which comes after this
    // this.fetchAllBlogs();
  }

  topicSelected(): boolean{
    if ( this.contestStateService.currentContest && this.contestStateService.currentContest.topicReference){
      return true;
    }
    return false;
  }
  subtopicSelected(): boolean{
    // tslint:disable-next-line:max-line-length
    if ( this.contestStateService.currentContest && this.contestStateService.currentContest.subtopicReference && this.canExcecuteRankingsNow ){
      return true;
    }
    return false;
  }

get allTopics(): TopicsEntity[]{
  return this.topicService.allTopics._embedded.topicDTOList;
}

  getTopicRankings(topic: TopicsEntity): void{
    this.contestStateService.currentContest.topicReference = null;
    this.topicService.currentTopic = topic;
    this.contestStateService.currentContest.topicReference = {id: topic.id, name: topic.name};
  }

  getSubtopicRankings(contestType: string, timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                      subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void{
    this.contestStateService.currentContest.subtopicReference = null;
    this.underOverSubtopicRankingsMap = [];
    this.marginOfErrorSubtopicRankingsMap = [];
    this.subtopicRankingsErrorMessage = '';
    this.subtopicRankingService.getSubtopicRankings(subtopic.id,  contestType, timeAndGameDurationPeriodType, this.currentTag.id)
    .subscribe((subtopicRankings: PaginatedSubtopicRanking) => {
      if (contestType === 'UNDER_OVER'){
        this.underOverSubtopicRankingsMap.push({timeAndGameDurationPeriodType, subtopicRankings});
      }
      else if (contestType === 'MARGIN_OF_ERROR'){
        this.marginOfErrorSubtopicRankingsMap.push({timeAndGameDurationPeriodType, subtopicRankings});
      }
      this.subtopicRankings =  subtopicRankings;
      // this.contestType = contestType;
      this.subtopicService.currentSubtopic = subtopic;
      this.contestStateService.currentContest.subtopicReference = {id: subtopic.id, name: subtopic.name};
      this.canExcecuteRankingsNow = true;
    },
    err =>
     this.subtopicRankingsErrorMessage =  err.error + ' for: ['
    + this.contestType + '][' + this.tag.name + '][' + this.periodType + '] ');

  }

  get blogs(): Blog[]{
    if (this.paginatedBlogs && this.paginatedBlogs._embedded && this.paginatedBlogs._embedded.blogPreviewDTOList){
      return this.paginatedBlogs._embedded.blogPreviewDTOList;
    }
    return null;
  }

  get newBlogs(): Blog[]{
    if (this.newPaginatedBlog && this.newPaginatedBlog._embedded &&  this.newPaginatedBlog._embedded.blogPreviewDTOList){
      return this.newPaginatedBlog._embedded.blogPreviewDTOList;
    }
    return null;
  }

  get upVotedBlogs(): Blog[]{
    if (this.upVotesPaginatedBlog && this.upVotesPaginatedBlog._embedded && this.upVotesPaginatedBlog._embedded.blogPreviewDTOList){
      return this.upVotesPaginatedBlog._embedded.blogPreviewDTOList;
    }
    return null;
  }

  get mostViewedBlogs(): Blog[]{
    if (this.mostViewedPaginatedBlog && this.mostViewedPaginatedBlog._embedded
      && this.mostViewedPaginatedBlog._embedded.blogPreviewDTOList){
      return this.mostViewedPaginatedBlog._embedded.blogPreviewDTOList;
    }
    return null;
  }

  fetchAllBlogs(): void {
    this.fetchMostViewedBlogsBySubtopic();
    this.fetchMostUpvotedBlogsBySubtopic();
    // this.fetchNewBlogsBySubtopic();
  }

  fetchAllBlogsWithTagId(): void {
    this.fetchMostViewedBlogsBySubtopicAndTagId();
    this.fetchMostUpvotedBlogsBySubtopicAndTagId();
    // this.fetchNewBlogsBySubtopic();
  }

  fetchMostUpvotedBlogsBySubtopic(): void {
    this.blogService.getBlogsForSubtopicsWithFiltersNEW
    (this.currentSubtopic.id, this.startDate, this.endDate, 'byUpvotes', -1).subscribe((result: PaginatedBlog) => {
     this.upVotesPaginatedBlog = result;
    });
  }


  fetchMostUpvotedBlogsBySubtopicAndTagId(): void {
    this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
    (this.currentSubtopic.id, this.startDate, this.endDate, 'byUpvotes', this.tag).subscribe((result: PaginatedBlog) => {
     this.upVotesPaginatedBlog = result;
    });
  }

  // fetchNewBlogsBySubtopic(): void {
  //   this.blogService.getBlogsForSubtopicsWithFiltersNEW
  //  (this.currentSubtopic.id, this.startDate, this.endDate, 'byViewCount', -1).subscribe((result: PaginatedBlog) => {
  //   this.mostViewedPaginatedBlog = result;
  //  });
  // }

    fetchMostViewedBlogsBySubtopic(): void {
      this.blogService.getBlogsForSubtopicsWithFiltersNEW
   (this.currentSubtopic.id, this.startDate, this.endDate, 'byViewCount', -1).subscribe((result: PaginatedBlog) => {
    this.mostViewedPaginatedBlog = result;
   });
  }

  fetchMostViewedBlogsBySubtopicAndTagId(): void {
    this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
 (this.currentSubtopic.id, this.startDate, this.endDate, 'byViewCount', this.tag).subscribe((result: PaginatedBlog) => {
  this.mostViewedPaginatedBlog = result;
 });
}

  createDatesOnInit(): void{
    const endDate = new Date();
    const dateOffset = (24 * 60 * 60 * 1000) * 30; // 30 days
    const startDate = new Date();
    startDate.setTime(startDate.getTime() - dateOffset);
    startDate.setHours(0);
    startDate.setMinutes(0);
    this.startDate = startDate;
    this.endDate = endDate;
  }


  selectCurrentBlog(blog: Blog): void{
    this.currentBlog = null;
    this.currentBlog = blog;
    this.blogService.currentBlog = blog;
    this.data = blog.contentString;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    this.data = this.toHTML(this.data);
    this.viewBlog(this.data);
    // this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(this.data));
  }
  viewUserProfile(blogOwnerId: number): void{
    this.userService.getUser(blogOwnerId).subscribe((user: Me) => {
      this.userService.user = user;
      this.userService.id = user.id;
      this.router.navigate([routingGlobals.absUser, blogOwnerId]);
    });
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
}

  viewBlog(blogHtml: SafeHtml): void{
    this.blogService.blogSafeHtml = blogHtml;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    this.router.navigate(['../' + routingGlobals.media + '/' + routingGlobals.dynamicBlog, this.currentBlog.id,
    'author', this.currentBlog._owner_id], {relativeTo: this.route});
    // this.router.navigate(['../' + routingGlobals.media + '/' + routingGlobals.dynamicBlog], {relativeTo: this.route});
  }

  // createConnectedAccount(): void{
  //   // tslint:disable-next-line:max-line-length
  //   this.httpClient.get(HOST_IP_INFO_MARKETS +
  // `/create-checkout-session-for-account/${this.meService.user.stripeConnectedAccountId}`).subscribe(
  //     (data: any) => {
  //       window.location.href = data.url;
  //   });
  // }

  get tag(): Tag{
    return this.currentTag;
  }
}

export interface SubtopicPaginatedBlogMap{
  subtopic: SubTopicsEntityOrOwnedSubtopicsEntity;
  paginatedBlog: PaginatedBlog;
}

export interface TimeAndGameDurationPeriodTypeAndPaginatedSubtopicRankingMap {
  timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType;
  subtopicRankings: PaginatedSubtopicRanking;
}

export interface PaginatedSubtopicRanking{
  _embedded: EmbeddedSubtopicRanking;
  _links: Links;
  page: Page;
}

export interface EmbeddedSubtopicRanking{
  subtopicRankingDTOList?: SubtopicRanking[];
}

export interface EmbeddedContestOutcomeRanking{
  contestOutcomeRankingDTOList?: ContestOutcomeRanking[];
}


export interface SubtopicRanking {
  eloValue?: number;
  id?: number;
  owner_id?: number;
  ranking?: number;
  subtopicName?: string;
  timestampLastUpdated?: number;
  username?: string;
  timeAndGameDurationPeriodType?: TimeAndGameDurationPeriodType;
  timeAndGameDurationPeriod?: string;
  totalGameLosses?: number;
  totalGameWins?: number;
  totalH2HLosses?: number;
  totalH2HWins?: number;
  totalH2HDraws?: number;
  activeStartTime?: string;
  contestType?: ContestType;
  percentileRank?: number;
  tagId?: number;
  tagName?: number;
}

export interface SubtopicAggregationItem {

  id?: number;


  numberOfUsersPaid?: number;
  totalAmountPaidOut?: number;
  numberOfSubscriber?: number;
  numberOfContestsPlayed?: number;
  numberOfArticlesWritten?: number;
  numberOfUpvotes?: number;
  avgNumberOfArticlesPerUser?: number;
  subtopicName?: string;
}

export interface ContestOutcomeRanking {
  eloValue?: number;
  owner_id?: number;
  ranking?: number;
  subtopicName?: string;
  timestampLastUpdated?: number;
  totalLosses?: number;
  totalWins?: number;
  username?: string;
  percentileRank?: number;
  startingElo?: number;
}

export interface PaginatedContestOutcomeRanking{
  _embedded: EmbeddedContestOutcomeRanking;
  _links: Links;
  page: Page;
}
