import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
  name: 'phone',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneValue: string): any {
    if (!!!phoneValue){
      return;
    }
    const stringPhone = phoneValue + '';
    const phoneNumber = parsePhoneNumber(stringPhone, 'US');
    const formatted = phoneNumber.formatNational();
    return formatted;
  }
}
