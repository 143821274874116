import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubtopicRanking, SubtopicAggregationItem } from '../contest/contest.component';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { MeService } from '../profile/Me/me.service';
import { TimeAndGameDurationPeriodType } from './subtopic-ranking.service';
import { Time } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class SubtopicAggregateItemService {
  constructor(private httpClient: HttpClient, private meService: MeService) { }


  public getSubtopicAggregationItem(subtopicId: number,
                                    timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType
     = TimeAndGameDurationPeriodType.YEAR ): Observable<SubtopicAggregationItem> {
      return this.httpClient.get(HOST_IP_INFO_MARKETS
        + `/subtopicAggregateItem/subtopic/${subtopicId}?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`);
}
}
