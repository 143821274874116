<div class="container">
  <div class="row">
    <div
      id="loginbox"
      style="margin-top: 50px"
      class="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
    >
      <div class="panel panel-info">
        <div class="panel-heading">
          <!-- <div class="panel-title font-weight-light">Sign In</div> -->
          <div
            style="float: right; font-size: 80%; position: relative; top: -10px"
            class="font-weight-light"
          >
            <a href="javascript:void(0)" (click)="goToForgotPassword()"
              >Forgot password?</a
            >
          </div>
        </div>

        <div style="padding-top: 30px" class="panel-body">
          <div
            style="display: none"
            id="login-alert"
            class="alert alert-danger col-sm-12"
          ></div>

          <form
            id="loginform"
            class="form-horizontal"
            role="form"
            (ngSubmit)="onLogin(f)"
            #f="ngForm"
          >
            <div style="margin-bottom: 25px" class="input-group">
              <span class="input-group-addon"
                ><i class="glyphicon glyphicon-user"></i
              ></span>
              <input
                id="login-username"
                type="text"
                class="form-control"
                ngModel
                name="email"
                required
                placeholder="Enter your email or username"
              />
            </div>

            <div style="margin-bottom: 25px" class="input-group">
              <span class="input-group-addon"
                ><i class="glyphicon glyphicon-lock"></i
              ></span>
              <input
                id="login-password"
                type="password"
                class="form-control"
                ngModel
                name="password"
                required
                placeholder="password"
              />
            </div>

            <div style="margin-top: 10px" class="form-group">
              <div class="col-sm-12 controls">
                <button
                  class="btn-sm btn-secondary font-weight-light"
                  [disabled]="!f.valid"
                  type="submit"
                >
                  Login
                </button>
              </div>
            </div>

            <div class="form-group">
              <div class="col-md-12 control">
                <div
                  style="
                    border-top: 1px solid#888;
                    padding-top: 15px;
                    font-size: 85%;
                  "
                  class="font-weight-light"
                >
                  Don't have an account!
                  <a
                    class="nav-item nav-link font-weight-light"
                    routerLinkActive="active"
                    routerLink="/signup"
                  >
                    Sign Up Here</a
                  >
                </div>
                <a
                  class="nav-item nav-link font-weight-light"
                  routerLinkActive="active"
                  routerLink="/privacy-policy"
                >
                  View Privacy Policy Here</a
                >
              </div>
            </div>
          </form>

          <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="margin-top: 50px">
      <img
        class="w-100 h-100"
        src="/assets/SigSookLogo_from_Looka.png"
        alt="image"
      />
    </div>
  </div>
</div>
<div *ngIf="!isProduction">
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <ul>
    <li>
      <div class="col-sm">
        <button (click)="createUser()">create user</button>
      </div>
    </li>
    <li>
      <div class="col-sm">
        <button (click)="loginUser()">login user</button>
      </div>
    </li>
    <li>
      <div class="col-sm">
        <button (click)="loginUser2()">login user 2</button>
      </div>
    </li>
    <li>
      <div class="col-sm">
        <button (click)="getEnteredContests()">getEnteredContests</button>
      </div>
    </li>
  </ul>
</div>
