import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { SubTopicsEntityOrOwnedSubtopicsEntity, PaginatedSubtopic } from 'src/app/profile/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class SubtopicService {
  allSubtopics: PaginatedSubtopic;
  currentSubtopic: SubTopicsEntityOrOwnedSubtopicsEntity = null;

  constructor(private httpClient: HttpClient) { }

  getUserSubtopics(userId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/user/${userId}/subtopic` );
  }

  getSubtopics(): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/subtopic` );
  }

  getMySubtopics(): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/me/subtopic` );
  }

  subscribeToSubtopic(subtopicId: number): Observable<any>{
    const data = {};
    return this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/subtopic?subtopicId=${subtopicId}`, data);
  }
}
