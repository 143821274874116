import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { NgForm } from '@angular/forms';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import * as localStore from '../GlobalVars/localStorageVars';
import { AuthJavaService } from '../shared/auth-java.service';
import { ContestService } from '../contest/contest.service';
import { ContestStateService } from '../contest/contest-state.service';
import { MeService, MyUserDetails } from '../profile/Me/me.service';
import { PaginatedContest } from '../profile/User/user.service';
import { StorageService } from '../shared/storage.service';
import * as moment from 'moment-timezone';
import { ContestType, DurationType } from '../contest/game/game.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  _error: string;
  isLoggedIn = false;
  isLoginFailed = false;

  constructor(
    private router: Router,
    private meService: MeService,
    private authJavaService: AuthJavaService,
    private contestService: ContestService,
    private contestStateService: ContestStateService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    // console.log(new Date().getTimezoneOffset() + ' is time offest');
    // console.log(new Date().getUTCDate() + ' is date');
    // console.log(new Date().getUTCHours() + ' is hours');
    // console.log(new Date().getUTCDay() + ' is day');
    // console.log(new Date().getTime());
    // const from = new Date();
    // const date = moment(from).format('Z');

    // const timezone = moment.tz(moment.tz.guess()).format('z');
    // console.log(timezone);
    // this.meService.currentTimeZone = timezone;

    if (this.storageService.isLoggedIn()){
      this.isLoggedIn = true;
      const myUserDetails =  this.storageService.getUser();
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
      this.router.navigate([routingGlobals.absProfile]);
    }

  }

  onLogin(form: NgForm): void {
    const email = form.value.email;
    const regexEmail = new RegExp('^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$');
    const regexAlphaNumeric = new RegExp('([A-Za-z0-9\-\_]+)');
    if (!regexAlphaNumeric.test(email) && !regexEmail.test(email)){
      this._error = 'Must enter email or username';
      return;
    }
    const password = form.value.password;
    // const username = form.value.email;
    this.authJavaService.signInUserWithEmail(email, password).subscribe((myUserDetails: MyUserDetails) => {
      this.storageService.saveUser(myUserDetails);
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
      if (this.meService.jwt == null && !!!this.meService.user.isVerifiedByOTP){
        this.router.navigate([routingGlobals.absVerifyAccount]);
      }
      else{
        this.router.navigate([routingGlobals.absProfile]);
      }

    },
    error => {
      console.log('error');
      this._error = error.error.message;
    });
  }

  get error(): string {
    return this._error;
  }

  goToForgotPassword(): void{
    this.router.navigate([routingGlobals.absUsernameForPassword]);
  }
  getEnteredContests(): void{
    this.contestService.getContests({type: 'userCompetitor', tagId: -1, topicId: -1,
    durationType: DurationType.ALL, contestType: ContestType.ALL,
     page: 0, atomicGameName: 'ALL'}).subscribe((result: PaginatedContest) => {
      this.contestStateService.setEnteredContestsOnLogin(result);
    });
  }

  loginUser2(): void{
    console.log('autologin for now');
    const username = 'KingPhillip';
    const email = 'phil@gmail.com';
    const password = 'test1234';
    this.authJavaService.signInUserWithUsername(username, password).subscribe((myUserDetails: MyUserDetails) => {
      this.meService.jwt = myUserDetails.jwt;
      this.meService.user.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
    },
    error => {
      console.log('error');
    });
  }

  loginUser(): void{
    console.log('autologin for now');
    const username = 'BillyBobThortonFan4';
    const email = 'bob@gmail.com';
    const password = 'test1234';
    this.authJavaService.signInUserWithUsername(username, password).subscribe((myUserDetails: MyUserDetails) => {
      this.storageService.saveUser(myUserDetails);
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
    },
    error => {
      console.log('error');
    });
  }
  createUser(): void {
    let email = 'test@gmail.com';
    let password = 'test1234';
    let firstname = 'mike';
    let lastname = 'tomk';
    let phoneNumber = '732-552-5928';
    let username = 'testusername';

    let data = { username: email, password: password };
    this.authJavaService.signupUser(email, password, phoneNumber, username).subscribe(() => {
      email = 'test1@gmail.com';
      password = 'test1234';
      firstname = 'bob';
      lastname = 'smith';
      phoneNumber = '732-552-5928';
      username = 'testusername';

      data = { username: email, password: password };
      this.authJavaService.signupUser(email, password, phoneNumber, username).subscribe((dataJwt: any) => {
        localStorage.setItem(localStore.firstname, firstname);
        localStorage.setItem(localStore.lastname, lastname);
        localStorage.setItem(localStore.email, email);
        this.meService.accountInfo = {
          displayName: firstname + ' ' + lastname,
          email: email,
          phoneNumber: phoneNumber
        };
        this.meService.jwt = dataJwt.jwt;
        this.meService.id = dataJwt.id;
        this.router.navigate([routingGlobals.absSignupPaymentInfo]);
      });
    },
    error => {
      console.log('error on sign up');
    });
  }

  get isProduction(): boolean{
    return environment.production;
  }
}
