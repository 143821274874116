import { Component, OnInit } from '@angular/core';

import { MeService } from '../me.service';


@Component({
  selector: 'app-your-contests',
  templateUrl: './your-contests.component.html',
  styleUrls: ['./your-contests.component.css']
})
export class YourContestsComponent implements OnInit {

  endOfContests = false;
  isMobile: boolean;

  constructor(private meService: MeService) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
  }
    get userId(): number{
      return this.meService.id;
    }

}

