import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { PaginatedTopic, TopicsEntity } from '../profile/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  allTopics: PaginatedTopic;
  currentTopic: TopicsEntity = null;
  constructor(private httpClient: HttpClient) { }

  // tslint:disable-next-line:ban-types
  getMyTopics(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/me/topic`);
  }

  getUserTopics(userId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/user/${userId}/topic`);
  }

  getTopics(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/topic`);
  }

  subscribeToTopic(topicId: number): Observable<any>{
    const data = {};
    return this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/topic?topicId=${topicId}`, data);
  }

}
