import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeAndGameDurationPeriodType } from 'src/app/Aggregation/subtopic-ranking.service';
import { UserContestState, GlobalContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { StorageService } from 'src/app/shared/storage.service';
import { ContestStateService } from '../../contest-state.service';
import { Game, ContestType } from '../../game/game.service';
import { EnterContestModalContentComponent } from '../enter-contest-modal-content/enter-contest-modal-content.component';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { FootballGameService } from '../football-game.service';

@Component({
  selector: 'app-total-points-scored-live',
  templateUrl: './total-points-scored-live.component.html',
  styleUrls: ['./total-points-scored-live.component.css']
})
export class TotalPointsScoredLiveComponent implements OnInit {

  @Input()
  games: Game[];
  @Input()
  containsOtherUserBetslip = false;
  @Input()
  isMe: boolean;

  UserContestState = UserContestState;

  ContestType = ContestType;

  TimeAndGameDurationPeriodType = TimeAndGameDurationPeriodType;
  loadedGames = false;

  GlobalContestState = GlobalContestState;
  constructor(public contestStateService: ContestStateService, private storageService: StorageService,
              private modalService: NgbModal,  private router: Router, private footballGameService: FootballGameService ) { }

  ngOnInit(): void {
    this.footballGameService.getSportsGamesForContest(this.contestStateService.currentContest.id).subscribe(result => {
      this.footballGameService.sportsGames = result;
      this.footballGameService.sportsGames.forEach(collegeFootballGame => {
        this.contestStateService.currentContest.games.forEach(game => {
          if (collegeFootballGame.event_id === game.sportsEventId){
            game.sportsGame = collegeFootballGame;
            if (!!!game.sportsGame.home_score){
              game.sportsGame.home_score = 0;
            }
            if (!!!game.sportsGame.away_score){
              game.sportsGame.away_score = 0;
            }
            return;
          }
        });
      });
      this.loadedGames = true;
    }, err => {
      this.contestStateService.currentContest.games.forEach(game => {
          game.sportsGame = {home_score: -1, away_score: -1};
        });
      this.loadedGames = true;
    }) ;
  }

  get currentContest(): any {
    return this.contestStateService.currentContest;
  }
  get userContestState(): UserContestState{
    if (this.contestStateService && this.contestStateService.currentContest && this.contestStateService.currentContest.userContestState){
      return this.contestStateService.currentContest.userContestState;
    }

  }
  get isLoggedIn(): boolean {
    return this.storageService.isLoggedIn();
  }
  get contestSubtopic(): any{
    return this.contestStateService.currentContest.subtopicReference;
  }
  canSubmitContestEntry(): boolean {

      let canSubmitEntry = true;
      this.games.forEach((game: Game) => {
        if (game.betSlip.predictedValue < 0){
          canSubmitEntry = false;
          return;
        }
      });
      if (this.contestStateService.currentContest.globalContestState !== GlobalContestState.UPCOMING){
        return false;
      }
      const contestStartInMs = new Date(this.contestStateService.currentContest.startTime).getTime();
      const currentTimeInMs = new Date().getTime();
      const ms = contestStartInMs - currentTimeInMs ;
      if (ms < 0){
        // contest has started
        return false;
      }
      return canSubmitEntry;

  }

  selectUnderOver(selection: string, game: Game): void{

    const betSlip = game.betSlip;
    if (selection === 'over'){
      betSlip.over = true;
      betSlip.under = false;
      game.betSlip = betSlip;
    }
    else{
      betSlip.over = false;
      betSlip.under = true;
      game.betSlip = betSlip;
    }
  }

  enterContest(): void{
    const modalRef = this.modalService.open(EnterContestModalContentComponent, { centered: true,  size: 'lg'});
    modalRef.componentInstance.name = 'World';
  }

  linkToSignUpPage(): void{
    this.router.navigate([routingGlobals.absSignup]);
  }
  updateNameOfContest(prediction: number, game: Game ): void{
    if (!prediction){
      prediction = -1000;
    }
    const betSlip = game.betSlip;

    betSlip.predictedValue = prediction;
    game.betSlip = betSlip;

  }

  getProjectedTpsError(game: Game): number{
    if (game.sportsGame.status !== 'NS'){
      const projectedTps = game.sportsGame.home_score + game.sportsGame.away_score;
      const projectedTpsError = Math.abs(projectedTps - game.betSlip.predictedValue);
      return projectedTpsError;
    }
  }
}
