import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';

@Injectable({
  providedIn: 'root'
})
export class AuthJavaService {

  constructor(private httpClient: HttpClient) { }

  signupUser(
    username: string,
    email: string,
    password: string,
    // firstname: string,
    // lastname: string,
    // phoneNumber: string,
    inviterIdToken: string = null
  ): Observable<any> {
    const data = { username, email, password };
    if (!!!inviterIdToken){
      return this.httpClient.post(HOST_IP_INFO_MARKETS + '/addAndLogin', data);
    }
    else{
      return this.httpClient.post(HOST_IP_INFO_MARKETS + `/addAndLogin?inviterIdToken=${inviterIdToken}`, data);
    }

  }

  signInUserWithUsername(username: string, password: string): Observable<any> {
    const data = { password, username };
    return this.httpClient.post(HOST_IP_INFO_MARKETS + '/login', data);
  }

  signInUserWithEmail(email: string, password: string): Observable<any> {
    const data = { password, email };
    return this.httpClient.post(HOST_IP_INFO_MARKETS + '/login-with-email', data);
  }
}
