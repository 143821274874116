import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbCalendar, NgbCarouselConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameService } from '../../game/game.service';
import { ContestService } from '../../contest.service';
import { ContestStateService } from '../../contest-state.service';

@Component({
  selector: 'app-create-financial-game',
  templateUrl: './create-financial-game.component.html',
  styleUrls: ['./create-financial-game.component.css']
})
export class CreateFinancialGameComponent implements OnInit {
  // gameTypes = [  'StraightSelection', 'MarginOfError', 'CombinedDAndWeightedMarginOfError', 'WeightedStraightSelection'];
  selectedStock: StockData;
  availableStocks: StockData[] = [];
  betSlip: any;
  bettingValue = 0;
  _fieldToBetOn = '';
  currentValueOfField = 0;
  searchText = '';
  currentGameType: any;
  currentDate = new Date();
  gameTypes = [ {type: 'StraightSelection', name: 'StraightSelectionGame',
  description: 'select a field to compete on. Then select under or over with a start and end time'},
  {type: 'MarginOfError', name: 'MarginOfErrorGame',
  description: 'select a field to compete on. Then guess a future value and a time when the game will finsish. Weight will always be 1'},
  {type: 'WeightedMarginOfError',  name: 'WeightedMarginOfErrorGame',
   description: 'Same as margin of error, but you can assign a ' +
  'weight of any value to this guess Ie make this game worth 2x or.5x?? should user be allowed to assign own weight in series of picks? I think so... add that in later'},
  {type: 'WeightedStraightSelection', name: 'WeightedStraightSelectionGame', description: 'self explanatory'}];
  endModel: NgbDateStruct;
  startModel: NgbDateStruct;
  today = this.calendar.getToday();
  tomorrow = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
  time = {hour: new Date().getHours() + 12, minute: 30};
  meridian = true;

  isDateSelection = false;
  isStockSelection = false;
  isNaming = false;



  constructor(private calendar: NgbCalendar,
              private gameService: GameService,
              private contestStateService: ContestStateService) {

               }

  ngOnInit(): void {
    // this.httpClient.get
    // ('https://api.polygon.io/v1/open-close/SPY/2020-10-13?adjusted=true&apiKey=satA4eyPu6b5RoLHOctuGa6yU8uFJk6b')
    // .subscribe((data: StockData) => {
    //   this.selectedStock = data;
    // });
    const stock1 = {
      afterHours: 322.1,
      close: 325.12,
      high: 326.2,
      low: 322.3,
      open: 324.66,
      preMarket: 324.5,
      status: 'OK',
      symbol: 'AAPL',
      volume: 26122646
     };

    const stock2 = {
      afterHours: 26.93,
      close: 26.93,
      high: 26.93,
      low: 26.93,
      open: 26.93,
      preMarket: 26.93,
      status: 'OK',
      symbol: 'FSSNX',
      volume: 26122646
     };

    const stock3 = {
      afterHours: 467.90,
      close: 	467.61,
      high: 	472.87,
      low: 322.3,
      open: 	472.60,
      preMarket: 467.90,
      status: 'OK',
      symbol: 'SPY',
      volume: 2000000
     };

    const stock4 = {
      afterHours: 322.1,
      close: 325.96,
      high: 325.96,
      low: 322.3,
      open: 320.06,
      preMarket: 324.5,
      status: 'OK',
      symbol: 'MSFT',
      volume: 500000
     };

    this.availableStocks.push(stock1);
    this.availableStocks.push(stock2);
    this.availableStocks.push(stock3);
    this.availableStocks.push(stock4);
    // this.selectedStock = stock1;
  }

  selectFieldToBetOn(fieldToBeBetOn: string, currentValueOfField: number): void{
    this._fieldToBetOn = fieldToBeBetOn;
    this.currentValueOfField = currentValueOfField;
  }

  get fieldToBeBetOn(): string{
    return this._fieldToBetOn;
  }

  get fieldToBeBetOnDescription(): string{
    if (this._fieldToBetOn){
        if (this._fieldToBetOn === 'Open'){
          return 'Game will conclude at 9:30 AM Eastern time of whatever date is selected for end time';
        }
        if (this._fieldToBetOn === 'Close' || this._fieldToBetOn === 'Low'
        || this._fieldToBetOn === 'High' || this._fieldToBetOn === 'Volume'){
          return 'Game will conclude at 4 PM Eastern time of whatever date is selected for end time';
        }

    }
    return '';
  }

  selectCurrentStock(stock: StockData): void{
    this.selectedStock = stock;
  }

  toggleMeridian(): any {
    this.meridian = !this.meridian;
  }

  selectGameType(gameType): void{
    this.currentGameType = gameType;
    this.setEvents(gameType);
  }

  setEvents(gameType): void{
    if (gameType.type === 'StraightSelection'){
      this.betSlip = {over: false, under: false, bettingValue: this.bettingValue, weight: 0};
    }
    else  if (gameType.type === 'MarginOfError'){
      this.betSlip = {userGuess: 0, weight: 0};
    }
    else  if (gameType.type === 'WeightedMarginOfError'){
      this.betSlip = {userGuess: 0, weight: 0};
    }
    else  if (gameType.type === 'WeightedStraightSelection'){
      this.betSlip = {over: false, under: false, bettingValue: this.bettingValue, weight: 0};
    }
  }

  canCreateGame(): boolean{
    return !!(this.startModel && this.endModel && this.currentGameType.name && this.selectedStock.symbol
              && this.betSlip && this.currentGameType);
  }

  createGame(): void {
    this.gameService.games.push({betSlip: this.betSlip,
      stockSymbol: this.selectedStock.symbol,
      name: this.currentGameType.name, // todo need this for java atm
      field: this.fieldToBeBetOn,
      startTime: this.createDate(this.startModel, false),
      endTime: this.createDate(this.endModel, true),
      gameType: this.currentGameType,
      gameTypeName: this.currentGameType.name,
      gameState: 'UPCOMING'
      });
    this.setEvents(this.currentGameType);
    this.contestStateService.currentContest.games = [];
    this.contestStateService.currentContest.games = this.gameService.games;
  }

  createDate(model: NgbDateStruct, isEndDate: boolean): Date{
    const date = new Date();
    date.setFullYear(model.year, model.month, model.day);
    if (isEndDate){
      if (this.fieldToBeBetOn === 'Open'){
        date.setHours(9, 30, 0, 0);
      }
      else{
        date.setHours(16, 0, 0, 0);
      }
    }
    else{
      date.setHours(this.time.hour, this.time.minute, 0, 0);
    }

    return date;
  }

  get games(): any{
    return this.gameService.games;
  }

}

export interface StockData{
  afterHours: number;
  close: number;
  high: number;
  low: number;
  open: number;
  preMarket: number;
  status: string;
  symbol: string;
  volume: number;
}
// export interface BetSlip{
//   fieldToBeBetOn: string;
// }

interface MarginOfError {
  timestamp?: number;
  /* more general properties here */
}
interface StraightSelection {
  over: boolean;
  under: false;
  bettingValue: number;
}

type GameEvent = MarginOfError | StraightSelection;
enum StraightSelectionChoices {
  Under,
  Over
}

enum StockGameableFields {
  Open,
  Close,
  Low,
  High,
  Volume
}
