import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import Quill from 'quill';

import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { SubtopicRankingService } from 'src/app/Aggregation/subtopic-ranking.service';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { ContestStateService } from 'src/app/contest/contest-state.service';
import { SubtopicPaginatedBlogMap } from 'src/app/contest/contest.component';
import { Tag } from 'src/app/guru/guru.component';
import { Blog, PaginatedBlog, Page } from 'src/app/media/media.component';
import { TopicsEntity, SubTopicsEntityOrOwnedSubtopicsEntity } from 'src/app/profile/User/user.service';
import { SubtopicService } from 'src/app/topic/subtopic/subtopic.service';
import { TopicService } from 'src/app/topic/topic.service';
import { MeService } from '../../me.service';
import { YourBlogsService } from '../your-blogs.service';
import { BlogService } from 'src/app/media/blog/blog.service';
import { TagReference } from '../../../../contest/game/game.service';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css']
})
export class CreateBlogComponent implements OnInit {


// tslint:disable-next-line:max-line-length
  // todo -> Issue… pass in as property binding… innerhtml can’t be changed and needs to be present when rendered -> make it a child component that is created and destroyed on click
  // tslint:disable-next-line:max-line-length
  // test = '<!-- SC_OFF --><div class="md"><p>First time poster. This week was also first time I have ever purchased a crypto. I am digging into it and had a ETH trading question. Its about buy/sell walls. I did search other posts and looked up info on this but sill have a question. I am learning so please correct. </p> <p>My question is why are the sell walls I am seeing on ETH based on maintaining a sell order volume milestone and not a sell price?. The most obvious is the 10,000 sell order mark which seems to hover around 1.2-1.3% above mid market. This sell order will adjust 4800-4995 and a few dollars every 5-15 min to maintain this volume ceiling. WHY volume and not static price? I understand a sell wall can drive down a price but a sell wall that increases its sell price to maintain 1% above market seems to only be useful to limit FAST % increase not pushing down. Am I understanding this incorrectly?<br/><a href="https://imgur.com/a/yS4ebPX">https://imgur.com/a/yS4ebPX</a></p> </div><!-- SC_ON --> (see http://g.co/ng/security#xss)';
  data: any = '';
  // currentBlog: Blog = null;
  blurred = false;
  focused = false;
  quil: any; // Quill;
  quilTitle: any; // Quill;
  canViewBlog = false;
  viewListOfYourBlogs = false;
  createYourOwnBlog = false;
  endOfBlogs = false;
  messageOnSave = '';
  paginatedBlogs: PaginatedBlog;
  PaginationCommand = PaginationCommand;
  currentTopic: any;
  currentSubtopic: any;
  subtopicBlogSetMap: SubtopicPaginatedBlogMap[] = [];
  private _filteredBlogList: any[] = [];
  private _filteredTagList: Tag[];
  isMobile: boolean;
  private _nonfilteredTagList: Tag[];
  selectedValue: string;
  private _selectedTag: Tag;
  unauthorizedToEdit  = '';
  _isEdit: boolean;


  constructor(private httpClient: HttpClient,
              public yourBlogsService: YourBlogsService,  private activatedRoute: ActivatedRoute,
              private router: Router, private topicService: TopicService,
              private subtopicService: SubtopicService,
              private contestStateService: ContestStateService,
              private route: ActivatedRoute, private meService: MeService,
              private subtopicRankingService: SubtopicRankingService,
              private blogService: BlogService) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }

    if (this.meService.isAuthenticated() && ((!!this.route.snapshot.queryParamMap.get('blogId')))){
      this._isEdit = true;

      this.activatedRoute.data.subscribe((response: any) => {
        this.blogService.currentBlog = response.blogData[0];
      });

      if (+this.route.snapshot.queryParamMap.get('blogId') !== this.blogService.currentBlog.id
       || this.blogService.currentBlog._owner_id !== this.meService.id){
        this.unauthorizedToEdit = 'You are not authorized to edit this blog';
        return;
       }


      this.topicService.allTopics._embedded.topicDTOList.forEach((topic: TopicsEntity) => {
        if (topic.name === 'Finance') {
          this.selectTopic(topic);
        }
      });
      this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
        if (subtopic.id === this.blogService.currentBlog.subtopicId) {
          this.subtopicService.currentSubtopic = subtopic;
          this.currentSubtopic = subtopic;
          this.subtopicRankingService.getAllTagsForSubtopic(subtopic.id, false, false, false, false, this.meService.id, true).subscribe(
            (result) => {
              this._nonfilteredTagList = [];
              this._nonfilteredTagList = result._embedded.tagList;
              this._nonfilteredTagList.forEach(tag => {
                if (tag.id === this.blogService.currentBlog.tagId){
                  // this.selectTag(this._filteredTagList[0]);
                  this.selectTag(tag);
                  this.selectedValue = tag.name;
                  this._filteredTagList = this.nonFilteredTagList.slice();
                }
              });
            }
          );
        }
      });
      // is edit blog
      // this.blogService.blogSafeHtml = data;
      this.quil = this.blogService.currentBlog.contentString;
      this.quil = this.toHTML(this.quil);
      this.quilTitle = this.blogService.currentBlog.title;

    }
    else{
      // is create blog
      this._isEdit = false;
      this.topicService.allTopics._embedded.topicDTOList.forEach((topic: TopicsEntity) => {
        if (topic.name === 'Finance') {
          this.selectTopic(topic);
        }
      });

    }
  }




  topicSelected(): boolean{
    if ( this.contestStateService.currentContest && this.contestStateService.currentContest.topicReference){
      return true;
    }
    return false;
  }
  subtopicSelected(): boolean{
    if ( this.contestStateService.currentContest && this.contestStateService.currentContest.subtopicReference ){
      return true;
    }
    return false;
  }

  isSelected(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): boolean {
    return this.subtopicService.currentSubtopic === subtopic;
  }

  isSelectedTag(tag: Tag): boolean {
    return this._selectedTag === tag;
  }


  selectTopic(topic: TopicsEntity): void {
    this.topicService.currentTopic = topic;
    this.currentTopic = topic;

    if (topic.name.toUpperCase() === 'FINANCE'){
    this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
      if (subtopic.name.toUpperCase()  === 'STOCKS'){
        this.selectSubTopic(subtopic);
        this.subtopicSelected();
      }
    });
  }
    if (topic.name.toUpperCase() === 'SPORTS'){
    this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
      if (subtopic.name.toUpperCase()  === 'NCAAF'){
        this.selectSubTopic(subtopic);
        this.subtopicSelected();
      }
    });
  }
  }
  selectSubTopic(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
    this.subtopicService.currentSubtopic = subtopic;
    if (subtopic.name === 'All'){
      return;
    }
    this.currentSubtopic = subtopic;
    this.subtopicRankingService.getAllTagsForSubtopic(subtopic.id, false, false, false, false, this.meService.id, true).subscribe(
      (result) => {
        this._nonfilteredTagList = [];
        this._nonfilteredTagList = result._embedded?.tagList;
        if (this._nonfilteredTagList === undefined || this._nonfilteredTagList === null){
          this._nonfilteredTagList = [];
          this._filteredTagList = this.nonFilteredTagList.slice();
          this._selectedTag = null;
          return;
        }
        this._filteredTagList = this.nonFilteredTagList.slice();
        this._nonfilteredTagList.forEach(tag => {
          if (tag.name.toLowerCase() === subtopic.name.toLowerCase()){
            // this.selectTag(this._filteredTagList[0]);
            this.selectTag(tag);
            this.selectedValue = tag.name;
            this._filteredTagList = this.nonFilteredTagList.slice();
          }
        },
        err => {
          this._nonfilteredTagList = [];
          this._filteredTagList = this.nonFilteredTagList.slice();
          this._selectedTag = null;
        });
      }
    );
  }

  canSaveNewBlog(): boolean{
    return !!this._selectedTag?.id && !!this.subtopicService.currentSubtopic?.id && !!this.currentTopic?.id;
  }

  titleAndBodyPopulatedWithMinimum(): boolean{
    return !!this.quilTitle && !!this.quil;
  }

  save(): void{
     console.log('in save');
     const x = this.quil;
     console.log('above is quil');
  }

  get blogs(): any[] {
    return this.yourBlogsService.paginatedBlog._embedded.blogPreviewDTOList;
  }


  public saveBlog(): void {
    // const html = this.quil.root.innerHTML;
    const blog: Blog = {
      contentString: this.quil,
      date: new Date(),
      title: this.quilTitle,
      selftext: this.quil,
      selftext_html: this.quil,
      subtopicId: this.subtopicService.currentSubtopic.id,
      isPublished: false,
      tagId: this._selectedTag.id,
      _owner_id: this.meService.id,
      author_fullname: this.meService.user.username,
      topicId: this.currentTopic.id
    };
    this.httpClient.post(HOST_IP_INFO_MARKETS + `/me/blog`, blog).subscribe((_blog: Blog) => {
      console.log('Success on blog saving');
      this.messageOnSave = 'Blog was saved!';
      this.router.navigate([routingGlobals.absProfile]);
      this.viewExistingBlogsAndClearEditor();
      // this.yourBlogsService.getBlogsForMe().subscribe(response => {
      //   this.yourBlogsService.paginatedBlog = response as PaginatedBlog;
      // });
    },
    err => {
      console.log('error on blog saving');
      this.messageOnSave = err.error;
    });
  }

  public updateAndDontPublishBlog(): void {
    // const html = this.quil.root.innerHTML;
    const blog: Blog = {
      contentString: this.quil,
      title: this.quilTitle,
      selftext: this.quil,
      selftext_html: this.quil,
      isPublished: false,
      _owner_id: this.meService.id,
      author_fullname: this.meService.user.username,
      id: this.currentBlog.id
    };
    this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/blog`, blog).subscribe((_blog: Blog) => {
      console.log('Success on blog saving');
      this.messageOnSave = 'Blog was updated!';
      this.router.navigate([routingGlobals.absProfile]);
      this.viewExistingBlogsAndClearEditor();
      // this.yourBlogsService.getBlogsForMe().subscribe(response => {
      //   this.yourBlogsService.paginatedBlog = response as PaginatedBlog;
      // });
    },
    err => console.log('error on blog saving'));
  }

  public updateAndPublishBlog(): void {
    // const html = this.quil.root.innerHTML;
    const blog: Blog = {
      contentString: this.quil,
      title: this.quilTitle,
      selftext: this.quil,
      selftext_html: this.quil,
      isPublished: true,
      _owner_id: this.meService.id,
      author_fullname: this.meService.user.username,
      id: this.currentBlog.id
    };
    this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/blog`, blog).subscribe((_blog: Blog) => {
      console.log('Success on blog saving');
      this.messageOnSave = 'Blog was updated!';
      this.router.navigate([routingGlobals.absProfile]);
      this.viewExistingBlogsAndClearEditor();
      // this.yourBlogsService.getBlogsForMe().subscribe(response => {
      //   this.yourBlogsService.paginatedBlog = response as PaginatedBlog;
      // });
    },
    err => console.log('error on blog saving'));
  }

  public deleteBlog(): void {
    // const html = this.quil.root.innerHTML;

    this.httpClient.delete(HOST_IP_INFO_MARKETS + `/me/blog/delete-blog/${this.currentBlog.id}`).subscribe((_blog: Blog) => {
      console.log('Delete Blog Successfully');
      this.messageOnSave = 'Blog was Deleted!';
      this.router.navigate([routingGlobals.absProfile]);
      this.viewExistingBlogsAndClearEditor();
      // this.yourBlogsService.getBlogsForMe().subscribe(response => {
      //   this.yourBlogsService.paginatedBlog = response as PaginatedBlog;
      // });
    },
    err => console.log('error on blog saving'));
  }

  public saveBlogAndPublish(): void {
    // const html = this.quil.root.innerHTML;
    const blog: Blog = {
      contentString: this.quil,
      date: new Date(),
      title: this.quilTitle,
      selftext: this.quil,
      selftext_html: this.quil,
      subtopicId: this.subtopicService.currentSubtopic.id,
      isPublished: true
    };
    this.httpClient.post(HOST_IP_INFO_MARKETS + `/me/blog`, blog).subscribe((_blog: Blog) => {
      console.log('Success on blog saving');
      this.messageOnSave = 'Blog was saved!';
      this.router.navigate([routingGlobals.absProfile]);
    },
    err => console.log('error on blog saving'));
  }

  created(event: Quill): void {
    // tslint:disable-next-line:no-console
    console.log('editor-created', event);
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection): void {
    // tslint:disable-next-line:no-console
    console.log('editor-change', event);
  }

  focus($event): void {
    // tslint:disable-next-line:no-console
    console.log('focus', $event);
    this.focused = true;
    this.blurred = false;
  }

  blur($event): void {
    // tslint:disable-next-line:no-console
    console.log('blur', $event);
    this.focused = false;
    this.blurred = true;
  }

  viewBlog(blogHtml: SafeHtml, blogId: number): void{
    this.yourBlogsService.blogSafeHtml = blogHtml;
    this.canViewBlog = true;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    // this.router.navigate(['../' + routingGlobals.yourBlogs + '/' + routingGlobals.dynamicBlog, blogId], {relativeTo: this.route});
    this.router.navigate(['../../' + routingGlobals.media + '/' + routingGlobals.dynamicBlog, blogId,
    'author', this.meService.id], {relativeTo: this.route});
  }

  get viewAbleBlog(): SafeHtml{
    return this.yourBlogsService.blogSafeHtml;
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
}

createBlog(): void{
  this.createYourOwnBlog = true;
  this.viewListOfYourBlogs = false;
}

viewExistingBlogs(): void{
  this.viewListOfYourBlogs = true;
  this.createYourOwnBlog = false;
}

viewExistingBlogsAndClearEditor(): void{
  this.viewExistingBlogs();
  this.quil = '';
  this.quilTitle = '';
  // this.quil.setContents([{ insert: '\n' }]);

  this.quil.deleteText(0, this.quilTitle.getLength());
  this.quil.deleteText(0, this.quil.getLength());
}



get pageData(): Page{
  if (this.yourBlogsService.paginatedBlog){
    return this.yourBlogsService.paginatedBlog.page;
  }
  return undefined;

}

  get filteredTagList(): Tag[]{
    return this._filteredTagList;
  }

  get nonFilteredTagList(): Tag[]{
    return this._nonfilteredTagList;
  }

  selectValue(tag: Tag): void {
    this.selectedValue = tag.name;
    this.selectTag(tag);
  }

  selectTag(tag: Tag): void{
    this._selectedTag = tag;
   }

   get selectedTag(): Tag{
    return this._selectedTag;
   }

   get userId(): number{
    return this.meService.id;
   }

   get isEdit(): boolean{
    return this._isEdit;
   }

   get currentBlog(): Blog{
    return this.blogService.currentBlog;
   }

   isSelectedTopic(topic: TopicsEntity): boolean {
    return this.topicService.currentTopic === topic;
  }

  get topics(): any{
    return this.topicService.allTopics?._embedded?.topicDTOList;
   }
}

export enum PaginationCommand{
  NEXT,
  PREVIOUS,
  FIRST,
  LAST
}
