import { Component, OnInit, ViewChild } from '@angular/core';
import { ContestStateService } from '../contest-state.service';
import { GameService } from '../game/game.service';

@Component({
  selector: 'app-create-game',
  templateUrl: './create-game.component.html',
  styleUrls: ['./create-game.component.css']
})
export class CreateGameComponent implements OnInit {
  events = [];
  gameTypes = [  'StraightSelection',
    'MarginOfError',
    'CombinedDAndWeightedMarginOfError',
    'WeightedStraightSelection'];
  constructor(private gameService: GameService, private contestStateService: ContestStateService) { }

  @ViewChild('eventM') inputName; // accessing the reference element

  ngOnInit(): void {
  }

  get games(): any{
    return this.gameService.games;
  }


  addEvent(event): void {
    console.log('it does nothing', event);
    this.events.push(event);
    this.inputName.nativeElement.value = ' ';
  }

  createGame(): void {

    this.events = [];
    this.contestStateService.currentContest.games = [];
    this.contestStateService.currentContest.games = this.gameService.games;
  }

}
