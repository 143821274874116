
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionDataResolverService {

  constructor(private subscriptionService: SubscriptionService) { }
  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    return this.subscriptionService.getSubscription().pipe(
      catchError(error => {
        return of('No data');
      })
    );
  }
}


