import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { TopicService } from './topic.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../shared/storage.service';
import { MeService } from '../profile/Me/me.service';
import * as routingGlobals from '../GlobalVars/routingGlobal';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:ban-types
export class AllTopicsResolverService implements Resolve<Object>{

  constructor(private topicService: TopicService, private storageService: StorageService,
              private meService: MeService, private router: Router) { }
  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    return this.topicService.getTopics().pipe(
      catchError(error => {
        this.meService.logout();
        this.storageService.clean();
        this.router.navigate([routingGlobals.absLogin]);
        return of('No data');
      })
    );
  }
}
