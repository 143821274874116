<div class="row">
  <div class="col-2"></div>
  <div class="col-8">
    <div class="container ml-1 mt-4 font-weight-light text-center mb-3">
      <p class="font-weight-light">
        Make Predictions - Prove You're an Expert - Gain a following in Your
        Areas of Knowledge - To Learn More,
        <a routerLink="/about-us"><b>Click Here</b></a>
      </p>
      <p class="font-weight-light" *ngIf="userId === -1">
        Invite your friends to sign up and be elible to win 100 dollars! (Must
        use below link)
        <a href="javascript:void(0);" (click)="copyURL()"
          ><b>Copy Your Link</b></a
        >
      </p>
    </div>
  </div>
  <div class="col-2"></div>
</div>
<p class="text-center font-weight-bold"><u>Select a Topic</u></p>
<div class="row">
  <div
    *ngFor="let topic of topics; let i = index"
    (click)="selectTopic(topic)"
    class="col-4"
    [ngClass]="{
      'font-weight-bold text-success font-italic':
        isSelectedTopic(topic) === true
    }"
    style="font-size: 12px; cursor: pointer"
  >
    {{ topic.name }}
  </div>
</div>
<p *ngIf="!currentSubtopic" class="text-center font-weight-bold">
  Select a Subtopic
</p>
<p *ngIf="currentSubtopic" class="text-center font-weight-bold">
  Selected {{ currentSubtopic.name }}
</p>
<div class="row">
  <div
    *ngFor="let subtopic of subtopics; let i = index"
    (click)="selectSubtopic(subtopic)"
    class="col-4 text-center"
    [ngClass]="{
      'font-weight-bold text-success font-italic': isSelected(subtopic) === true
    }"
    style="font-size: 12px; cursor: pointer"
  >
    {{ subtopic.name }}
  </div>
</div>
<p *ngIf="!selectedTag" class="text-center font-weight-bold">Select a Tag</p>
<p *ngIf="selectedTag" class="text-center font-weight-bold">
  Selected {{ selectedTag.name | lowercase | titlecase }}
</p>
<div class="row">
  <div
    *ngFor="let tag of filteredTagList; let i = index"
    (click)="selectTag(tag)"
    class="col-3 text-center"
    [ngClass]="{
      'font-weight-bold text-success font-italic': isSelectedTag(tag) === true,
      elite: tag.percentileForSubtopicRanking > 98,
      aboveAvg:
        tag.percentileForSubtopicRanking > 80 &&
        tag.percentileForSubtopicRanking <= 98,
      average:
        tag.percentileForSubtopicRanking > 60 &&
        tag.percentileForSubtopicRanking <= 80,
      belowAvg:
        tag.percentileForSubtopicRanking > 40 &&
        tag.percentileForSubtopicRanking <= 60,
      bad:
        tag.percentileForSubtopicRanking > 20 &&
        tag.percentileForSubtopicRanking <= 40
    }"
    style="font-size: 12px; cursor: pointer"
    [ngStyle]="
      (tag.percentileForSubtopicRanking >= 0 &&
        tag.percentileForSubtopicRanking <= 20 && {
          'background-color': 'darkpurple'
        }) ||
      (tag.percentileForSubtopicRanking > 20 &&
        tag.percentileForSubtopicRanking <= 40 && {
          'background-color': 'purple'
        }) ||
      (tag.percentileForSubtopicRanking > 40 &&
        tag.percentileForSubtopicRanking <= 60 && {
          'background-color': 'blue'
        }) ||
      (tag.percentileForSubtopicRanking > 60 &&
        tag.percentileForSubtopicRanking <= 80 && {
          'background-color': 'green'
        }) ||
      (tag.percentileForSubtopicRanking > 80 &&
        tag.percentileForSubtopicRanking <= 99 && {
          'background-color': 'orange'
        }) ||
      (tag.percentileForSubtopicRanking > 99 &&
        tag.percentileForSubtopicRanking <= 100 && {
          'background-color': 'gold'
        })
    "
  >
    {{ tag.name | lowercase | titlecase }} - ({{
      tag.percentileForSubtopicRanking
    }})
    <span
      *ngIf="
        tag.percentileForSubtopicRanking >= 0 &&
        tag.percentileForSubtopicRanking <= 20
      "
    >
      Simple Minded
    </span>
    <span
      *ngIf="
        tag.percentileForSubtopicRanking > 20 &&
        tag.percentileForSubtopicRanking <= 40
      "
    >
      Likely Stupid
    </span>
    <span
      *ngIf="
        tag.percentileForSubtopicRanking > 40 &&
        tag.percentileForSubtopicRanking <= 60
      "
    >
      Ape Helper
    </span>
    <span
      *ngIf="
        tag.percentileForSubtopicRanking > 60 &&
        tag.percentileForSubtopicRanking <= 80
      "
    >
      Autistic Ape
    </span>
    <span
      *ngIf="
        tag.percentileForSubtopicRanking > 80 &&
        tag.percentileForSubtopicRanking <= 98
      "
    >
      Autistic Ape Council Member
    </span>
    <span
      *ngIf="
        tag.percentileForSubtopicRanking > 98 &&
        tag.percentileForSubtopicRanking <= 100
      "
    >
      King of Autistic Ape Council
    </span>
  </div>
</div>
<p *ngIf="!contestType" class="text-center font-weight-bold">
  Select a Contest Type
</p>
<p *ngIf="contestType" class="text-center font-weight-bold">
  Selected {{ contestType }}
</p>
<div class="row" *ngIf="!topicSelectedIsSports">
  <div
    *ngFor="let contestType of contestTypesFinance; let i = index"
    (click)="selectContestType(contestType)"
    class="col-3 text-center"
    [ngClass]="{
      'font-weight-bold text-success font-italic':
        isSelectedContestType(contestType) === true
    }"
    style="font-size: 12px; cursor: pointer"
  >
    <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_ERROR'">
      <p>Margin of Error</p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'UNDER_OVER'">
      <p>Under/Over</p>
    </div>
  </div>
</div>

<div class="row" *ngIf="topicSelectedIsSports">
  <div
    *ngFor="let contestType of contestTypesSports; let i = index"
    (click)="selectContestType(contestType)"
    class="col-3 text-center"
    [ngClass]="{
      'font-weight-bold text-success font-italic':
        isSelectedContestType(contestType) === true
    }"
    style="font-size: 12px; cursor: pointer"
  >
    <p *ngIf="contestType === 'MARGIN_OF_VICTORY'">
      Selected <b>Margin Of Victory</b>
    </p>
    <p *ngIf="contestType === 'TOTAL_POINTS_SCORED'">
      Selected <b>Total Points Scored</b>
    </p>
  </div>
</div>

<p *ngIf="!currentSubtopic" class="text-center font-weight-bold">
  Select a Timeframe Type
</p>
<p *ngIf="currentSubtopic" class="text-center font-weight-bold">
  Selected {{ timeframeType | lowercase | titlecase }}
</p>
<div class="row">
  <div
    *ngFor="
      let timeframeType of timeAndGameDurationPeriodTypesList;
      let i = index
    "
    (click)="selectTimeframeType(timeframeType)"
    class="col-4 text-center"
    [ngClass]="{
      'font-weight-bold text-success font-italic':
        (isSelectedtimeframeType(timeframeType) === true) === true
    }"
    style="font-size: 12px; cursor: pointer"
  >
    {{ timeframeType | lowercase | titlecase }}
  </div>
</div>
<div *ngIf="!canCallForOutcomes()">
  <p class="text-center">
    <b
      ><u
        >Please select a Subtopic, Tag, Contest Type and Timeframe type from
        above</u
      ></b
    >
  </p>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
<div>
  <div>
    <div>
      <div *ngIf="canCallForOutcomes()">
        <app-user-contest-outcomes
          [subtopic]="currentSubtopic"
          [tag]="selectedTag"
          [contestType]="contestType"
          [timeframeType]="timeframeType"
          [userId]="userId"
        ></app-user-contest-outcomes>
      </div>
    </div>
  </div>
</div>
