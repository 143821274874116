
import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { MeService } from 'src/app/profile/Me/me.service';


// declare var Stripe;


@Component({
  selector: 'app-signup-payment-information',
  templateUrl: './signup-payment-information.component.html',
  styleUrls: [],
})
export class SignupPaymentInformationComponent implements OnInit {

  // stripe;

  constructor(private httpClient: HttpClient, private meService: MeService){}

  ngOnInit(): void {
    // this.stripe = Stripe('pk_test_51MF0ocLpZTpaDYiC2gSU718VbZ1cBq9mDgKkJEHwGkfIiUPB61I6CfP4h6sDl1AABDwhbeshFiaD8Y8j6vXaLKFw00ZmPmOOqX');
  }

  // checkout(product: string): any{
  //   this.checkoutCall(product).subscribe(response => {
  //     console.log(response);

  //     this.stripe.
  //     redirectToCheckout({
  //       sessionId: response.sessionId
  //     })
  //     .then((res) => {
  //       console.log('finsihed');
  //     });
  //   });
  // }

  // checkoutCall(product: string): any{
  //   let _priceid = '';
  //   if (product === 'Basic') {
  //     _priceid = 'price_1MT9FyLpZTpaDYiCM6cGOlT4';
  //   }
  //   const data = { priceId: _priceid, id: this.meService.id };

  //   // todo make successful redirect link carry jwt
  //   return this.httpClient.post(HOST_IP_INFO_MARKETS + '/create-checkout-session', data);
  // }

}
