import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContestStateService, UnderOverOutcomeRanking, PaginatedUnderOverOutcomeRanking } from '../../contest-state.service';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { BetSlipService } from '../../bet-slip.service';

import { Me, MeService } from 'src/app/profile/Me/me.service';
import { UserService } from 'src/app/profile/User/user.service';
import { PaginatedContestOutcomeRanking, PaginatedSubtopicRanking } from '../../contest.component';
import { BetSlip, ContestType, Game } from '../../game/game.service';
import { UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { subtopic } from '../../../GlobalVars/routingGlobal';
import { UserContestOutcomesService } from '../../../profile/User/user-contest-outcomes/user-contest-outcomes.service';
import { Tag } from 'src/app/guru/guru.component';

@Component({
  selector: 'app-rankings-card',
  templateUrl: './rankings-card.component.html',
  styleUrls: ['./rankings-card.component.css']
})
export class RankingsCardComponent implements OnInit {

  @Output()
  selectedContestOutcomes = new EventEmitter<any>();

  @Input()
  underOverOutcomeRankings: PaginatedUnderOverOutcomeRanking;

  @Input()
  topicRankings: PaginatedSubtopicRanking;

  @Input()
  isRefresh: boolean;
  _tag: Tag;
  private _viewLimit = 150;
  isMobile: boolean;
  private _timeframeType: string;

  @Input() set
  subtopicRankings(subtopicRanikings: PaginatedSubtopicRanking)
  {
    this._topicRankingsVO = [];
    this._subtopicsRanking = subtopicRanikings;
    if (this._subtopicsRanking && this._subtopicsRanking._embedded && this._subtopicsRanking._embedded.subtopicRankingDTOList){
      this._topicRankingsVO = this._subtopicsRanking._embedded.subtopicRankingDTOList;
    }
  }

  get subtopicRankings(): PaginatedSubtopicRanking{
    return this._subtopicsRanking;
  }

  @Input() set
  tag(value: Tag)
  {
    if (!!value){
      this._tag = value;
    }
  }

  get tag(): Tag{
    return this._tag;
  }

  @Input() set
  timeframeType(value: string)
  {
    if (!!value){
      this._timeframeType = value;
    }
  }

  get timeframeType(): string{
    return this._timeframeType;
  }

  @Input() set
  viewLimit(value: number)
  {
    if (!!value){
      this._viewLimit = value;
    }
  }

  get viewLimit(): number{
    return this._viewLimit;
  }

  @Input()
  contestRankings: PaginatedContestOutcomeRanking;

  _underOverOutcomeRankingsVO = [];
  _topicRankingsVO = [];
  ContestType = ContestType;

  isTopicRanking = false;
  isUnderOverRanking = false;
  isContestRanking = false;
  activeUserId = -100;
  _subtopicsRanking: PaginatedSubtopicRanking;


  constructor(public contestStateService: ContestStateService, private router: Router,
              private betSlipService: BetSlipService, private meService: MeService,
              private userService: UserService, private route: ActivatedRoute,
              private userContestOutcomesService: UserContestOutcomesService) {
              }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
      this.viewLimit = 10;
    }
    else{
      this.isMobile = false;
      this.viewLimit = 150;
    }
    if (this.topicRankings && this.topicRankings._embedded.subtopicRankingDTOList.length > 0) {
      this._topicRankingsVO = this.topicRankings._embedded.subtopicRankingDTOList;
      this.isUnderOverRanking = false;
      this.isContestRanking = false;
      this.isTopicRanking = true;
    }
    else if (this.subtopicRankings
      && this.subtopicRankings._embedded
      && this.subtopicRankings._embedded.subtopicRankingDTOList.length > 0) {
      this._topicRankingsVO = this.subtopicRankings._embedded.subtopicRankingDTOList;
      this.isUnderOverRanking = false;
      this.isContestRanking = false;
      this.isTopicRanking = true;
    }
    else if (this.contestRankings && this.contestRankings._embedded.contestOutcomeRankingDTOList.length > 0){
      this._topicRankingsVO = this.contestRankings._embedded.contestOutcomeRankingDTOList;
      this.isUnderOverRanking = false;
      this.isTopicRanking = false;
      this.isContestRanking = true;
      this.selectedContestOutcomes.emit(this.underOverOutcomeRankings);
      if (this.isRefresh){
        console.log('in refresh');
        this._topicRankingsVO = this.contestRankings._embedded.contestOutcomeRankingDTOList;
        this.isUnderOverRanking = false;
        this.isTopicRanking = false;
        this.isContestRanking = true;
        this.selectedContestOutcomes.emit(this.underOverOutcomeRankings);
        this.contestStateService.setRankingsforContest(this.contestRankings);
        this._underOverOutcomeRankingsVO = this.contestRankings._embedded.contestOutcomeRankingDTOList;
        this.viewEnteredContestWithTagIdAndTimeframeType( this.contestStateService.activeContestOutcome.owner_id,
            this.contestStateService.activeContestOutcome.username, this._tag.id, this._timeframeType);
        return;
      }

      // tslint:disable-next-line:max-line-length
      this.betSlipService.getContestRankings(this.contestStateService.currentContest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
        console.log('retreived outcomes');
        this.contestStateService.setRankingsforContest(outcomes);
        this._underOverOutcomeRankingsVO = outcomes._embedded.contestOutcomeRankingDTOList;

    });
    }
    else {
        // tslint:disable-next-line:max-line-length
      this.betSlipService.getContestRankings(this.contestStateService.currentContest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
        console.log('retreived outcomes');
        this.contestStateService.setRankingsforContest(outcomes);
        this._underOverOutcomeRankingsVO = outcomes._embedded.contestOutcomeRankingDTOList;
        this.selectedContestOutcomes.emit(this.underOverOutcomeRankings);
        this.isUnderOverRanking = true;
        this.isTopicRanking = false;
        this.isContestRanking = false;
      });
      // }
      if (this.route.snapshot.queryParamMap.get('user')){
      this.viewEnteredContest(+this.route.snapshot.queryParamMap.get('user'), '');
    }
    }
  }

  get meId(): number{
    if (this.meService.user && this.meService.user.id){
      return this.meService.user.id;
    }
    return -1;
  }

  get userId(): number {
    if (this.activeUserId > 0){
      return this.activeUserId;
    }
    if (this.userService.user && this.userService.user.id){
      return this.userService.user.id;
    }

    return -1;
  }

  viewEnteredContest(userId: number, username: string): void{
    // this.contestService.currentViewState(ContestOwnershipState.isEntered, ContestActiveState.LIVE, contest);
    // this.contestStateService.viewOtherUsersContestBetSlips(this.contestStateService.currentContest, userId, username);
      this.activeUserId = userId;
      this.userService.id = userId;
      // this.userService.user.id = userId;
      this.router.navigate([routingGlobals.absViewContest, this.contestStateService.currentContest.id],
        { queryParams: { user: userId }});
      this.betSlipService.setBetSlipsForContest(this.contestStateService.currentContest, userId, username);

      this.contestStateService.currentContest.userContestState = this.contestStateService.currentContest.userContestState;
      if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
        this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
      }
      this.userContestOutcomesService.
      getContestOutcomesForUserInSingleContest(userId, this.contestStateService.currentContest.id)
      .subscribe(contestOutcome => {
        this.contestStateService.activeContestOutcome = contestOutcome;
      });
      this.selectedContestOutcomes.emit(this.underOverOutcomeRankings);
  }

  viewEnteredContestWithTagIdAndTimeframeType(userId: number, username: string, tagId: number, timeframeType: string): void{
    // this.contestService.currentViewState(ContestOwnershipState.isEntered, ContestActiveState.LIVE, contest);
    // this.contestStateService.viewOtherUsersContestBetSlips(this.contestStateService.currentContest, userId, username);
      this.activeUserId = userId;
      this.userService.id = userId;
      // this.userService.user.id = userId;
      this.router.navigate([routingGlobals.absViewContest, this.contestStateService.currentContest.id],
        { queryParams: { user: userId }});
      this.betSlipService.setBetSlipsForContest(this.contestStateService.currentContest, userId, username);

      this.contestStateService.currentContest.userContestState = this.contestStateService.currentContest.userContestState;
      if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
        this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
      }
      this.userContestOutcomesService.
      getContestOutcomesForUserInSingleContestWithTagIdAndTimeframeType(userId,
        this.contestStateService.currentContest.id, tagId, timeframeType)
      .subscribe(contestOutcome => {
        this.contestStateService.activeContestOutcome = contestOutcome;
      });
      this.selectedContestOutcomes.emit(this.underOverOutcomeRankings);
  }

  viewUserProfile(userId: number): void {
    this.userService.getUser(userId).subscribe((user: Me) => {
      this.userService.user = user;
      this.userService.id = user.id;
      this.router.navigate([routingGlobals.absUser, userId]);
    });

  }

  get underOverOutcomeRankingsVO(): any[]{
    const tempList = this._underOverOutcomeRankingsVO;
    return tempList.slice(0, Math.min(this.viewLimit, tempList.length));
  }

  get topicRankingsVO(): any[]{
    const tempList = this._topicRankingsVO;
    return tempList.slice(0, Math.min(this.viewLimit, tempList.length));
  }
}


