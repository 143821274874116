import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../create-financial-contest-modal-create-contest-or-another-game/create-financial-contest-modal-create-contest-or-another-game.component';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import { ActivatedRoute, Router } from '@angular/router';
import { createFinancialContestModalStockGameTypeSelection } from '../../../../GlobalVars/routingGlobal';
import { CreateContestService, GameObject } from '../../create-contest.service';
import { StockData } from '../../../create-game/create-financial-game/create-financial-game.component';

@Component({
  selector: 'app-create-financial-contest-modal-stock-field-selection',
  templateUrl: './create-financial-contest-modal-stock-field-selection.component.html',
  styleUrls: ['./create-financial-contest-modal-stock-field-selection.component.css']
})
export class CreateFinancialContestModalStockFieldSelectionComponent implements OnInit, Modal {

  constructor( private route: ActivatedRoute, private router: Router,
               private createContestService: CreateContestService) { }
  nextPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockGameTypeSelection], {relativeTo: this.route});
  }
  previousPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
  }
  startPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
  }

  ngOnInit(): void {
  }

  selectFieldToBetOn(fieldToBeBetOn: string, currentValueOfField: number): void{
    this.createContestService.fieldToBetOn = fieldToBeBetOn;
    this.createContestService.currentValueOfField = currentValueOfField;
  }

  get selectedGameObject(): GameObject {
    return this.createContestService.gameObject;
  }

  get selectedFieldToBetOn(): string {
    return this.createContestService.fieldToBetOn;
  }

}
