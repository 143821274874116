<p>
  Click into topics here... unsure if users should be allowed to create topics
  initially
</p>
<p>List of topic cards</p>
<p>
  If topics arent user created, this becomes the media tab - promoting blogs and
  short videos
</p>

<div class="ml-1 mt-4 font-weight-light">
  <div class="row">
    <div class="col text-center mb-3">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>
          Select Topic
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div
            *ngFor="let topic of allTopics; let i = index"
            (click)="selectTopic(topic)"
          >
            <button ngbDropdownItem>{{ topic.name }} <br /></button>
          </div>
        </div>
      </div>
      <div *ngIf="currentTopic" (click)="subscribeToTopic()">
        {{ currentTopic.name }}
      </div>
    </div>
    <div *ngIf="topicSelected()" div class="col text-center mb-3">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>
          Select Subtopic
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div
            *ngFor="let subtopic of currentTopic.subtopics; let i = index"
            (click)="selectSubtopic(subtopic)"
          >
            <button ngbDropdownItem>{{ subtopic.name }} <br /></button>
          </div>
        </div>
        <div *ngIf="currentSubtopic" (click)="subscribeToSubtopic()">
          {{ currentSubtopic.name }}
        </div>
      </div>
    </div>
    <div *ngIf="topicSelected()">
      <button (click)="viewContests()">view contests for topic</button>
      <div *ngIf="contestsForTopicAvailable()">
        <div *ngFor="let contest of contestsForTopic">
          <div
            class="card bg-light mb-3"
            style="max-width: 24rem"
            (click)="viewContest(contest)"
          >
            <div class="card-header font-weight-bold" style="font-size: 18px">
              {{ contest.name }}
            </div>
            <div class="card-body">
              <h5 class="card-title" style="font-size: 16px">topic name</h5>
              <p class="card-text" style="font-size: 14px">
                Games... number of entrants... start date
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
