import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MeService } from '../profile/Me/me.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptorService implements HttpInterceptor  {

  constructor(private meService: MeService, private router: Router, private storageService: StorageService) { }
  // intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   if (httpRequest instanceof HttpResponse){
  //     if (httpRequest.status === 403 && httpRequest.body === 'Expired token'){
  //       this.meService.logout();
  //     }
  // }
  //   return next.handle(httpRequest).pipe(
  //   finalize(() => {
  //   })
  // );
  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {
          if (error instanceof HttpErrorResponse) {
               if (error && error.error && error.error.message === 'Expired token'){
        this.storageService.clean();
        this.meService.logout();
        this.router.navigate([routingGlobals.absLogin]);
      }
          }
        }
      ));

  }

}
