<table
  class="table table-striped table-dark table-xs table-hover table-condensed font-weight-light"
  style="width: 100%"
  #target
>
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th
        *ngIf="
          contestSubtopic.name.toUpperCase() !== 'NCAAF'.toUpperCase() &&
          contestSubtopic.name.toUpperCase() !== 'NFL'.toUpperCase()
        "
        scope="col"
      >
        Locked Start Value
      </th>

      <th
        scope="col"
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_LIVE ||
          currentContest.userContestState ===
            UserContestState.ENTERED_UPCOMING ||
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED ||
          userContestState ===
            UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
          containsOtherUserBetslip === true
        "
      >
        Selection
      </th>

      <th scope="col">Final Value</th>

      <th
        scope="col"
        *ngIf="
          userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED ||
          userContestState === UserContestState.ENTERED_LIVE
        "
      >
        Projected Outcome
      </th>
      <th
        scope="col"
        *ngIf="
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
          userContestState === UserContestState.ENTERED_PAST
        "
      >
        Outcome
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
      <td
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
          href="{{
            'https://www.bing.com/search?q=' +
              game.name +
              '+Upcoming College Football Game'
          }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.startTime | date : "medium" }}
        </div>
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
          style="font-size: 10px"
        >
          Home/Away
        </div>
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'stocks'.toUpperCase()"
          href="{{ 'https://www.bing.com/search?q=' + game.name + '+Stock' }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'stocks'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
        <a
          *ngIf="
            contestSubtopic.name.toUpperCase() === 'currency'.toUpperCase()
          "
          href="{{ 'https://www.bing.com/search?q=USD+to+' + game.name }}"
          target="_blank"
          >{{ game.name }}</a
        >

        <div
          *ngIf="
            contestSubtopic.name.toUpperCase() === 'currency'.toUpperCase()
          "
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'crypto'.toUpperCase()"
          href="{{ 'https://www.bing.com/search?q=' + game.name + '+Price' }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'crypto'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
      </td>
      <td
        *ngIf="
          contestSubtopic.name.toUpperCase() !== 'NCAAF'.toUpperCase() &&
          contestSubtopic.name.toUpperCase() !== 'NFL'.toUpperCase()
        "
        class="bg-secondary"
      >
        {{ game.startAtom.value | number : "1.2-4" }}
      </td>

      <td
        *ngIf="
          game.betSlip.over &&
          (currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            currentContest.userContestState ==
              UserContestState.ENTERED_UPCOMING ||
            currentContest.userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED ||
            userContestState ===
              UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
            containsOtherUserBetslip === true)
        "
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
        class="bg-secondary"
      >
        OVER
      </td>
      <td
        *ngIf="
          game.betSlip.under &&
          (currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            currentContest.userContestState ===
              UserContestState.ENTERED_UPCOMING ||
            currentContest.userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED ||
            userContestState ===
              UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
            containsOtherUserBetslip === true)
        "
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
        class="bg-secondary"
      >
        UNDER
      </td>

      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED) &&
          game.betSlip.over
        "
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
        class="bg-secondary"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>
      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED) &&
          game.betSlip.under
        "
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
        class="bg-secondary"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>

      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
            containsOtherUserBetslip === true) &&
          game.betSlip.underOverOutcome === UnderOverOutcome.WIN
        "
        class="bg-success"
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        Win
      </td>
      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
            containsOtherUserBetslip === true) &&
          game.betSlip.underOverOutcome === UnderOverOutcome.LOSS
        "
        class="bg-danger"
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        Loss
      </td>

      <div
        *ngIf="
          userContestState === UserContestState.ENTERED_UPCOMING ||
          userContestState === UserContestState.CREATING ||
          userContestState === UserContestState.NON_ENTERED_UPCOMING
        "
      >
        <td
          *ngIf="
            currentContest.userContestState ===
              UserContestState.ENTERED_UPCOMING ||
            currentContest.userContestState ===
              UserContestState.NON_ENTERED_UPCOMING ||
            currentContest.userContestState === UserContestState.ENTERED_PAST
          "
          (click)="selectUnderOver('over', game)"
        >
          <button
            [ngClass]="{
              'btn-xs btn-success text-center': game.betSlip.over === true,
              'btn-xs btn-danger text-center': game.betSlip.over === false
            }"
            style="font-size: 8.8px"
            [disabled]="
              userContestState ===
              UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED
            "
          >
            Choose Over
          </button>
          <!-- : {{ game.betSlip.over }} -->
        </td>
        <td
          *ngIf="
            currentContest.userContestState ===
              UserContestState.ENTERED_UPCOMING ||
            currentContest.userContestState ===
              UserContestState.NON_ENTERED_UPCOMING ||
            currentContest.userContestState === UserContestState.ENTERED_PAST
          "
          (click)="selectUnderOver('under', game)"
        >
          <button
            [ngClass]="{
              'btn-xs btn-success text-center': game.betSlip.under === true,
              'btn-xs btn-danger text-center': game.betSlip.under === false
            }"
            style="font-size: 8.8px"
            [disabled]="
              userContestState ===
              UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED
            "
          >
            Choose Under
          </button>
          <!-- : {{ game.betSlip.under }} -->
        </td>
      </div>
    </tr>
  </tbody>
</table>
<div class="widget-49-meeting-action">
  <button
    *ngIf="isLoggedIn"
    [ngClass]="{
      'btn-sm btn-secondary text-center': canSubmitContestEntry() === false,
      'btn-sm btn-success text-center': canSubmitContestEntry() === true,
      scratched: !canSubmitContestEntry()
    }"
    style="font-size: 8.8px"
    [disabled]="!canSubmitContestEntry()"
    (click)="enterContest()"
  >
    Enter or Update Entry
  </button>

  <button
    *ngIf="!isLoggedIn"
    style="font-size: 8.8px"
    (click)="linkToSignUpPage()"
    class="btn btn-success text-center"
  >
    Sign up/in to Play!
  </button>
</div>

<div class="widget-49-meeting-action" *ngIf="!canSubmitContestEntry()">
  <div class="btn btn-sm btn-flash-border-primary">Contest is Finished</div>
</div>
