<div *ngIf="!isMobile">
  <div *ngIf="!!!filteredContestOutcomes || filteredContestOutcomes.length < 1">
    <p>No Contest Outcomes for {{ subtopic.name }}</p>
  </div>
  <div *ngIf="!!filteredContestOutcomes && filteredContestOutcomes.length >= 1">
    <p class="text-center font-weight-bold">Active/Final Subtopic Rankings</p>

    <table
      class="table table-striped table-dark table-xs table-hover table-condensed"
    >
      <thead>
        <tr>
          <th scope="col">Elo</th>
          <th scope="col">Ranking</th>
          <th scope="col">Percentile</th>
          <th scope="col">Timeframe</th>
          <th scope="col">Date of Rank</th>
          <th scope="col">H2H W/L/D</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let subtopicRankingActiveForTimeframeType of subtopicRankingsActiveForTimeframeType
          "
          (click)="
            viewAllSubtopicRankingsForTimeframe(
              subtopicRankingActiveForTimeframeType
            )
          "
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              activeSubtopicRankingSet === subtopicRankingActiveForTimeframeType
          }"
          style="cursor: pointer"
        >
          <td>
            {{
              subtopicRankingActiveForTimeframeType.eloValue | number : "1.3-3"
            }}
          </td>

          <td>{{ subtopicRankingActiveForTimeframeType.ranking }}</td>
          <td>
            {{
              subtopicRankingActiveForTimeframeType.percentileRank
                | number : "1.3-3"
            }}
          </td>

          <td>
            {{
              subtopicRankingActiveForTimeframeType.timeAndGameDurationPeriod
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}
          </td>
          <td>
            {{ subtopicRankingActiveForTimeframeType.activeStartTime }}
          </td>
          <td>
            {{ subtopicRankingActiveForTimeframeType.totalH2HWins }}-{{
              subtopicRankingActiveForTimeframeType.totalH2HLosses
            }}-{{ subtopicRankingActiveForTimeframeType.totalH2HDraws }}
          </td>
        </tr>
      </tbody>
    </table>

    <p class="text-center font-weight-bold">
      Veiwing
      {{
        activeSubtopicRankingSet.timeAndGameDurationPeriod
          | allCapsUnderscore
          | lowercase
          | titlecase
      }}
      Subtopic Rankings
    </p>

    <table
      class="table table-striped table-dark table-xs table-hover table-condensed"
    >
      <thead>
        <tr>
          <th scope="col">Elo</th>
          <th scope="col">Ranking</th>
          <th scope="col">Percentile</th>
          <th scope="col">Timeframe</th>
          <th scope="col">Date of Rank</th>
          <th scope="col">H2H W/L/D</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let subtopicRankingAllForTimeFrame of subtopicRankingsAllForTimeFrame
          "
        >
          <td>
            {{ subtopicRankingAllForTimeFrame.eloValue | number : "1.3-3" }}
          </td>

          <td>{{ subtopicRankingAllForTimeFrame.ranking }}</td>

          <td>
            {{
              subtopicRankingAllForTimeFrame.percentileRank | number : "1.3-3"
            }}
          </td>

          <td>
            {{
              subtopicRankingAllForTimeFrame.timeAndGameDurationPeriod
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}
          </td>
          <td>
            {{ subtopicRankingAllForTimeFrame.activeStartTime }}
          </td>
          <td>
            {{ subtopicRankingAllForTimeFrame.totalH2HWins }}-{{
              subtopicRankingAllForTimeFrame.totalH2HLosses
            }}-{{ subtopicRankingAllForTimeFrame.totalH2HDraws }}
          </td>
        </tr>
      </tbody>
    </table>

    <p class="text-center font-weight-bold">Contest Outcomes</p>
    <table
      class="table table-striped table-dark table-xs table-hover table-condensed"
    >
      <thead>
        <tr>
          <!-- <th scope="col">Subtopic Name</th> -->
          <th scope="col">Contest Id</th>
          <th *ngIf="contestType === 'UNDER_OVER'" scope="col">Right Picks</th>
          <th *ngIf="contestType === 'UNDER_OVER'" scope="col">Wrong Picks</th>
          <th *ngIf="contestType === 'MARGIN_OF_ERROR'" scope="col">
            Your MOE
          </th>

          <!-- <th scope="col">Avg Winning Elo</th>
  
        <th scope="col">Avg Losing Elo</th> -->

          <th scope="col">H2H W/L/D</th>

          <th scope="col">Begin/End Elo</th>
          <th scope="col">Rank in Contest</th>
          <th scope="col">Percentile</th>

          <!-- <th scope="col">Elo</th> -->
          <!-- <th scope="col">processed</th>
        <th scope="col">processed Date</th> -->
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <!-- <th scope="col">Size Of Processed Contest Outcomes</th>
        <th scope="col">contest id</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let contestOutcome of filteredContestOutcomes"
          (click)="viewContest(contestOutcome.contestId)"
          style="cursor: pointer"
        >
          <!-- <td>{{ contestOutcome.subtopicName }}</td> -->
          <td>
            {{ contestOutcome.contestId }}
          </td>
          <td *ngIf="contestType === 'UNDER_OVER'">
            {{ contestOutcome.totalWins }}
          </td>
          <td *ngIf="contestType === 'UNDER_OVER'">
            {{ contestOutcome.totalLosses }}
          </td>
          <td *ngIf="contestType === 'MARGIN_OF_ERROR'">
            {{ contestOutcome.avgMarginOfError | number : "1.2-5" }}
          </td>
          <!-- <td>{{ contestOutcome.avgWinningElo }}</td>
        <td>{{ contestOutcome.avgLosingElo }}</td> -->

          <td>
            {{ contestOutcome.totalHeadToHeadWins }}-{{
              contestOutcome.totalHeadToHeadLosses
            }}-{{ contestOutcome.totalHeadToHeadDraws }}
          </td>

          <td>
            {{ contestOutcome.startingEloValue | number : "1.3-3" }}-->
            {{ contestOutcome.endingEloValue | number : "1.3-3" }}
          </td>
          <td>{{ contestOutcome.rankingWithinSingleContest }}</td>
          <td>{{ contestOutcome.percentileRank | number : "1.3-3" }}</td>

          <!-- <td>{{ contestOutcome.eloValue | number : "1.3-3" }}</td> -->
          <!-- <td>{{ contestOutcome.processed }}</td>
        <td>{{ contestOutcome.processedDate | date : "short" }}</td> -->
          <td>{{ contestOutcome.startOfContestDate | date : "short" }}</td>

          <td>{{ contestOutcome.endOfContestDate | date : "short" }}</td>
          <!-- <td>{{ contestOutcome.sizeOfProcessedContestOutcomes }}</td>
        <td>{{ contestOutcome.contestId }}</td> -->
        </tr>
      </tbody>
    </table>
    <div *ngIf="!endOfContestOutcomes">
      <button (click)="onScroll()">see more</button>
    </div>
    <div *ngIf="endOfContestOutcomes">Loaded all outcomes</div>

    <div *ngIf="contestOutcomesForSingleContest">
      <table
        class="table table-striped table-dark table-xs table-hover table-condensed"
      >
        <thead>
          <tr>
            <th scope="col">User id and name</th>
            <th scope="col">Subtopic Name</th>
            <th scope="col">Total wins</th>
            <th scope="col">Total Losses</th>
            <th scope="col">Avg Winning Elo</th>

            <th scope="col">Avg Losing Elo</th>

            <th scope="col">Total H2H W/L/D</th>

            <th scope="col">Begin/End Elo</th>

            <th scope="col">rankingWithinSingleContest</th>
            <th scope="col">Percentile</th>

            <!-- <th scope="col">eloValue</th> -->
            <!-- <th scope="col">processed</th>
          <th scope="col">processedDate</th> -->
            <th scope="col">startOfContestDate</th>
            <th scope="col">endOfContestDate</th>
            <!-- <th scope="col">sizeOfProcessedContestOutcomes</th>
          <th scope="col">contestid</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contestOutcome of contestOutcomesForSingleContest">
            <td>{{ contestOutcome.userId }}--{{ contestOutcome.username }}</td>
            <td>{{ contestOutcome.subtopicName }}</td>
            <td>{{ contestOutcome.totalWins }}</td>
            <td>{{ contestOutcome.totalLosses }}</td>
            <td>{{ contestOutcome.avgWinningElo }}</td>
            <td>{{ contestOutcome.avgLosingElo }}</td>

            <td>
              {{ contestOutcome.totalHeadToHeadWins }}-{{
                contestOutcome.totalHeadToHeadLosses
              }}-{{ contestOutcome.totalHeadToHeadDraws }}
            </td>

            <td>
              {{ contestOutcome.startingEloValue | number : "1.3-3" }}-->
              {{ contestOutcome.endingEloValue | number : "1.3-3" }}
            </td>
            <td>{{ contestOutcome.rankingWithinSingleContest }}</td>
            <td>{{ contestOutcome.percentileRank | number : "1.3-3" }}</td>

            <!-- <td>{{ contestOutcome.eloValue | number : "1.3-3" }}</td> -->
            <!-- <td>{{ contestOutcome.processed }}</td>
          <td>{{ contestOutcome.processedDate }}</td> -->
            <td>{{ contestOutcome.startOfContestDate }}</td>

            <td>{{ contestOutcome.endOfContestDate }}</td>
            <!-- <td>{{ contestOutcome.sizeOfProcessedContestOutcomes }}</td> -->
            <!-- <td>{{ contestOutcome.contestId }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div *ngIf="isMobile">
  <div *ngIf="!!!filteredContestOutcomes || filteredContestOutcomes.length < 1">
    <p>No Contest Outcomes for {{ subtopic.name }}</p>
  </div>

  <div *ngIf="!!filteredContestOutcomes && filteredContestOutcomes.length >= 1">
    <div>
      <p class="text-center font-weight-bold">Active/Final Subtopic Rankings</p>
      <div class="d-flex justify-content-center">
        <div class="text-center my-2 mx-auto px-2">
          <table
            class="table table-striped table-dark table-xs table-hover table-condensed"
            style="width: 100%; table-layout: fixed"
          >
            <thead>
              <tr>
                <th scope="col">Elo</th>
                <th scope="col">Ranking</th>
                <th scope="col">Percentile</th>
                <th scope="col">Timeframe</th>
                <th scope="col">Date of Rank</th>
                <th scope="col">H2H W/L/D</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let subtopicRankingActiveForTimeframeType of subtopicRankingsActiveForTimeframeType
                "
                (click)="
                  viewAllSubtopicRankingsForTimeframe(
                    subtopicRankingActiveForTimeframeType
                  )
                "
                [ngClass]="{
                  'font-weight-bold text-success font-italic':
                    activeSubtopicRankingSet ===
                    subtopicRankingActiveForTimeframeType
                }"
              >
                <td>
                  {{
                    subtopicRankingActiveForTimeframeType.eloValue
                      | number : "1.3-3"
                  }}
                </td>

                <td>{{ subtopicRankingActiveForTimeframeType.ranking }}</td>
                <td>
                  {{
                    subtopicRankingActiveForTimeframeType.percentileRank
                      | number : "1.3-3"
                  }}
                </td>

                <td>
                  {{
                    subtopicRankingActiveForTimeframeType.timeAndGameDurationPeriod
                      | allCapsUnderscore
                      | lowercase
                      | titlecase
                  }}
                </td>
                <td>
                  {{ subtopicRankingActiveForTimeframeType.activeStartTime }}
                </td>
                <td>
                  {{ subtopicRankingActiveForTimeframeType.totalH2HWins }}-{{
                    subtopicRankingActiveForTimeframeType.totalH2HLosses
                  }}-{{ subtopicRankingActiveForTimeframeType.totalH2HDraws }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div>
          <p class="text-center font-weight-bold">
            {{
              activeSubtopicRankingSet.timeAndGameDurationPeriod
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}
            Subtopic Rankings
          </p>
          <div class="d-flex justify-content-center">
            <div class="text-center my-2 mx-auto">
              <table
                class="table table-striped table-dark table-xs table-hover table-condensed"
                style="width: 100%; table-layout: fixed"
              >
                <thead>
                  <tr>
                    <th scope="col">Elo</th>
                    <th scope="col">Ranking</th>
                    <th scope="col">Percentile</th>
                    <th scope="col">Timeframe</th>
                    <th scope="col">Date of Rank</th>
                    <th scope="col">H2H W/L/D</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let subtopicRankingAllForTimeFrame of subtopicRankingsAllForTimeFrame
                    "
                  >
                    <td>
                      {{
                        subtopicRankingAllForTimeFrame.eloValue
                          | number : "1.3-3"
                      }}
                    </td>

                    <td>{{ subtopicRankingAllForTimeFrame.ranking }}</td>
                    <td>
                      {{
                        subtopicRankingAllForTimeFrame.percentileRank
                          | number : "1.3-3"
                      }}
                    </td>

                    <td>
                      {{
                        subtopicRankingAllForTimeFrame.timeAndGameDurationPeriod
                          | allCapsUnderscore
                          | lowercase
                          | titlecase
                      }}
                    </td>
                    <td>
                      {{ subtopicRankingAllForTimeFrame.activeStartTime }}
                    </td>
                    <td>
                      {{ subtopicRankingAllForTimeFrame.totalH2HWins }}-{{
                        subtopicRankingAllForTimeFrame.totalH2HLosses
                      }}-{{ subtopicRankingAllForTimeFrame.totalH2HDraws }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div>
          <p class="text-center font-weight-bold">Contest Outcomes</p>
          <div class="d-flex justify-content-center">
            <div class="text-center my-2 mx-auto">
              <table
                class="table table-striped table-dark table-xs table-hover table-condensed"
                style="width: 100%; table-layout: fixed"
              >
                <thead>
                  <tr>
                    <!-- <th scope="col">Subtopic Name</th> -->
                    <th scope="col">Contest Id</th>
                    <th *ngIf="contestType === 'UNDER_OVER'" scope="col">
                      Right/ Wrong
                    </th>

                    <th *ngIf="contestType === 'MARGIN_OF_ERROR'" scope="col">
                      MOE
                    </th>

                    <!-- <th scope="col">Avg Winning Elo</th>
        
              <th scope="col">Avg Losing Elo</th> -->

                    <th scope="col">H2H W/L/D</th>

                    <th scope="col">Begin/End Elo</th>
                    <th scope="col">Rank in Contest</th>
                    <th scope="col">Percentile</th>

                    <!-- <th scope="col">Elo</th> -->
                    <!-- <th scope="col">processed</th>
              <th scope="col">processed Date</th> -->
                    <th scope="col">Start/End Date</th>
                    <!-- <th scope="col">Size Of Processed Contest Outcomes</th>
              <th scope="col">contest id</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let contestOutcome of filteredContestOutcomes"
                    (click)="viewContest(contestOutcome.contestId)"
                    style="cursor: pointer"
                  >
                    <!-- <td>{{ contestOutcome.subtopicName }}</td> -->
                    <td>
                      {{ contestOutcome.contestId }}
                    </td>
                    <td *ngIf="contestType === 'UNDER_OVER'">
                      {{ contestOutcome.totalWins }}/{{
                        contestOutcome.totalLosses
                      }}
                    </td>
                    <td *ngIf="contestType === 'MARGIN_OF_ERROR'">
                      {{ contestOutcome.avgMarginOfError | number : "1.2-5" }}
                    </td>
                    <!-- <td>{{ contestOutcome.avgWinningElo }}</td>
              <td>{{ contestOutcome.avgLosingElo }}</td> -->

                    <td>
                      {{ contestOutcome.totalHeadToHeadWins }}-{{
                        contestOutcome.totalHeadToHeadLosses
                      }}-{{ contestOutcome.totalHeadToHeadDraws }}
                    </td>

                    <td>
                      {{
                        contestOutcome.startingEloValue | number : "1.2-2"
                      }}-{{ contestOutcome.endingEloValue | number : "1.2-2" }}
                    </td>

                    <td>{{ contestOutcome.rankingWithinSingleContest }}</td>

                    <td>
                      {{ contestOutcome.percentileRank | number : "1.3-3" }}
                    </td>
                    <!-- <td>{{ contestOutcome.eloValue | number : "1.3-3" }}</td> -->
                    <!-- <td>{{ contestOutcome.processed }}</td>
              <td>{{ contestOutcome.processedDate | date : "short" }}</td> -->
                    <td>
                      {{
                        contestOutcome.startOfContestDate | date : "short"
                      }}/{{ contestOutcome.endOfContestDate | date : "short" }}
                    </td>

                    <!-- <td>{{ contestOutcome.sizeOfProcessedContestOutcomes }}</td>
              <td>{{ contestOutcome.contestId }}</td> -->
                  </tr>
                </tbody>
              </table>
              <div *ngIf="!endOfContestOutcomes">
                <button (click)="onScroll()">see more</button>
              </div>
              <div *ngIf="endOfContestOutcomes">Loaded all outcomes</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
