import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog, Links, Page, PaginatedBlog } from 'src/app/media/media.component';
import { MeService } from '../me.service';
import { SubTopicsEntityOrOwnedSubtopicsEntity, TopicsEntity } from '../../User/user.service';
import { SubtopicService } from '../../../topic/subtopic/subtopic.service';
import { PaginatedUnderOverOutcomeRanking } from 'src/app/contest/contest-state.service';
import { PaginatedSubtopicRanking, SubtopicPaginatedBlogMap } from 'src/app/contest/contest.component';
import { BlogService } from 'src/app/media/blog/blog.service';
import { TopicService } from '../../../topic/topic.service';
import { GlobalContestState, UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from '../../../Aggregation/subtopic-ranking.service';
import { Tag } from 'src/app/guru/guru.component';
import { ContestType } from 'src/app/contest/game/game.service';
import { InviteLinkService } from 'src/app/shared/invite-link.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-your-virtual-account',
  templateUrl: './your-virtual-account.component.html',
  styleUrls: ['./your-virtual-account.component.css']
})
export class YourVirtualAccountComponent implements OnInit {


  timeAndGameDurationPeriodTypesList = [
    // TimeAndGameDurationPeriodType.ALL_GAMES_ALL_TIME,
    // TimeAndGameDurationPeriodType.WEEK,
    TimeAndGameDurationPeriodType.YEAR,
    TimeAndGameDurationPeriodType.MONTH
  ];

  contestTypesFinance = [ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
  contestTypesSports = [ContestType.MARGIN_OF_VICTORY, ContestType.TOTAL_POINTS_SCORED];

  topicSelectedIsSports = false;

  currentTopic: TopicsEntity;
  currentSubtopic;
  createContestFlag = false;
  enterExistingContestFlag = false;
  topicRankings: PaginatedUnderOverOutcomeRanking;
  subtopicRankings: PaginatedSubtopicRanking;
  GlobalContestState = GlobalContestState;
  paginatedBlogs: PaginatedBlog;
  canExcecuteRankingsNow = false;
  subtopicBlogSetMap: SubtopicPaginatedBlogMap[] = [];
  newPaginatedBlog: PaginatedBlog;
  upVotesPaginatedBlog: PaginatedBlog;
  accountTotalPaginatedBlog: PaginatedBlog;
  currentBlog: Blog;
  data: string;
  // contestTypesFinance = [
  //   'MARGIN_OF_ERROR',
  //   'UNDER_OVER'
  // ];

  // contestTypesSports = [
  //   'TOTAL_POINTS_SCORED',
  //   'MARGIN_OF_VICTORY'
  // ];


  selectedTag: Tag;
  private _filteredTagList: Tag[];
  private _originalTagList: Tag[];
  contestType: string;
  timeframeType: TimeAndGameDurationPeriodType;

  constructor(private router: Router, private route: ActivatedRoute,
              private userService: MeService, private subtopicService: SubtopicService,
              private blogService: BlogService, private topicService: TopicService,
              private subtopicRankingService: SubtopicRankingService, private clipboard: Clipboard,
              private inviteLinkService: InviteLinkService) { }

  ngOnInit(): void {
    this.topics.forEach((topic: TopicsEntity) => {
      if (topic.name === 'Finance'){
        this.selectTopic(topic);
      }
    });
  }

  selectTopic(topic: TopicsEntity): void {
    this.topicService.currentTopic = topic;
    this.currentTopic = topic;
    this.getTopicRankings(topic);
    this.selectSubtopic(this.currentTopic.subtopics[0]);
  }

  getTopicRankings(topic: TopicsEntity): void{
    this.topicService.currentTopic = topic;
    // this.contestStateService.currentContest.topicReference = null;

  }
  get subtopics(): any{
    if ( this.currentTopic &&  this.currentTopic.subtopics ){
      return this.currentTopic.subtopics;
    }

  }

  isSelectedTag(tag: Tag): boolean {
    return this.selectedTag === tag;
  }

  selectTag(tag: Tag): void {
    this.selectedTag = tag;
  }

  get topics(): any{
    return this.topicService.allTopics?._embedded?.topicDTOList;
   }


  isSelected(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): boolean {
    return this.subtopicService.currentSubtopic === subtopic;
  }

  subtopicSelected(): boolean{
    // tslint:disable-next-line:max-line-length
    if (this.subtopicService.currentSubtopic ){
      return true;
    }
    return false;
  }

  selectSubtopic(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
    this.subtopicService.currentSubtopic = subtopic;
    if (subtopic.name === 'All'){
      return;
    }
    this.currentSubtopic = subtopic;

    this.subtopicRankingService.getAllTagsForSubtopic(subtopic.id, true, false, false, false, this.userService.user.id, false).subscribe(
      (result) => {
        this._originalTagList = result._embedded?.tagList;
        this._filteredTagList = result._embedded?.tagList;
        this.emptyOutcomes();
        this._filteredTagList?.forEach(tag => {
          if (tag.name.toLowerCase() === subtopic.name.toLowerCase()){
            // this.selectTag(this._filteredTagList[0]);
            this.selectTag(tag);
            if (this.currentTopic.name.toUpperCase() === 'SPORTS'){
              this.selectContestType(this.contestTypesSports[0]);
              this.topicSelectedIsSports = true;
            }
            else{
              this.selectContestType(this.contestTypesFinance[0]);
              this.topicSelectedIsSports = false;
            }
            this.selectTimeframeType(this.timeAndGameDurationPeriodTypesList[0]);
            this.subtopicRankingService.getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframePercentileRank(
              this.userId,
              this.subtopicService.currentSubtopic.id,  this.contestType, TimeAndGameDurationPeriodType.YEAR
            ).subscribe((rankingsResult: PaginatedSubtopicRanking) => {
                 // tslint:disable-next-line:no-unused-expression
                 const tempList = [];
                 this._filteredTagList.forEach(tagResult => {
                    rankingsResult._embedded.subtopicRankingDTOList.forEach(subtopicDTO => {
                    if (subtopicDTO.tagId === tagResult.id){
                      const tempTag = cloneDeep(tagResult);
                      tempTag.percentileForSubtopicRanking = subtopicDTO.percentileRank;
                      tempList.push(tempTag);
                      return;
                    }
                  });
                 });
                 this._filteredTagList = tempList;
                 this._filteredTagList.sort((a, b) => b.percentileForSubtopicRanking - a.percentileForSubtopicRanking);
            });
          }
        });
      }
    );
  }
  emptyOutcomes(): void {
    this.selectedTag = null;
    this.contestType = null;
  }
  get filteredTagList(): Tag[]{
    return this._filteredTagList;
  }


  selectTimeframeType(timeframeType: TimeAndGameDurationPeriodType): void{
    this.timeframeType = timeframeType;
    this.subtopicRankingService.getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframePercentileRank(
      this.userId,
      this.subtopicService.currentSubtopic.id,  this.contestType, this.timeframeType
    ).subscribe((rankingsResult: PaginatedSubtopicRanking) => {
         // tslint:disable-next-line:no-unused-expression
         const tempList = [];
         this._filteredTagList = this._originalTagList;
         this._filteredTagList.forEach(tagResult => {
            rankingsResult._embedded.subtopicRankingDTOList.forEach(subtopicDTO => {
            if (subtopicDTO.tagId === tagResult.id){
              const tempTag = cloneDeep(tagResult);
              tempTag.percentileForSubtopicRanking = subtopicDTO.percentileRank;
              tempList.push(tempTag);
              return;
            }
          });
         });
         this._filteredTagList = tempList;
         this._filteredTagList.sort((a, b) => b.percentileForSubtopicRanking - a.percentileForSubtopicRanking);
        //  this._filteredTagList = [];
        //  this._filteredTagList = tempList;
    });
  }

  isSelectedContestType(contestType: string): boolean {
    return this.contestType === contestType;
  }

  isSelectedtimeframeType(timeframeType: TimeAndGameDurationPeriodType): boolean {
    return this.timeframeType === timeframeType;
  }

  selectContestType(contestType: string): void {
    this.contestType = contestType;
    this.subtopicRankingService.getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframePercentileRank(
      this.userId,
      this.subtopicService.currentSubtopic.id,  this.contestType, this.timeframeType
    ).subscribe((rankingsResult: PaginatedSubtopicRanking) => {
         // tslint:disable-next-line:no-unused-expression
         const tempList = [];
         this._filteredTagList = this._originalTagList;
         this._filteredTagList.forEach(tagResult => {
            rankingsResult._embedded.subtopicRankingDTOList.forEach(subtopicDTO => {
            if (subtopicDTO.tagId === tagResult.id){
              const tempTag = cloneDeep(tagResult);
              tempTag.percentileForSubtopicRanking = subtopicDTO.percentileRank;
              tempList.push(tempTag);
              return;
            }
          });
         });
         this._filteredTagList = tempList;
         this._filteredTagList.sort((a, b) => b.percentileForSubtopicRanking - a.percentileForSubtopicRanking);
        //  this._filteredTagList = [];
        //  this._filteredTagList = tempList;
    });
  }

  canCallForOutcomes(): boolean {
    return !!this.currentSubtopic && !!this.contestType && !!this.selectedTag && !!this.timeframeType;
 }

 get userId(): number{
  return this.userService.user.id;
 }

 copyURL(): void {
  console.log('attempt copy to clipboard');
  this.inviteLinkService.getYourInviteLink().subscribe(result => {
    console.log('result is ' + result);
    this.clipboard.copy( location.origin + '/signup?inviter=' + result.message);
    console.log('copied to clipboard ONE');
  });
  console.log('copied to clipboard');
}
isSelectedTopic(topic: TopicsEntity): boolean {
  return this.topicService.currentTopic === topic;
}

}



