import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { SocialLink } from '../../Me/your-social-links/your-social-links.component';

@Injectable({
  providedIn: 'root'
})
export class UserSocialLinksService {

  constructor(private httpClient: HttpClient) { }

  getUserSocialLinks(userId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/social-link/user/${userId}`);
  }

  postYourSocialLink(socialLink: SocialLink): Observable<any> {
    return this.httpClient.post(HOST_IP_INFO_MARKETS + `/social-link/me/add-link`, socialLink);
  }

  deleteYourSocialLink(id: number): Observable<any> {
    return this.httpClient.delete(HOST_IP_INFO_MARKETS + `/social-link/me/delete-link/${id}`);
  }

}
