<div *ngIf="isMobile && !!!unauthorizedToEdit">
  <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
  <div class="row">
    <div
      *ngFor="let topic of topics; let i = index"
      (click)="selectTopic(topic)"
      class="col-4"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedTopic(topic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ topic.name }}
    </div>
  </div>
  <p class="text-center font-weight-bold" *ngIf="!isEdit">
    <u>Select a Subtopic</u>
  </p>
  <p class="text-center" *ngIf="isEdit">Subtopic</p>
  <div class="row" *ngIf="!isEdit">
    <div
      *ngFor="let subtopic of currentTopic.subtopics; let i = index"
      (click)="selectSubTopic(subtopic)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelected(subtopic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ subtopic.name }}
    </div>
  </div>
  <div class="row" *ngIf="!isEdit">
    <div
      *ngIf="isEdit"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelected(subtopic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ subtopic.name }}
    </div>
  </div>

  <p class="text-center font-weight-bold"><u>Select a Tag</u></p>
  <p class="text-center font-weight-bold" style="font-size: 8px">
    (Note: You may only choose a Tag for Contests you have played in - If a tag
    is unavaiable for you, enter a contest that is tagged with the category you
    want to write about)
  </p>
  <div class="row">
    <div
      *ngFor="let tag of filteredTagList; let i = index"
      (click)="selectTag(tag)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic': isSelectedTag(tag) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ tag.name }}
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12">
      <h5 class="text-center ml-4 mt-1 font-weight-light">Blog Editor</h5>

      <quill-editor
        [(ngModel)]="quilTitle"
        [styles]="{ height: '50px' }"
        (onFocus)="focus($event)"
        (onEditorChanged)="changedEditor($event)"
        (onBlur)="blur($event)"
        (onEditorCreated)="created($event)"
        style="text-align: center"
        placeholder="Write Title Here"
      >
        <div
          quill-editor-toolbar
          style="
            display: none;
            text-align: center;
            border-bottom: 1px solid black;
          "
        ></div>
      </quill-editor>

      <quill-editor
        [(ngModel)]="quil"
        [styles]="{ height: '200px' }"
        (onFocus)="focus($event)"
        (onEditorChanged)="changedEditor($event)"
        (onBlur)="blur($event)"
        (onEditorCreated)="created($event)"
        placeholder="Write Blog Here"
      ></quill-editor>
      <button
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="saveBlog()"
        [ngClass]="{
          'btn-sm btn-secondary text-center': canSaveNewBlog() === false,
          'btn-sm btn-success text-center': canSaveNewBlog() === true,
          scratched: !canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()
        }"
        [disabled]="!canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()"
      >
        Save and continue later
      </button>
      <button
        [disabled]="!canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()"
        class="btn-sm btn-success ml-4 mt-1 font-weight-light"
        [ngClass]="{
          'btn-sm btn-secondary text-center': canSaveNewBlog() === false,
          'btn-sm btn-success text-center': canSaveNewBlog() === true,
          scratched: !canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()
        }"
        (click)="saveBlog()"
      >
        Save and publish
      </button>
      <button
        class="btn-sm btn-danger ml-4 mt-1 font-weight-light"
        (click)="viewExistingBlogsAndClearEditor()"
      >
        Discard blog
      </button>
    </div>
  </div>
  {{ messageOnSave }}
</div>

<div *ngIf="!isMobile && !!!unauthorizedToEdit">
  <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
  <div class="row">
    <div
      *ngFor="let topic of topics; let i = index"
      (click)="selectTopic(topic)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedTopic(topic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ topic.name }}
    </div>
  </div>
  <p class="text-center font-weight-bold" *ngIf="!isEdit">
    <u>Select a Subtopic</u>
  </p>
  <p class="text-center" *ngIf="isEdit">Subtopic</p>
  <div class="row" *ngIf="!isEdit">
    <div
      *ngFor="let subtopic of currentTopic.subtopics; let i = index"
      (click)="selectSubTopic(subtopic)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelected(subtopic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ subtopic.name }}
    </div>
  </div>
  <div *ngIf="isEdit">
    <p
      *ngIf="isEdit && !!currentSubtopic"
      class="text-center"
      style="font-size: 12px; cursor: pointer"
    >
      <b>[{{ currentSubtopic.name }}]</b>
    </p>
  </div>
  <p class="text-center font-weight-bold" *ngIf="!isEdit">
    <u>Select a Tag</u>
  </p>
  <p class="text-center" *ngIf="isEdit">Tag(s)</p>
  <div class="row" *ngIf="!isEdit">
    <div
      *ngFor="let tag of filteredTagList; let i = index"
      (click)="selectTag(tag)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic': isSelectedTag(tag) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ tag.name }}
    </div>
  </div>
  <div *ngIf="isEdit && !!currentBlog && !!currentBlog.tagReferenceList">
    <div
      *ngFor="let tag of currentBlog.tagReferenceList; let i = index"
      class="text-center"
      style="font-size: 12px; cursor: pointer"
    >
      <b>[{{ tag.name }}]</b>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12">
      <h5 class="text-center mt-2 font-weight-bold" style="font-size: 18px">
        <u>Blog Editor</u>
      </h5>

      <quill-editor
        [(ngModel)]="quilTitle"
        [styles]="{ height: '50px' }"
        (onFocus)="focus($event)"
        (onEditorChanged)="changedEditor($event)"
        (onBlur)="blur($event)"
        (onEditorCreated)="created($event)"
        style="text-align: center"
        placeholder="Write Title Here"
      >
        <div
          quill-editor-toolbar
          style="
            display: none;
            text-align: center;
            border-bottom: 1px solid black;
          "
        ></div>
      </quill-editor>

      <quill-editor
        [(ngModel)]="quil"
        [styles]="{ height: '200px' }"
        (onFocus)="focus($event)"
        (onEditorChanged)="changedEditor($event)"
        (onBlur)="blur($event)"
        (onEditorCreated)="created($event)"
        placeholder="Write Blog Here"
      ></quill-editor>
      <button
        *ngIf="!!!isEdit"
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="saveBlog()"
        [disabled]="!canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()"
        [ngClass]="{
          'btn-sm btn-secondary text-center': canSaveNewBlog() === false,
          'btn-sm btn-success text-center': canSaveNewBlog() === true,
          scratched: !canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()
        }"
      >
        Save and continue later
      </button>
      <button
        *ngIf="!!!isEdit"
        class="btn-sm btn-success ml-4 mt-1 font-weight-light"
        (click)="saveBlog()"
        [disabled]="!canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()"
        [ngClass]="{
          'btn-sm btn-secondary text-center': canSaveNewBlog() === false,
          'btn-sm btn-success text-center': canSaveNewBlog() === true,
          scratched: !canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()
        }"
      >
        Save and publish
      </button>
      <button
        *ngIf="!!isEdit"
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="updateAndDontPublishBlog()"
        [disabled]="!canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()"
        [ngClass]="{
          'btn-sm btn-secondary text-center': canSaveNewBlog() === false,
          'btn-sm btn-success text-center': canSaveNewBlog() === true,
          scratched: !canSaveNewBlog() || !titleAndBodyPopulatedWithMinimum()
        }"
      >
        Save update and continue later
      </button>
      <button
        *ngIf="!!isEdit"
        class="btn-sm btn-success ml-4 mt-1 font-weight-light"
        (click)="updateAndPublishBlog()"
      >
        Update and publish
      </button>
      <button
        *ngIf="!!isEdit"
        class="btn-sm btn-danger ml-4 mt-1 font-weight-light"
        (click)="deleteBlog()"
      >
        Delete Blog
      </button>
      <button
        class="btn-sm btn-danger ml-4 mt-1 font-weight-light"
        (click)="viewExistingBlogsAndClearEditor()"
      >
        Discard blog
      </button>
    </div>
  </div>
  <div *ngIf="!canSaveNewBlog()" class="bg-danger">
    You must select a Topic, Subtopic and Tag. If you have not competed in a
    contest with any of these items within the last 60 days, you are ineligble
    to write an article for this set of items
  </div>
  <div *ngIf="!titleAndBodyPopulatedWithMinimum()" class="bg-info">
    You must have a title and a body of at least 100 characters long
  </div>
  <div class="bg-danger">
    {{ messageOnSave }}
  </div>
</div>
