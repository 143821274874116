<head>
  <title>Checkout</title>
  <script src="https://js.stripe.com/v3/"></script>
</head>
<!-- <div> Current Special - 3 day trial period - If you dont like our product you can cancel any subscription at any time within the first 3 days in the Account Settings Tab and you will not be charged</div> -->
<div class="container ml-1 mt-4">
  <div class="row">
    <div class="col-sm">
      <div class="card" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">Basic</h5>
          <p class="card-text">Daily Subscription: $10</p>
          <button class="btn btn-primary">Click to subscribe now</button>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm">
      <div class="card" style="width: 18rem" (click)="checkout('weekly')">
        <div class="card-body">
          <h5 class="card-title">Weekly</h5>
          <p class="card-text">Weekly Subscription: $29.95</p>
          <button (click)="checkout('weekly')" class="btn btn-primary">
            Click to subscribe now
          </button>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-sm">
      <div class="card" style="width: 18rem" (click)="checkout('monthly')">
        <div class="card-body">
          <h5 class="card-title">Monthly</h5>
          <p class="card-text">Monthly Subscription: $45.00</p>
          <button (click)="checkout('monthly')" class="btn btn-primary">
            Click to subscribe now
          </button>
        </div>
      </div>
    </div> -->
  </div>
</div>
