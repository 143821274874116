<div class="container font-weight-light">
  <div class="row">
    <form class="col-md-6">
      <label for="Email" class="font-weight-bold">Email</label>
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          [placeholder]="email"
          readonly
        />
      </div>
      <!-- <div class="form-group">
        <label for="exampleInputPassword1" class="font-weight-bold"
          >Phone Number</label
        >
        <input
          type="password"
          class="form-control"
          id="exampleInputPassword1"
          [placeholder]="accountInfo.phoneNumber"
          readonly
        />
      </div> -->
    </form>
  </div>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card" style="width: 18rem" (click)="createCustomerPortal()">
          <div class="card-body">
            <h5 class="card-title font-weight-bold">Manage Payment</h5>
            <p class="card-text font-weight-light">
              Upgrade, downgrade, change payment information, or cancel
              subscritpion
            </p>
            <button
              (click)="createCustomerPortal()"
              class="btn-sm btn-secondary font-weight-light"
            >
              Manage Subscription
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="hasSubscription">
      <table
        class="col-10 table table-striped table-dark mx-1 my-1 table-xs table-hover table-condensed"
      >
        <thead>
          <tr>
            <th scope="col">Period Start</th>
            <th scope="col">Period End</th>
            <th scope="col">Price</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ subscription.billingPeriodStartDate | date }}</td>
            <td>{{ subscription.billingPeriodEndDate | date }}</td>
            <td>{{ subscription.price }}</td>
            <td>{{ subscription.status }}</td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="!!subscriptionMessage">
        <p>{{ subscriptionMessage }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 font-weight-light">
      <label for="pwd" class="ml-1 mt-2"
        >Enter password to delete account:</label
      >
      <br />
      <input type="password" #userPassword class="ml-1 mt-1" />
      <br />
      <button
        class="btn-sm btn-danger ml-1 mt-2 font-weight-light"
        (click)="deleteAccount(userPassword)"
      >
        Clicking this button with password entered will delete your account!
      </button>
    </div>
  </div>
</div>

<!-- <app-signup-payment-information></app-signup-payment-information> -->
