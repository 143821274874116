<div *ngIf="!isMobile">
  <div *ngIf="canViewBlog">
    <div class="row">
      <div
        [ngClass]="{
          'bg-white mt-4 col-8': isMobile === false,
          'bg-white  col-12': isMobile === true
        }"
      >
        <div class="row my-4">
          <div class="col-1" style="margin-top: -2em">
            <div class="row">
              <span
                class="material-icons"
                (click)="upvoteBlog(blogService.currentBlog)"
                >keyboard_arrow_up</span
              >
            </div>
            <div class="row">
              <span
                style="white-space: pre-line; font-size: 11px; color: green"
                >{{ upvotes }}</span
              >
            </div>
            <div class="row">
              <span
                style="white-space: pre-line; font-size: 11px; color: red"
                >{{ downvotes }}</span
              >
            </div>
            <div class="row">
              <span
                class="material-icons"
                (click)="downvoteBlog(blogService.currentBlog)"
                >keyboard_arrow_down</span
              >
            </div>
          </div>

          <div
            *ngIf="!isMobile"
            style="white-space: pre-line; font-size: 28px"
            class="col-10 font-weight-heavy d-flex justify-content-center ql-editor"
            [innerHTML]="blogService.blogSafeHtmlTitle"
          ></div>
          <div
            *ngIf="isMobile"
            style="white-space: pre-line; font-size: 12px"
            class="col-10 font-weight-heavy d-flex justify-content-center ql-editor"
            [innerHTML]="blogService.blogSafeHtmlTitle"
          ></div>
          <div class="row">
            <p
              class="ml-2"
              style="white-space: pre-line; font-size: 8px"
              *ngIf="isMobile"
            >
              {{ date_written | date : "medium" }}
            </p>

            <p
              class="ml-2"
              style="white-space: pre-line; font-size: 8px"
              *ngIf="isMobile"
            >
              page visits: {{ pageVisits }}
            </p>
            <p
              class="ml-2"
              style="white-space: pre-line; font-size: 8px"
              *ngIf="isMobile"
            >
              logged in user views: {{ loggedInUserViews }}
            </p>
            <!-- <div
              *ngIf="!isMobile"
              class="col-1"
              style="white-space: pre-line; font-size: 11px; margin-top: -2em"
            >
              <p>Page Visits: {{ pageVisits }}</p>
              <p>Logged in User Views: {{ loggedInUserViews }}</p>
            </div> -->
          </div>
        </div>
        <!-- <div
          style="white-space: pre-line; font-size: 11px"
          class="col-12"
          [innerHTML]="copy.contentStringSafeHtml"
        ></div> -->
        <!-- <div class="col-12" style="white-space: pre-line; font-size: 11px">
          <quill-editor
            [(ngModel)]="copy.contentStringSafeHtml"
            [readOnly]="true"
            [modules]="{ toolbar: false }"
            [style]="{ border: '0px' }"
          ></quill-editor>
        </div> -->
        <div
          *ngIf="!isMobile"
          class="ql-container"
          style="font-size: 11px; border-width: 0; height: auto"
        >
          <!-- style="font-size: 11px; height: '200px'" -->
          <div class="ql-editor" [innerHTML]="copy.contentStringSafeHtml"></div>
        </div>
        <div
          *ngIf="isMobile"
          class="ql-container"
          style="border-width: 0"
          style="font-size: 11px; border-width: 0; height: auto"
        >
          <!-- style="font-size: 10px; height: '500px'" -->
          <div class="ql-editor" [innerHTML]="copy.contentStringSafeHtml"></div>
        </div>
        <div class="col-12">
          <quill-editor
            [(ngModel)]="quil"
            [styles]="{ height: '125px' }"
            (onFocus)="focus($event)"
            (onEditorChanged)="changedEditor($event)"
            (onBlur)="blur($event)"
            (onEditorCreated)="created($event)"
            [placeholder]="commentPlaceholderValue"
          ></quill-editor>

          <button
            class="btn-sm btn-success ml-4 mt-1 font-weight-light"
            (click)="addComment(quil)"
            [ngClass]="{
              scratched: !isAuthenticated()
            }"
          >
            Add Comment
          </button>
          <button
            class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
            (click)="discardComment()"
            [ngClass]="{
              scratched: !isAuthenticated()
            }"
          >
            Discard Comment
          </button>
          <button
            class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
            (click)="goBackToPreviousPage()"
          >
            Go Back to other blogs
          </button>
          <button
            (click)="editBlog()"
            *ngIf="isAuthenticatedAndIsAuthorOfBlog()"
            class="btn-sm btn-primary ml-4 mt-1 font-weight-light"
          >
            Update or Delete Blog
          </button>

          <!-- <button class="btn-sm btn-secondary ml-4 mt-1 font-weight-light">
            share
          </button> -->
        </div>
        <div style="white-space: pre-line" class="border"></div>

        <div
          infinite-scroll
          [infiniteScrollDistance]="distance"
          [infiniteScrollThrottle]="throttle"
          (scrolled)="onScroll()"
        >
          <div *ngFor="let _comment of viewableComments">
            <div class="row my-2 bg-white border">
              <div class="col-2">
                <div
                  class="row"
                  style="
                    white-space: pre-line;
                    font-size: 10px;
                    cursor: pointer;
                    font-weight: bold;
                  "
                  (click)="viewUserProfile(_comment._owner_id)"
                >
                  {{ _comment.username }}
                </div>
                <div class="row" style="white-space: pre-line; font-size: 9px">
                  {{ _comment.date | date : "medium" }}
                </div>
              </div>
              <!-- <div
              style="white-space: pre-line; font-size: 11px"
              class="col-8"
              [innerHTML]="_comment.contentStringSafeHtml"
            ></div> -->
              <div
                class="ql-container col-8"
                style="border-width: 0"
                style="font-size: 11px; height: '100px'"
              >
                <div
                  class="ql-editor"
                  [innerHTML]="_comment.contentStringSafeHtml"
                ></div>
              </div>
              <div class="col-2">
                <div class="col-8">
                  <div
                    class="row"
                    style="white-space: pre-line; font-size: 10px; color: green"
                  >
                    <span
                      class="material-icons"
                      (click)="upvoteComment(_comment)"
                      >keyboard_arrow_up</span
                    >{{ _comment.upVotes }}
                  </div>
                  <div
                    class="row"
                    style="white-space: pre-line; font-size: 9px; color: red"
                  >
                    <span
                      (click)="downvoteComment(_comment)"
                      class="material-icons"
                      >keyboard_arrow_down</span
                    >{{ _comment.downVotes }}
                  </div>
                </div>
                <div class="col-4">
                  <!-- <p
                  *ngIf="isAuthenticated()"
                  style="white-space: pre-line; font-size: 9px"
                  (click)="replyToComment(_comment, quil)"
                >
                  Reply
                </p> -->
                  <!-- <p
                  (click)="editComment(quil, _comment.id)"
                  *ngIf="
                    isAuthenticatedAndIsAuthorOfComment(_comment._owner_id)
                  "
                  style="cursor: pointer; white-space: pre-line; font-size: 9p"
                >
                  Edit Comment
                </p> -->
                  <p
                    (click)="deleteComment(_comment.id)"
                    *ngIf="
                      isAuthenticatedAndIsAuthorOfComment(_comment._owner_id)
                    "
                    style="
                      cursor: pointer;
                      white-space: pre-line;
                      font-size: 9p;
                    "
                  >
                    Delete Comment
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!endOfComments">Scroll for more comments</div>
        <div *ngIf="endOfComments">End of comments</div>
      </div>

      <div *ngIf="!isMobile" class="bg-white mt-4 col-4">
        <!-- <div class="row">
          <div
            class="col-6"
            style="white-space: pre-line; font-size: 12px"
            [innerHTML]="topOfArticle"
          ></div>
        </div> -->
        <div class="row">
          <table
            class="table table-striped mr-3 table-xs table-hover table-condensed"
          >
            <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Subtopic</th>
                <th *ngIf="!isMobile" scope="col">Date Written</th>
                <!-- <th scope="col">Coin Value</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style="font-weight: bold; cursor: pointer"
                  (click)="viewUserProfile(blog_owner_id)"
                >
                  {{ blog_owner_username }}
                </td>
                <td>{{ currentBlogData.subtopicName }}</td>
                <td *ngIf="!isMobile">{{ date_written | date : "medium" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <table
            class="table table-striped mr-3 table-xs table-hover table-condensed"
          >
            <thead>
              <tr>
                <th scope="col">Page Visits (for this blog)</th>
                <th scope="col">Logged in User Views (for this blog)</th>

                <!-- <th scope="col">Coin Value</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="font-weight: bold">
                  <p>{{ pageVisits }}</p>
                </td>
                <td>
                  <p>{{ loggedInUserViews }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center">Select Ranking Card</div>
        <div class="row">
          <div
            *ngFor="let contestType of contestTypes; let i = index"
            (click)="selectContestType(contestType)"
            class="col-6 text-center"
            [ngClass]="{
              'font-weight-bold text-success font-italic':
                isSelectedContestType(contestType) === true
            }"
            style="font-size: 12px"
          >
            <div
              style="cursor: pointer"
              *ngIf="contestType === 'MARGIN_OF_ERROR'"
            >
              <p>Margin of Error</p>
            </div>
            <div style="cursor: pointer" *ngIf="contestType === 'UNDER_OVER'">
              <p>Under/Over</p>
            </div>
            <div
              style="cursor: pointer"
              *ngIf="contestType === 'MARGIN_OF_VICTORY'"
            >
              <p>Selected Margin Of Victory</p>
            </div>
            <div
              style="cursor: pointer"
              *ngIf="contestType === 'TOTAL_POINTS_SCORED'"
            >
              <p>Selected Total Points Scored</p>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-6">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic':
                  contestType === 'MARGIN_OF_ERROR'
              }"
              (click)="flipContestType(ContestTypeEnum.MARGIN_OF_ERROR)"
            >
              <p class="text-center">Margin of Error</p>
            </div>
          </div>
          <div class="col-6">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic':
                  contestType === 'UNDER_OVER'
              }"
              (click)="flipContestType(ContestTypeEnum.UNDER_OVER)"
            >
              <p class="text-center">Under/Over</p>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-6" *ngFor="let tag of tags">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic': tag === selectedTag
              }"
              (click)="selectTag(tag)"
            >
              <p class="text-center">{{ tag.name }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <table
            class="table table-striped mr-3 table-xs table-hover table-condensed"
          >
            <thead>
              <tr>
                <th scope="col">Attribute</th>
                <th scope="col">Month</th>
                <th scope="col" *ngIf="!isMobile">Year</th>
                <!-- <th scope="col">Coin Value</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ranking Percentile :</td>
                <td
                  *ngIf="!!subtopicRankingCurrentMonth"
                  [ngClass]="{
                    elite: subtopicRankingCurrentMonth.percentileRank > 98,
                    aboveAvg:
                      subtopicRankingCurrentMonth.percentileRank > 80 &&
                      subtopicRankingCurrentMonth.percentileRank <= 98,
                    average:
                      subtopicRankingCurrentMonth.percentileRank > 60 &&
                      subtopicRankingCurrentMonth.percentileRank <= 80,
                    belowAvg:
                      subtopicRankingCurrentMonth.percentileRank > 40 &&
                      subtopicRankingCurrentMonth.percentileRank <= 60,
                    bad:
                      subtopicRankingCurrentMonth.percentileRank > 20 &&
                      subtopicRankingCurrentMonth.percentileRank <= 40
                  }"
                  [ngStyle]="
                    (subtopicRankingCurrentMonth.percentileRank >= 0 &&
                      subtopicRankingCurrentMonth.percentileRank <= 20 && {
                        'background-color': 'darkpurple'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 20 &&
                      subtopicRankingCurrentMonth.percentileRank <= 40 && {
                        'background-color': 'purple'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 40 &&
                      subtopicRankingCurrentMonth.percentileRank <= 60 && {
                        'background-color': 'blue'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 60 &&
                      subtopicRankingCurrentMonth.percentileRank <= 80 && {
                        'background-color': 'green'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 80 &&
                      subtopicRankingCurrentMonth.percentileRank <= 99 && {
                        'background-color': 'orange'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 99 &&
                      subtopicRankingCurrentMonth.percentileRank <= 100 && {
                        'background-color': 'gold'
                      })
                  "
                >
                  {{ subtopicRankingCurrentMonth.percentileRank }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">
                  Not Available:
                  {{
                    subtopicRankingsMonthError
                      | allCapsUnderscore
                      | lowercase
                      | titlecase
                  }}
                </td>

                <td
                  *ngIf="!isMobile && !!subtopicRankingYear"
                  [ngClass]="{
                    elite: subtopicRankingYear.percentileRank > 98,
                    aboveAvg:
                      subtopicRankingYear.percentileRank > 80 &&
                      subtopicRankingYear.percentileRank <= 98,
                    average:
                      subtopicRankingYear.percentileRank > 60 &&
                      subtopicRankingYear.percentileRank <= 80,
                    belowAvg:
                      subtopicRankingYear.percentileRank > 40 &&
                      subtopicRankingYear.percentileRank <= 60,
                    bad:
                      subtopicRankingYear.percentileRank > 20 &&
                      subtopicRankingYear.percentileRank <= 40
                  }"
                  [ngStyle]="
                    (subtopicRankingYear.percentileRank >= 0 &&
                      subtopicRankingYear.percentileRank <= 20 && {
                        'background-color': 'darkpurple'
                      }) ||
                    (subtopicRankingYear.percentileRank > 20 &&
                      subtopicRankingYear.percentileRank <= 40 && {
                        'background-color': 'purple'
                      }) ||
                    (subtopicRankingYear.percentileRank > 40 &&
                      subtopicRankingYear.percentileRank <= 60 && {
                        'background-color': 'blue'
                      }) ||
                    (subtopicRankingYear.percentileRank > 60 &&
                      subtopicRankingYear.percentileRank <= 80 && {
                        'background-color': 'green'
                      }) ||
                    (subtopicRankingYear.percentileRank > 80 &&
                      subtopicRankingYear.percentileRank <= 99 && {
                        'background-color': 'orange'
                      }) ||
                    (subtopicRankingYear.percentileRank > 99 &&
                      subtopicRankingYear.percentileRank <= 100 && {
                        'background-color': 'gold'
                      })
                  "
                >
                  {{ subtopicRankingYear.percentileRank }}
                </td>
                <td *ngIf="!isMobile && !!!subtopicRankingYear">
                  Not Available:
                  {{
                    subtopicRankingsYearError
                      | allCapsUnderscore
                      | lowercase
                      | titlecase
                  }}
                </td>
              </tr>
              <tr>
                <td>Overall Rank :</td>
                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{ subtopicRankingCurrentMonth.ranking }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!isMobile && !!subtopicRankingYear">
                  {{ subtopicRankingYear.ranking }}
                </td>

                <td *ngIf="!isMobile && !!!subtopicRankingYear">
                  Not Available
                </td>
              </tr>
              <tr>
                <td>Ape Score:</td>

                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{ subtopicRankingCurrentMonth.eloValue | number : "1.2-2" }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!isMobile && !!subtopicRankingYear">
                  {{ subtopicRankingYear.eloValue | number : "1.2-2" }}
                </td>

                <td *ngIf="!isMobile && !!!subtopicRankingYear">
                  Not Available
                </td>
              </tr>
              <tr>
                <td>H2H Wins %:</td>

                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{
                    (subtopicRankingCurrentMonth.totalH2HWins /
                      (subtopicRankingCurrentMonth.totalH2HLosses +
                        subtopicRankingCurrentMonth.totalH2HWins +
                        subtopicRankingCurrentMonth.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!isMobile && !!subtopicRankingYear">
                  {{
                    (subtopicRankingYear.totalH2HWins /
                      (subtopicRankingYear.totalH2HLosses +
                        subtopicRankingYear.totalH2HWins +
                        subtopicRankingYear.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>

                <td *ngIf="!isMobile && !!!subtopicRankingYear">
                  Not Available
                </td>
              </tr>
              <tr>
                <td>H2H Losses %:</td>
                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{
                    (subtopicRankingCurrentMonth.totalH2HLosses /
                      (subtopicRankingCurrentMonth.totalH2HLosses +
                        subtopicRankingCurrentMonth.totalH2HWins +
                        subtopicRankingCurrentMonth.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!isMobile && !!subtopicRankingYear">
                  {{
                    (subtopicRankingYear.totalH2HLosses /
                      (subtopicRankingYear.totalH2HLosses +
                        subtopicRankingYear.totalH2HWins +
                        subtopicRankingYear.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>

                <td *ngIf="!isMobile && !!!subtopicRankingYear">
                  Not Available
                </td>
              </tr>
              <tr>
                <td>H2H Draws %:</td>
                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{
                    (subtopicRankingCurrentMonth.totalH2HDraws /
                      (subtopicRankingCurrentMonth.totalH2HLosses +
                        subtopicRankingCurrentMonth.totalH2HWins +
                        subtopicRankingCurrentMonth.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!isMobile && !!subtopicRankingYear">
                  {{
                    (subtopicRankingYear.totalH2HDraws /
                      (subtopicRankingYear.totalH2HLosses +
                        subtopicRankingYear.totalH2HWins +
                        subtopicRankingYear.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>

                <td *ngIf="!isMobile && !!!subtopicRankingYear">
                  Not Available
                </td>
              </tr>
            </tbody>
          </table>
          <p style="font-size: 10px">Color Rank Chart</p>
          <ul>
            <li style="font-size: 8px" class="elite">
              King of Autistic Ape Council
            </li>
            <li style="font-size: 8px" class="aboveAvg">
              Autistic Ape Council Member
            </li>
            <li style="font-size: 8px" class="average">Autistic Ape</li>
            <li style="font-size: 8px" class="belowAvg">Ape Helper</li>
            <li style="font-size: 8px" class="bad">Likely Stupid</li>
            <li style="font-size: 8px">Simple Minded</li>
          </ul>
        </div>

        <div class="bg-white">
          <div class="row">
            <table
              class="table table-striped mr-3 table-xs table-hover table-condensed"
            >
              <thead>
                <tr>
                  <th scope="col">Subtopic</th>
                  <th scope="col">Last Updated</th>
                  <!-- <th scope="col">Coin Value</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>S/{{ currentBlogData.subtopicName }}</td>
                  <td>{{ date_written | date : "medium" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!!subtopicAggregateItemYear" class="row">
            <table
              class="table table-striped mr-3 table-xs table-hover table-condensed"
            >
              <thead>
                <tr>
                  <th scope="col">Attribute</th>
                  <th scope="col">This Month</th>
                  <th *ngIf="!isMobile" scope="col">Last Month</th>
                  <th *ngIf="!isMobile" scope="col">All Time</th>
                  <!-- <th scope="col">Coin Value</th> -->
                </tr>
              </thead>
              <tbody
                *ngIf="
                  !!subtopicAggregateItemYear &&
                  !!subtopicAggregateItemYear.numberOfSubscriber
                "
              >
                <tr>
                  <td># of Subscribers</td>
                  <td>{{ subtopicAggregateItemYear.numberOfSubscriber }}</td>
                  <td *ngIf="!isMobile">
                    {{ subtopicAggregateItemYear.numberOfSubscriber }}
                  </td>
                  <td *ngIf="!isMobile">
                    {{ subtopicAggregateItemYear.numberOfSubscriber }}
                  </td>
                </tr>
                <tr>
                  <td># of Articles</td>
                  <td>{{ subtopicAggregateItemYear.numberOfSubscriber }}</td>
                  <td *ngIf="!isMobile">
                    {{ subtopicAggregateItemYear.numberOfSubscriber }}
                  </td>
                  <td *ngIf="!isMobile">
                    {{ subtopicAggregateItemYear.numberOfSubscriber }}
                  </td>
                </tr>
                <tr>
                  <td># of Contests</td>
                  <td>
                    {{ subtopicAggregateItemYear.numberOfContestsPlayed }}
                  </td>
                  <td *ngIf="!isMobile">
                    {{ subtopicAggregateItemYear.numberOfContestsPlayed }}
                  </td>
                  <td *ngIf="!isMobile">
                    {{ subtopicAggregateItemYear.numberOfContestsPlayed }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <p style="white-space: pre-line; font-size: 12px">Topic: Economics</p> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isMobile">
  <div *ngIf="canViewBlog">
    <div class="row">
      <div class="col-1">
        <div>
          <div class="pl-2 pt-2 mt-2">
            <span
              style="white-space: pre-line; font-size: 21px"
              class="material-icons"
              (click)="downvoteBlog(blogService.currentBlog)"
              >keyboard_arrow_down</span
            >
          </div>
          <span style="white-space: pre-line; font-size: 8px; color: red">{{
            downvotes
          }}</span>
        </div>
      </div>

      <div
        style="white-space: pre-line; font-size: 14px"
        class="col-9 font-weight-heavy font-weight-bold text-center ql-editor"
        [innerHTML]="blogService.blogSafeHtmlTitle"
      ></div>
      <div class="col-2">
        <div>
          <div class="pr-4 pt-2 mt-2 mr-4">
            <span
              style="white-space: pre-line; font-size: 21px"
              class="material-icons m-0"
              (click)="upvoteBlog(blogService.currentBlog)"
              >keyboard_arrow_up</span
            >
          </div>
          <div>
            <span style="white-space: pre-line; font-size: 8px; color: green">{{
              upvotes
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <p
        class="col-4"
        style="
          font-weight: bold;
          cursor: pointer;
          white-space: pre-line;
          font-size: 8px;
        "
        (click)="viewUserProfile(blog_owner_id)"
      >
        <u>{{ blog_owner_username }}</u>
      </p>
      <p class="col-4" style="white-space: pre-line; font-size: 8px">
        {{ currentBlogData.subtopicName }}
      </p>
      <p class="col-4" style="white-space: pre-line; font-size: 8px">
        {{ date_written | date : "medium" }}
      </p>
    </div>

    <div class="row text-center">
      <p class="col-4" style="white-space: pre-line; font-size: 8px">
        {{ date_written | date : "medium" }}
      </p>

      <p class="col-4" style="white-space: pre-line; font-size: 8px">
        page visits: {{ pageVisits }}
      </p>
      <p class="col-4" style="white-space: pre-line; font-size: 8px">
        logged in user views: {{ loggedInUserViews }}
      </p>
    </div>
    <div class="newMike">
      <div class="text-center">
        <b><u>Select Ranking Card</u></b>
      </div>
      <div class="row">
        <div
          *ngFor="let contestType of contestTypes; let i = index"
          (click)="selectContestType(contestType)"
          class="col-6 text-center"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              isSelectedContestType(contestType) === true
          }"
          style="font-size: 10px"
        >
          <div
            style="cursor: pointer; font-size: 8.5px"
            *ngIf="contestType === 'MARGIN_OF_ERROR'"
          >
            <p>Margin of Error</p>
          </div>
          <div
            style="cursor: pointer; font-size: 8.5px"
            *ngIf="contestType === 'UNDER_OVER'"
          >
            <p>Under/Over</p>
          </div>
          <div
            style="cursor: pointer; font-size: 8.5px"
            *ngIf="contestType === 'MARGIN_OF_VICTORY'"
          >
            <p>Selected Margin Of Victory</p>
          </div>
          <div
            style="cursor: pointer; font-size: 8.5px"
            *ngIf="contestType === 'TOTAL_POINTS_SCORED'"
          >
            <p>Selected Total Points Scored</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6" *ngFor="let tag of tags">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic': tag === selectedTag
            }"
            (click)="selectTag(tag)"
          >
            <p class="text-center" style="font-size: 8.5px">{{ tag.name }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <table
            class="table table-striped mr-3 table-xs table-hover table-condensed"
          >
            <thead>
              <tr>
                <th scope="col">Attribute</th>
                <th scope="col">Month</th>
                <th scope="col">Year</th>
                <!-- <th scope="col">Coin Value</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rank %:</td>
                <td
                  *ngIf="!!subtopicRankingCurrentMonth"
                  [ngClass]="{
                    elite: subtopicRankingCurrentMonth.percentileRank > 98,
                    aboveAvg:
                      subtopicRankingCurrentMonth.percentileRank > 80 &&
                      subtopicRankingCurrentMonth.percentileRank <= 98,
                    average:
                      subtopicRankingCurrentMonth.percentileRank > 60 &&
                      subtopicRankingCurrentMonth.percentileRank <= 80,
                    belowAvg:
                      subtopicRankingCurrentMonth.percentileRank > 40 &&
                      subtopicRankingCurrentMonth.percentileRank <= 60,
                    bad:
                      subtopicRankingCurrentMonth.percentileRank > 20 &&
                      subtopicRankingCurrentMonth.percentileRank <= 40
                  }"
                  [ngStyle]="
                    (subtopicRankingCurrentMonth.percentileRank >= 0 &&
                      subtopicRankingCurrentMonth.percentileRank <= 20 && {
                        'background-color': 'darkpurple'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 20 &&
                      subtopicRankingCurrentMonth.percentileRank <= 40 && {
                        'background-color': 'purple'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 40 &&
                      subtopicRankingCurrentMonth.percentileRank <= 60 && {
                        'background-color': 'blue'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 60 &&
                      subtopicRankingCurrentMonth.percentileRank <= 80 && {
                        'background-color': 'green'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 80 &&
                      subtopicRankingCurrentMonth.percentileRank <= 99 && {
                        'background-color': 'orange'
                      }) ||
                    (subtopicRankingCurrentMonth.percentileRank > 99 &&
                      subtopicRankingCurrentMonth.percentileRank <= 100 && {
                        'background-color': 'gold'
                      })
                  "
                >
                  {{
                    subtopicRankingCurrentMonth.percentileRank
                      | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">
                  Not Available:
                  {{
                    subtopicRankingsMonthError
                      | allCapsUnderscore
                      | lowercase
                      | titlecase
                  }}
                </td>

                <td
                  *ngIf="!!subtopicRankingYear"
                  [ngClass]="{
                    elite: subtopicRankingYear.percentileRank > 98,
                    aboveAvg:
                      subtopicRankingYear.percentileRank > 80 &&
                      subtopicRankingYear.percentileRank <= 98,
                    average:
                      subtopicRankingYear.percentileRank > 60 &&
                      subtopicRankingYear.percentileRank <= 80,
                    belowAvg:
                      subtopicRankingYear.percentileRank > 40 &&
                      subtopicRankingYear.percentileRank <= 60,
                    bad:
                      subtopicRankingYear.percentileRank > 20 &&
                      subtopicRankingYear.percentileRank <= 40
                  }"
                  [ngStyle]="
                    (subtopicRankingYear.percentileRank >= 0 &&
                      subtopicRankingYear.percentileRank <= 20 && {
                        'background-color': 'darkpurple'
                      }) ||
                    (subtopicRankingYear.percentileRank > 20 &&
                      subtopicRankingYear.percentileRank <= 40 && {
                        'background-color': 'purple'
                      }) ||
                    (subtopicRankingYear.percentileRank > 40 &&
                      subtopicRankingYear.percentileRank <= 60 && {
                        'background-color': 'blue'
                      }) ||
                    (subtopicRankingYear.percentileRank > 60 &&
                      subtopicRankingYear.percentileRank <= 80 && {
                        'background-color': 'green'
                      }) ||
                    (subtopicRankingYear.percentileRank > 80 &&
                      subtopicRankingYear.percentileRank <= 99 && {
                        'background-color': 'orange'
                      }) ||
                    (subtopicRankingYear.percentileRank > 99 &&
                      subtopicRankingYear.percentileRank <= 100 && {
                        'background-color': 'gold'
                      })
                  "
                >
                  {{ subtopicRankingYear.percentileRank | number : "1.2-2" }}
                </td>
                <td *ngIf="!!!subtopicRankingYear">
                  Not Available:
                  {{
                    subtopicRankingsYearError
                      | allCapsUnderscore
                      | lowercase
                      | titlecase
                  }}
                </td>
              </tr>
              <tr>
                <td>Rank #:</td>
                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{ subtopicRankingCurrentMonth.ranking }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!!subtopicRankingYear">
                  {{ subtopicRankingYear.ranking }}
                </td>

                <td *ngIf="!!!subtopicRankingYear">Not Available</td>
              </tr>
              <tr>
                <td>Ape_Score:</td>

                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{ subtopicRankingCurrentMonth.eloValue | number : "1.2-2" }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!!subtopicRankingYear">
                  {{ subtopicRankingYear.eloValue | number : "1.2-2" }}
                </td>

                <td *ngIf="!!!subtopicRankingYear">Not Available</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <table
            class="table table-striped mr-3 table-xs table-hover table-condensed"
          >
            <thead>
              <tr>
                <th scope="col">Attribute</th>
                <th scope="col">Month</th>
                <th scope="col">Year</th>
                <!-- <th scope="col">Coin Value</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>H2H W%:</td>

                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{
                    (subtopicRankingCurrentMonth.totalH2HWins /
                      (subtopicRankingCurrentMonth.totalH2HLosses +
                        subtopicRankingCurrentMonth.totalH2HWins +
                        subtopicRankingCurrentMonth.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!!subtopicRankingYear">
                  {{
                    (subtopicRankingYear.totalH2HWins /
                      (subtopicRankingYear.totalH2HLosses +
                        subtopicRankingYear.totalH2HWins +
                        subtopicRankingYear.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>

                <td *ngIf="!!!subtopicRankingYear">Not Available</td>
              </tr>
              <tr>
                <td>H2H L%:</td>
                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{
                    (subtopicRankingCurrentMonth.totalH2HLosses /
                      (subtopicRankingCurrentMonth.totalH2HLosses +
                        subtopicRankingCurrentMonth.totalH2HWins +
                        subtopicRankingCurrentMonth.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!!subtopicRankingYear">
                  {{
                    (subtopicRankingYear.totalH2HLosses /
                      (subtopicRankingYear.totalH2HLosses +
                        subtopicRankingYear.totalH2HWins +
                        subtopicRankingYear.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>

                <td *ngIf="!!!subtopicRankingYear">Not Available</td>
              </tr>
              <tr>
                <td>H2H D%:</td>
                <td *ngIf="!!subtopicRankingCurrentMonth">
                  {{
                    (subtopicRankingCurrentMonth.totalH2HDraws /
                      (subtopicRankingCurrentMonth.totalH2HLosses +
                        subtopicRankingCurrentMonth.totalH2HWins +
                        subtopicRankingCurrentMonth.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>
                <td *ngIf="!!!subtopicRankingCurrentMonth">Not Available</td>

                <td *ngIf="!!subtopicRankingYear">
                  {{
                    (subtopicRankingYear.totalH2HDraws /
                      (subtopicRankingYear.totalH2HLosses +
                        subtopicRankingYear.totalH2HWins +
                        subtopicRankingYear.totalH2HDraws)) *
                      100 | number : "1.2-2"
                  }}
                </td>

                <td *ngIf="!!!subtopicRankingYear">Not Available</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-center">
          <p style="font-size: 10px">Color Rank Chart</p>
        </div>
        <div class="col-6">
          <ul>
            <li style="font-size: 8px" class="elite">
              King of Autistic Ape Council
            </li>
            <li style="font-size: 8px" class="aboveAvg">
              Autistic Ape Council Member
            </li>
            <li style="font-size: 8px" class="average">Autistic Ape</li>
          </ul>
        </div>
        <div class="col-6">
          <ul>
            <li style="font-size: 8px" class="belowAvg">Ape Helper</li>
            <li style="font-size: 8px" class="bad">Likely Stupid</li>
            <li style="font-size: 8px">Simple Minded</li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="ql-container"
      style="border-width: 0"
      style="font-size: 11px; border-width: 0; height: auto"
    >
      <!-- style="font-size: 10px; height: '500px'" -->
      <div class="ql-editor" [innerHTML]="copy.contentStringSafeHtml"></div>
    </div>
    <div class="col-12">
      <quill-editor
        [(ngModel)]="quil"
        [styles]="{ height: '125px' }"
        (onFocus)="focus($event)"
        (onEditorChanged)="changedEditor($event)"
        (onBlur)="blur($event)"
        (onEditorCreated)="created($event)"
        [placeholder]="commentPlaceholderValue"
      ></quill-editor>

      <button
        class="btn-sm btn-success ml-4 mt-1 font-weight-light"
        (click)="addComment(quil)"
        [ngClass]="{
          scratched: !isAuthenticated()
        }"
      >
        Add Comment
      </button>
      <button
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="discardComment()"
        [ngClass]="{
          scratched: !isAuthenticated()
        }"
      >
        Discard Comment
      </button>
      <button
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="goBackToPreviousPage()"
      >
        Go Back to other blogs
      </button>
      <!-- <button class="btn-sm btn-secondary ml-4 mt-1 font-weight-light">
        share
      </button> -->
      <button
        *ngIf="isAuthenticatedAndIsAuthorOfBlog()"
        class="btn-sm btn-primary ml-4 mt-1 font-weight-light"
      >
        Update or Delete Blog
      </button>
    </div>
    <div style="white-space: pre-line" class="border"></div>

    <div *ngFor="let _comment of viewableComments">
      <div class="row my-2 bg-white border">
        <div class="col-2">
          <div
            class="row"
            style="
              white-space: pre-line;
              font-size: 10px;
              cursor: pointer;
              font-weight: bold;
            "
            (click)="viewUserProfile(_comment._owner_id)"
          >
            {{ _comment.username }}
          </div>
          <div class="row" style="white-space: pre-line; font-size: 9px">
            {{ _comment.date | date : "medium" }}
          </div>
        </div>
        <!-- <div
              style="white-space: pre-line; font-size: 11px"
              class="col-8"
              [innerHTML]="_comment.contentStringSafeHtml"
            ></div> -->
        <div
          class="ql-container col-8"
          style="border-width: 0"
          style="font-size: 11px; height: '100px'"
        >
          <div
            class="ql-editor"
            [innerHTML]="_comment.contentStringSafeHtml"
          ></div>
        </div>
        <div class="col-2">
          <div class="col-8">
            <div
              class="row"
              style="white-space: pre-line; font-size: 10px; color: green"
            >
              <span class="material-icons" (click)="upvoteComment(_comment)"
                >keyboard_arrow_up</span
              >{{ _comment.upVotes }}
            </div>
            <div
              class="row"
              style="white-space: pre-line; font-size: 9px; color: red"
            >
              <span (click)="downvoteComment(_comment)" class="material-icons"
                >keyboard_arrow_down</span
              >{{ _comment.downVotes }}
            </div>
          </div>
          <div class="col-4">
            <p
              *ngIf="isAuthenticated()"
              style="white-space: pre-line; font-size: 9px"
              (click)="replyToComment(_comment, quil)"
            >
              Reply
            </p>
            <!-- <p
              (click)="editComment(quil, _comment.id)"
              *ngIf="isAuthenticatedAndIsAuthorOfComment(_comment._owner_id)"
              style="cursor: pointer; white-space: pre-line; font-size: 9p"
            >
              Edit Comment
            </p> -->
            <p
              (click)="deleteComment(_comment.id)"
              *ngIf="isAuthenticatedAndIsAuthorOfComment(_comment._owner_id)"
              style="cursor: pointer; white-space: pre-line; font-size: 9p"
            >
              Delete Comment
            </p>
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn-sm btn-success ml-4 mt-1 font-weight-light"
      *ngIf="!endOfComments"
      (click)="onScroll()"
    >
      View more comments
    </button>
    <div *ngIf="endOfComments">End of comments</div>
  </div>
</div>
