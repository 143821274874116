import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-sports-game',
  templateUrl: './create-sports-game.component.html',
  styleUrls: ['./create-sports-game.component.css']
})
export class CreateSportsGameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
export interface Spreads {
  odds: number[];
  points: string[];
}

export interface Odds {
  spreads: Spreads;
}

export interface Site {
  site_key: string;
  site_nice: string;
  last_update: Date;
  odds: Odds;
}

export interface Datum {
  id: string;
  sport_key: string;
  sport_nice: string;
  teams: string[];
  home_team: string;
  commence_time: Date;
  sites: Site[];
  sites_count: number;
}

export interface RootObject {
  success: boolean;
  data: Datum[];
}
