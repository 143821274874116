<div class="container">
  <div class="row">
    <table
      class="table table-striped table-dark table-xs table-hover table-condensed"
    >
      <thead>
        <tr>
          <th scope="col">Platform</th>
          <th scope="col">URL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div>
              <div class="dropdown h-100" class="statusDropdownContainer">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Platform:
                  {{ selectedPlatform | lowercase | titlecase }}
                </button>
                <div
                  class="dropdown-menu w-100 pt-0"
                  style="color: whitesmoke"
                  aria-labelledby="dropdownMenuButton"
                >
                  <input
                    type="text"
                    class="w-100 p-2 searchInput"
                    (ngModelChange)="filterDropdown($event)"
                  />
                  <a
                    *ngFor="let option of filteredPlatformList; let i = index"
                    class="dropdown-item pointer text-contain"
                    [ngClass]="{ alternateBackground: i % 2 == 0 }"
                    (click)="selectValue(option)"
                  >
                    {{ option | lowercase | titlecase }}
                  </a>
                </div>
              </div>
            </div>
            <input
              type="text"
              id="event"
              name="nameOfPlatform"
              value="{{ selectedPlatform }}"
              placeholder="Select or Write Your Own"
              #nameOfPlatform
            />
          </td>
          <td><input type="text" id="event" name="link" #link /></td>
          <td>
            <button
              type="button"
              class="btn btn-sm btn-success"
              (click)="addSocialLink(nameOfPlatform.value, link.value)"
            >
              Add
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <table
      class="table table-striped table-dark table-xs table-hover table-condensed"
    >
      <thead>
        <tr>
          <th scope="col">Platform</th>
          <th scope="col">URL</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let userSocialLink of socialLinks">
          <td>{{ userSocialLink.platform }}</td>
          <td>
            <a target="_blank" [href]="'//' + userSocialLink.url">{{
              userSocialLink.url
            }}</a>
          </td>
          <td>
            <button
              class="btn btn-sm btn-success"
              (click)="copyLink(userSocialLink.url)"
            >
              Share/Copy
            </button>
          </td>
          <td>
            <button
              (click)="deleteSocialLink(userSocialLink.id)"
              type="button"
              class="btn btn-danger"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- <button shareButton="facebook" (click)="copyLink()">Share</button>
<button shareButton="twitter">Tweet</button>
<button shareButton="pinterest">Pin</button> -->
