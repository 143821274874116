import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { storage } from 'firebase';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { MeService } from '../profile/Me/me.service';
import { StorageService } from '../shared/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  constructor(private httpClient: HttpClient, private meService: MeService,
              private storageService: StorageService) { }

  pageVisit(blogId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/${blogId}/pageVisit`);
  }

  viewBlogAsSignedInUser(blogId: number): Observable<any>{
    if (this.storageService.isLoggedIn()){
      return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/${blogId}/viewedByUser`);
    }
  }
}
