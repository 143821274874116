<p class="container text-center font-weight-bold">
  {{ title }} in {{ subtopic.name }}
</p>
<div style="height: auto; width: 100%">
  <div *ngFor="let blog of blogs">
    <div>
      <div class="font-weight-light d-flex justify-content-center">
        <div class="card" style="height: auto; width: 100%">
          <div class="card-body">
            <div class="row">
              <div
                class="text-center col-12 d-flex justify-content-center font-weight-bold"
                style="font-size: 16px; width: 100"
              >
                <b class="ql-editor text-center" [innerHTML]="blog.title"></b>
              </div>
            </div>
            <div class="row">
              <span
                class="col-12 d-flex justify-content-center my-2"
                style="font-size: 10px; cursor: pointer"
                (click)="viewUserProfile(blog._owner_id)"
                ><b>{{ blog.name }}</b
                >-/s/ {{ blog.subtopicName }} - {{ blog.date | date }}</span
              >
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-2"></div>
              <div
                class="col-10 d-flex justify-content-start align-items-start"
              >
                <span
                  class="material-icons col-1 px-o"
                  style="font-size: 12px; padding-right: 0; padding-left: 0"
                  >thumb_up_rounded
                </span>

                <span
                  class="col-2"
                  style="
                    font-size: 10px;
                    padding-right: 0;
                    padding-bottom: 2px;
                    padding-left: 0;
                  "
                  >{{ blog.upVotes }}</span
                >

                <!-- <span class="material-icons">favorite_border_rounded</span> -->

                <span
                  class="material-icons col-1 px-o"
                  style="font-size: 12px; padding-right: 2px; padding-left: 2px"
                >
                  remove_red_eye_outlined</span
                ><span
                  class="col-2"
                  style="
                    padding-right: 2px;
                    padding-left: 2px;
                    font-size: 10px;
                    padding-bottom: 2px;
                  "
                  >{{ blog.pageVisits }}
                </span>

                <span
                  class="material-icons col-1 px-o"
                  style="
                    font-size: 12px;
                    padding-right: 2px;
                    padding-left: 2px;
                    padding-top: 1px;
                  "
                  >chat_bubble_outline_rounded</span
                ><span
                  class="col-2"
                  style="
                    padding-right: 0;
                    padding-left: 0px;

                    padding-bottom: 2px;
                    font-size: 10px;
                  "
                  >{{ blog.commentCount }}</span
                >

                <span
                  class="material-icons col-1 px-o"
                  style="
                    font-size: 12px;
                    padding-right: 2px;
                    padding-left: 2px;
                    padding-top: 1px;
                  "
                  >pageview</span
                ><span
                  class="col-2"
                  style="
                    padding-right: 0;
                    padding-left: 0px;

                    padding-bottom: 2px;
                    font-size: 10px;
                  "
                  >{{ blog.loggedInUserViews }}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center mt-2">
                <a
                  class="btn-sm btn-secondary mt-7 font-weight-light"
                  style="font-size: 9px; cursor: pointer"
                  (click)="selectCurrentBlog(blog)"
                  >View
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
