import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnterContestModalContentComponent } from '../enter-contest-modal-content/enter-contest-modal-content.component';


@Component({
  selector: 'app-enter-contest-modal',
  templateUrl: './enter-contest-modal.component.html',
  styleUrls: ['./enter-contest-modal.component.css']
})
export class EnterContestModalComponent implements OnInit {



  ngOnInit(): void {
  }

  constructor(private modalService: NgbModal) {}

}
