import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-stock-contest',
  templateUrl: './view-stock-contest.component.html',
  styleUrls: ['./view-stock-contest.component.css']
})
export class ViewStockContestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
