import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateContestModalComponent } from '../create-contest-modal.component';

@Component({
  selector: 'app-create-contest-modal-blank',
  templateUrl: './create-contest-modal-blank.component.html',
  styleUrls: ['./create-contest-modal-blank.component.css']
})
export class CreateContestModalBlankComponent implements OnInit {

  constructor(            private modalService: NgbModal) { }

  ngOnInit(): void {
        // // todo -> create shell class that isnt itselt and run this function in there
    const modalRef = this.modalService.open(CreateContestModalComponent, { centered: true,  size: 'lg'});
    modalRef.componentInstance.name = 'World1';
  }

}
