import { Component, OnInit } from '@angular/core';
import { MeService } from '../profile/Me/me.service';
import { LoaderService } from '../shared/loader.service';
import { StorageService } from '../shared/storage.service';
import { TokenInterceptorService } from '../shared/token-interceptor.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  show = true;

  showText = 'collapse navbar-collapse show';
  dontShowTest = 'collapse navbar-collapse';
  currentText = this.showText;
  constructor( public meService: MeService, private storageService: StorageService,
               public loaderService: LoaderService) {}

  ngOnInit(): void {}

  onLogout(): void {
    this.meService.logout();
    this.storageService.clean();
    window.location.reload();
  }

  toggleShowDropDown(): void {
    if (this.show) {
      this.show = !this.show;
      this.currentText = this.dontShowTest;
    } else {
      this.show = !this.show;
      this.currentText = this.showText;
    }
  }

  isLoggedIn(): boolean  {
    return this.storageService.isLoggedIn();
  }
}
