import { Component, OnInit } from '@angular/core';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import Quill from 'quill';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {  SafeHtml } from '@angular/platform-browser';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { Blog, Page } from 'src/app/media/media.component';
import { PaginatedBlog } from '../../../media/media.component';
import { YourBlogsService } from './your-blogs.service';
import { TopicService } from '../../../topic/topic.service';
import { SubTopicsEntityOrOwnedSubtopicsEntity, TopicsEntity } from '../../User/user.service';
import { SubtopicService } from '../../../topic/subtopic/subtopic.service';
import { SubtopicPaginatedBlogMap } from 'src/app/contest/contest.component';
import { ContestStateService } from '../../../contest/contest-state.service';
import { MeService } from '../me.service';
import { SubtopicRankingService } from '../../../Aggregation/subtopic-ranking.service';
import { Tag } from 'src/app/guru/guru.component';


@Component({
  selector: 'app-your-blogs',
  templateUrl: './your-blogs.component.html',
  styleUrls: ['./your-blogs.component.css']
})
export class YourBlogsComponent implements OnInit {



  constructor(private meService: MeService) { }

  ngOnInit(): void {

  }

  get userId(): number{
    return this.meService.id;
  }

}

export enum PaginationCommand{
  NEXT,
  PREVIOUS,
  FIRST,
  LAST
}
