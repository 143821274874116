import { Injectable } from '@angular/core';
import { UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { Links, Page } from 'src/app/media/media.component';
import { GlobalContestState } from '../../GlobalVars/Enums/ContestEnums';
import { SportsGame } from '../view-contest/football-game.service';


@Injectable({
  providedIn: 'root'
})
export class GameService {
  games: Game[] = [];
  currentGame: Game;
  constructor() { }
}

export interface GameType {
    id?: number;
    type: string;
    name: string;
    description?: string;
}

export enum UnderOverOutcome{
    WIN = 'Win',
    LOSS = 'Loss',
    NO_RESULT = 'NoResult'
}

export enum ProcessState{
    Unprocessed = 'Unprocessed',
    Processed = 'Processed'
}

export interface BetSlip {
    gameId?: number;
    over: boolean;
    under: boolean;
    weight?: number;
    underOverOutcome: UnderOverOutcome;
    processState: ProcessState;
    predictedValue: number;
    marginOfErrorOutcome?: number;
    atomicGame?: AtomicGame;
    gameState?: string;
    ownerId?: number;
    id?: number;

}

export interface Atom {
    id: number;
    name: string;
    value: number;
    timeOfValue: Date;
    field: string;
    valueIsConfirmed: boolean;
    live: boolean;
}


export interface AtomicGame {
    startAtom: Atom;
    endAtom: Atom;
    liveAtom: Atom;
    startTime: Date;
    endTime: Date;
    gameState?: string;
    betSlips?: any[];
    betSlip?: any;
    id: number;
    name?: any;
    field?: any;
    scratchGame?: boolean;
}


export interface Game {
    name: AbstractGameName;
    id?: number;
    stockSymbol: string;
    field: string;
    // valueToBeBetOn: number;
    // bettingValue: number;
    startTime: Date;
    endTime: Date;
    betSlip?: BetSlip;
    betSlips?: BetSlip[];
    gameType: GameType;
    gameTypeName: string;
    gameState: string;
    liveValueDuringGame?: number; // for live polling
    valueEndOfGame?: number; // result
    valueLocked?: boolean;
    sportsEventId?: string;
    sportsGame?: SportsGame; // for contests to hold on front end only
}

export interface TopicReference {
    id: number;
    name: string;
}

export interface SubtopicReference {
    id: number;
    name: string;
}

export interface TagReference {
    id: number;
    name: string;
}

export interface Coin {
    id?: number;
    dateIssued: Date;
    dateExpired?: any;
    tCoin: boolean;
    cCoin: boolean;
}

export interface Contest {
    id?: number;
    maxUsers?: number;
    numberOfCompetitors?: number;
    name?: string;
    games: Game[];
    startTime?: Date;
    endTime?: Date;
    topicReference?: TopicReference;
    subtopicReference?: SubtopicReference;
    tagReferences?: TagReference[];
    coins?: Coin[];
    userContestState: UserContestState;
    globalContestState: GlobalContestState;
    ranking?: number;
    totalWins?: number;
    totalLosses?: number;
    contestType: ContestType;
    durationType: DurationType;
    payoutType?: string;
    subscriptionOnly?: boolean;
    k_factor?: boolean;
}

export enum ContestType {
    // UNDER_OVER = 'Under/Over',
    // MARGIN_OF_ERROR = 'Margin of Error'
    UNDER_OVER = 'UNDER_OVER',
    MARGIN_OF_ERROR = 'MARGIN_OF_ERROR',
    MARGIN_OF_VICTORY = 'MARGIN_OF_VICTORY',
    TOTAL_POINTS_SCORED = 'TOTAL_POINTS_SCORED',
    ALL = 'ALL'
}

export enum DurationType {
    // UNDER_OVER = 'Under/Over',
    // MARGIN_OF_ERROR = 'Margin of Error'
    THIRTY_DAY= 'THIRTY_DAY',
    FOURTEEN_DAY = 'FOURTEEN_DAY',
    SEVEN_DAY= 'SEVEN_DAY',
    ONE_DAY = 'ONE_DAY',
    ALL = 'ALL'
}


export enum AbstractGameName {
    StraightSelectionGame = 'StraightSelectionGame'
}

export interface EmbeddedBetSlips {
    betSlipList: BetSlip[];
  }

export interface PaginatedBetSlip {
    _embedded: EmbeddedBetSlips;
    _links: Links;
    page: Page;
  }
