import { Component, Input, OnInit } from '@angular/core';
import { BlogService } from './blog/blog.service';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TopicService } from '../topic/topic.service';
import { ContestStateService, UnderOverOutcomeRanking, PaginatedUnderOverOutcomeRanking } from '../contest/contest-state.service';
import { SubtopicService } from '../topic/subtopic/subtopic.service';
import { BetSlipService } from '../contest/bet-slip.service';
import { ContestService } from '../contest/contest.service';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import { PaginationCommand } from '../profile/Me/your-blogs/your-blogs.component';
import { SubTopicsEntityOrOwnedSubtopicsEntity, TopicsEntity, UserService } from '../profile/User/user.service';
import { Me } from '../profile/Me/me.service';
import { SubtopicPaginatedBlogMap } from '../contest/contest.component';
import { SubtopicRankingService } from '../Aggregation/subtopic-ranking.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CreateContestService } from '../contest/create-contest/create-contest.service';
import { Tag } from '../guru/guru.component';
import { TagReference } from '../contest/game/game.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  @Input()
  userId = -1;

  PaginationCommand = PaginationCommand;
  currentTopic;
  currentSubtopic;
  data: any = '';
  currentBlog: Blog = null;
  canViewBlog = false;
  creatYourOwnBlog = false;
  topicRankings: PaginatedUnderOverOutcomeRanking;
  subtopicRankings: PaginatedUnderOverOutcomeRanking;
  endOfBlogs = false;
  subtopicBlogSetMap: SubtopicPaginatedBlogMap[] = [];
  newPaginatedBlog: PaginatedBlog;
  upVotesPaginatedBlog: PaginatedBlog;
  accountTotalPaginatedBlog: PaginatedBlog;
  filteredPaginatedBlog: PaginatedBlog;
  sortBlogs = '';
  isBlogsFiltered = false;
  currentDate = new Date();
  endModel: NgbDateStruct;
  startModel: NgbDateStruct;
  today = this.calendar.getToday();
  tomorrow = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
  time = {hour: new Date().getHours() + 12, minute: 30};
  meridian = true;
  startDate: Date;
  endDate: Date;
  startModelIsActive: any;
  endModelIsActive: any;
  isMobile: boolean;
  selectedValue: string;
  private _filteredTagList: Tag[];
  private _nonfilteredTagList: Tag[];
  private _selectedTag: Tag = {name: '', id: -1};

  constructor(public blogService: BlogService, private activatedRoute: ActivatedRoute,
              private topicService: TopicService, private contestStateService: ContestStateService,
              private subtopicService: SubtopicService, private betSlipService: BetSlipService,
              private contestService: ContestService, private router: Router,
              private route: ActivatedRoute, private userService: UserService,
              private subtopicRankingService: SubtopicRankingService, private calendar: NgbCalendar) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    this.createDatesOnInit();
    // if (this.userId === -1){
    //   this.activatedRoute.data.subscribe((response: any) => {
    //     this.filteredPaginatedBlog = response.blogs;
    //   });
    // }

    if (!!!this.topicService.allTopics){
      this.activatedRoute.data.subscribe((response: any) => {
        this.topicService.allTopics = response.topicData;
      });
    }

    this.topicService.allTopics._embedded.topicDTOList.forEach((topic: TopicsEntity) => {
      if (topic.name === 'Finance'){
        this.selectTopic(topic);
      }
    });
    this.activatedRoute.data.subscribe((response: any) => {
      this.filteredPaginatedBlog = response.blogs;
    });

    this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
      if (subtopic.name === 'Stocks'){
        this.selectSubTopic(subtopic);
        this.subtopicSelected();
        this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
        (subtopic.id, this.startDate, this.endDate, 'byViewCount',
         this._selectedTag, this.userId, 0).subscribe((paginatedBlog: PaginatedBlog) => {
          this.filteredPaginatedBlog = paginatedBlog;
          this.subtopicBlogSetMap.push({subtopic, paginatedBlog});
        });
        // this.fetchAllBlogs();
      }
    });
  }

  selectCurrentBlog(blog: Blog): void{
    this.currentBlog = null;
    this.currentBlog = blog;
    this.blogService.currentBlog = blog;
    this.data = blog.contentString;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    this.data = this.toHTML(this.data);
    this.viewBlog(this.data, blog.id, blog._owner_id);
    // this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(this.data));
  }

  viewBlog(blogHtml: SafeHtml, blogId: number, ownerId: number): void{
    this.blogService.blogSafeHtml = blogHtml;
    this.canViewBlog = true;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    // this.router.navigate(['../' + routingGlobals.media + '/' + routingGlobals.dynamicBlog, blogId,
    //  'author', ownerId], {relativeTo: this.route});
    this.router.navigate(['/' + routingGlobals.media + '/' + routingGlobals.dynamicBlog, blogId,
    'author', ownerId]);
  }

  get viewAbleBlog(): SafeHtml{
    return this.blogService.blogSafeHtml;
  }

  get blogs(): Blog[]{
    if (this.filteredPaginatedBlog && this.filteredPaginatedBlog._embedded && this.filteredPaginatedBlog._embedded.blogPreviewDTOList){
      return this.filteredPaginatedBlog._embedded.blogPreviewDTOList;
    }
    else{
      return null;
    }
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
}

get allTopics(): TopicsEntity[]{
  if (this.topicService.allTopics && this.topicService.allTopics._embedded){
    return this.topicService.allTopics._embedded.topicDTOList;
  }
  return null;
}
selectTopic(topic: TopicsEntity): void {
  this.topicService.currentTopic = topic;
  this.currentTopic = topic;
  this.getTopicRankings(topic);
  this.selectSubTopic(this.currentTopic.subtopics[0]);
}
selectSubTopic(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
  this.subtopicService.currentSubtopic = subtopic;
  if (subtopic.name === 'All'){
    return;
  }
  this.currentSubtopic = subtopic;
  this.subtopicBlogSetMap.forEach(item => {
    if (item.subtopic.id === subtopic.id){
      this.filteredPaginatedBlog = item.paginatedBlog;
    }
  });
  this.subtopicService.currentSubtopic = subtopic;
  // this.getSubtopicRankings(this.topicService.currentTopic, subtopic);
  // this.fetchAllBlogs();
  if (this.userId == null || this.userId === -1 ){
    this.subtopicRankingService.getAllTagsForSubtopicWithNameMappings(subtopic.id).subscribe(
      (result) => {
        this._nonfilteredTagList = [];
        this._nonfilteredTagList = result._embedded.tagList;
        this._filteredTagList = this.nonFilteredTagList.slice();
        if (this._selectedTag.id === -1){
          this._nonfilteredTagList.forEach(tag => {
           if (tag.name.toLowerCase() === this.currentSubtopic.name.toLowerCase()){
             // this.selectTag(this._filteredTagList[0]);
             this._selectedTag = tag;
             // this.searchTagValue = tag.name;
            //  this.selectedTagValue = tag.name;
            //  this.selectedATag.emit( tag);
             this._filteredTagList = this.nonFilteredTagList.slice();

           }
         });
          this.sortBlogs = '';
          this.filterArticles();
         }
         else{
          this.sortBlogs = '';
          this.filterArticles();
         }
      }
    );

  }
  else{
    this.subtopicRankingService.getAllTagsForSubtopic(subtopic.id, false, true, false, false, this.userId, false).subscribe(
      (result) => {
        console.log('this is the me/user blog call for tags... check if me id and user id are same');
        this._nonfilteredTagList = [];
        this._nonfilteredTagList = result._embedded.tagList;
        this._filteredTagList = this.nonFilteredTagList.slice();
        if (this._selectedTag.id === -1){
          this._nonfilteredTagList.forEach(tag => {
           if (tag.name.toLowerCase() === this.currentSubtopic.name.toLowerCase()){
             // this.selectTag(this._filteredTagList[0]);
             this._selectedTag = tag;
             // this.searchTagValue = tag.name;
            //  this.selectedTagValue = tag.name;
            //  this.selectedATag.emit( tag);
             this._filteredTagList = this.nonFilteredTagList.slice();

           }
         });
          this.sortBlogs = '';
          this.filterArticles();
         }
         else{
          this.sortBlogs = '';
          this.filterArticles();
         }
        // this.emptyRankings();
        // let foundRootTag = false;
        // this._nonfilteredTagList.forEach(tag => {
        //   if (tag.name.toLowerCase() === subtopic.name.toLowerCase()){
        //     // this.selectTag(this._filteredTagList[0]);
        //     foundRootTag = true;
        //     this.selectTag(tag);
        //     this.selectedValue = tag.name;
        //     this._filteredTagList = this.nonFilteredTagList.slice();
        //   }
        // });
        // if (!foundRootTag){
        //   this.selectTag(this.nonFilteredTagList[0]);
        //   this.selectedValue = this.nonFilteredTagList[0].name;
        //   this._filteredTagList = this.nonFilteredTagList.slice();
        // }
      }
    );
  }
}
getTopicRankings(topic: TopicsEntity): void{
  this.topicService.currentTopic = topic;
  this.currentTopic = topic;
}



isSelected(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): boolean {
  return this.subtopicService.currentSubtopic === subtopic;
}

isSelectedTopic(topic: TopicsEntity): boolean {
  return this.topicService.currentTopic === topic;
}

topicSelected(): boolean{
  if ( this.contestStateService.currentContest && this.contestStateService.currentContest.topicReference){
    return true;
  }
  return false;
}

subtopicSelected(): boolean{
  if ( this.contestStateService.currentContest && this.contestStateService.currentContest.subtopicReference ){
    return true;
  }
  return false;
}

onPaginate(paginationCommand: PaginationCommand): void {
  let nextPage = null;
  if (paginationCommand === PaginationCommand.NEXT){
    if (this.filteredPaginatedBlog.page.number + 1 === this.filteredPaginatedBlog.page.totalPages){
      return;
    }
    nextPage = this.filteredPaginatedBlog.page.number + 1;
  }
  else if (paginationCommand === PaginationCommand.PREVIOUS){
    if (this.filteredPaginatedBlog.page.number - 1 === this.filteredPaginatedBlog.page.totalPages){
      return;
    }
    nextPage = this.filteredPaginatedBlog.page.number - 1;
  }
  else if (paginationCommand === PaginationCommand.FIRST){
    nextPage = 0;
  }
  else if (paginationCommand === PaginationCommand.LAST){
    nextPage = this.filteredPaginatedBlog.page.totalPages - 1;
  }

  this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
  (this.currentSubtopic.id, this.startDate, this.endDate, this.sortBlogs,
   this._selectedTag, this.userId, nextPage).subscribe((newPosts: PaginatedBlog) => {
    if (this.filteredPaginatedBlog._embedded.blogPreviewDTOList.length > 0){
      this.filteredPaginatedBlog = newPosts;
      this.router.navigate(
        ['../' + this.activatedRoute.snapshot.url], {relativeTo: this.route,
         queryParams: { page: nextPage, size : this.filteredPaginatedBlog.page.size } }
      );
    }
    else{
      this.endOfBlogs = true;
    }
  });
  }

  get pageData(): Page{
    if (this.filteredPaginatedBlog && this.filteredPaginatedBlog.page){
      return this.filteredPaginatedBlog.page;
    }
    return undefined;
  }

  viewUserProfile(userId: number): void {
    // userId;
    this.userService.getUser(userId).subscribe((user: Me) => {
      this.userService.user = user;
      this.userService.id = user.id;
      this.router.navigate([routingGlobals.absUser, this.userService.id]);
    });
  }

  // fetchAllBlogs(): void {
  //   this.fetchHighestAccountTotalBlogsBySubtopic();
  //   this.fetchMostUpvotedBlogsBySubtopic();
  //   this.fetchNewBlogsBySubtopic();
  // }

  // fetchMostUpvotedBlogsBySubtopic(): void {

  //   this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
  //   (this.currentSubtopic.id, this.startDate, this.endDate, 'byUpvotes',
  //    this._selectedTag, this.userId, 0).subscribe((result: PaginatedBlog) => {
  //     this.upVotesPaginatedBlog = result;
  //   });
  // }

  // fetchNewBlogsBySubtopic(): void {
  //   this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
  //   (this.currentSubtopic.id, this.startDate, this.endDate, 'byViewCount',
  //    this._selectedTag, this.userId, 0).subscribe((result: PaginatedBlog) => {
  //     this.newPaginatedBlog = result;
  //   });
  // }

  //   fetchHighestAccountTotalBlogsBySubtopic(): void {
  //     this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
  //     (this.currentSubtopic.id, this.startDate, this.endDate, 'byLoggedInUserViews',
  //      this._selectedTag, this.userId, 0).subscribe((result: PaginatedBlog) => {
  //       this.newPaginatedBlog = result;
  //     });
  // }

  addSortAttribute(att: string): void{
  this.sortBlogs = '';
  this.sortBlogs = att;

  }

  clearSortAttribute(): void{
   this.sortBlogs = '';
   this.isBlogsFiltered = false;
   this.endModelIsActive = false;
   this.startModelIsActive = false;
  }

  filterArticles(): void{
   this.router.navigate(
      ['../' + this.activatedRoute.snapshot.url], {relativeTo: this.route,
        queryParams: { page: 0, size : this.filteredPaginatedBlog.page.size } }
    );
   if (this.startModelIsActive){
    this.startDate.setFullYear(this.startModel.year, this.startModel.month - 1, this.startModel.day);
    this.startDate.setHours(0, 0, 0, 0);
   }
   if (this.endModelIsActive){
    this.endDate.setFullYear(this.endModel.year, this.endModel.month - 1, this.endModel.day);
    this.endDate.setHours(0, 0, 0, 0);
   }
   this.blogService.getBlogsForSubtopicsWithFiltersNEWWithTag
   (this.currentSubtopic.id, this.startDate, this.endDate, this.sortBlogs,
    this._selectedTag, this.userId, 0).subscribe((result: PaginatedBlog) => {
    this.filteredPaginatedBlog = result;
    this.isBlogsFiltered = true;
   });
  }

  setStartModelToActive(): void{
    this.startModelIsActive = true;
  }

  setEndModelToActive(): void{
    this.endModelIsActive = true;
  }

  toggleMeridian(): any {
    this.meridian = !this.meridian;
  }

  get bothDatesSelected(): boolean {
    if (this.startDate && this.endDate){
      return true;
    }
    return false;
   }

   createDate(model: NgbDateStruct, isEndDate: boolean): Date{
    const date = new Date();
    date.setFullYear(model.year, model.month - 1, model.day);
    date.setHours(this.time.hour, this.time.minute, 0, 0);
    if (isEndDate){
      this.endDate = date;
    }
    else{
      this.startDate = date;
    }
    return date;
  }

  createDatesOnInit(): void{
    const endDate = new Date();
    const dateOffset = (24 * 60 * 60 * 1000) * 30; // 30 days
    const startDate = new Date();
    startDate.setTime(startDate.getTime() - dateOffset);
    startDate.setHours(0);
    startDate.setMinutes(0);
    this.startDate = startDate;
    this.endDate = endDate;
  }

  filterDropdown(e): void {
    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this._filteredTagList = this.nonFilteredTagList.slice();
      return;
    } else {
      this._filteredTagList = this.nonFilteredTagList.filter(
        (tag: Tag) => tag.name.toLowerCase().indexOf(searchString) > -1
      );
    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
    console.log('this.filteredList indropdown -------> ', this._filteredTagList);
  }

  get filteredTagList(): Tag[]{
    return this._filteredTagList;
  }

  get nonFilteredTagList(): Tag[]{
    return this._nonfilteredTagList;
  }

  selectValue(tag: Tag): void {
    this.selectedValue = tag.name;
    this.selectTag(tag);
  }

  selectTag(tag: Tag): void{
    this._selectedTag = tag;
   }

   get topics(): any{
    return this.topicService.allTopics?._embedded?.topicDTOList;
   }
}

export interface Blog {
  contentString?: string;
  contentStringSafeHtml?: SafeHtml;
  title?: string;
  date?: Date;
  upVotes?: any;
  downVotes?: any;
  topicId?: number;
  subtopicId?: number;
  selftext?: string;
  selftext_html: string;
  author_fullname?: string;
  name?: string;
  created_timestamp?: number;
  score?: number;
  id?: number;
  mongoId?: any;
  topicName?: string;
  subtopicName?: string;
  _owner_id?: number;
  comments?: Comment[];
  viewCount?: number;
  tagReferenceList?: any[];
  accountTotalOfOwnerForSubtopicAccount?: number;
  isPublished: boolean;
  loggedInUserViews?: number;
  pageVisits?: number;
  tagIds?: number[]; // used for reading blog (to include subtopic tag), not for creating
  tagId?: number; // used for creating blog, not for reading
  subscriptionOnly?: boolean;
  createDate?: Date;
  publishedDate?: Date; // Todo need to add to DTO and likely entity
  commentCount?: number;
}


export interface Comment {
  contentString: string;
  contentStringSafeHtml?: SafeHtml;
  date?: Date;
  downVotes?: number;
  id?: number;
  title?: string;
  upVotes?: number;
  username?: string;
  _blog_id: number;
  _owner_id: number;
  _original_comment_id?: number;
}

export interface EmbeddedBlog {
  blogPreviewDTOList: Blog[];
}

export interface EmbeddedComment {
  commentDTOList: Comment[];
}

export interface Links {
  valueRelationMapList: [Map<string, string>];
}

export interface Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}

export interface PaginatedBlog {
  _embedded: EmbeddedBlog;
  _links: Links;
  page: Page;
}

export interface PaginatedComment {
  _embedded: EmbeddedComment;
  _links: Links;
  page: Page;
}

