import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { Observable } from 'rxjs';
import { Contest, ContestType, DurationType } from './game/game.service';
import { GlobalContestState } from '../GlobalVars/Enums/ContestEnums';
import { ContestCreationObject } from './create-contest/create-contest.service';
import { TopicService } from '../topic/topic.service';
import { SubtopicService } from '../topic/subtopic/subtopic.service';
import { PaginatedContest } from '../profile/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class ContestService {

  // errorResponse: HttpErrorResponse;

  contestCreationObject: ContestCreationObject;

  constructor(private httpClient: HttpClient) { }

  createContest(data: any): Observable<any>{
    return this.httpClient.post(HOST_IP_INFO_MARKETS + '/contest', data);
  }

  // tslint:disable-next-line:max-line-length
  getContests(getContestRequest: GetContestRequest): Observable<PaginatedContest>{

    if (getContestRequest.type === 'topicAndSubtopic'){
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/contest/topic/${getContestRequest.topicId}/subtopic/${getContestRequest.subtopicId}?globalContestState=${GlobalContestState[getContestRequest.globalContestState]}&tagId=${getContestRequest.tagId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25&atomicGameDescription=${getContestRequest.atomicGameName}&atomicGameName=${getContestRequest.atomicGameName}` );
    }
    if (getContestRequest.type === 'allUpcoming'){
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/contest/all?globalContestState=${GlobalContestState[getContestRequest.globalContestState]}&tagId=${getContestRequest.tagId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25` );
    }
  }

  getMyContests(getContestRequest: GetContestRequest): Observable<PaginatedContest>{
    if (getContestRequest.type === 'topic'){
      // -1 -> all topics
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/me/contest?type=${getContestRequest.type}&tagId=${getContestRequest.tagId}&topicId=${getContestRequest.topicId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25` );
    }
    if (getContestRequest.type === 'subtopic'){
      // -1 -> all topics
      // tslint:disable-next-line:max-line-length
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/me/contest?type=${getContestRequest.type}&tagId=${getContestRequest.tagId}&subtopicId=${getContestRequest.subtopicId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25` );
    }
    else{
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/me/contest?type=${getContestRequest.type}&tagId=${getContestRequest.tagId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25` );
    }
  }

  getUserContests(getContestRequest: GetContestRequest, userId: number): Observable<PaginatedContest>{
    if (userId === -1){
      this.getContests(getContestRequest);
      return;
    }
    if (getContestRequest.type === 'topic'){
      // -1 -> all topics
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/user/${userId}/contest?type=${getContestRequest.type}&topicId=${getContestRequest.topicId}&tagId=${getContestRequest.tagId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25&atomicGameName=${getContestRequest.atomicGameName}&atomicGameDescription=${getContestRequest.atomicGameName}}`);
    }
    if (getContestRequest.type === 'subtopic'){
      // -1 -> all topics
      // tslint:disable-next-line:max-line-length
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/user/${userId}/contest?type=${getContestRequest.type}&subtopicId=${getContestRequest.subtopicId}&tagId=${getContestRequest.tagId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25&atomicGameName=${getContestRequest.atomicGameName}&atomicGameDescription=${getContestRequest.atomicGameName}` );
    }
    else{
      return this.httpClient.get<PaginatedContest>(HOST_IP_INFO_MARKETS + `/user/${userId}/contest?type=${getContestRequest.type}&subtopicId=${getContestRequest.subtopicId}&tagId=${getContestRequest.tagId}&contestTypeString=${getContestRequest.contestType}&durationTypeString=${getContestRequest.durationType}&page=${getContestRequest.page}&size=25&atomicGameName=${getContestRequest.atomicGameName}&atomicGameDescription=${getContestRequest.atomicGameName}` );
    }
  }

  // tslint:disable-next-line:no-shadowed-variable
  updateContest(type: string, contest: Contest): Observable<any>{
    if (type === 'enterContest'){
      // -1 -> all topics
      // const data = {contest: financialContest};
      return this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/contest?type=${type}`, contest );
    }
    if (type === 'updateContest'){
      return this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/contest?type=${type}`, contest );
    }
  }

  getContest(contestId: number): Observable<Contest>{
    return this.httpClient.get<Contest>(HOST_IP_INFO_MARKETS + `/contest/${contestId}`);
  }
}

export interface GetContestRequest{
  type: string;
  page: number;
  topicId?: number;
  subtopicId?: number;
  globalContestState?: GlobalContestState;
  userId?: number;
  tagId: number;
  durationType: DurationType;
  contestType: ContestType;
  atomicGameName: string;

}
