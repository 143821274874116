<div *ngIf="selectedGameObject" class="container ml-1 mt-4 font-weight-light">
  <div class="row justify-content-center">
    <div class="col-sm-6 col-auto">
      <table
        class="table table-striped mr-3 table-xs table-hover table-condensed"
      >
        <thead>
          <tr>
            <th scope="col">Field to Bet on</th>
            <th scope="col">Current Value of Field</th>
            <th scope="col">Create this type of Game</th>
          </tr>
        </thead>
        <tbody>
          <tr (click)="selectFieldToBetOn('Close', selectedStock.close)">
            <td>Close</td>
            <td>{{ selectedStock.close }}</td>
            <td>Create Close Game by clicking on row</td>
          </tr>
          <tr (click)="selectFieldToBetOn('High', selectedStock.high)">
            <td>Daily High</td>
            <td>{{ selectedStock.high }}</td>
            <td>Create Daily High Game by clicking on row</td>
          </tr>
          <tr (click)="selectFieldToBetOn('Low', selectedStock.low)">
            <td>Daily High</td>
            <td>{{ selectedStock.low }}</td>
            <td>Create Daily Low Game by clicking on row</td>
          </tr>
          <tr (click)="selectFieldToBetOn('Open', selectedStock.open)">
            <td>Open</td>
            <td>{{ selectedStock.open }}</td>
            <td>Create Daily Low Game by clicking on row</td>
          </tr>
          <tr (click)="selectFieldToBetOn('volume', selectedStock.volume)">
            <td>Volume</td>
            <td>{{ selectedStock.volume }}</td>
            <td>Create Volume Game by clicking on row</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ul class="pagination">
  <li class="page-item">
    <a class="page-link" (click)="previousPage()">Previous</a>
  </li>
  <li class="page-item" [class.disabled]="!selectedFieldToBetOn">
    <a class="page-link" (click)="nextPage()">Next</a>
  </li>
  <li class="page-item">
    <a class="page-link" (click)="startPage()">Start</a>
  </li>
</ul>
