import { Injectable } from '@angular/core';
import { BetSlip, Contest, Game, ProcessState, UnderOverOutcome } from './game/game.service';
import { BetSlipService } from './bet-slip.service';
import { UserContestState } from '../GlobalVars/Enums/ContestEnums';
import { Links, Page } from '../media/media.component';
import { PaginatedContest } from '../profile/User/user.service';
import { PaginatedContestOutcomeRanking, ContestOutcomeRanking } from './contest.component';
import { user } from '../GlobalVars/routingGlobal';


@Injectable({
  providedIn: 'root'
})
export class ContestStateService {

  public paginatedContests: PaginatedContest;
  public paginatedEnteredContests: PaginatedContest;
  public paginatedCurrentListOfContestsByTopicAndSubtopic: PaginatedContest;
  private _currentContest: Contest;
  public paginatedRankingsForContest: PaginatedContestOutcomeRanking;
  public userContestState: UserContestState;
  activeContestOutcome: any;

  constructor(private betSlipService: BetSlipService) { }


    viewOtherUsersContestBetSlips(contest: Contest, userId: number, username: string): void{
      this._currentContest = contest;
      // this.betSlipService.setBetSlipsForContest(this._currentContest, userId, username);
    }

    viewEnteredUpcomingContest(contest: Contest): void {
     // todo
     this._currentContest = contest;
    //  this._currentContest.userContestState = UserContestState.ENTERED_UPCOMING;
     // this.betSlipService.setBetSlipsForContest(this._currentContest);
     if (contest.userContestState === UserContestState.ENTERED_UPCOMING || true){
       this.betSlipService.getContestRankings(contest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
           console.log('retreived outcomes');
           this.paginatedRankingsForContest = outcomes;
       });
     }
    }

    setContestFromAPI(contest: Contest, userId: number = -1): void{
      this._currentContest = contest;
      // this.betSlipService.setBetSlipsForContest(this._currentContest, userId);
    }

    viewEnteredLiveContest(contest: Contest): void {
     // todo
     this._currentContest = contest;
    //  this._currentContest.userContestState = UserContestState.ENTERED_LIVE;
    //  this.betSlipService.setBetSlipsForContest(this._currentContest);
     if (contest.userContestState === UserContestState.ENTERED_LIVE || true){
       this.betSlipService.getContestRankings(contest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
           console.log('retreived outcomes');
           this.paginatedRankingsForContest = outcomes;
       });
     }
    }

    viewEnteredPastContest(contest: Contest): void {
     // todo
     this._currentContest = contest;
    //  this._currentContest.userContestState = UserContestState.ENTERED_PAST;
     // this.betSlipService.setBetSlipsForContest(this._currentContest);
     if (contest.userContestState === UserContestState.ENTERED_PAST || true){
       this.betSlipService.getContestRankings(contest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
           console.log('retreived outcomes');
           this.paginatedRankingsForContest = outcomes;

       });
     }
    }

    viewCreatingContest(contest: Contest): void{

    }

    viewNonEnteredUpcomingContest(contest: Contest): void {
      this._currentContest = contest;
      // this.currentContest.userContestState = UserContestState.NON_ENTERED_UPCOMING;
      this.createEmptyBetSlipsForContest();
    }

    viewNonEnteredLiveContest(contest: Contest): void {
      this._currentContest = contest;
      // this.currentContest.userContestState = UserContestState.NON_ENTERED_LIVE;
      this.createEmptyBetSlipsForContest();
    }

    viewNonEnteredPastContest(contest: Contest): void {
      this._currentContest = contest;
      // this.currentContest.userContestState = UserContestState.NON_ENTERED_PAST;
      this.createEmptyBetSlipsForContest();
    }

    viewNonEnteredUpcomingContestAsOtherUserIsEntered(contest: Contest, userId: number, username: string): void {
      this._currentContest = contest;
      this.currentContest.userContestState = UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED;
      // this.betSlipService.setBetSlipsForContest(this._currentContest, userId, username);
      if (contest.userContestState === UserContestState.ENTERED_PAST || true){
        this.betSlipService.getContestRankings(contest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
            console.log('retreived outcomes');
            this.paginatedRankingsForContest = outcomes;
        });
      }
    }

    viewNonEnteredLiveContestAsOtherUserIsEntered(contest: Contest, userId: number, username: string): void {
      this._currentContest = contest;
      this.currentContest.userContestState = UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED;
      // this.betSlipService.setBetSlipsForContest(this._currentContest, userId, username);
      if (contest.userContestState === UserContestState.ENTERED_PAST || true){
        this.betSlipService.getContestRankings(contest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
            console.log('retreived outcomes');
            this.paginatedRankingsForContest = outcomes;
        });
      }
    }

    viewNonEnteredPastContestAsOtherUserIsEntered(contest: Contest, userId: number, username: string): void {
      this._currentContest = contest;
      // this.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
      // this.betSlipService.setBetSlipsForContest(this._currentContest, userId, username);
      if (contest.userContestState === UserContestState.ENTERED_PAST || true){
        this.betSlipService.getContestRankings(contest.id).subscribe((outcomes: PaginatedContestOutcomeRanking) => {
            console.log('retreived outcomes');
            this.paginatedRankingsForContest = outcomes;
        });
      }
    }

    setEnteredContestsOnLogin(enteredContests: PaginatedContest): void{
      this.paginatedEnteredContests = enteredContests;
    }

    setListOfContestsByTopicAndSubtopicOnContestTab(contests: PaginatedContest): void{
      this.paginatedCurrentListOfContestsByTopicAndSubtopic = contests;
    }

    get contests(): Contest[] {
      return this.paginatedContests._embedded.contestPreviewDTOList;
    }

    get enteredContests(): Contest[] {
      return this.paginatedEnteredContests._embedded.contestPreviewDTOList;
    }

    get currentContest(): Contest {
      return this._currentContest;
    }

    get currentListOfContestsByTopicAndSubtopic(): PaginatedContest {
      return this.paginatedCurrentListOfContestsByTopicAndSubtopic;
    }

    get rankingsForContest(): ContestOutcomeRanking[] {
      return this.paginatedRankingsForContest._embedded.contestOutcomeRankingDTOList;
    }

    setRankingsforContest(rankings: PaginatedContestOutcomeRanking): void{
      this.paginatedRankingsForContest = rankings;
    }

    createEmptyBetSlipsForContest(): void {
      this._currentContest.games.forEach((game: Game) => {
         game.betSlip = { gameId: game.id,
         underOverOutcome: UnderOverOutcome.NO_RESULT, processState: ProcessState.Unprocessed,
         over: false, under: false, weight: 0.0, predictedValue: -1000};
      });
      this.betSlipService.isEnteredAndHasBetSlips = false;
    }
}

export interface UnderOverOutcomeRanking {
  contestId?: number;
  ranking: number;
  totalLosses: number;
  totalWins: number;
  userId: number;
  username: string;
  value: number;
  elo: number;
  avgMarginOfError?: number;
  totalMarginOfError?: number;
  totalHeadToHeadWins?: number;
  totalHeadToHeadLosses?: number;
  totalHeadToHeadDraws?: number;
}


export interface EmbeddedUnderOverOutcomeRanking{
  underOverContestOutcomeUsernameVOList: UnderOverOutcomeRanking[];
}

export interface PaginatedUnderOverOutcomeRanking {
  _embedded: EmbeddedUnderOverOutcomeRanking;
  _links: Links;
  page: Page;
}
