'use strict';
import { environment } from './../../environments/environment';

export const HOST_IP_INFO_MARKETS = environment.toilzzUrl;
export const HOST_IP_STATS = environment.statsUrl;
export const STRIPE_PK = environment.STRIPE_PUBLIC_KEY;
export const STRIPE_STANDARD_SUB = environment.STRIPE_STANDARD_SUBSCRIPTION;
// 'http://localhost:9000';
// 'https://api.qa.toilzz.com:9000';

// ng serve --configuration=prod
// ng serve --configuration=qa
// ng serve  // this is just local config
