<div *ngIf="!isMobile">
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="container ml-1 mt-4 font-weight-light text-center mb-3">
        <p class="font-weight-light">
          Make Predictions - Prove You're an Expert - Gain a following in Your
          Areas of Knowledge - To Learn More,
          <a routerLink="/about-us"><b>Click Here</b></a>
        </p>
        <p class="font-weight-light" *ngIf="userId === -1">
          Invite your friends to sign up and be elible to win 100 dollars! (Must
          use below link)
          <button (click)="copyURL()"><b>Copy Your Link</b></button>
        </p>
        <p *ngIf="yourLink">{{ yourLink }}</p>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
  <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
  <div class="row">
    <div
      *ngFor="let topic of topics; let i = index"
      (click)="selectTopic(topic)"
      class="col-4 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedTopic(topic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ topic.name }}
    </div>
  </div>
  <p *ngIf="!currentSubtopic" class="text-center font-weight-bold">
    Select a Subtopic
  </p>
  <p *ngIf="currentSubtopic" class="text-center font-weight-bold">
    <u>Selected {{ currentSubtopic.name }}</u>
  </p>
  <div class="row">
    <div
      *ngFor="let subtopic of currentTopic.subtopics; let i = index"
      (click)="selectSubtopic(subtopic)"
      class="col-4 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelected(subtopic) === true
      }"
      style="font-size: 12px"
    >
      <p style="cursor: pointer">{{ subtopic.name }}</p>
    </div>
  </div>
  <p *ngIf="!selectedTag" class="text-center font-weight-bold">Select a Tag</p>

  <p *ngIf="selectedTag" class="text-center font-weight-bold">
    <u>Selected {{ selectedTag.name | lowercase | titlecase }}</u>
  </p>
  <div class="text-center mb-3">
    <button
      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
      type="button"
      *ngIf="!showTagMappings"
      (click)="toggleTagMappings()"
    >
      Expand tags
    </button>
    <button
      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light text-center"
      type="button"
      *ngIf="showTagMappings"
      (click)="toggleTagMappings()"
    >
      Hide Tag Items
    </button>
  </div>

  <div class="md-form d-flex justify-content-center">
    <input type="search" [(ngModel)]="searchText" placeholder="Search Tags" />
  </div>
  <div class="row">
    <div
      *ngFor="let tag of filteredTagList | search : searchText"
      (click)="selectTag(tag)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic': isSelectedTag(tag) === true
      }"
      style="font-size: 12px"
    >
      <p style="cursor: pointer">{{ tag.name | lowercase | titlecase }}</p>
      <ul *ngIf="showTagMappings">
        <li *ngFor="let nameMapping of tag.validNameMappings">
          {{ nameMapping }}
        </li>
      </ul>
    </div>
  </div>
  <p *ngIf="!contestType" class="text-center font-weight-bold">
    Select a Contest Type
  </p>
  <div *ngIf="contestType" class="text-center font-weight-bold">
    <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_ERROR'">
      <p><u>Selected Margin of Error</u></p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'UNDER_OVER'">
      <p><u>Selected Under/Over</u></p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_VICTORY'">
      <p><u>Selected Margin Of Victory</u></p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'TOTAL_POINTS_SCORED'">
      <p><u>Selected Total Points Scored</u></p>
    </div>
  </div>
  <div class="row">
    <div
      *ngFor="let contestType of contestTypes; let i = index"
      (click)="selectContestType(contestType)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedContestType(contestType) === true
      }"
      style="font-size: 12px"
    >
      <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_ERROR'">
        <p>Margin of Error</p>
      </div>
      <div style="cursor: pointer" *ngIf="contestType === 'UNDER_OVER'">
        <p>Under/Over</p>
      </div>
      <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_VICTORY'">
        <p>Selected Margin Of Victory</p>
      </div>
      <div
        style="cursor: pointer"
        *ngIf="contestType === 'TOTAL_POINTS_SCORED'"
      >
        <p>Selected Total Points Scored</p>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-3">
      <button class="btn btn-sm btn-secondary" (click)="setAllUsersFlag()">
        All Users
      </button>
    </div>
    <div class="col-3">
      <button
        class="btn btn-sm btn-secondary"
        (click)="setUsersWhoMetThresholdFlag()"
      >
        Met Contest Threshold
      </button>
    </div>
  </div> -->

  <div class="row my-2">
    <div class="col-6 text-center">
      <button class="btn btn-sm btn-secondary" (click)="setUnfilteredFlag()">
        Unfiltered Rankings
      </button>
    </div>
    <div class="col-6 text-center">
      <button class="btn btn-sm btn-secondary" (click)="setFilteredFlag()">
        Filtered Rankings
      </button>
    </div>
  </div>
  <div *ngIf="filterRankings" class="row my-2">
    <div class="col-3">
      <button
        class="btn btn-sm btn-secondary"
        (click)="setMinimumContests(minimumContestsInput.value)"
      >
        Minimum Contests
      </button>
      <p>
        <input
          type="number"
          id="event"
          name="nameOfContestInput"
          style="font-size: 16px; width: 125px"
          placeholder="5"
          #minimumContestsInput
        />
      </p>
    </div>
    <div class="col-3">
      <button
        class="btn btn-sm btn-secondary"
        (click)="setMinimumArticles(minimumArticlesInput.value)"
      >
        Minimum Articles
      </button>
      <p>
        <input
          type="number"
          id="event"
          name="nameOfContestInput"
          style="font-size: 16px; width: 125px"
          placeholder="5"
          #minimumArticlesInput
        />
      </p>
    </div>

    <div class="col-3">
      <button
        class="btn btn-sm btn-secondary"
        (click)="setRankUpperLimit(rankUpperLimitInput.value)"
      >
        Rank Limit
      </button>
      <p>
        <input
          type="number"
          id="event"
          name="nameOfContestInput"
          style="font-size: 16px; width: 125px"
          placeholder="1000"
          #rankUpperLimitInput
        />
      </p>
    </div>
  </div>

  <div *ngIf="!canCallForRankings()">
    <p class="text-center">
      <b><u>Please select a Subtopic, Tag and Contest Type from above</u></b>
    </p>
  </div>

  <div *ngIf="canCallForRankings()">
    <div class="row">
      <div class="col-6">
        <div class="text-center">
          <p class="font-weight-bold font-italic mt-2 mb-0">Month</p>
          <app-guru-ranking-timeframe
            [timeframeType]="TimeAndGameDurationPeriodType.MONTH"
            [contestType]="contestType"
            [tagId]="tagId"
            [subtopicId]="subtopicId"
            [subtopicName]="subtopicName"
            [minimumArticles]="minimumArticles"
            [minimumContests]="minimumContests"
            [rankUpperLimit]="rankUpperLimit"
            [isFiltered]="filterRankings"
          >
          </app-guru-ranking-timeframe>
        </div>
      </div>
      <div class="col-6">
        <div class="text-center">
          <p class="font-weight-bold font-italic mt-2 mb-0">Year</p>
          <app-guru-ranking-timeframe
            [timeframeType]="TimeAndGameDurationPeriodType.YEAR"
            [contestType]="contestType"
            [tagId]="tagId"
            [subtopicId]="subtopicId"
            [subtopicName]="subtopicName"
            [minimumArticles]="minimumArticles"
            [minimumContests]="minimumContests"
            [rankUpperLimit]="rankUpperLimit"
            [isFiltered]="filterRankings"
          >
          </app-guru-ranking-timeframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isMobile">
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="container mt-4 font-weight-light text-center mb-3">
        <p class="font-weight-bold font-italic">
          "Wall Street Bets, The Game" - Some Guy on Twitter
        </p>
        <p class="font-weight-light">
          Make Predictions - Prove You're an Expert - Gain a following in Your
          Areas of Knowledge - To Learn More,
          <a routerLink="/about-us"><b>Click Here</b></a>
        </p>
        <p class="font-weight-light" *ngIf="userId === -1">
          Invite your friends to sign up and be elible to win 100 dollars! (Must
          use below link)
          <button (click)="copyURL()"><b>Copy Your Link</b></button>
        </p>
        <p *ngIf="yourLink">{{ yourLink }}</p>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
  <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
  <div class="row">
    <div
      *ngFor="let topic of topics; let i = index"
      (click)="selectTopic(topic)"
      class="col-4 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedTopic(topic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ topic.name }}
    </div>
  </div>
  <p *ngIf="!currentSubtopic" class="text-center font-weight-bold">
    Select a Subtopic
  </p>
  <p *ngIf="currentSubtopic" class="text-center font-weight-bold">
    <u>Selected {{ currentSubtopic.name }}</u>
  </p>
  <div class="row">
    <div
      *ngFor="let subtopic of currentTopic.subtopics; let i = index"
      (click)="selectSubtopic(subtopic)"
      class="col-4 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelected(subtopic) === true
      }"
      style="font-size: 12px"
    >
      <p style="cursor: pointer">{{ subtopic.name }}</p>
    </div>
  </div>
  <p *ngIf="!selectedTag" class="text-center font-weight-bold">Select a Tag</p>

  <p *ngIf="selectedTag" class="text-center font-weight-bold">
    <u>Selected {{ selectedTag.name | lowercase | titlecase }}</u>
  </p>
  <div class="text-center mb-3">
    <button
      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
      type="button"
      *ngIf="!showTagMappings"
      (click)="toggleTagMappings()"
    >
      Expand tags
    </button>
    <button
      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light text-center"
      type="button"
      *ngIf="showTagMappings"
      (click)="toggleTagMappings()"
    >
      Hide Tag Items
    </button>
  </div>

  <div class="md-form d-flex justify-content-center">
    <input type="search" [(ngModel)]="searchText" placeholder="Search Tags" />
  </div>
  <div class="row">
    <div
      *ngFor="let tag of filteredTagList | search : searchText"
      (click)="selectTag(tag)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic': isSelectedTag(tag) === true
      }"
      style="font-size: 12px"
    >
      <p style="cursor: pointer">{{ tag.name | lowercase | titlecase }}</p>
      <ul *ngIf="showTagMappings">
        <li *ngFor="let nameMapping of tag.validNameMappings">
          {{ nameMapping }}
        </li>
      </ul>
    </div>
  </div>
  <p *ngIf="!contestType" class="text-center font-weight-bold">
    Select a Contest Type
  </p>
  <div *ngIf="contestType" class="text-center font-weight-bold">
    <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_ERROR'">
      <p><u>Selected Margin of Error</u></p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'UNDER_OVER'">
      <p><u>Selected Under/Over</u></p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_VICTORY'">
      <p><u>Selected Margin Of Victory</u></p>
    </div>
    <div style="cursor: pointer" *ngIf="contestType === 'TOTAL_POINTS_SCORED'">
      <p><u>Selected Total Points Scored</u></p>
    </div>
  </div>
  <div class="row">
    <div
      *ngFor="let contestType of contestTypes; let i = index"
      (click)="selectContestType(contestType)"
      class="col-3 text-center"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedContestType(contestType) === true
      }"
      style="font-size: 12px"
    >
      <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_ERROR'">
        <p>Margin of Error</p>
      </div>
      <div style="cursor: pointer" *ngIf="contestType === 'UNDER_OVER'">
        <p>Under/Over</p>
      </div>
      <div style="cursor: pointer" *ngIf="contestType === 'MARGIN_OF_VICTORY'">
        <p><u>Margin Of Victory</u></p>
      </div>
      <div
        style="cursor: pointer"
        *ngIf="contestType === 'TOTAL_POINTS_SCORED'"
      >
        <p><u>Total Points Scored</u></p>
      </div>
    </div>
  </div>
  <div *ngIf="!canCallForRankings()">
    <p class="text-center">
      <b><u>Please select a Subtopic, Tag and Contest Type from above</u></b>
    </p>
  </div>

  <div *ngIf="canCallForRankings()">
    <div class="d-flex justify-content-center">
      <div class="text-center my-2 mx-auto">
        <p>Year</p>
        <app-guru-ranking-timeframe
          [timeframeType]="TimeAndGameDurationPeriodType.YEAR"
          [contestType]="contestType"
          [tagId]="tagId"
          [subtopicId]="subtopicId"
          [subtopicName]="subtopicName"
          [viewLimit]="viewLimit"
        >
        </app-guru-ranking-timeframe>
        <button
          class="btn-xs btn-info text-center"
          style="font-size: 8.8px"
          *ngIf="!viewMoreRankingsToggle && isMobile"
          (click)="viewMoreRankings()"
        >
          View More Rankings!
        </button>
        <button
          class="btn-xs btn-info text-center"
          style="font-size: 8.8px"
          *ngIf="viewMoreRankingsToggle && isMobile"
          (click)="viewLessRankings()"
        >
          View Less Rankings!
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="text-center my-2 mx-auto">
        <p>Month</p>
        <app-guru-ranking-timeframe
          [timeframeType]="TimeAndGameDurationPeriodType.MONTH"
          [contestType]="contestType"
          [tagId]="tagId"
          [subtopicId]="subtopicId"
          [subtopicName]="subtopicName"
          [viewLimit]="viewLimit"
        >
        </app-guru-ranking-timeframe>
        <button
          class="btn-xs btn-info text-center"
          style="font-size: 8.8px"
          *ngIf="!viewMoreRankingsToggle && isMobile"
          (click)="viewMoreRankings()"
        >
          View More Rankings!
        </button>
        <button
          class="btn-xs btn-info text-center"
          style="font-size: 8.8px"
          *ngIf="viewMoreRankingsToggle && isMobile"
          (click)="viewLessRankings()"
        >
          View Less Rankings!
        </button>
      </div>
    </div>
  </div>
</div>
