import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { Modal } from './create-financial-contest-modal-create-contest-or-another-game/create-financial-contest-modal-create-contest-or-another-game.component';
import { CreateContestService } from '../create-contest.service';

@Component({
  selector: 'app-create-contest-modal',
  templateUrl: './create-contest-modal.component.html',
  styleUrls: ['./create-contest-modal.component.css']
})
export class CreateContestModalComponent implements OnInit, Modal {
closeResult;
@ViewChild('content') content;
  constructor(private route: ActivatedRoute, private router: Router,
              private createContestService: CreateContestService) {
              }

  nextPage(): void {
    // this.router.navigate([routingGlobals.createFinancialContestModal], {relativeTo: this.route});
  }
  previousPage(): void {
    // this.router.navigate([routingGlobals.createFinancialContestModal], {relativeTo: this.route});
  }
  startPage(): void {
    // this.router.navigate([routingGlobals.createFinancialContestModal], {relativeTo: this.route});
  }

  ngOnInit(): void {
    this.router.navigate([routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
  }

  updateNameOfContest(nameOfContest: string): void{
    this.createContestService.contestName = nameOfContest;
  }

  get nameOfContest(): string{
    return this.createContestService.contestName;
  }

}
