<div class="container-fluid">
  <div class="font-weight-light" style="font-size: 10px">
    <p class="font-weight-bold my-1" style="font-size: 14px">
      <u
        ><strong><span>How our site works</span></strong></u
      >
    </p>

    <div class="font-weight-bold my-1" style="font-size: 14px">
      <ul style="margin-bottom: 0in">
        <li style="margin: 0in; font-size: 14px">
          <strong
            ><span
              >Gain a following and earn money by producing content and winning
              games in your areas of expertise*</span
            ></strong
          >
        </li>
      </ul>
    </div>

    <div class="font-weight-bold my-1" style="font-size: 14px">
      <ul style="margin-bottom: 0in; list-style-type: disc">
        <li style="margin: 0in; font-size: 14px">
          <strong
            ><span
              >With a proven track record, you can gain a following on our site.
              You can also use your rating and performance on this site to help
              promote your content elsewhere</span
            ></strong
          >
        </li>
      </ul>
    </div>
    <p class="font-weight-bold my-0" style="font-size: 14px">
      <strong
        ><u><span>How You Earn Money</span></u></strong
      >
    </p>

    <p class="font-weight-bold mb-2" style="font-size: 14px">
      <strong
        ><u><span>"The Sigsouk Promise"</span></u></strong
      >
    </p>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><span
          >We guarantee that at least 50 percent of our subscription revenue
          will go directly back into the hands of content creators on our site.
          We will choose to give &ldquo;premium contracts&rdquo; to at least 50
          users for a monthly contract.** The more valuable your knowledge and
          content is, the more your contract will be worth. We determine your
          contract offer using a variety of factors including but not limited
          to.***</span
        ></strong
      >
    </p>
    <ol style="list-style-type: decimal; margin-left: 0.25in">
      <li>
        <strong
          ><span style="font-size: 12px"
            >How many impressions you receive on your non premium content</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Your knowledge score (AKA Ape Score listed on the site)</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Your general activity on our site and how frequently you
            participate in our sites activities</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Number of profile views and social link usages you get from other
            users</span
          ></strong
        >
      </li>
    </ol>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><u><span>How do I earn money?</span></u></strong
      >
    </p>
    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong><span>It is simple</span></strong>
    </p>
    <ol style="list-style-type: decimal">
      <li>
        <strong
          ><span style="font-size: 12px"
            >Sign up for a subscription</span
          ></strong
        >
        <ol style="list-style-type: lower-alpha">
          <li>
            <strong
              ><span style="font-size: 12px"
                >We use the subscription for 4 primary purposes.&nbsp;</span
              ></strong
            >
            <ol style="list-style-type: lower-roman">
              <li>
                <strong
                  ><span style="font-size: 12px"
                    >To verify you're a real person</span
                  ></strong
                >
              </li>
              <li>
                <strong
                  ><span style="font-size: 12px"
                    >To create revenue for the business using a freemium
                    business model</span
                  ></strong
                >
              </li>
              <li>
                <strong
                  ><span style="font-size: 12px"
                    >To create a pool of money to pay our best content
                    creators</span
                  ></strong
                >
              </li>
              <li>
                <strong
                  ><span style="font-size: 12px"
                    >To pay for developers, servers and other associated
                    business costs</span
                  ></strong
                >
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Play in contests that you want to write about</span
          ></strong
        >
        <ol style="list-style-type: lower-alpha">
          <li>
            <strong
              ><span style="font-size: 12px"
                >If you want to write about index funds, play in contests that
                involve index funds</span
              ></strong
            >
          </li>
          <li>
            <strong
              ><span style="font-size: 12px"
                >If you want to write about tech stocks, play in contests that
                involve tech stocks</span
              ></strong
            >
          </li>
        </ol>
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Write content in our forums</span
          ></strong
        >
        <ol style="list-style-type: lower-alpha">
          <li>
            <strong
              ><span style="font-size: 12px"
                >Getting upvotes and views will help you be in the running for
                earning a contract even if you aren&rsquo;t a top 1% performer
                in our contests</span
              ></strong
            >
          </li>
        </ol>
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Look out for the lists of users that will be listed at the
            beginning of the next month</span
          ></strong
        >
        <ol style="list-style-type: lower-alpha">
          <li>
            <strong
              ><span style="font-size: 12px"
                >You will also be individually contacted for contract and
                payment details</span
              ></strong
            >
          </li>
        </ol>
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >If we don&rsquo;t hear back from you within 1 week of the contract
            offer, your name will be removed from the contract bid and the
            contract will be offered to the person next in line</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >From the time the contract is signed, you have 1 month to submit
            stock/currency/crypto picks/analysis and your 2 premium
            articles</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >These articles will only be viewable for our paid subscribers</span
          ></strong
        >
      </li>
    </ol>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><u><span>What comes with my subscription?</span></u></strong
      >
    </p>
    <ul style="list-style-type: disc">
      <li>
        <strong
          ><span style="font-size: 12px"
            >Ability to play games in subscription only contests (ie: etf
            contests)</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Ability to write in subscription only tags (ie: etf contests)</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Verification to be able to be offered a contract if you are a top
            user on our site</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Ability to access subscriber only stock/crypto/currency picks and
            other premium content</span
          ></strong
        >
      </li>
    </ul>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><u><span>Contract</span></u></strong
      >
    </p>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><span
          >The contracts are basic. We will ask you to give your picks/analysis
          for stocks, crypto or currency for the upcoming month and year. Your
          picks and analysis will only be available to our subscribers as
          premium content. We will also ask you to provide 2 premium articles
          that will only be available to our subscribers. In exchange, we will
          pay you a sum of money that is stated in the contract after the
          content is submitted. The contracts are on a monthly basis and will
          not be renewed at the end of the month unless we notify you.</span
        ></strong
      >
    </p>
    <ul style="list-style-type: disc">
      <li>
        <strong
          ><span style="font-size: 12px"
            >Your picks/analysis for stocks, crypto or currency for the upcoming
            month and year.</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Two (2) premium articles that will only be available to our
            subscribers</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span style="font-size: 12px"
            >Once the 3 above items are submitted to and approved by Sigsouk,
            you will recieve payment for your work. The contracts are on a
            monthly basis and will not be renewed at the end of the month unless
            we notify you.</span
          ></strong
        >
      </li>
    </ul>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><u><span>Help make this site grow</span></u></strong
      >
    </p>

    <ul>
      <li>
        <strong
          ><span style="font-size: 12px"
            >The more this site grows, the larger the pool of revenue will be
            that goes directly back into the hands of our subscription content
            creators. Once we hit a critical mass, we can move the minimum of 50
            creators a month to something substantially larger. That can only
            happen if this site grows</span
          ></strong
        >
      </li>
    </ul>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><u><span>Why subscriber growth matters for you</span></u></strong
      >
    </p>

    <p class="font-weight-light my-1" style="font-size: 14px">
      <strong
        ><span>
          The following is subject to change and will likely change based on
          user feedback as we grow and exapnd our user base. Our contracts are
          currently top heavy contracts where only a small amount of users get
          large contracts. As we grow our user base, the &ldquo;smaller&rdquo;
          contracts will become much larger, and we will likely shift towards a
          model that pays more people less top heavy contracts.</span
        ></strong
      >
    </p>

    <p class="font-weight-bold my-1" style="font-size: 14px">
      <strong
        ><span
          >Some sample data below to show why our site growing benefits you as a
          user creator:</span
        ></strong
      >
    </p>

    <ul class="decimal_type" style="list-style-type: disc">
      <li>
        <strong
          ><span class="font-weight-bold my-1" style="font-size: 14px"
            >1000 subscribers at 10 dollars a month. 5000 will go back to our
            top 50 content creators for an avg of 100 dollars a contract.</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span class="font-weight-bold my-1" style="font-size: 14px"
            >10000 subscribers at 10 a month. 50 will get paid out from a pool
            of 50k. The avg contract will be 1000 dollars for that month.</span
          ></strong
        >
      </li>
      <li>
        <strong
          ><span class="font-weight-bold my-1" style="font-size: 14px"
            >100000 subscribers at 10 a month. 50 will get paid from a pool of
            500k. Contracts will now be 10k a month. Around this juncture of
            subscription growth, we will probably change our model to pay more
            users. 10k a month would come out to roughly 120k a year for someone
            who gets a contract every month. If we raised our user contracts to
            250, the pay would still avg out to be substantial (2k a month)
            while paying many more users (5x) ***</span
          ></strong
        >
      </li>
    </ul>

    <p class="font-weight-bold my-0" style="font-size: 8px">
      <strong
        ><span
          >*Sigsouk always holds the final say on who we reward contracts to.
          While on site behavior helps with the possibility of you being offered
          a contract, there are no guaratees of you getting offered
          one.&nbsp;</span
        ></strong
      >
    </p>
    <p class="font-weight-bold my-0" style="font-size: 8px">
      <strong
        ><span
          >**Terms and conditions can change at any time.&nbsp;</span
        ></strong
      >
    </p>
    <p class="font-weight-bold my-0" style="font-size: 8px">
      <strong
        ><span
          >**50 users is only applicable if 1000 subscriptions exist for the
          given month. If less than 1000 subscriptions exists, a smaller number
          will be chosen for contracts while still maintaining our 50% contract
          promise. Regardless of number of subscriptions, we will always uphold
          our 50% susbcription revenue share promise&nbsp;</span
        ></strong
      >
    </p>
  </div>
</div>
