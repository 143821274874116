import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allCapsUnderscore'
})
export class AllCapsUnderscorePipe implements PipeTransform {

  transform(value: string): string {
    if (!!!value){
      return;
    }
    value = value.split('_').join(' ');
    return value;
  }

}
