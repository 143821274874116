
<div class="ml-1 mt-4 font-weight-light">
    <div class="row">
      <div class="col text-center mb-3">
        <p>Enter Name of Game</p>
        <p><input type="text" id="gameName" name="gameName" #gameName></p>
<p>Enter event</p>
<p><input type="text" id="event" name="event" #eventM></p>
<button class="btn btn-primary" type="button" (click)="addEvent(eventM.value)">add event</button>
<p>Current Events For Single Game</p>
<ul >
    <li *ngFor="let event of events">{{event}}</li>
</ul>
<button class="btn btn-primary" (click)="createGame()">
    Enter game
</button>

</div>
</div>
</div>