import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GuruService } from './guru.service';

@Injectable({
  providedIn: 'root'
})
export class GuruResolverService implements Resolve<any>  {

  constructor(private guruService: GuruService) { }
  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    return  this.guruService.getAllTags().pipe(
      catchError(error => {
        return of('No data');
      })
    );
  }
}
