import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_STATS, HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { HttpClient } from '@angular/common/http';
import { MeService } from '../profile/Me/me.service';
import { SubtopicRanking, PaginatedSubtopicRanking } from '../contest/contest.component';
import { Time } from '@angular/common';
import { subtopic } from '../GlobalVars/routingGlobal';
import { Contest, ContestType } from '../contest/game/game.service';
import { GlobalContestState } from '../GlobalVars/Enums/ContestEnums';



@Injectable({
  providedIn: 'root'
})
export class SubtopicRankingService {

  currentPaginatedSubtopicRanking: PaginatedSubtopicRanking;

  constructor(private httpClient: HttpClient, private meService: MeService) { }


  public getSubtopicRankings(subtopicId: number,
                             contestType: string,
                             timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                             tagId: number,
                             timeAndGameDurationPeriod: string = 'NONE' ): Observable<any> {
    return this.httpClient.get(HOST_IP_STATS + `/subtopicRanking/subtopic/${subtopicId}?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}&contestTypeString=${contestType}&timeAndGameDurationPeriod=${timeAndGameDurationPeriod}&tagId=${tagId}`);
  }

  public getSubtopicRankingsForUser(subtopicId: number, userId: number,
                                    timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                                    contestType: ContestType = ContestType.MARGIN_OF_ERROR,
                                    tagId: number = -1): Observable<SubtopicRanking> {
      return this.httpClient.get(HOST_IP_STATS
        + `/subtopicRanking/subtopic/${subtopicId}/user/${userId}`
        + `?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}&contestType=${contestType}`
        + `&tagId=${tagId}`);
}

public getSubtopicRankingsForUserWithContestOutcomes(userId: number, subtopicId: number, tagId: number, contestType: string,
                                                     timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                                                     isSeries: boolean = false)
                                                     : Observable<PaginatedSubtopicRanking> {
return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
+ `/subtopicRanking/timeframe/subtopic/${subtopicId}/user/${userId}?tagId=${tagId}&contestType=${contestType}&timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}&isSeries=${isSeries}`);
}



public getSubtopicRankingsTimeframesForTimeframeType(subtopicId: number, tagId: number, contestType: string,
                                                     timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType)
: Observable<string[]> {
return this.httpClient.get<string[]>(HOST_IP_STATS
+ `/subtopicRanking/timeframe/subtopic/${subtopicId}/?tagId=${tagId}&contestType=${contestType}&timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`);
}


public getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframe(userId: number,
                                                                         subtopicId: number, tagId: number, contestType: string,
                                                                         timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                                                                         timeAndGameDurationPeriod: string)
  : Observable<PaginatedSubtopicRanking> {
return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
+ `/subtopicRanking/timeframe/subtopic/${subtopicId}/user/${userId}/timeframe/${timeAndGameDurationPeriod}?tagId=${tagId}&contestType=${contestType}&timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`);
}

public getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframePercentileRank(userId: number,
                                                                                       subtopicId: number, contestType: string,
                                                                                       // tslint:disable-next-line:max-line-length
                                                                                       timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType)
: Observable<PaginatedSubtopicRanking> {
return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
+ `/subtopicRanking/timeframe/subtopic/${subtopicId}/user/${userId}/timeframe/percentile-rank?contestType=${contestType}&timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`);
}

public getSubtopicRankingSpecificTimeframeAndTimeFrameTypePercentileRank(userId: number,
                                                                         subtopicId: number, contestType: string,
                                                                         // tslint:disable-next-line:max-line-length
                                                                         timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                                                                         timeframe: string)
: Observable<PaginatedSubtopicRanking> {
return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
+ `/subtopicRanking/timeframe/subtopic/${subtopicId}/user/${userId}/timeframe/${timeframe}/percentile-rank?contestType=${contestType}&timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`);
}



public getSubtopicRankingsForTag(tagId: number,
                                 subtopicId: number,
                                 contestType: string,
                                 timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                                 timeAndGameDurationPeriod: string = 'NONE' ): Observable<PaginatedSubtopicRanking> {
      return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
      + `/subtopicRanking/subtopic/${subtopicId}/tag/${tagId}?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}&contestType=${contestType}&timeAndGameDurationPeriod=${timeAndGameDurationPeriod}`);
}


public getSubtopicRankingsForTagForContest(tagId: number,
                                           subtopicId: number,
                                           contestType: string,
                                           timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
                                           contestId: number,
                                           timeAndGameDurationPeriod: string = 'NONE',
                                           ): Observable<PaginatedSubtopicRanking> {
return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
+ `/subtopicRanking/subtopic/${subtopicId}/tag/${tagId}/contest/${contestId}?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}&contestType=${contestType}&timeAndGameDurationPeriod=${timeAndGameDurationPeriod}`);
}

public getAllSubtopicRankingsForUser(userId: number,
                                     timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType
= TimeAndGameDurationPeriodType.YEAR): Observable<any> {
return this.httpClient.get(HOST_IP_STATS
+ `/subtopicRanking/user/${userId}?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`);
}
public getAllTagsForSubtopic(subtopicId: number,
                             forSubtopicRankings: boolean = false,
                             forMedia: boolean = false,
                             forContests: boolean = false,
                             forNonEnteredUpcomingContests: boolean = false,
                             userId: number = -1,
                             forWriting: boolean = false,
                             // tslint:disable-next-line:no-shadowed-variable
                             globalContestState: GlobalContestState = GlobalContestState.NONE): Observable<any> {
                              let url = '';
                              if (forSubtopicRankings){
                                url = HOST_IP_STATS;
                              }
                              else{
                                url = HOST_IP_INFO_MARKETS;
                              }
                              return this.httpClient.get(url
                              // tslint:disable-next-line:max-line-length
                              + `/tag/subtopic/${subtopicId}?forSubtopicRankings=${forSubtopicRankings}`
                              + `&forMedia=${forMedia}&userId=${userId}&forContests=${forContests}`
                              + `&forNonEnteredUpcomingContests=${forNonEnteredUpcomingContests}`
                              + `&forWriting=${forWriting}`
                              + `&globalContestState=${globalContestState}`);
}

getAllTagsForSubtopicWithNameMappings(subtopicId: number): Observable<any>{
  return this.httpClient.get(HOST_IP_INFO_MARKETS + `/tag/subtopic/${subtopicId}/name-mappings`);
}

public getAllGameNamesForSubtopicUpcomingContests(subtopicId: number): Observable<any> {
return this.httpClient.get(HOST_IP_INFO_MARKETS
// tslint:disable-next-line:max-line-length
+ `/atom/allAtomicGamesUpcoming/${subtopicId}`);
}

public getSubtopicRankingsTimeframesForTimeframeTypeFiltered(
  subtopicId: number, tagId: number, contestType: string,
  timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
  minimumArticles: number = -1,
  minimumContests: number = -1,
  upperRankLimit: number = 1000)
: Observable<string[]> {
return this.httpClient.get<string[]>(HOST_IP_STATS
+ `/subtopicRanking/timeframe/subtopic/${subtopicId}/?tagId=${tagId}`
+ `&contestType=${contestType}&timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}`
+ `&minimumArticles=${minimumArticles}`
+ `&minimumContests=${minimumContests}&isFiltered=true&upperRankLimit=${upperRankLimit}`);
}


public getSubtopicRankingsForTagFiltered(
  tagId: number,
  subtopicId: number,
  contestType: string,
  timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType,
  timeAndGameDurationPeriod: string = 'NONE',
  minimumArticles: number = -1,
  minimumContests: number = -1,
  upperRankLimit: number = 1000): Observable<PaginatedSubtopicRanking> {
return this.httpClient.get<PaginatedSubtopicRanking>(HOST_IP_STATS
+ `/subtopicRanking/subtopic/${subtopicId}/tag/${tagId}`
+ `?timeAndGameDurationPeriodType=${timeAndGameDurationPeriodType}&contestType=${contestType}`
+ `&timeAndGameDurationPeriod=${timeAndGameDurationPeriod}&minimumArticles=${minimumArticles}`
+ `&minimumContests=${minimumContests}&isFiltered=true&upperRankLimit=${upperRankLimit}`);
}


}


export enum TimeAndGameDurationPeriodType {
  // ALL_GAMES_ALL_TIME = 'ALL_GAMES_ALL_TIME', // will just implement this to start
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  // WEEK = 'WEEK',
  // QUARTER = 'QUARTER'

}

