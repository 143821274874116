<nav
  class="navbar navbar-expand-sm navbar-light bg-light navbar-inverse navbar-dark bg-dark"
>
  <a class="navbar-brand font-weight-light" routerLink="/login">Sigsouk</a>
  <button
    (click)="toggleShowDropDown()"
    appDropdown
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#aTarget"
    aria-controls="aTarget"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [class]="currentText" appDropdown id="aTarget">
    <ul class="navbar-nav font-weight-light">
      <li class="nav-item font-weight-light">
        <a
          class="nav-item nav-link font-weight-light"
          routerLinkActive="active"
          routerLink="/about-us"
          style="cursor: pointer"
          >About Us</a
        >
      </li>
      <li class="nav-item">
        <!-- <ng-template [ngIf]="meService.isAuthenticated()"> -->
        <ng-template [ngIf]="isLoggedIn()">
          <a
            class="nav-item nav-link"
            routerLinkActive="active"
            routerLink="/profile"
            style="cursor: pointer"
            >Profile</a
          >
        </ng-template>
      </li>
      <li class="nav-item">
        <ng-template [ngIf]="true"
          ><a
            class="nav-item nav-link"
            routerLinkActive="active"
            routerLink="/contest"
            style="cursor: pointer"
            >Contests</a
          >
        </ng-template>
      </li>
      <li class="nav-item">
        <ng-template [ngIf]="true"
          ><a
            class="nav-item nav-link"
            routerLinkActive="active"
            routerLink="/media"
            style="cursor: pointer"
            >Media</a
          >
        </ng-template>
      </li>
      <li class="nav-item">
        <ng-template [ngIf]="true"
          ><a
            class="nav-item nav-link"
            routerLinkActive="active"
            routerLink="/guru"
            style="cursor: pointer"
            >Gurus</a
          >
        </ng-template>
      </li>
      <!-- <li class="nav-item">
        <ng-template [ngIf]="userService.isAuthenticated()"
          ><a
            class="nav-item nav-link"
            routerLinkActive="active"
            routerLink="/profile"
            >Feed</a
          >
        </ng-template>
      </li> -->

      <li class="nav-item">
        <!-- <ng-template [ngIf]="meService.isAuthenticated()"> -->
        <ng-template [ngIf]="isLoggedIn()">
          <a
            class="nav-item nav-link"
            routerLinkActive="active"
            (click)="onLogout()"
            style="cursor: pointer"
            >logout</a
          ></ng-template
        >
      </li>
    </ul>
  </div>
</nav>
<div *ngIf="loaderService.isLoading | async" class="loader-container">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <!-- <mat-spinner style="top: 50%; left: 50%"></mat-spinner> -->
</div>
