import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { SafeHtml } from '@angular/platform-browser';
import { Blog, PaginatedBlog} from '../media.component';
import { Tag } from 'src/app/guru/guru.component';
import { user } from '../../GlobalVars/routingGlobal';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  paginatedBlog: PaginatedBlog;
  currentBlog: Blog;
  blogSafeHtml: SafeHtml;
  blogSafeHtmlTitle: SafeHtml;
  constructor(private httpClient: HttpClient) { }

  // getBlogsForMedia(page: number = 0): Observable<any>{
  //   return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/topic/2?page=${page}&size=10`);
  // }

  getBlog(blogId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/${blogId}`);
  }

  // getBlogsForSubtopicWithRankings(subtopicId: number, userId: number = -1, page: number = 0): Observable<any>{
  //   if (userId === -1){
  //     return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/subtopic/${subtopicId}?page=${page}&size=10`);
  //   }
  //   else{
  //     return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/subtopic/${subtopicId}?page=${page}&size=10&userId=${userId}`);
  //   }
  // }
  // getBlogsForSubtopicsWithTimeFrameAndFilterType(subtopicId: number, page: number = 0, timeFrame: string =
  // 'weekly', rankingType: string = 'totalWins', userId: number = -1): Observable<any>{
  //   if (userId === -1){
  //     return this.httpClient.get(HOST_IP_INFO_MARKETS +
  // `/blog/subtopic/${subtopicId}?page=${page}&size=10&rankingType=${rankingType}&timeFrame=${timeFrame}`);
  //   }
  //   else{
  //     return this.httpClient.get(HOST_IP_INFO_MARKETS +
  // `/blog/subtopic/${subtopicId}?page=${page}&size=10&rankingType=${rankingType}&timeFrame=${timeFrame}&userId=${userId}`);
  //   }

  // }

  // // tslint:disable-next-line:max-line-length
  // getBlogsForSubtopicsWithFilters(subtopicId: number,
  // sortBlogs: {}, page: number = 0, timeFrame: string = 'weekly', rankingType: string = 'totalWins'): Observable<any>{
  //   return this.httpClient.post(HOST_IP_INFO_MARKETS +
  // `/blog/subtopic/${subtopicId}?page=${page}&size=10&rankingType=${rankingType}&timeFrame=${timeFrame}`, sortBlogs);
  // }

  getBlogsForSubtopicsWithFiltersNEW(subtopicId: number, startDate: Date,
                                     endDate: Date, sortBlogs: {},  userId: number = -1, page: number = 0): Observable<any>{
    // const unique = crypto.randomUUID();
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/subtopic/${subtopicId}/filtered?page=${page}&size=10&${sortBlogs}=true&startTime=${startDate.getTime()}&endTime=${endDate.getTime()}&userId=${userId}`);
  }

  getBlogsForSubtopicsWithFiltersNEWWithTag(subtopicId: number, startDate: Date,
                                            endDate: Date, sortBlogs: {}, tag: Tag, userId: number = -1, page: number = 0): Observable<any>{
// const unique = crypto.randomUUID();
return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/subtopic/${subtopicId}/filtered?page=${page}&size=10&${sortBlogs}=true&startTime=${startDate.getTime()}&endTime=${endDate.getTime()}&tagId=${tag.id}&userId=${userId}`);
}
}
