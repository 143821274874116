import { Component, OnInit } from '@angular/core';
import { UserSocialLinksService } from './user-social-links.service';
import { PaginatedSocialLink, SocialLink } from '../../Me/your-social-links/your-social-links.component';
import { UserService } from '../user.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-user-social-links',
  templateUrl: './user-social-links.component.html',
  styleUrls: ['./user-social-links.component.css']
})
export class UserSocialLinksComponent implements OnInit {

  _nameOfPlatform = '';
  _link = '';

  paginatedSocialLink: PaginatedSocialLink;

  constructor(private userSocialLinksService: UserSocialLinksService, private userService: UserService,
              private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.getSocialLinks();
  }

  getSocialLinks(): void{

    this.userSocialLinksService.getUserSocialLinks(this.userService.user.id).subscribe((result: PaginatedSocialLink) => {
      this.paginatedSocialLink = result;
    });
    // this.userSocialLinks.push({nameOfPlatform, link});
}


  get nameOfPlatform(): string{
    return this._nameOfPlatform;
  }

  get link(): string{
    return this._link;
  }

  get socialLinks(): SocialLink[]{
    if (this.paginatedSocialLink && this.paginatedSocialLink._embedded && this.paginatedSocialLink._embedded.socialLinkDTOList){
      return this.paginatedSocialLink._embedded.socialLinkDTOList;
    }

  }
  copyLink(url: string): void{
    this.clipboard.copy(url);
  }
}
