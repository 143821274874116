import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/media/blog/blog.service';
import { Blog } from 'src/app/media/media.component';
import { SubTopicsEntityOrOwnedSubtopicsEntity } from 'src/app/profile/User/user.service';
import * as routingGlobals from '../../GlobalVars/routingGlobal';

@Component({
  selector: 'app-article-holder',
  templateUrl: './article-holder.component.html',
  styleUrls: ['./article-holder.component.css']
})
export class ArticleHolderComponent implements OnInit {

  @Input()
  blogs: Blog[] = [];

  @Input()
  subtopic: SubTopicsEntityOrOwnedSubtopicsEntity;

  @Input()
  title = '';

  currentBlog: Blog;
  data: string;

  constructor(private blogService: BlogService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  selectCurrentBlog(blog: Blog): void{
    this.currentBlog = null;
    this.currentBlog = blog;
    this.blogService.currentBlog = blog;
    this.data = blog.contentString;
    this.data = this.toHTML(this.data);
    this.viewBlog(this.data);
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
}

  viewBlog(blogHtml: SafeHtml): void{
    this.blogService.blogSafeHtml = blogHtml;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    this.router.navigate(['../' + routingGlobals.media + '/' + routingGlobals.dynamicBlog, this.currentBlog.id,
    'author', this.currentBlog._owner_id], {relativeTo: this.route});
    // this.router.navigate(['../' + routingGlobals.media + '/' + routingGlobals.dynamicBlog], {relativeTo: this.route});
  }

}
