import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm} from '@angular/forms';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import * as localStore from '../../../GlobalVars/localStorageVars';
import { AuthJavaService } from 'src/app/shared/auth-java.service';
import { HttpClient } from '@angular/common/http';
import { MeService } from 'src/app/profile/Me/me.service';
import { MyUserDetails } from '../../../profile/Me/me.service';
import { StorageService } from '../../../shared/storage.service';




@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:max-line-length
  constructor(private authJavaService: AuthJavaService, private router: Router,
              private httpClient: HttpClient, private meService: MeService,
              private storageService: StorageService, private activatedRoute: ActivatedRoute) {}
  id: string = undefined;
  _error: string;
  phoneRecaptchaVerifier;
  // phoneNumber;
  // lastname;
  // firstname;
  _acceptedPolicy = false;
  inviterIdToken = '';

  @ViewChild('f') currentFormState: NgForm;

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    // const params = this.collectRouteParams([this.activatedRoute.root.snapshot]);
    if (!!this.activatedRoute.snapshot.queryParamMap.get('inviter')){
      const inviterIdToken = this.activatedRoute.snapshot.queryParamMap.get('inviter');
      if (!!inviterIdToken){
        this.inviterIdToken = inviterIdToken;
      }
    }

  }

  goToProfilePage(form: NgForm): void {
    this._error = '';
    const email_ = form.value.email_;
    const username: string = form.value.username;
    if (!this.isAlphanumeric(username)){
      this._error = 'Username must be alphanumeric';
      return;
    }
    const password = form.value.password;
    // const firstname = form.value.firstname;
    // const lastname = form.value.lastname;
    // const stringPhoneNumber = form.value.phoneNumber;
    // let phoneNumber = stringPhoneNumber.match(/\d/g);
    // phoneNumber = phoneNumber.join('');
    // phoneNumber = '+1' + phoneNumber;
    const data = { username: email_, password: password };
    // tslint:disable-next-line:max-line-length
    this.authJavaService.signupUser(username, email_, password, this.inviterIdToken).subscribe((myUserDetails: MyUserDetails) => {
      this.storageService.saveUser(myUserDetails);
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
      this.meService.accountInfo = {
        displayName: username,
        email: email_
      };
      if (this.meService.jwt == null && !!!this.meService.user.isVerifiedByOTP){
        this.router.navigate([routingGlobals.absVerifyAccount]);
      }
      else{
        this.router.navigate([routingGlobals.absContest]);
      }
      // this.router.navigate([routingGlobals.absSignupPaymentInfo]);
    },
    error => {
      this._error = error.error.message;
      console.log('error on sign up');
    });
  }

  acceptPolicy(): void{
    this._acceptedPolicy = !this._acceptedPolicy;
  }

  acceptedPolicy(): boolean{
    return this._acceptedPolicy;
  }

  // getNumber(phoneNumber: string): void {
  //   this.phoneNumber = phoneNumber;
  // }

  get error(): string {
    return this._error;
  }

  goToSignUpPaymentInfo(): any {
    this.router.navigate([routingGlobals.absSignupPaymentInfo]);
  }

  linkToLogin(): void{
    this.router.navigate([routingGlobals.absLogin]);
  }


  collectRouteParams(stack: ActivatedRouteSnapshot[]): any {
    let params = {};
    // const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
    while (stack.length > 0) {
      // tslint:disable-next-line:no-non-null-assertion
      const route = stack.pop()!;
      params = {...params, ...route.params};
      stack.push(...route.children);
    }
    return params;
  }

  isAlphanumeric(str: string): boolean {
    return /^[a-zA-Z0-9]+$/.test(str);
  }
}
