/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from '../../shared/token-interceptor.service';
import { ResponseInterceptorService } from '../response-interceptor.service';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorService, multi: true },
];
