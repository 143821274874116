<div *ngIf="!isMobile">
  <div class="text-center">
    <div class="dropdown">
      <button
        class="btn btn-sm btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ chosenTimeFrame | allCapsUnderscore | lowercase | titlecase }}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          *ngFor="let subtopicRankingTimeframeString of rankingsList"
          class="dropdown-item"
          (click)="selectSpecificTimeFrame(subtopicRankingTimeframeString)"
          >{{
            subtopicRankingTimeframeString
              | allCapsUnderscore
              | lowercase
              | titlecase
          }}</a
        >
      </div>
    </div>
    <p style="font-size: 11px" class="font-weight-bold my-0">Guru's for:</p>
    <p
      *ngIf="!!subtopicName"
      style="font-size: 10px"
      class="font-weight-bold text-center my-0"
    >
      [ {{ subtopicName | lowercase | titlecase }}]
    </p>

    <div
      *ngIf="!!subtopicRankingsError"
      style="font-size: 10px"
      class="font-weight-bold text-center mb-1 font-italic"
    >
      {{ subtopicRankingsError | allCapsUnderscore | lowercase | titlecase }}
    </div>
    <div *ngIf="rankings">
      <p
        *ngIf="!!contestType"
        style="font-size: 10px"
        class="font-weight-bold font-italic text-center my-1"
      >
        [
        {{ contestType | allCapsUnderscore | lowercase | titlecase }}]
      </p>
      <p
        style="font-size: 10px"
        class="font-weight-bold font-italic text-center my-1"
      >
        [
        {{
          rankings[0].timeAndGameDurationPeriod
            | allCapsUnderscore
            | lowercase
            | titlecase
        }}]
      </p>
      <p
        *ngIf="!!contestId"
        style="font-size: 10px"
        class="font-weight-bold font-italic text-center my-1"
      >
        Rankings for User's entered in Contest: {{ contestId }}
      </p>
      <p
        *ngIf="!!tagName"
        style="font-size: 10px"
        class="font-weight-bold font-italic text-center my-1"
      >
        [{{ tagName }}]
      </p>
      <table
        class="table table-striped table-dark table-xs table-hover table-condensed"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Username</th>

            <th scope="col">Elo Value</th>
            <th scope="col">Percentile</th>
            <th scope="col">Date of Rank</th>

            <th scope="col">Total H2H W/L/D</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let subtopicRanking of rankings"
            style="cursor: pointer"
            (click)="
              viewUserProfile(
                subtopicRanking.owner_id,
                subtopicRanking.username
              )
            "
          >
            <td>{{ subtopicRanking.ranking }}</td>
            <td>{{ subtopicRanking.username }}</td>
            <td>{{ subtopicRanking.eloValue | number : "1.2-4" }}</td>
            <td>{{ subtopicRanking.percentileRank | number : "1.2-4" }}</td>
            <td>{{ subtopicRanking.activeStartTime }}</td>

            <td>
              {{ subtopicRanking.totalH2HWins }}/{{
                subtopicRanking.totalH2HLosses
              }}/{{ subtopicRanking.totalH2HDraws }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="isMobile">
  <div class="text-center">
    <div class="dropdown">
      <button
        class="btn btn-sm btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ chosenTimeFrame }}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          *ngFor="let subtopicRankingTimeframeString of rankingsList"
          class="dropdown-item"
          (click)="selectSpecificTimeFrame(subtopicRankingTimeframeString)"
          >{{ subtopicRankingTimeframeString }}</a
        >
      </div>
    </div>
    <p style="font-size: 11px" class="font-weight-bold my-0">Guru's for:</p>
    <p
      *ngIf="!!subtopicName"
      style="font-size: 10px"
      class="font-weight-bold text-center my-0"
    >
      [ {{ subtopicName | lowercase | titlecase }}]
    </p>

    <div
      *ngIf="!!subtopicRankingsError"
      style="font-size: 10px"
      class="font-weight-bold text-center mb-1 font-italic"
    >
      {{ subtopicRankingsError | allCapsUnderscore | lowercase | titlecase }}
    </div>

    <div *ngIf="rankings">
      <p style="font-size: 10px" class="font-weight-bold text-center my-2">
        {{ rankings[0].timeAndGameDurationPeriod }}
      </p>
      <table
        class="table table-striped table-dark table-xs table-hover table-condensed"
        style="width: 100%; word-break: break-all"
      >
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Username</th>
            <th scope="col">Elo Value</th>
            <th scope="col">Percentile</th>
            <th scope="col">Date of Rank</th>
            <th scope="col">Total H2H W/L/D</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let subtopicRanking of rankings"
            style="cursor: pointer"
            (click)="
              viewUserProfile(
                subtopicRanking.owner_id,
                subtopicRanking.username
              )
            "
          >
            <td>{{ subtopicRanking.ranking }}</td>

            <td>{{ subtopicRanking.username }}</td>
            <td>{{ subtopicRanking.eloValue | number : "1.2-4" }}</td>
            <td>{{ subtopicRanking.percentileRank | number : "1.2-4" }}</td>
            <td>{{ subtopicRanking.activeStartTime }}</td>
            <td>
              {{ subtopicRanking.totalH2HWins }}-{{
                subtopicRanking.totalH2HLosses
              }}-{{ subtopicRanking.totalH2HDraws }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
