<div *ngIf="!isMobile">
  <!-- <div class="col-md-12"> -->
  <div class="container font-weight-light text-center">
    <p class="font-weight-light">
      Read what our experts have to say - Filter by any of the following
      categories And timeframes below. Use this platform to grow your following.
      Individual users, companies, university classes, or the new financial
      modeling bot you created (or anything else) are free to sign up and prove
      that youre the best. If you want to learn more about our site
      <a routerLink="/about-us"><b>Click Here</b></a>
    </p>
    <!-- <p class="font-weight-light">
        If youd like to start to start winning virtual currency, increasing your
        elo, test your knowledge in the given domains, and start to EARN REAL
        MONEY, click the 'contests' button above or click here
        <a routerLink="/about-us"><b>Click Here</b></a>
      </p> -->
    <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
    <div class="row">
      <div
        *ngFor="let topic of topics; let i = index"
        (click)="selectTopic(topic)"
        class="col-4"
        [ngClass]="{
          'font-weight-bold text-success font-italic':
            isSelectedTopic(topic) === true
        }"
        style="font-size: 12px; cursor: pointer"
      >
        {{ topic.name }}
      </div>
    </div>
    <p class="text-center font-weight-bold"><u>Select a Subtopic</u></p>
    <div class="row">
      <div
        *ngFor="let subtopic of currentTopic.subtopics; let i = index"
        (click)="selectSubTopic(subtopic)"
        class="col-4"
        [ngClass]="{
          'font-weight-bold text-success font-italic':
            isSelected(subtopic) === true
        }"
        style="font-size: 12px; cursor: pointer"
      >
        {{ subtopic.name }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 mx-auto text-center">
        <div class="dropdown h-100" class="statusDropdownContainer">
          <a
            class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-truncate"
              >Tags: {{ selectedValue | lowercase | titlecase }}</span
            >
          </a>
          <div
            class="dropdown-menu w-100 pt-0"
            aria-labelledby="dropdownMenuButton"
          >
            <input
              type="text"
              class="w-100 p-2 searchInput"
              [ngModel]="searchValue"
              (ngModelChange)="filterDropdown($event)"
            />
            <a
              *ngFor="let option of filteredTagList; let i = index"
              class="dropdown-item pointer text-contain"
              [ngClass]="{ alternateBackground: i % 2 == 0 }"
              (click)="selectValue(option)"
            >
              {{ option.name | lowercase | titlecase }}
            </a>
            <div
              *ngIf="!filteredList || filteredList.length <= 0"
              class="text-center text-muted mt-1"
            >
              No Tag found
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="btn-toolbar text-center my-3 d-flex justify-content-center"
    role="toolbar"
    aria-label="Toolbar with button groups"
  >
    <div class="btn-group mr-2" role="group" aria-label="First group">
      <button
        class="btn-sm btn-secondary font-weight-light"
        (click)="addSortAttribute('byLoggedInUserViews')"
        [ngClass]="{
          'font-weight-bold btn-dark': sortBlogs === 'byLoggedInUserViews'
        }"
      >
        Most Subscriber Views
      </button>

      <button
        class="btn-sm btn-secondary font-weight-light"
        (click)="addSortAttribute('byViewCount')"
        [ngClass]="{
          'font-weight-bold btn-dark': sortBlogs === 'byViewCount'
        }"
      >
        Most Total Views
      </button>

      <button
        class="btn-sm btn-secondary font-weight-light"
        (click)="addSortAttribute('byUpvotes')"
        [ngClass]="{
          'font-weight-bold btn-dark': sortBlogs === 'byUpvotes'
        }"
      >
        Most Upvotes
      </button>

      <button
        class="btn-sm btn-secondary font-weight-light"
        (click)="addSortAttribute('byDownVotes')"
        [ngClass]="{
          'font-weight-bold btn-dark': sortBlogs === 'byDownVotes'
        }"
      >
        Most downvotes
      </button>
    </div>
  </div>

  <div
    class="btn-toolbar text-center my-3 d-flex justify-content-center"
    role="toolbar"
    aria-label="Toolbar with button groups"
  >
    <!-- <div class="mr-2" role="group" aria-label="First group">
          <button
            class="btn-sm btn-secondary font-weight-light"
            (click)="addSortAttribute('daily')"
          >
            Date Range here
          </button>
        </div> -->
    <div class="ml-1 mt-4 font-weight-light">
      <div class="container ml-1 mt-4 font-weight-light">
        <div class="row justify-content-center">
          <div class="col-auto">
            <div>
              <p class="text-left">Start Date</p>
              <form class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      [readonly]="true"
                      class="form-control"
                      [placeholder]="startDate | date : 'M/d/yy'"
                      name="dp"
                      [(ngModel)]="startModel"
                      ngbDatepicker
                      #dStart="ngbDatepicker"
                    />
                    <div class="row">
                      <div class="input-group-append">
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 calendar"
                          (click)="dStart.toggle(); setStartModelToActive()"
                          type="button"
                        >
                          Pick Start Date
                        </button>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto">
              <p class="text-left">End Date</p>
              <form class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      [readonly]="true"
                      class="form-control"
                      [placeholder]="endDate | date : 'M/d/yy'"
                      name="dp"
                      [(ngModel)]="endModel"
                      ngbDatepicker
                      #d="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn-sm btn-secondary m-2 font-weight-light calendar"
                        (click)="d.toggle(); setEndModelToActive()"
                        type="button"
                      >
                        Pick End Date
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="btn-toolbar text-center my-3 d-flex justify-content-center"
    role="toolbar"
    aria-label="Toolbar with button groups"
  >
    <button
      class="btn-sm btn-secondary font-weight-light"
      (click)="clearSortAttribute()"
    >
      Clear Filters
    </button>
    <button
      class="btn-sm btn-success font-weight-light"
      (click)="filterArticles()"
    >
      Search
    </button>
  </div>

  <div class="row">
    <div *ngIf="true" class="col-md-12">
      <p class="container text-center font-weight-bold"></p>
      <div>
        <div *ngFor="let blog of blogs">
          <div>
            <div class="font-weight-light d-flex justify-content-center">
              <div class="card" style="width: 100%">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="text-center col-12 d-flex justify-content-center font-weight-bold"
                      style="font-size: 16px"
                    >
                      <b
                        class="ql-editor text-center"
                        [innerHTML]="blog.title"
                      ></b>
                    </div>
                  </div>
                  <div class="row">
                    <span
                      class="col-12 d-flex justify-content-center my-1"
                      style="font-size: 10px; cursor: pointer"
                      (click)="viewUserProfile(blog._owner_id)"
                      ><b>{{ blog.author_fullname }}</b
                      >-/s/ {{ blog.subtopicName }} - {{ blog.date | date }}
                      <span
                        *ngIf="blog.subscriptionOnly"
                        class="material-icons col-1 px-o"
                        style="
                          font-size: 12px;
                          padding-right: 2px;
                          padding-left: 2px;
                          padding-top: 1px;
                        "
                        >verified_user</span
                      ></span
                    >
                  </div>
                  <div class="row">
                    <span
                      class="col-12 d-flex justify-content-center my-1"
                      style="font-size: 10px; cursor: pointer"
                      (click)="viewUserProfile(blog._owner_id)"
                      ><b *ngFor="let tagReference of blog.tagReferenceList"
                        >[{{ tagReference.name | lowercase | titlecase }}]</b
                      ></span
                    >
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-2"></div>
                    <div
                      class="col-10 d-flex justify-content-start align-items-start"
                    >
                      <span
                        class="material-icons col-1 px-o"
                        style="
                          font-size: 12px;
                          padding-right: 0;
                          padding-left: 0;
                        "
                        >thumb_up_rounded
                      </span>

                      <span
                        class="col-2"
                        style="
                          font-size: 10px;
                          padding-right: 0;
                          padding-bottom: 2px;
                          padding-left: 0;
                        "
                        >{{ blog.upVotes }}</span
                      >

                      <!-- <span class="material-icons">favorite_border_rounded</span> -->

                      <span
                        class="material-icons col-1 px-o"
                        style="
                          font-size: 12px;
                          padding-right: 2px;
                          padding-left: 2px;
                        "
                      >
                        remove_red_eye_outlined</span
                      ><span
                        class="col-2"
                        style="
                          padding-right: 2px;
                          padding-left: 2px;
                          font-size: 10px;
                          padding-bottom: 2px;
                        "
                        >{{ blog.pageVisits }}</span
                      >

                      <span
                        class="material-icons col-1 px-o"
                        style="
                          font-size: 12px;
                          padding-right: 2px;
                          padding-left: 2px;
                          padding-top: 1px;
                        "
                        >chat_bubble_outline_rounded</span
                      ><span
                        class="col-2"
                        style="
                          padding-right: 0;
                          padding-left: 0px;

                          padding-bottom: 2px;
                          font-size: 10px;
                        "
                        >{{ blog.commentCount }}</span
                      >

                      <span
                        class="material-icons col-1 px-o"
                        style="
                          font-size: 12px;
                          padding-right: 2px;
                          padding-left: 2px;
                          padding-top: 1px;
                        "
                        >pageview</span
                      ><span
                        class="col-2"
                        style="
                          padding-right: 0;
                          padding-left: 0px;

                          padding-bottom: 2px;
                          font-size: 10px;
                        "
                        >{{ blog.loggedInUserViews }}</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center mt-2">
                      <a
                        class="btn-sm btn-secondary mt-7 font-weight-light"
                        style="font-size: 9px; cursor: pointer"
                        (click)="selectCurrentBlog(blog)"
                        >View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nav
    class="btn-group ml-4"
    role="group"
    aria-label="Basic example"
    *ngIf="!!pageData"
  >
    <button
      type="button"
      class="btn-sm btn-secondary font-weight-light"
      (click)="onPaginate(PaginationCommand.FIRST)"
    >
      First
    </button>
    <button
      type="button"
      class="btn-sm btn-secondary font-weight-light"
      (click)="onPaginate(PaginationCommand.PREVIOUS)"
    >
      Previous
    </button>
    <button type="button" class="btn-sm btn-secondary font-weight-light">
      {{ pageData.number + 1 }}
    </button>
    <button
      type="button"
      class="btn-sm btn-secondary font-weight-light"
      (click)="onPaginate(PaginationCommand.NEXT)"
    >
      Next
    </button>
    <button
      type="button"
      class="btn-sm btn-secondary font-weight-light"
      (click)="onPaginate(PaginationCommand.LAST)"
    >
      Last &nbsp;[ {{ pageData.totalPages }}]
    </button>
  </nav>
  <!-- </div> -->
</div>

<div *ngIf="isMobile" class="container-fluid">
  <div>
    <div class="container-fluid font-weight-light text-center">
      <p class="font-weight-light">
        Read what our experts have to say - Filter by any of the following
        categories And timeframes below. Use this platform to grow your
        following. Individual users, companies, university classes, or the new
        financial modeling bot you created (or anything else) are free to sign
        up and prove that youre the best. If you want to learn more about our
        site
        <a routerLink="/about-us"><b>Click Here</b></a>
      </p>
      <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
      <div class="row">
        <div
          *ngFor="let topic of topics; let i = index"
          (click)="selectTopic(topic)"
          class="col-4"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              isSelectedTopic(topic) === true
          }"
          style="font-size: 12px; cursor: pointer"
        >
          {{ topic.name }}
        </div>
      </div>
      <p class="text-center font-weight-bold"><u>Select a Subtopic</u></p>
      <div class="row">
        <div
          *ngFor="let subtopic of currentTopic.subtopics; let i = index"
          (click)="selectSubTopic(subtopic)"
          class="col-4"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              isSelected(subtopic) === true
          }"
          style="font-size: 12px; cursor: pointer"
        >
          {{ subtopic.name }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 mx-auto text-center">
          <div class="dropdown h-100" class="statusDropdownContainer">
            <a
              class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="text-truncate"
                >Tags: {{ selectedValue | lowercase | titlecase }}</span
              >
            </a>
            <div
              class="dropdown-menu w-100 pt-0"
              aria-labelledby="dropdownMenuButton"
            >
              <input
                type="text"
                class="w-100 p-2 searchInput"
                [ngModel]="searchValue"
                (ngModelChange)="filterDropdown($event)"
              />
              <a
                *ngFor="let option of filteredTagList; let i = index"
                class="dropdown-item pointer text-contain"
                [ngClass]="{ alternateBackground: i % 2 == 0 }"
                (click)="selectValue(option)"
              >
                {{ option.name | lowercase | titlecase }}
              </a>
              <div
                *ngIf="!filteredList || filteredList.length <= 0"
                class="text-center text-muted mt-1"
              >
                No Tag found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="btn-toolbar text-center my-3 d-flex justify-content-center"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button
          class="btn-sm btn-secondary font-weight-light"
          (click)="addSortAttribute('byLoggedInUserViews')"
          [ngClass]="{
            'font-weight-bold btn-dark': sortBlogs === 'byLoggedInUserViews'
          }"
        >
          Most Subscriber Views
        </button>

        <button
          class="btn-sm btn-secondary font-weight-light"
          (click)="addSortAttribute('byViewCount')"
          [ngClass]="{
            'font-weight-bold btn-dark': sortBlogs === 'byViewCount'
          }"
        >
          Most Total Views
        </button>

        <button
          class="btn-sm btn-secondary font-weight-light"
          (click)="addSortAttribute('byUpvotes')"
          [ngClass]="{
            'font-weight-bold btn-dark': sortBlogs === 'byUpvotes'
          }"
        >
          Most Upvotes
        </button>

        <button
          class="btn-sm btn-secondary font-weight-light"
          (click)="addSortAttribute('byDownVotes')"
          [ngClass]="{
            'font-weight-bold btn-dark': sortBlogs === 'byDownVotes'
          }"
        >
          Most downvotes
        </button>
      </div>
    </div>

    <div
      class="btn-toolbar text-center my-3 d-flex justify-content-center"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      <!-- <div class="mr-2" role="group" aria-label="First group">
          <button
            class="btn-sm btn-secondary font-weight-light"
            (click)="addSortAttribute('daily')"
          >
            Date Range here
          </button>
        </div> -->
      <div class="ml-1 mt-4 font-weight-light">
        <div class="container ml-1 mt-4 font-weight-light">
          <div class="row">
            <div class="col-md-12">
              <p class="text-left">Start Date</p>
              <form class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      [readonly]="true"
                      class="form-control"
                      [placeholder]="startDate | date : 'M/d/yy'"
                      name="dp"
                      [(ngModel)]="startModel"
                      ngbDatepicker
                      #dStart="ngbDatepicker"
                    />
                    <div class="row">
                      <div class="input-group-append">
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 calendar"
                          (click)="dStart.toggle(); setStartModelToActive()"
                          type="button"
                        >
                          Pick Start Date
                        </button>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="text-left">End Date</p>
              <form class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      [readonly]="true"
                      class="form-control"
                      [placeholder]="endDate | date : 'M/d/yy'"
                      name="dp"
                      [(ngModel)]="endModel"
                      ngbDatepicker
                      #d="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn-sm btn-secondary m-2 font-weight-light calendar"
                        (click)="d.toggle(); setEndModelToActive()"
                        type="button"
                      >
                        Pick End Date
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="btn-toolbar text-center my-3 d-flex justify-content-center"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      <button
        class="btn-sm btn-secondary font-weight-light"
        (click)="clearSortAttribute()"
      >
        Clear Filters
      </button>
      <button
        class="btn-sm btn-success font-weight-light"
        (click)="filterArticles()"
      >
        Search
      </button>
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center">
        <p class="text-center font-weight-bold"></p>
        <div>
          <div *ngFor="let blog of blogs">
            <div>
              <div class="font-weight-light d-flex justify-content-center">
                <div class="card" style="width: 100%">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="text-center col-12 d-flex justify-content-center font-weight-bold"
                        style="font-size: 16px"
                      >
                        <b
                          class="ql-editor text-center m-3"
                          [innerHTML]="blog.title"
                        ></b>
                      </div>
                    </div>
                    <div class="row">
                      <span
                        class="col-12 d-flex justify-content-center my-1"
                        style="font-size: 10px; cursor: pointer"
                        (click)="viewUserProfile(blog._owner_id)"
                        ><b>{{ blog.name }}</b
                        >-/s/ {{ blog.subtopicName }} -
                        {{ blog.date | date }}</span
                      >
                    </div>
                    <div class="row">
                      <span
                        class="col-12 d-flex justify-content-center my-1"
                        style="font-size: 10px; cursor: pointer"
                        (click)="viewUserProfile(blog._owner_id)"
                        ><b *ngFor="let tagReference of blog.tagReferenceList"
                          >[{{ tagReference.name | lowercase | titlecase }}]</b
                        ></span
                      >
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-2"></div>
                      <div
                        class="col-10 d-flex justify-content-start align-items-start"
                      >
                        <span
                          class="material-icons col-1 px-o"
                          style="
                            font-size: 12px;
                            padding-right: 0;
                            padding-left: 0;
                          "
                          >thumb_up_rounded
                        </span>

                        <span
                          class="col-2"
                          style="
                            font-size: 10px;
                            padding-right: 0;
                            padding-bottom: 2px;
                            padding-left: 0;
                          "
                          >{{ blog.upVotes }}</span
                        >

                        <!-- <span class="material-icons">favorite_border_rounded</span> -->

                        <span
                          class="material-icons col-1 px-o"
                          style="
                            font-size: 12px;
                            padding-right: 2px;
                            padding-left: 2px;
                          "
                        >
                          remove_red_eye_outlined</span
                        ><span
                          class="col-2"
                          style="
                            padding-right: 2px;
                            padding-left: 2px;
                            font-size: 10px;
                            padding-bottom: 2px;
                          "
                          >{{ blog.pageVisits }}
                        </span>

                        <span
                          class="material-icons col-1 px-o"
                          style="
                            font-size: 12px;
                            padding-right: 2px;
                            padding-left: 2px;
                            padding-top: 1px;
                          "
                          >chat_bubble_outline_rounded</span
                        ><span
                          class="col-2"
                          style="
                            padding-right: 0;
                            padding-left: 0px;

                            padding-bottom: 2px;
                            font-size: 10px;
                          "
                          >{{ blog.commentCount }}</span
                        >

                        <span
                          class="material-icons col-1 px-o"
                          style="
                            font-size: 12px;
                            padding-right: 2px;
                            padding-left: 2px;
                            padding-top: 1px;
                          "
                          >pageview</span
                        ><span
                          class="col-2"
                          style="
                            padding-right: 0;
                            padding-left: 0px;

                            padding-bottom: 2px;
                            font-size: 10px;
                          "
                          >{{ blog.loggedInUserViews }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center mt-2">
                        <a
                          class="btn-sm btn-secondary mt-7 font-weight-light"
                          style="font-size: 9px; cursor: pointer"
                          (click)="selectCurrentBlog(blog)"
                          >View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav
      class="btn-group ml-4"
      role="group"
      aria-label="Basic example"
      *ngIf="!!pageData"
    >
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.FIRST)"
      >
        First
      </button>
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.PREVIOUS)"
      >
        Previous
      </button>
      <button type="button" class="btn-sm btn-secondary font-weight-light">
        {{ pageData.number + 1 }}
      </button>
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.NEXT)"
      >
        Next
      </button>
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.LAST)"
      >
        Last &nbsp;[ {{ pageData.totalPages }}]
      </button>
    </nav>
  </div>
</div>
