import { Component,  OnInit } from '@angular/core';
import { AuthJavaService } from '../shared/auth-java.service';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import { Router, ActivatedRoute } from '@angular/router';
import {  Contest, ContestType, DurationType } from '../contest/game/game.service';
import { ContestStateService } from '../contest/contest-state.service';
import { ContestService } from '../contest/contest.service';
import { MeService } from './Me/me.service';
import { GlobalContestState, UserContestState } from '../GlobalVars/Enums/ContestEnums';
import { TopicService } from '../topic/topic.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profilePictureUrl = 'https://infomarketz-content.s3.amazonaws.com/profilePictures/5.jpg';
  isMobile: boolean;


  constructor(private topicService: TopicService, public meService: MeService,
              private router: Router, private contestService: ContestService,
              private contestStateService: ContestStateService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.viewYourPosts();
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    const contest: Contest = {
      topicReference: null,
      subtopicReference: null,
      name: '',
      games: [],
      maxUsers: 100000,
      globalContestState: GlobalContestState.NONE,
      userContestState: UserContestState.NONE,
      contestType: ContestType.UNDER_OVER,
      durationType: DurationType.ALL
    };
    this.contestStateService.viewNonEnteredUpcomingContest(contest);
    this.route.data.subscribe((response: any) => {
      this.topicService.allTopics = response.topicData;
      this.meService.user.paginatedTopics = response;
    });
  }

  async getValueWithAsync(): Promise<any> {
    const value = await this.contestService.
                  getContests({type: 'userCompetitor', tagId: -1, topicId: -1,
                  durationType: DurationType.ALL, contestType: ContestType.ALL, page: 0, atomicGameName: 'ALL'}).toPromise().
                  then(result => {
                      this.meService.user.paginatedEnteredContests = result;
                      console.log('before async result');
                    });
    console.log(`async result: ${value}`);
  }

  // tslint:disable-next-line:no-shadowed-variable
  viewEnteredUpcomingContest(contest: Contest): void{
    // this.contestStateService.viewEnteredUpcomingContest(contest);
    this.router.navigate([routingGlobals.absViewContest, contest.id]);
  }

  viewYourContests(): void{
    this.router.navigate(['./' + routingGlobals.yourContests], {relativeTo: this.route});
  }

  viewYourBlogs(): void{
    this.router.navigate(['./' + routingGlobals.yourBlogs], {relativeTo: this.route});
  }

  viewCreateBlog(): void{
    this.router.navigate(['./' + routingGlobals.createBlog], {relativeTo: this.route});
  }

  viewYourPosts(): void{
    this.router.navigate(['./' + routingGlobals.yourPosts], {relativeTo: this.route});
  }

  viewYourSocialLinks(): void{
    this.router.navigate(['./' + routingGlobals.yourSocialLinks], {relativeTo: this.route});
  }

  viewYourSubscriptions(): void{
    this.router.navigate(['./' + routingGlobals.yourSubscriptions], {relativeTo: this.route});
  }

  viewAccounts(): void{
    this.router.navigate(['./' + routingGlobals.account], {relativeTo: this.route});
  }

  viewAccountSettings(): void{
    this.router.navigate(['./' + routingGlobals.accountSettings], {relativeTo: this.route});
  }

  get username(): any{
    return this.meService.user.username;
  }

  get followers(): any{
    return null;
  }

  get following(): any{
    return null;
  }

}
