import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { Blog, PaginatedBlog } from 'src/app/media/media.component';

@Injectable({
  providedIn: 'root'
})
export class YourBlogsService {

  paginatedBlog: PaginatedBlog;
  currentBlog: Blog;
  blogSafeHtml: SafeHtml;
  blogSafeHtmlTitle: SafeHtml;
  constructor(private httpClient: HttpClient) { }

  getBlogsForMe(page: number = 0): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/me/blog?page=${page}&size=10`);
  }
}
