import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.css']
})
export class DropdownSearchComponent implements OnInit {
  private _selectedValue: any;
  nonFilteredList: any;
  filteredList: any;

  constructor() { }

  ngOnInit(): void {
  }

  filterTagDropdown(e): void {
    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this.filteredList = this.nonFilteredList.slice();
      return;
    } else {
      this.filteredList = this.nonFilteredList.filter(
        (value: string) => value.toLowerCase().indexOf(searchString) > -1
      );
    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
    console.log('this.filteredList indropdown -------> ', this.filteredList);
  }

  selectTagValue(value: any): void {
    this._selectedValue = value;
    this.selectValue(value);
  }

  selectValue(tag: any): void{
    // this.guruService.currentTag = tag;
    this._selectedValue = tag;
    // this.refreshRankings();
   }

   get selectedValue(): any{
    return this._selectedValue;
   }

}
