import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedSubtopicRanking } from 'src/app/contest/contest.component';
import { HOST_IP_STATS} from 'src/app/GlobalVars/api';
import { ContestOutcome, PaginatedContestOutcome } from '../../Me/my-contest-outcomes/my-contest-outcome.service';

@Injectable({
  providedIn: 'root'
})
export class UserContestOutcomesService {

  paginatedSubtopicRanking: PaginatedSubtopicRanking;
  paginatedSubtopicRankingAllForTimeframe: PaginatedSubtopicRanking;
  paginatedSubtopicRankingActiveForTimeframeType: PaginatedSubtopicRanking;

  paginatedContestOutcome: PaginatedContestOutcome;

  currentContestOutcome: ContestOutcome;
  constructor(private httpClient: HttpClient) { }

  getContestOutcomesForContest(userId: number, page: number = 0): Observable<PaginatedContestOutcome>{
        return this.httpClient.get<PaginatedContestOutcome>(HOST_IP_STATS + `/contest-outcome/contest/${userId}?page=${page}&size=2`);
  }


  getContestOutcomesForUser(userId: number, subtopicId: number,
                            tagId: number, contestType: string, timeframe: string, page: number = 0): Observable<PaginatedContestOutcome>{
        return this.httpClient.get<PaginatedContestOutcome>(HOST_IP_STATS + `/contest-outcome/user/${userId}/subtopic/${subtopicId}?page=${page}&size=30&tagId=${tagId}&contestType=${contestType}&timeAndGameDurationPeriod=${timeframe}`);
  }

  getContestOutcomesForUserInSingleContest(userId: number, contestId: number): Observable<PaginatedContestOutcome>{
        return this.httpClient.get<PaginatedContestOutcome>(HOST_IP_STATS + `/user/${userId}/contest-outcome/contest/contest-ranking/${contestId}`);
  }

  getContestOutcomesForUserInSingleContestWithTagIdAndTimeframeType(userId: number, contestId: number,
                                                                    tagId: number, timeframeType: string):
                                                                     Observable<PaginatedContestOutcome>{
      return this.httpClient.get<PaginatedContestOutcome>(HOST_IP_STATS + `/user/${userId}/contest-outcome/contest/contest-ranking/${contestId}/tag/${tagId}/timeframeType/${timeframeType}`);
}

}
