import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../create-financial-contest-modal-create-contest-or-another-game/create-financial-contest-modal-create-contest-or-another-game.component';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateContestService } from '../../create-contest.service';
import { Game, GameService } from '../../../game/game.service';

@Component({
  selector: 'app-create-financial-contest-modal-stock-game-type-selection',
  templateUrl: './create-financial-contest-modal-stock-game-type-selection.component.html',
  styleUrls: ['./create-financial-contest-modal-stock-game-type-selection.component.css']
})
export class CreateFinancialContestModalStockGameTypeSelectionComponent implements OnInit, Modal {

  constructor( private route: ActivatedRoute, private router: Router,
               private createContestService: CreateContestService, private gameService: GameService) { }
  nextPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalDateSelection], {relativeTo: this.route});
  }
  previousPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockFieldSelection], {relativeTo: this.route});
  }
  startPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
  }

  ngOnInit(): void {
  }

  setEvents(gameType): void{
   this.createContestService.setEvents(gameType);
  }

  selectGameType(gameType): void{
    this.createContestService.currentGameType = gameType;
    this.setEvents(gameType);
  }

  get games(): Game[]{
    return this.gameService.games;
  }

  get currentGameType(): any{
    return this.createContestService.currentGameType;
  }

  get gameTypes(): any{
    return this.createContestService.gameTypes;
  }

}
