import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { SignupComponent } from './login/SignupProcess/signup/signup.component';
import { SignupPaymentInformationComponent } from './login/SignupProcess/signup-payment-information/signup-payment-information.component';

import * as ROUTE from './GlobalVars/routingGlobal';
import { AuthGuardGuard } from './shared/auth-guard.guard';

import { AccountSettingsComponent } from './profile/Me/account-settings/account-settings.component';

import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { UsernameForPasswordComponent } from './login/username-for-password/username-for-password.component';
import { AboutUsComponent } from './profile/Me/about-us/about-us.component';
import { ProfileComponent } from './profile/profile.component';
import { ContestComponent } from './contest/contest.component';
import { ViewContestComponent } from './contest/view-contest/view-contest.component';
import { TopicComponent } from './topic/topic.component';
import { SubtopicComponent } from './topic/subtopic/subtopic.component';
import { AllTopicsResolverService } from './topic/all-topics-resolver.service';
import { ViewContestResolverService } from './contest/view-contest-resolver.service';
import { CreateFinancialContestModalStockSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-stock-selection/create-financial-contest-modal-stock-selection.component';
import { CreateFinancialContestModalDateSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-date-selection/create-financial-contest-modal-date-selection.component';
import { CreateContestModalComponent } from './contest/create-contest/create-contest-modal/create-contest-modal.component';
import { CreateFinancialContestModalCreateContestOrAnotherGameComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-create-contest-or-another-game/create-financial-contest-modal-create-contest-or-another-game.component';
import { CreateFinancialContestModalReviewAndSubmitComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-review-and-submit/create-financial-contest-modal-review-and-submit.component';
import { CreateFinancialContestModalStockGameTypeSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-stock-game-type-selection/create-financial-contest-modal-stock-game-type-selection.component';
import { CreateFinancialContestModalStockFieldSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-stock-field-selection/create-financial-contest-modal-stock-field-selection.component';
import { YourContestsComponent } from './profile/Me/your-contests/your-contests.component';
// import { YourContestsResolverService } from './profile/Me/your-contests/your-contests-resolver.service';
import { YourVirtualAccountComponent } from './profile/Me/your-virtual-account/your-virtual-account.component';
import { MediaComponent } from './media/media.component';
import { YourSubscriptionsResolver } from './profile/Me/subscriptions/your-subscriptions.resolver.service';
import { ViewBlogComponent } from './media/blog/view-blog/view-blog.component';
import { YourBlogsComponent } from './profile/Me/your-blogs/your-blogs.component';
import { YourBlogResolverService } from './profile/Me/your-blogs/your-blog-resolver.service';
import { UserProfileComponent } from './profile/User/user-profile/user-profile.component';
import { UserContestsComponent } from './profile/User/user-contests/user-contests.component';
import { UserBlogsComponent } from './profile/User/user-blogs/user-blogs.component';
import { UserVirtualAccountComponent } from './profile/User/user-virtual-account/user-virtual-account.component';
import { UserSubscriptionsResolverService } from './profile/User/user-subscriptions/user-subscriptions-resolver.service';
// import { UserContestsResolverService } from './profile/User/user-contests/user-contests-resolver.service';
import { UserBlogsResolverService } from './profile/User/user-blogs/user-blogs-resolver.service';
import { UserDataResolverService } from './profile/User/resolvers/user-data-resolver.service';
import { BlogAndAuthorDataResolverServiceService } from './deep-link-resolvers/blog-and-author-data-resolver-service.service';
import { ContestResolverService } from './deep-link-resolvers/contest-resolver.service';
import { PrivacyPolicyComponent } from './privacyPolicy/privacy-policy/privacy-policy.component';
import { GuruComponent } from './guru/guru.component';
import { GuruResolverService } from './guru/guru.resolver.service';
import { ShareableLinkPageComponent } from './shareable-link-page/shareable-link-page.component';
import { YourSocialLinksComponent } from './profile/Me/your-social-links/your-social-links.component';
import { UserSocialLinksComponent } from './profile/User/user-social-links/user-social-links.component';
import { CreateBlogComponent } from './profile/Me/your-blogs/create-blog/create-blog.component';
import { EditBlogResolverService } from './deep-link-resolvers/edit-blog-resolver.service';
import { VerifyAccountComponent } from './login/verify-account/verify-account.component';
import { OtpGuard } from './login/otp.guard';
import { RevenueShareSignupComponent } from './login/SignupProcess/revenue-share-signup/revenue-share-signup.component';
import { SubscriptionDataResolverService } from './profile/Me/account-settings/subscription-data-resolver.service';

const appRoutes: Routes = [
  { path: ROUTE.empty, redirectTo: ROUTE.contest /*ROUTE.profile*/, pathMatch: 'full' },
  { path: ROUTE.login, component: LoginComponent },
  { path: ROUTE.aboutUs, component: AboutUsComponent},
  {
    path: ROUTE.accountSettings,
    // canActivate: [AuthGuardGuard],
    resolve: {
      subscriptionData:  SubscriptionDataResolverService
   },
    canActivate: [OtpGuard],
    component: AccountSettingsComponent,
  },
  {
    path: ROUTE.verifyAccount,
    // canActivate: [AuthGuardGuard],
    component: VerifyAccountComponent,
  },


  {
    path: ROUTE.media,
    // canActivate: [AuthGuardGuard],
    canActivate: [OtpGuard],
    component: MediaComponent,
    resolve: {
      topicData: AllTopicsResolverService
   },
  },
  {
    path: ROUTE.shareableLink,
    // canActivate: [AuthGuardGuard],
    canActivate: [OtpGuard],
    component: ShareableLinkPageComponent,
  //   resolve: {
  //     topicData: AllTopicsResolverService
  //  },
  },
  {
    path: ROUTE.guru,
    // canActivate: [AuthGuardGuard],
    canActivate: [OtpGuard],
    component: GuruComponent,
    resolve: {
      topicData: AllTopicsResolverService,
      guruData: GuruResolverService
   },
  },
  {
    canActivate: [OtpGuard],
    path: ROUTE.media + '/' + ROUTE.dynamicBlog + '/:blogId',
    component: ViewBlogComponent
  },

  {
    canActivate: [OtpGuard],
    path: ROUTE.media + '/' + ROUTE.dynamicBlog + '/:blogId/author/:userId',
    component: ViewBlogComponent,
    resolve: {
      data: BlogAndAuthorDataResolverServiceService
   },
  },
  {
    canActivate: [OtpGuard],
    path: ROUTE.profile,
    // canActivate: [AuthGuardGuard],
    component: ProfileComponent,
    resolve: {
      topicData: AllTopicsResolverService
    },
    children: [
      {
        path: ROUTE.yourContests,
        component: YourContestsComponent,
      },
      {
        path: ROUTE.accountSettings,
        canActivate: [OtpGuard],
        component: AccountSettingsComponent,
        resolve: {
          subscriptionData:  SubscriptionDataResolverService
       },
      },

      {
        path: '',
        redirectTo: ROUTE.yourBlogs,
        pathMatch: 'prefix'
      },
      {
        path: ROUTE.yourBlogs,
        component: YourBlogsComponent,
      },
      {
        path: ROUTE.createBlog,
        component: CreateBlogComponent,
        resolve: {
          blogData: EditBlogResolverService
       }
      },
      {
        path: ROUTE.yourSocialLinks,
        component: YourSocialLinksComponent
      },
      {
        path: ROUTE.account,
        component: YourVirtualAccountComponent,

      },

    ]
  },
  {
    canActivate: [OtpGuard],
    path: ROUTE.user + '/:userId',
    component: UserProfileComponent,
    resolve: {data: UserDataResolverService,
      topicData: AllTopicsResolverService,
      subscriptions: UserSubscriptionsResolverService},
    children: [
      {
        path: ROUTE.userContests,
        component: UserContestsComponent,
      },
      {
        path: ROUTE.userAccountSettings,
        component: AccountSettingsComponent,
      },
      {
        path: '',
        redirectTo: ROUTE.userBlogs,
        pathMatch: 'prefix'
      },
      {
        path: ROUTE.userBlogs,
        component: UserBlogsComponent
      },
      {
        path: ROUTE.userSocialLinks,
        component: UserSocialLinksComponent
      },
      {
        path: ROUTE.userAccount,
        component: UserVirtualAccountComponent
      },

    ]
  },
  {
    canActivate: [OtpGuard],
    path: ROUTE.viewContest + '/:contestId',
    // canActivate: [AuthGuardGuard],
    component: ViewContestComponent,
    resolve: {
      data: ContestResolverService
      // rankingData: ViewContestResolverService
   }
  },
  // {
  //   canActivate: [OtpGuard],
  //   path: ROUTE.viewContest + '/:contestId/user/:userId',
  //   // canActivate: [AuthGuardGuard],
  //   component: ViewContestComponent,
  //   resolve: {
  //     rankingData: ViewContestResolverService
  //  }
  // },

  {
    canActivate: [OtpGuard],
    path: ROUTE.contest,
    // canActivate: [AuthGuardGuard],
    component: ContestComponent,
    resolve: {
       topicData: AllTopicsResolverService,
    },
    children: [
      {
        path: '',
        redirectTo:  ROUTE.contest,
        pathMatch: 'full',
     },
      {
          path: ROUTE.createFinancialContestModal,
          component:  CreateContestModalComponent,
          children: [
            {
                path: '',
                redirectTo: ROUTE.createFinancialContestModal,
                pathMatch: 'full',
            },
            {
              path: ROUTE.createFinancialContestModalStockSelection,
              component: CreateFinancialContestModalStockSelectionComponent
            },
            {
              path: ROUTE.createFinancialContestModalDateSelection,
              component: CreateFinancialContestModalDateSelectionComponent,
            },
            {
              path: ROUTE.createFinancialContestModalCreateContestOrAnotherGame,
              // tslint:disable-next-line:max-line-length
              component: CreateFinancialContestModalCreateContestOrAnotherGameComponent,
            },
            {
              path: ROUTE.createFinancialContestModalReviewAndSubmit,
              component:  CreateFinancialContestModalReviewAndSubmitComponent,
            },
            {
              path: ROUTE.createFinancialContestModalStockFieldSelection,
              component: CreateFinancialContestModalStockFieldSelectionComponent
            },
            {
              path: ROUTE.createFinancialContestModalStockGameTypeSelection,
              component: CreateFinancialContestModalStockGameTypeSelectionComponent,
            },
            ]
      },
    ]
  },
  // {
  //   path: ROUTE.createFinancialContestModal,
  //   component:  CreateContestModalComponent, // child route component that the router renders
  // },
  // {
  //   path: ROUTE.createContestModalBlankComponent,
  //   component:   CreateContestModalBlankComponent, // child route component that the router renders
  // },
  // {
  //   path: ROUTE.createFinancialContestModalStockSelection,
  //   component: CreateFinancialContestModalStockSelectionComponent, // child route component that the router renders
  // },
  // {
  //   path: ROUTE.createFinancialContestModalDateSelection,
  //   component: CreateFinancialContestModalDateSelectionComponent, // another child route component that the router renders
  // },
  {
    path: ROUTE.topic,
    // canActivate: [AuthGuardGuard],
    component: TopicComponent,
    resolve: {
      topicData: AllTopicsResolverService
   }
  },
  {
    path: ROUTE.subtopic,
    // canActivate: [AuthGuardGuard],
    component: SubtopicComponent,
  },

  {
    path: ROUTE.forgotPassword,
    component: ForgotPasswordComponent,
  },
  {
    path: ROUTE.usernameForPassword,
    component: UsernameForPasswordComponent,
  },
  { path: ROUTE.signup, component: SignupComponent },
  {
    // canActivate: [AuthGuardGuard],
    path: ROUTE.signupPaymentInfo,
    component: SignupPaymentInformationComponent,
  },
  {
    // canActivate: [AuthGuardGuard],
    path: ROUTE.revenueShareSignup,
    component: RevenueShareSignupComponent,
  },
  { path: ROUTE.privacyPolicy, component: PrivacyPolicyComponent },

  { path: ROUTE.notFound, component: PageNotFoundComponent },
  { path: ROUTE.wildcard, redirectTo: ROUTE.absNotFound },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, initialNavigation: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
