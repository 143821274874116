import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Blog, Links, Page } from 'src/app/media/media.component';
import { SafeHtml } from '@angular/platform-browser';
import { Contest } from 'src/app/contest/game/game.service';
import { Me } from 'src/app/profile/Me/me.service';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  id;
  user: Me;

  constructor(private router: Router, private httpClient: HttpClient) {}

  getUser(userId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/user/${userId}/profile`);
  }

}

export interface AccountInfo {
  displayName: string;
  email: string;
  phoneNumber?: string;
}

export interface UserDetails {
  jwt: string;
  hasSubscription: boolean;
  id: number;
  phoneNumber?: null;
  firstname: string;
  lastname?: null;
  username: string;
  user: User;
  subscriptionEndDate?: null;
  subscriptionStartDate?: null;
}
export interface User {
  id: number;
  username: string;
  customerId?: null;
  hasSubscription: boolean;
  subscriptionEndDate?: null;
  subscriptionStartDate?: null;
  phoneNumber?: null;
  password: string;
  active: boolean;
  roles: string;
  firstname: string;
  lastname?: null;
  coins?: (CoinsEntity)[] | null;
  followers?: (null)[] | null;
  delegatedOwner?: (null)[] | null;
  profilePicture?: null;
  photos?: (null)[] | null;
  posts?: (PostsEntity)[] | null;
  header?: null;
}
export interface Reference{
  id: number;
  name: string;
}
export interface GamesEntity {
  name?: null;
  id: number;
  events?: (string)[] | null;
}
export interface CoinsEntity {
  id: number;
  dateIssued: string;
  dateExpired?: null;
  tCoin: boolean;
  cCoin: boolean;
}
export interface TopicsEntity {
  id: number;
  name: string;
  subtopics?: SubTopicsEntityOrOwnedSubtopicsEntity[];
}


export interface PaginatedTopic {
  _embedded: EmbeddedTopics;
  _links: Links;
  page: Page;
}

export interface EmbeddedTopics {
  topicDTOList: TopicsEntity[];
}

export interface PaginatedSubtopic {
  _embedded: EmbeddedSubtopics;
  _links: Links;
  page: Page;
}

export interface EmbeddedSubtopics {
  subtopicDTOList: SubTopicsEntityOrOwnedSubtopicsEntity[];
}



export interface SubTopicsEntityOrOwnedSubtopicsEntity {
  id: number;
  name: string;
}
export interface PostsEntity {
  id?: number;
  ownerId?: number;
  contentString?: string;
  contentStringSafeHtml?: SafeHtml;
  date?: Date;
  likes?: number;
  dislikes?: number;
  commentAmount?: number;
  shareAmount?: number;
}

export interface PaginatedPost {
  _embedded: EmbeddedPosts;
  _links: Links;
  page: Page;
}

export interface EmbeddedPosts {
  postList: PostsEntity[];
}


export interface EmbeddedContests {
  contestPreviewDTOList: Contest[];
}

export interface InviteeInviter {
  addedOn: Date;
  id?: number;
  inviteeId: number;
  inviteeUsername: string;
}

export interface EmbeddedInviterInvitee {
  inviteeInviterList: InviteeInviter[];
}

export interface PaginatedContest {
  _embedded: EmbeddedContests;
  _links: Links;
  page: Page;
}

export interface PaginatedInviterInvitee {
  _embedded: EmbeddedInviterInvitee;
  _links: Links;
  page: Page;
}
