<div class="ml-1">
  <form (ngSubmit)="submitNewPassword(f)" #f="ngForm" class="mt-2">
    <div class="form-group">
      <label for="exampleInputPassword1">Enter your new password</label>
      <input
        type="password"
        name="password"
        minlength="8"
        ngModel
        #passwordValid="ngModel"
        class="form-control"
        id="exampleInputPassword1"
        required
        placeholder="Password"
      />
    </div>
    <div
      *ngIf="
        passwordValid.invalid && (passwordValid.dirty || passwordValid.touched)
      "
      class="alert alert-danger"
    >
      <div *ngIf="passwordValid.errors.required">password is required.</div>
      <div *ngIf="passwordValid.errors.minlength">
        Name must be at least 8 characters long.
      </div>
    </div>

    <div class="col-sm-12 controls">
      <button class="btn btn-success" type="submit" [disabled]="!f.valid">
        Enter new password
      </button>
    </div>
  </form>
  <div *ngIf="!!message">{{ message }}</div>
  <div *ngIf="!!error">{{ error }}</div>
</div>
