<div class="container">
  <div class="row">
    <table
      class="table table-striped table-dark table-xs table-hover table-condensed"
    >
      <thead>
        <tr>
          <th scope="col">Platform</th>
          <th scope="col">URL</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let userSocialLink of socialLinks">
          <td>{{ userSocialLink.platform }}</td>
          <td>
            <a target="_blank" [href]="'//' + userSocialLink.url">{{
              userSocialLink.url
            }}</a>
          </td>
          <td>
            <button
              class="btn btn-sm btn-success"
              (click)="copyLink(userSocialLink.url)"
            >
              Share/Copy
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
