import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { UserService, PaginatedPost } from '../user.service';
import { ContestService } from '../../../contest/contest.service';
import { ContestType, DurationType } from 'src/app/contest/game/game.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataResolverService implements Resolve<any>  {

  constructor(private userService: UserService, private contestService: ContestService) { }

  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot): Object | Observable<Object> | Promise<Object> {
    const userId = +route.paramMap.get('userId');
    const tag = {name: '', id: -1};

    // const userCompetitorLiveContests =
    //  this.contestService.getUserContests({type: 'userCompetitorLive', topicId: -1, subtopicId: -1, tagId: tag.id, page: 0,
    // durationType: DurationType.ALL, contestType: ContestType.ALL}, userId);
    // const userCompetitorPastContests =
    //  this.contestService.getUserContests({type: 'userCompetitorPast', topicId: -1, subtopicId: -1, tagId: tag.id, page: 0,
    //  durationType: DurationType.ALL, contestType: ContestType.ALL}, userId);
    // const userCompetitorUpcomingContests =
    //  this.contestService.getUserContests({type: 'userCompetitorUpcoming', topicId: -1, subtopicId: -1, tagId: tag.id, page: 0,
    //  durationType: DurationType.ALL, contestType: ContestType.ALL},  userId);
    const user = this.userService.getUser(userId);

    console.log('Gather Use profile data attempt [resolve]');
    return forkJoin([user]).subscribe(results => {
      console.log('Entered fork join for user profile [resolve]');
      this.userService.user = results[0];
      // this.userService.user.paginatedEnteredLiveContests = results[0];
      // this.userService.user.paginatedEnteredPastContests = results[1];
      // this.userService.user.paginatedEnteredUpcomingContests = results[2];
      console.log('Gather Use profile success [resolve]');
   });
 }
}


