import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { ActivatedRoute } from '@angular/router';
import { MeService } from 'src/app/profile/Me/me.service';
import { StorageService } from '../../shared/storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
              private meService: MeService, private storageService: StorageService) { }

  message = '';
  error = '';

  ngOnInit(): void {
    const id = 'id';
    this.meService.jwt = this.activatedRoute.snapshot.paramMap.get(id);
  }
  submitNewPassword(form: NgForm): void{
    const password = form.value.password;
    const data = {password};

    this.httpClient.post(HOST_IP_INFO_MARKETS + '/forgotPasswordResetPassword', data).subscribe((response: any) => {
      this.message = response.message;
      this.meService.logout();
      this.storageService.clean();
      window.location.reload();
    },
    error => {
      console.log(error);
      this.error = error;
    });
  }

}
