import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeService } from '../profile/Me/me.service';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';

@Injectable({
  providedIn: 'root'
})
export class UpvoteService {

  constructor(private httpClient: HttpClient, private meService: MeService) { }

  upvoteBlog(blogId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/${blogId}/upvoted`);
  }

  downvoteBlog(blogId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/blog/${blogId}/downvoted`);
  }

  upvoteComment(blogId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/comment/${blogId}/upvoted`);
  }

  downvoteComment(blogId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/comment/${blogId}/downvoted`);
  }

  // upvoteContest(userId: number, blogId: number): void{
  //   // allow users to vote on contests they want others to enter... maybe
  // }

}
