<p class="font-italic font-weight-light">
  How many combined points will be scored in the game? If you think the home
  team will score 20 and the away team will score 30, enter 50
</p>
<table
  class="table table-striped table-dark table-xs table-hover table-condensed font-weight-light"
  style="width: 100%"
  #target
>
  <thead>
    <tr>
      <th scope="col">Game</th>

      <th scope="col">Score</th>

      <th scope="col">Final Value</th>
      <th
        scope="col"
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
          currentContest.userContestState === UserContestState.ENTERED_LIVE ||
          userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED
        "
      >
        Predicted Value
      </th>
      <th
        scope="col"
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED
        "
      >
        MOE
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
      <td
        *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        <a
          href="{{
            'https://www.bing.com/search?q=' +
              game.name +
              '+Upcoming College Football Game'
          }}"
          target="_blank"
          >H: {{ game.sportsGame.home_team }}
          <br />
          A: {{ game.sportsGame.away_team }}</a
        >
        <div style="font-size: 10px">
          {{ game.startTime | date : "medium" }}
        </div>
      </td>
      <td
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
        *ngIf="contestSubtopic.name.toUpperCase() === 'NFL'.toUpperCase()"
      >
        <a
          href="{{
            'https://www.bing.com/search?q=' + game.name + '+Upcoming NFL Game'
          }}"
          target="_blank"
          >H: {{ game.sportsGame.home_team }}
          <br />
          A: {{ game.sportsGame.away_team }}</a
        >
        <div style="font-size: 10px">
          {{ game.startTime | date : "medium" }}
        </div>
      </td>
      <td
        *ngIf="
          game.sportsGame.status !== null && game.sportsGame.status !== 'NS'
        "
      >
        {{ game.sportsGame.home_score }}-{{ game.sportsGame.away_score }} -
        status: {{ game.sportsGame.status }}
      </td>
      <td *ngIf="game.sportsGame.status === 'NS'">
        Status: {{ game.sportsGame.status }}
      </td>
      <td *ngIf="game.sportsGame.status === null">No Status</td>

      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED) &&
          game.betSlip.over
        "
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>
      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED) &&
          game.betSlip.under
        "
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>

      <td
        *ngIf="
          currentContest.userContestState ===
            UserContestState.NON_ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED
        "
        class="bg-secondary"
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>

      <td
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_LIVE ||
          currentContest.userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED ||
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED
        "
        class="bg-secondary"
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
      >
        {{ game.betSlip.predictedValue | number : "1.2-4" }}
      </td>

      <td
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED
        "
        class="bg-info"
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
      >
        {{ game.betSlip.marginOfErrorOutcome | number : "1.2-4" }}
      </td>
    </tr>
  </tbody>
</table>
<div class="widget-49-meeting-action">
  <button
    *ngIf="isLoggedIn"
    [ngClass]="{
      'btn-sm btn-secondary text-center': canSubmitContestEntry() === false,
      'btn-sm btn-success text-center': canSubmitContestEntry() === true,
      scratched: !canSubmitContestEntry()
    }"
    style="font-size: 8.8px"
    [disabled]="!canSubmitContestEntry()"
    (click)="enterContest()"
  >
    Enter or Update Entry
  </button>

  <button
    *ngIf="!isLoggedIn"
    style="font-size: 8.8px"
    (click)="linkToSignUpPage()"
    class="btn btn-success text-center"
  >
    Sign up/in to Play!
  </button>
</div>

<div class="widget-49-meeting-action" *ngIf="!canSubmitContestEntry()">
  <div class="btn btn-sm btn-flash-border-primary">Contest is Finished</div>
</div>
