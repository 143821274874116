import { Component, OnInit } from '@angular/core';
import { userSocialLinks } from '../../../GlobalVars/routingGlobal';
import { UserSocialLinksService } from '../../User/user-social-links/user-social-links.service';
import { Me, MeService } from '../me.service';
import { Links, Page } from 'src/app/media/media.component';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-your-social-links',
  templateUrl: './your-social-links.component.html',
  styleUrls: ['./your-social-links.component.css']
})
export class YourSocialLinksComponent implements OnInit {
  _nameOfPlatform = '';
  _link = '';
  nonFilteredPlatformList = ['Youtube', 'Facebook', 'Snapchat',
  'Twitch', 'Twitter', 'Reddit', 'Github', 'WhatsApp',
  'Tumblr', 'LinkedIn', 'Pinterest', 'Telegram',
  'Other'];

  paginatedSocialLink: PaginatedSocialLink;
  selectedValue: any;
  private _filteredPlatformList: string[];
  private _selectedPlatform: any;

  constructor(private userSocialLinksService: UserSocialLinksService, private meService: MeService,
              private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this._filteredPlatformList = this.nonFilteredPlatformList.slice();
    this.getSocialLinks();

  }

  addSocialLink(nameOfPlatform: string, link: string): void{
      console.log('name of platform is ' + nameOfPlatform);
      console.log('link is ' + link);
      const platform = nameOfPlatform;
      const userId = this.meService.id;
      const url = link;
      const socialLink: SocialLink = {
        platform, url, userId
      };
      this.userSocialLinksService.postYourSocialLink(socialLink).subscribe((result: SocialLink) => {
        console.log('received result... calling get social links');
        this.getSocialLinks();
      });
      // this.userSocialLinks.push({nameOfPlatform, link});
  }

  getSocialLinks(): void{

    this.userSocialLinksService.getUserSocialLinks(this.meService.id).subscribe((result: PaginatedSocialLink) => {
      this.paginatedSocialLink = result;
    });
    // this.userSocialLinks.push({nameOfPlatform, link});
}

deleteSocialLink(id: number): void{
  this.userSocialLinksService.deleteYourSocialLink(id).subscribe(result => {
      console.log('deleted link successfully');
      this.getSocialLinks();
  });
}

copyLink(url: string): void{
  this.clipboard.copy(url);
}

  get nameOfPlatform(): string{
    return this._nameOfPlatform;
  }

  get link(): string{
    return this._link;
  }

  get socialLinks(): SocialLink[]{
    if (this.paginatedSocialLink && this.paginatedSocialLink._embedded && this.paginatedSocialLink._embedded.socialLinkDTOList){
      return this.paginatedSocialLink._embedded.socialLinkDTOList;
    }

  }

  selectValue(option): void{
    this._selectedPlatform = option;
  }


   get selectedPlatform(): string{
    return this._selectedPlatform;
   }

  filterDropdown(e): void {

    console.log('e in filterDropdown -------> ', e);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this._filteredPlatformList = this.nonFilteredPlatformList.slice();
      return;
    } else {
      this._filteredPlatformList = this.nonFilteredPlatformList.filter(
        (platform: string) => platform.toLowerCase().indexOf(searchString) > -1
      );

    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
    console.log('this.filteredList indropdown -------> ', this._filteredPlatformList);
  }

  get filteredPlatformList(): string[]{
    return this._filteredPlatformList;
  }
}

export interface SocialLink{
  platform: string;
  url: string;
  userId: number;
}


export interface EmbeddedSocialLink{
  socialLinkDTOList?: SocialLink[];
}

export interface PaginatedSocialLink{
  _embedded: EmbeddedSocialLink;
  _links: Links;
  page: Page;
}
