import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { HOST_IP_INFO_MARKETS} from '../../../GlobalVars/api';
import { AccountInfo } from '../../User/user.service';
import { MeService } from '../me.service';
import { StorageService } from '../../../shared/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from './subscription.service';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css'],
})
export class AccountSettingsComponent implements OnInit {
  public accountInfo: AccountInfo;
  public _subscription: Subscription;
  hasSubscription = false;
  subscriptionErrorMessage: any;
  subscriptionMessage: any;
  constructor(public httpClient: HttpClient, public meService: MeService,
              private storageService: StorageService, private activatedRoute: ActivatedRoute,
              private subscriptionService: SubscriptionService, private router: Router) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((response: any) => {
      if (response.subscriptionData === 'No data'){
        this.hasSubscription = false;
      }
      else {
        this.hasSubscription = true;
        this._subscription = response.subscriptionData;
      }
    });
    this.subscriptionMessage = '';
    this.accountInfo = this.meService.accountInfo;
    const userId = this.meService.id;
    const email = this.meService.user.email;
  }

  get subscription(): Subscription{
    return this._subscription;
  }

  get email(): string{
    return this.meService.user.email;
  }

  subscribe(): void{
    // this.subscriptionService.doSubscribe();
  }

  cancelSubscription(): void{
    this.subscriptionService.cancelSubscription().subscribe((result) => {
      this._subscription = result;
      this.subscriptionMessage = 'Subscription Cancelled';
    },
    err => this.subscriptionMessage = err.error);
  }


deleteAccount(input: any): void{
  const email = this.meService.accountInfo.email;
  const data = {username: email, password: input.value};
  this.httpClient.post(HOST_IP_INFO_MARKETS + '/deleteUser', data).subscribe((response) => {
    console.log('successfully deleted');
    this.meService.logout();
    this.storageService.clean();
    window.location.reload();
  },
  error => {
    console.log(error);
    console.log('unable to delete account');
  });
  }

  createCustomerPortal(): any{
    this.httpClient.get(HOST_IP_INFO_MARKETS + '/customer-portal').subscribe((data: any) => {
      window.location.href = data.url;
    },
    err => {
      console.error('Error:', err);
    });
  }
}

export interface Subscription{
  billingDayOfMonth?: number;
  billingPeriodStartDate?: Date;
  billingPeriodEndDate?: Date;
  price?: number;
  nextBillingPeriodAmount?: number;
  status?: string;
}
