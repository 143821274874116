import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';
import { PaginatedTag, Tag } from './guru.component';

@Injectable({
  providedIn: 'root'
})
export class GuruService {

  searchText = '';
  paginatedTag: PaginatedTag;
  currentTag: Tag;

  constructor(private router: Router, private httpClient: HttpClient) { }

  getAllTags(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/tag`);
  }

  getAllTagsForSubtopicWithNameMappings(subtopicId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/tag/subtopic/${subtopicId}/name-mappings`);
  }

  getAllTagsForSubtopic(subtopicId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/tag/subtopic/${subtopicId}`);
  }

  getAllTagsForUser(userId: number): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/tag/user/${userId}`);
  }
}
