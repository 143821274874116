import { Component, Input, OnInit } from '@angular/core';
import { MyContestOutcomeService, PaginatedContestOutcome, ContestOutcome } from './my-contest-outcome.service';
import { MeService } from '../me.service';
import { subtopic } from '../../../GlobalVars/routingGlobal';
import { SubTopicsEntityOrOwnedSubtopicsEntity } from '../../User/user.service';
import { SubtopicService } from '../../../topic/subtopic/subtopic.service';
import { Tag } from '../../../guru/guru.component';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from 'src/app/Aggregation/subtopic-ranking.service';
import { PaginatedSubtopicRanking, SubtopicRanking } from 'src/app/contest/contest.component';

@Component({
  selector: 'app-my-contest-outcomes',
  templateUrl: './my-contest-outcomes.component.html',
  styleUrls: ['./my-contest-outcomes.component.css']
})
export class MyContestOutcomesComponent implements OnInit {
  private _subtopic: SubTopicsEntityOrOwnedSubtopicsEntity;
  private _tag: Tag;
  private _contestType: string;
  isMobile: boolean;
  private _timeframeType: any;
  activeSubtopicRankingSet: any;

  @Input() set
  subtopic(value: SubTopicsEntityOrOwnedSubtopicsEntity){
    this._subtopic = value;
    if (!!this.meService.id && !!this.meService.id &&
      !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get subtopic(): SubTopicsEntityOrOwnedSubtopicsEntity{
    return this._subtopic;
  }

  @Input() set
  tag(value: Tag){
    this._tag = value;
    if (!!this.meService.id && !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get tag(): Tag{
    return this._tag;
  }

  @Input() set
  contestType(value: string){
    this._contestType = value;
    if (!!this.meService.id && !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get contestType(): string{
    return this._contestType;
  }

  @Input() set
  timeframeType(value: TimeAndGameDurationPeriodType){
    this._timeframeType = value;
    if (!!this.meService.id &&
       !!this._subtopic && !!this._tag && !!this._contestType && !!this._timeframeType){
      // this.retrieveContestOutcomeList();
      this.retrieveSubtopicRankingList();
    }
  }

  get timeframeType(): TimeAndGameDurationPeriodType{
    return this._timeframeType;
  }

  _filteredOutcomes: ContestOutcome[] = [];

  paginatedContestOutcomesForSingleContest: PaginatedContestOutcome;

  constructor(private myContestOutcomeService: MyContestOutcomeService, private meService: MeService,
              private subtopicRankingService: SubtopicRankingService) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    if (!!this.meService.id && !!this._subtopic && !!this._tag && !!this._contestType){
     this.retrieveSubtopicRankingList();
    }

  }

  retrieveSubtopicRankingList(): void {
    this.subtopicRankingService.
    getSubtopicRankingsForUserWithContestOutcomes(this.meService.id,
      this._subtopic.id, this._tag.id, this._contestType, this.timeframeType)
    .subscribe((paginatedSubtopicRanking: PaginatedSubtopicRanking) => {
      this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType = paginatedSubtopicRanking;
      this.subtopicRankingService.
      getSubtopicRankingsForUserWithContestOutcomesForSpecificTimeframe(this.meService.id,
        this._subtopic.id, this._tag.id, this._contestType, this.timeframeType,
        // tslint:disable-next-line:max-line-length
        this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList[0].timeAndGameDurationPeriod)
      .subscribe((paginatedSubtopicRanking2: PaginatedSubtopicRanking) => {
        // tslint:disable-next-line:max-line-length
        this.activeSubtopicRankingSet = this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList[0];
        this.myContestOutcomeService.paginatedSubtopicRankingAllForTimeframe = paginatedSubtopicRanking2;
      });
      // tslint:disable-next-line:max-line-length
      this.retrieveContestOutcomeList(this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList[0].timeAndGameDurationPeriod);
    });
  }

  retrieveContestOutcomeList(timeframe: string = 'YEAR'): void {
    this.myContestOutcomeService.getContestOutcomesForMe(this.meService.id,
      this._subtopic.id, this._tag.id, this._contestType, timeframe)
    .subscribe((paginatedContestOutcome: PaginatedContestOutcome) => {
      this.myContestOutcomeService.paginatedContestOutcome = paginatedContestOutcome;
      this._filteredOutcomes = [];
      // console.log('Filtering outcomes');
      this.contestOutcomeList.forEach((contestOutcome: ContestOutcome) => {
          // console.log('subtopic . name :' + this.subtopic.name);
          // console.log('contest outcome. subtopicname :' + contestOutcome.subtopicName);
          if (contestOutcome.subtopicName === this._subtopic.name){
            // console.log('Found match');
            this._filteredOutcomes.push(contestOutcome);
          }
        });
      // this.retrieveSubtopicRankingList();
    });
  }

  get contestOutcomeList(): ContestOutcome[]{
    if (this.myContestOutcomeService.paginatedContestOutcome
      && this.myContestOutcomeService.paginatedContestOutcome._embedded
      && this.myContestOutcomeService.paginatedContestOutcome._embedded.contestOutcomeDTOList){
        return this.myContestOutcomeService.paginatedContestOutcome._embedded.contestOutcomeDTOList;
      }
    return null;
  }

  get subtopicRankingsAllForTimeFrame(): SubtopicRanking[] {
    if (this.myContestOutcomeService.paginatedSubtopicRankingAllForTimeframe &&
      this.myContestOutcomeService.paginatedSubtopicRankingAllForTimeframe._embedded &&
      this.myContestOutcomeService.paginatedSubtopicRankingAllForTimeframe._embedded.subtopicRankingDTOList){
        return  this.myContestOutcomeService.paginatedSubtopicRankingAllForTimeframe._embedded.subtopicRankingDTOList;
      }
    return null;
  }

  get subtopicRankingsActiveForTimeframeType(): SubtopicRanking[]{
    if (this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType &&
      this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded &&
      this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList) {
    return  this.myContestOutcomeService.paginatedSubtopicRankingActiveForTimeframeType._embedded.subtopicRankingDTOList;
    }
    return null;
  }
  get filteredOutcomes(): ContestOutcome[]{
    this._filteredOutcomes = [];
    console.log('Filtering outcomes');
    if (this.subtopic && this.contestOutcomeList){
      this.contestOutcomeList.forEach((contestOutcome: ContestOutcome) => {
        console.log('subtopic . name :' + this.subtopic.name);
        console.log('contest outcome. subtopicname :' + contestOutcome.subtopicName);
        if (contestOutcome.subtopicName === this.subtopic.name){
          console.log('Found match');
          this._filteredOutcomes.push(contestOutcome);
        }
      });
      return this._filteredOutcomes;
    }
    return null;
  }

  viewContestOutcomesForSpecificContest(contestOutcome: ContestOutcome): void {
    this.myContestOutcomeService.getContestOutcomesForContest(contestOutcome.contestId)
    .subscribe((paginatedContestOutcome: PaginatedContestOutcome) => {
      this.paginatedContestOutcomesForSingleContest = paginatedContestOutcome;
    });
  }

  get contestOutcomesForSingleContest(): ContestOutcome[] {
    if (this.paginatedContestOutcomesForSingleContest){
      return this.paginatedContestOutcomesForSingleContest._embedded.contestOutcomeDTOList;
    }
    return null;
  }

}
