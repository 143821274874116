<p>create-financial-contest-modal-review-and-submit works!</p>

<div>
  <!-- <button
    class="btn-sm btn-secondary m-2 text-center font-weight-light"
    (click)="createGame()"
  >
    Enter game
  </button> -->
  <p>The following game has been added</p>

  <p class="font-weight-light m-2">Current Games In Contest</p>

  <ol
    class="widget-49-meeting-points font-weight-light m-2"
    *ngFor="let game of games"
  >
    GameType:
    {{
      game.gameType.type
    }}
    <br />
    Stock Symbol:
    {{
      game.name
    }}
    <br />
    Field Being Bet On:
    {{
      game.fieldToBeBetOn
    }}
    <br />
    Value Being Bet On (if it exists... will not in margin of error):
    {{
      game.valueToBeBetOn
    }}
    <br />
    Start Time:
    {{
      game.startTime | date: "medium"
    }}
    <br />
    End Time:
    {{
      game.endTime | date: "medium"
    }}
    <br />
    Bet Slip ----
    <br />
    Over:
    {{
      game.betSlip.over
    }}
    <br />
    Undr:
    {{
      game.betSlip.under
    }}
    <br />
    Weight:
    {{
      game.betSlip.weight
    }}
  </ol>
</div>

<ul class="pagination">
  <li class="page-item">
    <a class="page-link" (click)="previousPage()">Previous</a>
  </li>
</ul>
