<app-header (featureSelected)="onNavigate($event)"></app-header>
<div
  *ngIf="!isMobile"
  class="container-fluid min-vh-100 bg-light align-items-center justify-content-center"
>
  <!-- <div class="row"> also... above was container and not container fluid
    <div class="col-md-12"> -->
  <!-- <div class="mx-4"> -->
  <router-outlet></router-outlet>
  <!-- </div> -->

  <!-- </div>
  </div> -->
</div>
<div
  *ngIf="isMobile"
  class="container-fluid bg-light align-items-center justify-content-center"
>
  <router-outlet></router-outlet>
</div>
