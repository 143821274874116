import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TopicService } from './topic.service';
import {  ContestService } from '../contest/contest.service';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import { SubtopicService } from './subtopic/subtopic.service';
import { Contest, ContestType, DurationType } from '../contest/game/game.service';
import { ContestStateService } from '../contest/contest-state.service';
import { SubTopicsEntityOrOwnedSubtopicsEntity, TopicsEntity } from '../profile/User/user.service';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent implements OnInit {
  contestsForTopic: Contest[];

  constructor(private topicService: TopicService,
              private subtopicService: SubtopicService,
              private activatedRoute: ActivatedRoute,
              private contestService: ContestService,
              private contestStateService: ContestStateService,
              private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((response: any) => {
      this.topicService.allTopics = response.topicData;
    });
  }

  selectTopic(topic): void {
    this.topicService.currentTopic = topic;
  }

  selectSubtopic(subtopic): void {
    this.subtopicService.currentSubtopic = subtopic;
  }

  subscribeToSubtopic(): void{
    this.subtopicService.subscribeToSubtopic( this.subtopicService.currentSubtopic.id).subscribe((result) => {
      this.contestsForTopic = result;
    });
  }

  subscribeToTopic(): void{
    this.topicService.subscribeToTopic( this.topicService.currentTopic.id).subscribe((result) => {
      this.contestsForTopic = result;
    });
  }

  viewContests(): void{
    this.contestService.getContests({type: 'topic', topicId: this.topicService.currentTopic.id, tagId: -1,
    durationType: DurationType.ALL, contestType: ContestType.ALL, page: 0, atomicGameName: 'ALL'}).subscribe((result) => {
      this.contestsForTopic = result._embedded.contestPreviewDTOList;
    });
  }

  get allTopics(): TopicsEntity[]{
    return this.topicService.allTopics._embedded.topicDTOList;
  }

  get currentTopic(): TopicsEntity{
    return this.topicService.currentTopic;
  }

  get currentSubtopic(): SubTopicsEntityOrOwnedSubtopicsEntity{
    return this.subtopicService.currentSubtopic;
  }

  viewEnteredUpcomingContest(contest: Contest): void{
    // this.contestStateService.viewEnteredUpcomingContest(contest);
    this.router.navigate([routingGlobals.absViewContest, contest.id]);
  }

  viewNonEnteredUpcomingContest(contest: Contest): void {
    // this.contestStateService.viewNonEnteredUpcomingContest(contest);
    this.router.navigate([routingGlobals.absViewContest, contest.id]);
  }

  topicSelected(): boolean{
    if (this.topicService.currentTopic){
      return true;
    }
    return false;
  }

  contestsForTopicAvailable(): boolean{
    if (this.contestsForTopic){
      return true;
    }
    return false;
  }

}
