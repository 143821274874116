import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from '../GlobalVars/api';

@Injectable({
  providedIn: 'root'
})
export class InviteLinkService {

  constructor(private httpClient: HttpClient) { }

  getYourInviteLink(): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + '/invite-url-token-for-user');
  }

  getYourRecentSignups(userId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/recent-signups/${userId}`);
  }

  getInvitedBy(userId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/invited-by/${userId}`);
  }
}
