<div class="row" style="font-size: 10px">
  <div class="col-4">
    <table class="table table-striped table-xs table-hover table-condensed">
      <thead>
        <tr>
          <th scope="col">Attribute</th>
          <th scope="col">This Month</th>
          <th scope="col">Last Month</th>
          <th scope="col">All Time</th>
          <!-- <th scope="col">Coin Value</th> -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>H2H Wins:</td>
          <td>{{ currentSubtopicRanking.totalH2HWins }}</td>
          <td>{{ currentSubtopicRanking.totalH2HWins }}</td>
          <td>{{ currentSubtopicRanking.totalH2HWins }}</td>
        </tr>
        <tr>
          <td>H2H Losses:</td>
          <td>{{ currentSubtopicRanking.totalH2HLosses }}</td>
          <td>{{ currentSubtopicRanking.totalH2HLosses }}</td>
          <td>{{ currentSubtopicRanking.totalH2HLosses }}</td>
        </tr>
        <tr>
          <td>H2H Draws:</td>
          <td>{{ currentSubtopicRanking.totalH2HDraws }}</td>
          <td>{{ currentSubtopicRanking.totalH2HDraws }}</td>
          <td>{{ currentSubtopicRanking.totalH2HDraws }}</td>
        </tr>
        <tr>
          <td>Elo:</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4">
    <table class="table table-striped table-xs table-hover table-condensed">
      <thead>
        <tr>
          <th scope="col">Attribute</th>
          <th scope="col">This Month</th>
          <th scope="col">Last Month</th>
          <th scope="col">All Time</th>
          <!-- <th scope="col">Coin Value</th> -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Elo High:</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
        <tr>
          <td>Elo Low:</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
        <tr>
          <td>Game Wins:</td>
          <td>{{ currentSubtopicRanking.totalGameWins }}</td>
          <td>{{ currentSubtopicRanking.totalGameWins }}</td>
          <td>{{ currentSubtopicRanking.totalGameWins }}</td>
        </tr>
        <tr>
          <td>Game Losses:</td>
          <td>{{ currentSubtopicRanking.totalGameLosses }}</td>
          <td>{{ currentSubtopicRanking.totalGameLosses }}</td>
          <td>{{ currentSubtopicRanking.totalGameLosses }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4">
    <table class="table table-striped table-xs table-hover table-condensed">
      <thead>
        <tr>
          <th scope="col">Attribute</th>
          <th scope="col">This Month</th>
          <th scope="col">Last Month</th>
          <th scope="col">All Time</th>
          <!-- <th scope="col">Coin Value</th> -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Finish %:</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
        <tr>
          <td>Contests:</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
        <tr>
          <td>Blogs</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
        <tr>
          <td>^ Per Blog</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
          <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
