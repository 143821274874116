<div class="row justify-content-center font-weight-light">
  <div class="col-auto text-center">
    <p>Name of Contest</p>
    <p>
      <input
        type="text"
        id="event"
        name="nameOfContestInput"
        #nameOfContestInput
      />
    </p>
    <button
      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
      type="button"
      (click)="updateNameOfContest(nameOfContestInput.value)"
    >
      Enter Contest Name
    </button>
    <p>{{ nameOfContest }}</p>
    <button
      type="button"
      class="btn-sm btn-secondary ml-1 mt-4 font-weight-light"
      data-toggle="modal"
      data-target="#exampleModal"
    >
      Begin creating contest
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg font-weight-light" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <router-outlet></router-outlet>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
