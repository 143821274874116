import { Component, OnInit } from '@angular/core';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import { ActivatedRoute, Router } from '@angular/router';
import { Contest, GameService } from '../../../game/game.service';
import { CreateContestService } from '../../create-contest.service';
import { ContestStateService } from '../../../contest-state.service';
import { GlobalContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { ContestService } from '../../../contest.service';
@Component({
  selector: 'app-create-financial-contest-modal-create-contest-or-another-game',
  templateUrl: './create-financial-contest-modal-create-contest-or-another-game.component.html',
  styleUrls: ['./create-financial-contest-modal-create-contest-or-another-game.component.css']
})
export class CreateFinancialContestModalCreateContestOrAnotherGameComponent implements OnInit, Modal {

  constructor(private router: Router, private route: ActivatedRoute,
              private gameService: GameService, private createContestService: CreateContestService,
              private contestStateService: ContestStateService, private contestService: ContestService) { }
  nextPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModal], {relativeTo: this.route});
  }
  previousPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalReviewAndSubmit], {relativeTo: this.route});
  }
  startPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
  }

  ngOnInit(): void {
  }

  submitContest(): void{
    this.createContest();
  }

  createContest(): void{
    console.log('in create');
    const date = new Date();
    date.setDate(date.getDate() + 7);
    this.contestStateService.currentContest.startTime = date;
    this.contestStateService.currentContest.name = this.createContestService.contestName;
    this.contestStateService.createEmptyBetSlipsForContest();
    this.contestService.createContest(this.contestStateService.currentContest).subscribe((result: Contest) => {
      console.log('success');
      // this.contestStateService.viewNonEnteredUpcomingContest(this.contestStateService.currentContest);
      // this.contestStateService.viewNonEnteredUpcomingContest(result);
      this.contestStateService.currentContest.globalContestState = GlobalContestState.UPCOMING;
      this.router.navigate([routingGlobals.absViewContest]);
    }, err => console.log('failure'));
  }


  canCreateGame(): boolean{
    return this.createContestService.canCreateGame();
  }

  get games(): any{
    return this.gameService.games;
  }

}

export declare interface Modal {
  nextPage(): void;
  previousPage(): void;
  startPage(): void;
}
