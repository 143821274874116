import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as routingGlobals from '../../GlobalVars/routingGlobal';
import { PaginatedBlog } from 'src/app/media/media.component';
import { CoinsEntity, PaginatedContest, PaginatedPost, PaginatedSubtopic, PaginatedTopic } from '../User/user.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
// tslint:disable-next-line:max-line-length


@Injectable({
  providedIn: 'root'
})
export class MeService {
  accountInfo: MyAccountInfo;
  jwt;
  id: number;
  user: Me;
  // currentTimeZone: any;

  constructor(private router: Router, private httpClient: HttpClient) { }

  isAuthenticated(): boolean {
    return this.jwt != null && !!this.user.isVerifiedByOTP;
  }

  logout(): any {
    this.jwt = null;
    this.user = null;
    // this.router.navigate([routingGlobals.absLogin]);
  }

  getClientToken(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/braintree/client-token`);
  }

  doSubscribe(userDTO: any): Observable<any>{
    return this.httpClient.post(HOST_IP_INFO_MARKETS + `/braintree/create-subscription`, userDTO);
  }

}

export interface Me {
  id: number;
  email?: string;
  username: string;
  customerId?: null;
  // stripeConnectedAccountId?: null;
  hasSubscription: boolean;
  subscriptionEndDate?: null;
  subscriptionStartDate?: null;
  phoneNumber?: null;
  password: string;
  active: boolean;
  roles: string;
  firstname: string;
  lastname?: null;
  followers?: (null)[] | null;
  delegatedOwner?: (null)[] | null;
  profilePicture?: null;
  photos?: (null)[] | null;
  header?: null;
  paginatedEnteredLiveContests?: PaginatedContest;
  paginatedEnteredPastContests?: PaginatedContest;
  paginatedEnteredUpcomingContests?: PaginatedContest;
  paginatedEnteredContests?: PaginatedContest;
  coins?: (CoinsEntity)[] | null;
  paginatedPosts?: PaginatedPost;
  paginatedTopics?: PaginatedTopic | null;
  paginatedSubtopics?: PaginatedSubtopic | null;
  paginatedBlogs?: PaginatedBlog;
  isVerifiedByOTP?: boolean;
}
export interface MyAccountInfo {
  displayName: string;
  email: string;
  phoneNumber?: string;
}

export interface MyUserDetails {
  jwt: string;
  id: number;
  user: Me;
}

