import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ContestStateService } from 'src/app/contest/contest-state.service';
import { StockData } from 'src/app/contest/create-game/create-financial-game/create-financial-game.component';
import { GameService } from 'src/app/contest/game/game.service';
import { Modal } from '../create-financial-contest-modal-create-contest-or-another-game/create-financial-contest-modal-create-contest-or-another-game.component';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import { CreateContestService, GameObject } from '../../create-contest.service';
import { ContestService } from '../../../contest.service';

@Component({
  selector: 'app-create-financial-contest-modal-stock-selection',
  templateUrl: './create-financial-contest-modal-stock-selection.component.html',
  styleUrls: ['./create-financial-contest-modal-stock-selection.component.css']
})
export class CreateFinancialContestModalStockSelectionComponent implements OnInit, Modal {

 constructor(private gameService: GameService,
             private contestStateService: ContestStateService,
             private route: ActivatedRoute, private router: Router,
             private createContestService: CreateContestService, private contestService: ContestService) {}

    nextPage(): void {
      this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockFieldSelection], {relativeTo: this.route});
    }
    previousPage(): void {}
    startPage(): void {
      this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
    }

 ngOnInit(): void {
   this.createContestService.loadFakeStockData();
 }

 get fieldToBeBetOn(): string{
   return this.createContestService.fieldToBetOn;
 }

 get fieldToBeBetOnDescription(): string{
   if (this.createContestService.fieldToBetOn){
       if (this.createContestService.fieldToBetOn === 'Open'){
         return 'Game will conclude at 9:30 AM Eastern time of whatever date is selected for end time';
       }
       if (this.createContestService.fieldToBetOn === 'Close' || this.createContestService.fieldToBetOn === 'Low'
       || this.createContestService.fieldToBetOn === 'High' || this.createContestService.fieldToBetOn === 'Volume'){
         return 'Game will conclude at 4 PM Eastern time of whatever date is selected for end time';
       }

   }
   return '';
 }

 selectCurrentStock(gameObject: GameObject): void{
  this.createContestService.gameObject = gameObject;
  this.createContestService.gameObjectName = gameObject.name;
}

addContestName(contestName): void{
  this.contestStateService.currentContest.name = contestName;
  this.createContestService.contestName = contestName;
  }

 toggleMeridian(): any {
  this.createContestService.meridian = !this.createContestService.meridian;
 }

 canCreateGame(): boolean{
   return this.createContestService.canCreateGame();
 }

 get searchText(): string{
  return this.createContestService.searchText;
 }

 set searchText(searchText: string){
    this.createContestService.searchText = searchText;
 }

 get availableStocks(): GameObject[] {
  // return this.createContestService.availableStocks;
  return this.contestService.contestCreationObject.gameObjectResponseList;

 }

 get games(): any{
   return this.gameService.games;
 }

 get selectedGameObjectName(): string{
  if (this.createContestService.gameObject && this.createContestService.gameObject.name){
    return this.createContestService.gameObject.name;
  }
  return '';
 }

 get contestName(): string{
  return this.createContestService.contestName;
 }
}
