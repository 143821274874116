import { Component, Input, OnInit } from '@angular/core';
import { TopicService } from '../../topic/topic.service';
import { SubtopicService } from '../../topic/subtopic/subtopic.service';
import { Contest, ContestType, TagReference } from '../game/game.service';
import * as routingGlobals from '../../GlobalVars/routingGlobal';
import { Router } from '@angular/router';
import { ContestStateService } from '../contest-state.service';
import { UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Tag } from 'src/app/guru/guru.component';
import { PaginatedContest } from '../../profile/User/user.service';
import { GlobalContestState } from '../../GlobalVars/Enums/ContestEnums';

@Component({
  selector: 'app-view-contests',
  templateUrl: './view-contests.component.html',
  styleUrls: ['./view-contests.component.css']
})
export class ViewContestsComponent implements OnInit {
  private _tagToFilterContestsOn: Tag;
  private _contestTypeToFilterContestsOn: ContestType = ContestType.ALL;
  private _durationTypeToFilterContestsOn: string;
  private _viewableContests: Contest[];

  tagFilterNotActive = true;

  _enterButtonText = 'Enter Contest';



  @Input()
  userId = -1;
  _paginatedContest: PaginatedContest;
  _enterButtonHeaderText: string;
  private _globalContestStateForAPICall: string;
  startingIn: boolean;

  @Input() set
  globalContestStateForAPICall(value: string){
    this._globalContestStateForAPICall = value;

  }

  get globalContestStateForAPICall(): string{
    return this._globalContestStateForAPICall;
  }

  @Input() set
  enterButtonText(value: string){
    this._enterButtonText = value;

  }

  get enterButtonText(): string{
    return this._enterButtonText;
  }


  @Input() set
  enterButtonHeaderText(value: string){
    this._enterButtonHeaderText = value;

  }

  get enterButtonHeaderText(): string{
    return this._enterButtonHeaderText;
  }

  @Input() set
  tag(value: Tag){
    this.tagFilterNotActive = false;
    this._tagToFilterContestsOn = null;
    this._tagToFilterContestsOn = value;
    this._viewableContests = null;
    if (value === null || value === undefined){
      this.tagFilterNotActive = true;
    }
    this.nonEnteredContests();

  }

  get tag(): Tag{
    return this._tagToFilterContestsOn;
  }

  contestTypeFilterNotActive = true;

  @Input() set
  contestType(value: ContestType){
    if (value === ContestType.ALL || value === null || value === undefined){
      this.contestTypeFilterNotActive = true;
      this._viewableContests = null;
      this.nonEnteredContests();
      return;
    }
    this.contestTypeFilterNotActive = false;
    this._contestTypeToFilterContestsOn  = null;
    this._contestTypeToFilterContestsOn = value;
    this._viewableContests = null;

    this.nonEnteredContests();

  }

  get contestType(): ContestType{
    return this._contestTypeToFilterContestsOn;
  }

  @Input() set
  paginatedContest(value: PaginatedContest){
    this._paginatedContest = value;
    this._viewableContests = null;
    this.nonEnteredContests();

  }

  get paginatedContest(): PaginatedContest{
    return this._paginatedContest;
  }

  currentContest: Contest;

  durationTypeFilterNotActive = true;

  @Input() set
  durationType(value: string){
    this.durationTypeFilterNotActive = false;
    this._durationTypeToFilterContestsOn = null;
    this._durationTypeToFilterContestsOn = value;
    this._viewableContests = null;
    if (value === 'ALL' || value === null || value === undefined){
      this.durationTypeFilterNotActive = true;
    }
    this.nonEnteredContests();

  }

  get durationType(): string{
    return this._durationTypeToFilterContestsOn;
  }

  currentDatetime;
  timer$: Observable<number>;
  _isMobile: boolean;
  ContestType = ContestType;
  constructor( private router: Router) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this._isMobile = true;
    }
    else{
      this._isMobile = false;
    }
    console.log('in view contests' + this.paginatedContest._embedded.contestPreviewDTOList + ' after');
    setInterval(() => {
      this.currentDatetime = Date.now();
    }, 1000);
    this._viewableContests = null;
    this.nonEnteredContests();
  }

  nonEnteredContests(): Contest[]{
    if (this.paginatedContest
      && this.paginatedContest._embedded
      && this.paginatedContest._embedded.contestPreviewDTOList){
        // if (this.tag){
          // const filterContestByTagList = [];
          this._viewableContests = [];
          this._viewableContests = this.paginatedContest._embedded.contestPreviewDTOList;
          // this._viewableContests = filterContestByTagList;
          return this._viewableContests;

        // }else {
        //   this._viewableContests = this.contestStateService.currentListOfContestsByTopicAndSubtopic._embedded.contestList;
        //   return this._viewableContests;
        // }
      }
    this._viewableContests = null;
    return this._viewableContests;
  }

  get dateFieldHeaderText(): string{
    switch (this.globalContestStateForAPICall){
      case  'userCompetitorUpcoming':
        this.startingIn = true;
        return 'Starting In';
      case 'userCompetitorPast':
        this.startingIn = false;
        return 'Start/ End';
      case  'userCompetitorLive':
        this.startingIn = false;
        return 'Start/ End';
      default:
        this.startingIn = true;
        return 'Starting In';
    }
  }

  get isMobile(): boolean{
    return this._isMobile;
  }

  get viewableContests(): Contest[]{
    return this._viewableContests;
  }


  viewNonEnteredContest(contest: Contest): void{
    if (this.userId === -1){
      this.router.navigate([routingGlobals.absViewContest, contest.id]);
    }
    else{
      this.router.navigate([routingGlobals.absViewContest, contest.id],  { queryParams: { user: this.userId }});
    }
  }


}
