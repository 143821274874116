<div class="ml-1 mt-4 font-weight-light">
  <div>
    <div class="row">
      <div class="col"></div>

      <table
        class="col-10 table table-striped table-dark mx-1 my-1 table-xs table-hover table-condensed"
      >
        <thead>
          <tr>
            <th scope="col">Topic</th>
            <th scope="col">Subtopic</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ topicName }}</td>
            <td>{{ subtopicName }}</td>
          </tr>
        </tbody>
      </table>
      <div class="col"></div>
    </div>
    <div class="col text-center mb-3" *ngIf="!nameOfContest">
      <p>Name of Contest</p>
      <p>
        <input
          type="text"
          id="event"
          name="nameOfContestInput"
          #nameOfContestInput
        />
      </p>
      <button
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        type="button"
        (click)="updateNameOfContest(nameOfContestInput.value)"
      >
        Enter Contest Name
      </button>
      <p>{{ nameOfContest }}</p>
    </div>
  </div>
</div>

<div class="container ml-1 mt-4 font-weight-light" *ngIf="!!nameOfContest">
  <app-create-financial-game
    *ngIf="isStockMarketTopic()"
  ></app-create-financial-game>

  <div class="row">
    <div class="col text-center">
      <div *ngIf="contestHasGames()">
        <button
          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
          (click)="createContest()"
        >
          Create Contest
        </button>
      </div>
    </div>
  </div>
</div>
