<!DOCTYPE html>
<html>
  <head>
    <title>Privacy Policy</title>
  </head>

  <body>
    <h1>Privacy Policy</h1>
    <p>
      This Privacy Policy ("Policy") outlines the types of personal information
      we collect from users ("you" or "user") and how we use, store, and protect
      that information. By accessing and using our website ("Website"), you
      consent to the terms of this Policy.
    </p>

    <h2>Information Collection and Use</h2>
    <h3>1. Personal Information:</h3>
    <p>
      We may collect certain personally identifiable information, such as your
      name, email address, and contact details, when you voluntarily provide it
      to us through forms or other interactions on the Website.
    </p>

    <h3>2. Non-Personal Information:</h3>
    <p>
      We may also collect non-personal information, such as your browser type,
      device information, and website usage data. This information is used to
      analyze trends, administer the site, track users' movements, and gather
      demographic information for aggregate use.
    </p>

    <h2>Data Usage and Processing</h2>
    <h3>2.1 Purpose:</h3>
    <p>We may use your personal information for the following purposes:</p>
    <ul>
      <li>To provide and personalize our services</li>
      <li>To communicate with you and respond to your inquiries</li>
      <li>To improve our Website and enhance user experience</li>
      <li>To send promotional materials or newsletters with your consent</li>
      <li>To analyze and track usage patterns to improve our services</li>
    </ul>

    <h3>2.2 Third-Party Sharing:</h3>
    <p>
      We do not sell, trade, or transfer your personal information to third
      parties without your explicit consent, except as required by law or as
      necessary to fulfill our services. However, non-personal information may
      be provided to third parties for various purposes, such as analytics or
      advertising.
    </p>

    <h3>2.3 Data Security:</h3>
    <p>
      We implement reasonable security measures to protect your personal
      information from unauthorized access, alteration, disclosure, or
      destruction. However, no data transmission over the internet or electronic
      storage method is 100% secure, and we cannot guarantee absolute security.
    </p>

    <h2>Cookies and Tracking Technologies</h2>
    <p>
      We may use cookies, web beacons, and similar tracking technologies to
      enhance your experience on our Website. These technologies allow us to
      collect information about your browsing activities and provide
      personalized content and advertisements. You may choose to disable cookies
      through your browser settings, although this may limit certain features or
      functionality of the Website.
    </p>

    <h2>Policy Updates</h2>
    <p>
      We reserve the right to update this Policy at any time without prior
      notice. Any changes will be effective immediately upon posting the revised
      Policy on the Website. It is your responsibility to review this Policy
      periodically for any updates or modifications.
    </p>

    <h2>Consent</h2>
    <p>
      By using our Website, you consent to the collection, use, and storage of
      your personal information as described in this Policy.
    </p>

    <h2>Contact Us</h2>
    <p>
      If you have any questions or concerns regarding this Privacy Policy or our
      data practices, please contact us at supportdesk@sigsouk.com
    </p>
  </body>
</html>
