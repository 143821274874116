import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';

@Injectable({
  providedIn: 'root'
})
export class RevenueShareSignupService {

  constructor(private httpClient: HttpClient) { }

  public getPaypalClient(): Observable<any> {
    return this.httpClient.get<any>(HOST_IP_INFO_MARKETS + `/payment/step1/authorize_payment`);
  }
}
