import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GuruService } from './guru.service';
import { guru } from '../GlobalVars/routingGlobal';
import * as routingGlobals from '../GlobalVars/routingGlobal';
import { Links, Page } from '../media/media.component';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from '../Aggregation/subtopic-ranking.service';
import { PaginatedSubtopicRanking, SubtopicRanking } from '../contest/contest.component';
import { TopicService } from '../topic/topic.service';
import { SubtopicService } from '../topic/subtopic/subtopic.service';
import { SubTopicsEntityOrOwnedSubtopicsEntity, TopicsEntity, UserService } from '../profile/User/user.service';
import { Me } from '../profile/Me/me.service';
import { InviteLinkService } from '../shared/invite-link.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { ContestType } from '../contest/game/game.service';

@Component({
  selector: 'app-guru',
  templateUrl: './guru.component.html',
  styleUrls: ['./guru.component.css']
})
export class GuruComponent implements OnInit {

  isMobile: boolean;
  currentTopic: TopicsEntity;
  canExcecuteRankingsNow: boolean;
  private _filteredTagList: Tag[];
  contestTypes = [];
  contestTypesFinance = [ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
  contestTypesSports = [ContestType.MARGIN_OF_VICTORY, ContestType.TOTAL_POINTS_SCORED];
  contestType: string;
  dataBeingViewedString: string;
  private _selectedTag: Tag;
  chosenTimeFrameForWeek: string;
  TimeAndGameDurationPeriodType = TimeAndGameDurationPeriodType;
  yourLink: string;
  minimumArticles = -1;
  minimumContests = -1;
  rankUpperLimit = -1;
  filterRankings: boolean;
  minimumContestThreshold: boolean;
  showTagMappings: boolean;
  viewMoreRankingsToggle = false;
  viewLimit = 25;

  constructor(private guruService: GuruService, private route: ActivatedRoute,
              private subtopicRankingService: SubtopicRankingService, private topicService: TopicService,
              private subtopicService: SubtopicService, private userService: UserService,
              private router: Router, private clipboard: Clipboard,
              private inviteLinkService: InviteLinkService) { }

  ngOnInit(): void {
    this.showTagMappings = false;
    this.route.data.subscribe((results: any) => {
      this.guruService.paginatedTag = results.guruData;
      this.topicService.allTopics = results.topicData;
    });

    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }

    this.topicService.allTopics._embedded.topicDTOList.forEach((topic: TopicsEntity) => {
      if (topic.name === 'Finance'){
        this.selectTopic(topic);
      }
    });

    this.currentTopic.subtopics.forEach((subtopic: SubTopicsEntityOrOwnedSubtopicsEntity) => {
      if (subtopic.name.toUpperCase() === 'STOCKS'){
        this.selectContestType(this.contestTypes[0]);
        this.selectSubtopic(subtopic);
        this.canExcecuteRankingsNow = true;
        this.subtopicSelected();
      }
    });
  }

  toggleTagMappings(): void{
    this.showTagMappings = !this.showTagMappings;
  }

  get searchText(): string{
    return this.guruService.searchText;
   }

   set searchText(searchText: string){
      this.guruService.searchText = searchText;
   }

   get selectedTag(): Tag{
    if (this.guruService.currentTag){
      return this.guruService.currentTag;
    }
    return null;
   }

   selectTag(tag: Tag): void{
    this.guruService.currentTag = tag;
    this._selectedTag = tag;
    this.refreshRankings();
   }

   canCallForRankings(): boolean {
      return !!this.contestType && !!this._selectedTag && !!this.subtopicService.currentSubtopic;
   }

   refreshRankings(): void{
    if (this.canCallForRankings()){
      this.dataBeingViewedString =  this.subtopicService.currentSubtopic.name + '|' + this.selectedTag.name + '|' + this.contestType;
      this.dataBeingViewedString.replace('_', ' ');
      this.dataBeingViewedString.toLowerCase();
    }
  }

  get availableTags(): Tag[]{
    if (this.guruService.paginatedTag && this.guruService.paginatedTag._embedded && this.guruService.paginatedTag._embedded.tagList){
      return this.guruService.paginatedTag._embedded.tagList;
    }
  }

   get filteredTagList(): Tag[]{
    return this._filteredTagList;
  }

  get tagId(): number{
    return this.guruService.currentTag.id;
  }

  get subtopicId(): number{
    return  this.subtopicService.currentSubtopic.id;
  }

  get subtopicName(): string{
    return this.subtopicService.currentSubtopic.name;
  }

  // get contestType(): string{
  //   return  this.contestType;
  // }

  selectTopic(topic: TopicsEntity): void {
    this.topicService.currentTopic = topic;
    this.currentTopic = topic;
    this.selectSubtopic(this.currentTopic.subtopics[0]);
    if (topic.name.toUpperCase() === 'FINANCE'){
      this.contestTypes = this.contestTypesFinance;
    }
    if (topic.name.toUpperCase() === 'SPORTS'){
      this.contestTypes = this.contestTypesSports;
    }

  }

  selectSubtopic(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): void {
    this.subtopicService.currentSubtopic = subtopic;

    this.subtopicRankingService.getAllTagsForSubtopicWithNameMappings(subtopic.id).subscribe(
      (result) => {
        this._filteredTagList = result._embedded.tagList;
        this._filteredTagList.forEach(tag => {
          if (tag.name.toLowerCase() === subtopic.name.toLowerCase()){
            // this.selectTag(this._filteredTagList[0]);
            this.selectTag(tag);
          }
        });
        this._filteredTagList.sort((a, b) => a.id - b.id);
      }

    );
  }

  viewUserProfile(userId: number): void {
    this.userService.getUser(userId).subscribe((user: Me) => {
      this.userService.user = user;
      this.userService.id = user.id;
      this.router.navigate([routingGlobals.absUser, userId]);
    });
  }

  subtopicSelected(): boolean{
    // tslint:disable-next-line:max-line-length
    if ( this.subtopicService.currentSubtopic ){
      return true;
    }
    return false;
  }

  isSelected(subtopic: SubTopicsEntityOrOwnedSubtopicsEntity): boolean {
    return this.subtopicService.currentSubtopic === subtopic;
  }

  isSelectedTag(tag: Tag): boolean {
    return this._selectedTag === tag;
  }

  get currentSubtopic(): SubTopicsEntityOrOwnedSubtopicsEntity{
    if (this.subtopicService.currentSubtopic ){
      return this.subtopicService.currentSubtopic;
    }
    else{
      return null;
    }
  }

  isSelectedContestType(contestType: string): boolean {
    return this.contestType === contestType;
  }

  selectContestType(contestType: string): void {
    this.contestType = contestType;
    this.refreshRankings();
  }

  copyURL(): void{
    console.log('attempt copy to clipboard');
    this.inviteLinkService.getYourInviteLink().subscribe(result => {
      console.log('result is ' + result);
      this.clipboard.copy( location.origin + '/signup?inviter=' + result.message);
      this.yourLink = location.origin + '/signup?inviter=' + result.message;
      console.log('copied to clipboard ONE');
    });
    console.log('copied to clipboard');
  }

  setMinimumArticles(minimumArticles: number): void{
    this.minimumArticles = minimumArticles;
  }

  setMinimumContests(minimumContests: number): void{
    this.minimumContests = minimumContests;
  }

  setRankUpperLimit(rankUpperLimit: number): void{
    this.rankUpperLimit = rankUpperLimit;
  }

  setFilteredFlag(): void{
    this.filterRankings = true;
  }

  setUnfilteredFlag(): void{
    this.filterRankings = false;
    this.minimumArticles = -1;
    this.minimumContests = -1;
    this.rankUpperLimit = -1;
    const temp = this._selectedTag;
    this._selectedTag = null;
    this._selectedTag = temp;
  }

  setUsersWhoMetThresholdFlag(): void{
    this.minimumContestThreshold = true;
  }

  setAllUsersFlag(): void{
    this.minimumContestThreshold = false;
  }

  get topics(): any{
    return this.topicService.allTopics?._embedded?.topicDTOList;
   }

   isSelectedTopic(topic: TopicsEntity): boolean {
    return this.topicService.currentTopic === topic;
  }

  viewMoreRankings(): void{
    this.viewMoreRankingsToggle = true;
    this.viewLimit = 150;
  }

  viewLessRankings(): void{
    this.viewMoreRankingsToggle = false;
    this.viewLimit = 25;
  }

}

export interface Tag{
  id: number;
  name?: string;
  parent?: Tag;
  isAtomicTag?: boolean;
  topicSubtopicEnumPair?: string;
  percentileForSubtopicRanking?: number;
}

export interface EmbeddedTag {
  tagList: Tag[];
}

export interface PaginatedTag{
  _embedded: EmbeddedTag;
  _links: Links;
  page: Page;
}
