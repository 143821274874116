import { Component, Input, OnInit } from '@angular/core';
import { PaginatedSubtopicRanking, SubtopicRanking } from 'src/app/contest/contest.component';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from '../../Aggregation/subtopic-ranking.service';
import { UserService } from 'src/app/profile/User/user.service';
import { Router } from '@angular/router';
import { Me } from 'src/app/profile/Me/me.service';
import * as routingGlobals from '../../GlobalVars/routingGlobal';
import { ContestStateService } from 'src/app/contest/contest-state.service';
import { BetSlipService } from 'src/app/contest/bet-slip.service';
import { UserContestOutcomesService } from 'src/app/profile/User/user-contest-outcomes/user-contest-outcomes.service';
import { UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { NumberFormat } from 'libphonenumber-js/types';

@Component({
  selector: 'app-guru-ranking-timeframe',
  templateUrl: './guru-ranking-timeframe.component.html',
  styleUrls: ['./guru-ranking-timeframe.component.css']
})
export class GuruRankingTimeframeComponent implements OnInit {
  private _timeframeType: TimeAndGameDurationPeriodType;
  private _contestType: string;
  private _subtopicId: number;
  private _tagId: number;
  isMobile: boolean;
  private _tagName: string;
  afterInit = false;
  _contestId: number;
  _subtopicName: string;
  private _rankUpperLimit: number;
  private _minimumContests: number;
  private _minimumArticles: number;
  private _isFiltered = false;
  subtopicRankingsError: any;
  private _viewLimit = 150;

  @Input() set
  viewLimit(value: number){
    this._viewLimit = value;
    if (this.canCallApi()){
      this.subtopicRankingsForTimePeriodType();
    }
  }

  get viewLimit(): number{
    return this._viewLimit;
  }

  @Input() set
  subtopicName(value: string){
    this._subtopicName = value;
  }

  get subtopicName(): string{
    return this._subtopicName;
  }

  @Input() set
  timeframeType(value: TimeAndGameDurationPeriodType){
    this._timeframeType = value;
    if (this.canCallApi()){
      this.subtopicRankingsForTimePeriodType();
    }

  }

  get timeframeType(): TimeAndGameDurationPeriodType{
    return this._timeframeType;
  }

  @Input() set
  contestType(value: string){
    this._contestType = value;
    if (this.canCallApi()){
      this.subtopicRankingsForTimePeriodType();
    }

  }

  get contestType(): string{
    return this._contestType;
  }

  @Input() set
  subtopicId(value: number){
    this._subtopicId = value;
    if (this.canCallApi()){
      this.subtopicRankingsForTimePeriodType();
    }
  }

  get subtopicId(): number{
    return this._subtopicId;
  }

  @Input() set
  tagId(value: number){
    this._tagId = value;
    if (this.canCallApi()){
      this.subtopicRankingsForTimePeriodType();
    }
  }

  get tagId(): number{
    return this._tagId;
  }


  @Input() set
  isFiltered(value: boolean){
    this._isFiltered = value;
    if (this.canCallFilteredApi()){
      this.subtopicRankingsForTimePeriodType();
    }
  }

  get isFiltered(): boolean{
    return this._isFiltered;
  }

  @Input() set
  tagName(value: string){
    this._tagName = value;
  }

  get tagName(): string{
    return this._tagName;
  }


  @Input() set
  contestId(value: number){
    this._contestId = value;
  }

  get contestId(): number{
    return this._contestId;
  }

  @Input() set
  minimumArticles(value: number){
    this._minimumArticles = value;
    if (this.canCallFilteredApi()){
      this.subtopicRankingsForTimePeriodTypeFiltered();
    }
  }

  get minimumArticles(): number{
    return this._minimumArticles;
  }

  @Input() set
  minimumContests(value: number){
    this._minimumContests = value;
    if (this.canCallFilteredApi()){
      this.subtopicRankingsForTimePeriodTypeFiltered();
    }
  }

  get minimumContests(): number{
    return this._minimumContests;
  }

  @Input() set
  rankUpperLimit(value: number){
    this._rankUpperLimit = value;
    if (this.canCallFilteredApi()){
      this.subtopicRankingsForTimePeriodTypeFiltered();
    }
  }

  get rankUpperLimit(): number{
    return this._rankUpperLimit;
  }

  _rankings: PaginatedSubtopicRanking;
  _rankingsList: string[];
  chosenTimeFrame: string;
  userContestState = UserContestState;
  constructor(private subtopicRankingService: SubtopicRankingService, private betslipService: BetSlipService,
              private userService: UserService, private contestStateService: ContestStateService,
              private router: Router, private userContestOutcomesService: UserContestOutcomesService) { }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
      this.viewLimit = 10;
    }
    else{
      this.isMobile = false;
      this.viewLimit = 150;
    }
    this.subtopicRankingsForTimePeriodType();
    this.afterInit = true;
  }

  get rankings(): SubtopicRanking[] {
    if (this._rankings && this._rankings._embedded){
      const tempList = this._rankings._embedded.subtopicRankingDTOList;
      return tempList.slice(0, Math.min(this.viewLimit, tempList.length));
    }
  }

  get rankingsList(): string[] {
    if (this._rankingsList){
      const tempList = this._rankingsList;
      return tempList.slice(0, Math.min(this.viewLimit, tempList.length));
    }
  }

  selectSpecificTimeFrame(timeframe: string): void {
    this.chosenTimeFrame = timeframe;
    this.subtopicRankingsForSpecificTimeFrame(timeframe);
  }

  private subtopicRankingsForSpecificTimeFrame(timeframe: string): void {
    if (!!!this.contestId){
      this.subtopicRankingService.getSubtopicRankingsForTag(this.tagId,
        this.subtopicId, this.contestType, this.timeframeType, timeframe)
        .subscribe(result => {
          this._rankings = result;
        },
          err => {
            this._rankings = null;
            this.subtopicRankingsError = err.error;
        });
    }
    else{
      this.subtopicRankingService.getSubtopicRankingsForTagForContest(this.tagId,
        this.subtopicId, this.contestType, this.timeframeType, this.contestId, timeframe)
        .subscribe(result => {
          if (result && result._embedded && result._embedded.subtopicRankingDTOList && result._embedded.subtopicRankingDTOList.length > 0){
            this._rankings = result;
          }
          else{
            this.subtopicRankingService.getSubtopicRankingsForTag(this.tagId,
              this.subtopicId, this.contestType, this.timeframeType, timeframe)
              .subscribe(resultGuru => {
                this._rankings = resultGuru;
              },
                err => {
                  this._rankings = null;
                  this.subtopicRankingsError = err.error;
              });
          }
        },
          err => {
            this._rankings = null;
            this.subtopicRankingsError = err.error;
        });
    }
  }

  private subtopicRankingsForSpecificTimeFrameFiltered(timeframe: string): void {
    if (!!!this.contestId){
      this.subtopicRankingService.getSubtopicRankingsForTagFiltered(this.tagId,
        this.subtopicId, this.contestType, this.timeframeType, timeframe,
        this._minimumArticles, this._minimumContests, this.rankUpperLimit)
        .subscribe(resultGuru => {
          this._rankings = resultGuru;
        },
          err => {
            this._rankings = null;
        });
    }
    else{
      this.subtopicRankingService.getSubtopicRankingsForTagForContest(this.tagId,
        this.subtopicId, this.contestType, this.timeframeType, this.contestId, timeframe)
        .subscribe(result => {
          if (result && result._embedded && result._embedded.subtopicRankingDTOList && result._embedded.subtopicRankingDTOList.length > 0){
            this._rankings = result;
          }
          else{
            this.subtopicRankingService.getSubtopicRankingsForTagFiltered(this.tagId,
              this.subtopicId, this.contestType, this.timeframeType, timeframe,
              this._minimumArticles, this._minimumContests, this.rankUpperLimit)
              .subscribe(resultGuru => {
                this._rankings = resultGuru;
              },
                err => {
                  this._rankings = null;
              });
          }
        },
          err => {
            this._rankings = null;
        });
    }
  }

  private subtopicRankingsForTimePeriodType(): void {
    this.subtopicRankingsError = '';
    this.subtopicRankingService.getSubtopicRankingsTimeframesForTimeframeType(
      this.subtopicId, this.tagId, this.contestType, this.timeframeType)
      .subscribe((resultList: string[]) => {
        this._rankingsList = [];
        this._rankingsList = resultList;
        let timeframe = 'NONE';
        if (resultList[0]) {
          timeframe = resultList[0];
        }
        this.chosenTimeFrame = timeframe;
        this.subtopicRankingsForSpecificTimeFrame(timeframe);
      },
      err => this.subtopicRankingsError = err.error);
  }

  private subtopicRankingsForTimePeriodTypeFiltered(): void {
    this.subtopicRankingsError = '';
    this.subtopicRankingService.getSubtopicRankingsTimeframesForTimeframeType(
      this.subtopicId, this.tagId, this.contestType, this.timeframeType)
      .subscribe((resultList: string[]) => {
        this._rankingsList = [];
        this._rankingsList = resultList;
        let timeframe = 'NONE';
        if (resultList[0]) {
          timeframe = resultList[0];
        }
        this.chosenTimeFrame = timeframe;
        this.subtopicRankingsForSpecificTimeFrameFiltered(timeframe);
      }
      ,
      err => this.subtopicRankingsError = err.error);
  }

  canCallApi(): boolean{
    return !!this._contestType && !!this._timeframeType && !!this._subtopicId && !!this._tagId && this.afterInit;
  }

  canCallFilteredApi(): boolean{
    return !!this._contestType && !!this._timeframeType && !!this._subtopicId
    && !!this._tagId && this.afterInit && (!!this._rankUpperLimit
    || !!this._minimumContests || !!this._minimumArticles) && this._isFiltered ;
  }

  viewUserProfile(userId: number, username: string): void {
    if (!!!this.contestId){
      this.userService.getUser(userId).subscribe((user: Me) => {
        this.userService.user = user;
        this.userService.id = user.id;
        this.router.navigate([routingGlobals.absUser, userId]);
      });
    }
    else if (!!this.contestId){
      // todo -> uncomment out below line and remove lines after if we just want to scroll to betslip
      // currently not showing betslips to users for other competitors
      // this.viewEnteredContest(userId, username);
      this.userService.getUser(userId).subscribe((user: Me) => {
        this.userService.user = user;
        this.userService.id = user.id;
        this.router.navigate([routingGlobals.absUser, userId]);
      });
    }

  }

  viewEnteredContest(userId: number, username: string): void{
    // this.contestService.currentViewState(ContestOwnershipState.isEntered, ContestActiveState.LIVE, contest);
    // this.contestStateService.viewOtherUsersContestBetSlips(this.contestStateService.currentContest, userId, username);
      this.userService.id = userId;
      // this.userService.user.id = userId;
      this.router.navigate([routingGlobals.absViewContest, this.contestStateService.currentContest.id],
        { queryParams: { user: userId }});
      // this.betslipService.setBetSlipsForContest(this.contestStateService.currentContest, userId, username);

      this.contestStateService.currentContest.userContestState = this.contestStateService.currentContest.userContestState;
      if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
        this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
      }
      this.userContestOutcomesService.
      getContestOutcomesForUserInSingleContest(userId, this.contestStateService.currentContest.id)
      .subscribe(contestOutcome => {
        this.contestStateService.activeContestOutcome = contestOutcome;
      });
  }
}
