<div class="ml-1">
  <form (ngSubmit)="submitUsername(f)" #f="ngForm">
    <div class="form-group" class="mt-2">
      <label for="exampleInputEmail1">Email address</label>
      <input
        type="email"
        name="email_"
        ngModel
        #emailValid="ngModel"
        class="form-control"
        id="email_"
        aria-describedby="emailHelp"
        email
        placeholder="Enter email"
      />
      <small id="emailHelp" class="form-text text-muted"></small>
    </div>
    <div
      *ngIf="
        emailValid.invalid &&
        emailValid.errors.email &&
        (emailValid.dirty || emailValid.touched)
      "
      class="alert alert-danger"
    >
      not a valid email
    </div>

    <div class="col-sm-12 controls">
      <button class="btn btn-success" type="submit" [disabled]="!f.valid">
        Send email to reset password
      </button>
    </div>
    <div *ngIf="!!message">{{ message }}</div>
    <div *ngIf="!!error">{{ error }}</div>
  </form>
</div>
