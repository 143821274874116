import { Component, OnInit } from '@angular/core';
import { InviteLinkService } from '../shared/invite-link.service';
import { MeService } from '../profile/Me/me.service';
import { StorageService } from '../shared/storage.service';
import { InviteeInviter, PaginatedInviterInvitee } from '../profile/User/user.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-shareable-link-page',
  templateUrl: './shareable-link-page.component.html',
  styleUrls: ['./shareable-link-page.component.css']
})
export class ShareableLinkPageComponent implements OnInit {

  public yourLink = '';
  private _invitees: PaginatedInviterInvitee;
  public invitedBy: InviteeInviter;

  constructor(private inviteLinkService: InviteLinkService, private meService: MeService,
              private storageService: StorageService,   private clipboard: Clipboard) { }

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()){
      const myUserDetails =  this.storageService.getUser();
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
      this.copyURL();
      this.retreiveRecentSignups();
      this.retreiveInvitedBy();
    }
    else{
      this.yourLink = 'Not Logged in. Please log in or sign up to get your invite link';
    }
  }

  copyURL(): void {
    console.log('attempt copy to clipboard');
    this.inviteLinkService.getYourInviteLink().subscribe(result => {
      console.log('result is ' + result);
      // this.clipboard.copy( location.origin + '/signup?inviter=' + result.message);
      this.yourLink = location.origin + '/signup?inviter=' + result.message;
      // console.log('copied to clipboard ONE');
    });
    console.log('copied to clipboard');
  }

  copyLink(): void{
    this.clipboard.copy(this.yourLink);
  }

  retreiveRecentSignups(): void {
    console.log('attempt copy to clipboard');
    this.inviteLinkService.getYourRecentSignups(this.meService.id).subscribe(result => {
      console.log('result is ' + result);
      // this.clipboard.copy( location.origin + '/signup?inviter=' + result.message);
      this._invitees = result;
      // console.log('copied to clipboard ONE');
    });
    console.log('copied to clipboard');
  }

  retreiveInvitedBy(): void {
    console.log('attempt copy to clipboard');
    this.inviteLinkService.getInvitedBy(this.meService.id).subscribe(result => {
      console.log('result is ' + result);
      // this.clipboard.copy( location.origin + '/signup?inviter=' + result.message);
      this.invitedBy = result;
      // console.log('copied to clipboard ONE');
    });
    console.log('copied to clipboard');
  }

  get invitees(): InviteeInviter[]{
    if (this._invitees && this._invitees._embedded && this._invitees._embedded.inviteeInviterList){
      return this._invitees._embedded.inviteeInviterList;
    }
  }

}
