import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS, HOST_IP_STATS } from '../GlobalVars/api';
import { HttpClient } from '@angular/common/http';
import { } from './create-game/create-financial-game/create-financial-game.component';
import { Contest, Game, BetSlip, PaginatedBetSlip } from './game/game.service';
import { MeService } from '../profile/Me/me.service';
import { PaginatedContestOutcomeRanking } from './contest.component';


@Injectable({
  providedIn: 'root'
})
export class BetSlipService {


  private paginatedBetSlip: PaginatedBetSlip;

  betslipCurrentUserId: number;
  betSlipcurrentUsername: string;
  isEnteredAndHasBetSlips: boolean;

  constructor(private httpClient: HttpClient, private meService: MeService) { }

  public getUserBetSlips(contestId: number, userId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/user/${userId}/betSlip/contest/${contestId}`);
  }

  public getMyBetSlips(contestId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/me/betSlip/contest/${contestId}`);
  }

  public getUserFinalBetSlips(contestId: number, userId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_STATS + `/user/${userId}/betSlip/contest/${contestId}`);
  }

  public getMyFinalBetSlips(contestId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_STATS + `/me/betSlip/contest/${contestId}`);
  }

  public getContestRankings(contestId: number): Observable<PaginatedContestOutcomeRanking> {
    return this.httpClient.get<PaginatedContestOutcomeRanking>(HOST_IP_STATS + `/contest-outcome/contest/contest-ranking/${contestId}`);
  }

  public getContestRankingsByTagIdAndTimeframeType(contestId: number,
                                                   timeframeType: string, tagId: number): Observable<PaginatedContestOutcomeRanking> {
    return this.httpClient.get<PaginatedContestOutcomeRanking>(HOST_IP_STATS + `/contest-outcome/contest/contest-ranking/${contestId}/timeframeType/${timeframeType}/tagId/${tagId}`);
  }

  setBetSlipsForContest(contest: Contest, userId: number = -1, username: string = ''): void {
    if (userId === -1) {
      this.betSlipcurrentUsername = this.meService.user.username;
      this.betslipCurrentUserId = this.meService.id;
      this.getMyFinalBetSlips(contest.id).subscribe((betSlipsFinal: any) => {
        // todo -> if betslips are empty, populate with empty ones on front end
        if (!!betSlipsFinal?._embedded?.betSlipFinalList) {
          contest.games.forEach((game: Game) => {
            betSlipsFinal._embedded.betSlipFinalList.forEach((betSlip: BetSlip) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          this.isEnteredAndHasBetSlips = true;
        }
        else{
          this.getMyBetSlips(contest.id).subscribe((betSlips: PaginatedBetSlip) => {
            // todo -> if betslips are empty, populate with empty ones on front end
            contest.games.forEach((game: Game) => {
              betSlips._embedded.betSlipList.forEach((betSlip: BetSlip) => {
                if (betSlip.gameId === game.id){
                  game.betSlip = betSlip;
                  return;
                }
              });
            });
            this.isEnteredAndHasBetSlips = true;
          });
        }
      }, err => {
        this.getMyBetSlips(contest.id).subscribe((betSlips: PaginatedBetSlip) => {
          // todo -> if betslips are empty, populate with empty ones on front end
          contest.games.forEach((game: Game) => {
            betSlips._embedded.betSlipList.forEach((betSlip: BetSlip) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          this.isEnteredAndHasBetSlips = true;
        });
      });
    }
    else{
      this.betSlipcurrentUsername = username;
      this.betslipCurrentUserId = userId;
      this.getUserFinalBetSlips(contest.id, userId).subscribe((betSlipsFinal: any) => {
        if (!!betSlipsFinal?._embedded?.betSlipFinalList) {
          contest.games.forEach((game: Game) => {
            // todo -> if betslips are empty, populate with empty ones on front end
            betSlipsFinal._embedded.betSlipFinalList.forEach((betSlip: BetSlip) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          this.isEnteredAndHasBetSlips = true;
        }
        else{
          this.getUserBetSlips(contest.id, userId).subscribe((betSlips: PaginatedBetSlip) => {
            contest.games.forEach((game: Game) => {
              // todo -> if betslips are empty, populate with empty ones on front end
              betSlips._embedded.betSlipList.forEach((betSlip: BetSlip) => {
                if (betSlip.gameId === game.id){
                  game.betSlip = betSlip;
                  return;
                }
              });
            });
            this.isEnteredAndHasBetSlips = true;
          });
        }
      }, err => {
        this.getUserBetSlips(contest.id, userId).subscribe((betSlips: PaginatedBetSlip) => {
          contest.games.forEach((game: Game) => {
            // todo -> if betslips are empty, populate with empty ones on front end
            betSlips._embedded.betSlipList.forEach((betSlip: BetSlip) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          this.isEnteredAndHasBetSlips = true;
        });
      });
    }
  }

  get betSlips(): BetSlip[]{
    return this.paginatedBetSlip._embedded.betSlipList;
  }
}
