'use strict';

export const login = 'login';
export const aboutUs = 'about-us';
export const signup = 'signup';
export const signupPersonal = 'signup-personal';
export const notFound = 'not-found';
export const wildcard = '**';
export const empty = '';
export const signupPaymentInfo = 'signup-payment-info';
export const revenueShareSignup = 'revenue-share-signup';
export const accountSettings = 'account-settings';
export const userAccountSettings = 'account-settings';
export const forgotPassword = 'forgot-password/:id';
export const usernameForPassword = 'usrname-for-password';
export const contest = 'contest';
export const profile = 'profile';
export const viewContest = 'view-contest';
export const topic = 'topic';
export const subtopic = 'subtopic';
export const user = 'user';
export const createFinancialContestModal = 'create-financial-contest-modal';
export const createFinancialContestModalDateSelection = 'create-financial-contest-modal-date-selection';
export const createFinancialContestModalStockSelection = 'create-financial-contest-modal-stock-selection';
export const createContestModalBlankComponent = 'blank-modal';
export const createFinancialContestModalCreateContestOrAnotherGame = 'create-financial-contest-modal-create-contest-or-another-game';
export const createFinancialContestModalReviewAndSubmit = 'create-financial-contest-modal-review-and-submit';
export const createFinancialContestModalStockFieldSelection = 'create-financial-contest-modal-stock-field-selection';
export const createFinancialContestModalStockGameTypeSelection = 'create-financial-contest-modal-stock-game-type-selection';
export const yourContests = 'your-contests';
export const yourSocialLinks = 'your-social-links';
export const yourPosts = 'your-posts';
export const yourBlogs = 'your-blogs';
export const createBlog = 'create-blog';
export const yourSubscriptions = 'your-subscriptions';
export const userContests = 'user-contests';
export const userPosts = 'user-posts';
export const userSocialLinks = 'user-social-links';
export const userBlogs = 'user-blogs';
export const userSubscriptions = 'user-subscriptions';
export const posts = 'posts';
export const blogs = 'blogs';
export const dynamicBlog = 'view-blog';
export const account = 'account';
export const userAccount = 'account';
export const privacyPolicy = 'privacy-policy';
export const connectedAccount = 'connected-account';
export const media = 'media';
export const guru = 'guru';
export const filterGuru = 'filter-guru';
export const shareableLink = 'shareable-link';
export const verifyAccount = 'verify-account';


export const absLogin = `/${login}`;
export const absAboutUs = `/${aboutUs}`;
export const absSignup = `/${signup}`;
export const absSignupPersonal = `/${signupPersonal}`;
export const absNotFound = `/${notFound}`;
export const absAccountSettings = `/${profile}/${accountSettings}`;
export const absSignupPaymentInfo = `/${signupPaymentInfo}`;
export const absForgotPassword = `/${forgotPassword}`;
export const absUsernameForPassword = `/${usernameForPassword}`;
export const absContest = `/${contest}`;
export const absProfile = `/${profile}`;
export const absViewContest = `/${viewContest}`;
export const absTopic = `/${topic}`;
export const abssubtopic = `/${subtopic}`;
export const absUser = `/${user}`;
export const absCreateFinancialContestModal = `/${createFinancialContestModal}`;
export const absCreateFinancialContestModalDateSelection = `/${contest}/${createFinancialContestModalDateSelection}`;
export const absCreateFinancialContestModalStockSelection = `/${contest}/${createFinancialContestModalStockSelection}`;
export const absCreateContestModalBlankComponent = `/${createContestModalBlankComponent}`;
export const absCreateFinancialContestModalCreateContestOrAnotherGame = `/${createContestModalBlankComponent}`;
export const absCreateFinancialContestModalReviewAndSubmit = `/${createContestModalBlankComponent}`;
export const absUserAccount = `/${userAccount}`;
export const absUserAccountSettings = `/${userAccountSettings}`;
export const absPrivacyPolicy =  `/${privacyPolicy}`;
export const absConnectedAccount =  `/${connectedAccount}`;
export const absGuru =  `/${guru}`;
export const absFilterGuru =  `/${filterGuru}`;
export const absShareableLink =  `/${shareableLink}`;
export const absUserSocialLinks =  `/${userSocialLinks}`;
export const absYourSocialLinks =  `/${yourSocialLinks}`;
export const absCreateBlog = `/${createBlog}`;
export const absVerifyAccount = `/${verifyAccount}`;
export const absRevenueShareSignup = `/${revenueShareSignup}`;
