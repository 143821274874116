export enum UserContestState{
  ENTERED_PAST = 'ENTERED_PAST',
  ENTERED_LIVE = 'ENTERED_LIVE',
  ENTERED_UPCOMING = 'ENTERED_UPCOMING',
  NON_ENTERED_PAST = 'NON_ENTERED_PAST',
  NON_ENTERED_LIVE = 'NON_ENTERED_LIVE',
  NON_ENTERED_UPCOMING = 'NON_ENTERED_UPCOMING',
  NON_ENTERED_PAST_OTHER_USER_IS_ENTERED = 'NON_ENTERED_PAST_OTHER_USER_IS_ENTERED',
  NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED = 'NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED',
  NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED = 'NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED',
  CREATING = 'CREATING ',
  NONE = 'NONE'
}

export enum GlobalContestState{
  PAST = 'PAST',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
  CREATE = 'CREATE',
  NOT_ENOUGH_COMPETITORS = 'NOT_ENOUGH_COMPETITORS',
  NONE = 'NONE'
}
