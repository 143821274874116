import { Directive, Input } from '@angular/core';
import {
  ValidatorFn,
  FormControl,
  AbstractControl,
  NG_VALIDATORS,
} from '@angular/forms';

@Directive({
  selector: '[appEmailExistsInList][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailExistsInListDirective,
      multi: true,
    },
  ],
})
export class EmailExistsInListDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('appEmailExistsInList') list: string[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    console.log(control.value);
    let foundMatch = false;
    this.list.forEach((e) => {
      if (e === control.value) {
        foundMatch = true;
        return;
      }
    });
    if (foundMatch) {
      return null;
    }
    return { emailNotFoundInList: 1 };
  }
}
