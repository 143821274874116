<div
  *ngIf="enterExistingContestFlag"
  class="container my-3 font-weight-light text-center"
>
  <p class="font-weight-bold font-italic" *ngIf="userId === -1">
    "Wall Street Bets, The Game" - Some Guy on Twitter
  </p>
  <p class="font-weight-light" *ngIf="userId === -1">
    Make Predictions - Prove You're an Expert - Gain a following in Your Areas
    of Knowledge - To Learn More,
    <a routerLink="/about-us"><b>Click Here</b></a>
  </p>
  <p class="font-weight-light" *ngIf="userId === -1">
    Invite your friends to sign up and be elible to win 100 dollars! (Must use
    below link)
    <button class="btn btn-sm btn-success" (click)="copyURL()">
      <b>Copy Your Link</b>
    </button>
  </p>
  <p *ngIf="yourLink">
    {{ yourLink }}
  </p>
  <p class="text-center font-weight-bold"><u>Select a Topic</u></p>
  <div class="row">
    <div
      *ngFor="let topic of topics; let i = index"
      (click)="selectTopic(topic)"
      class="col-4"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelectedTopic(topic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ topic.name }}
    </div>
  </div>
  <p class="text-center font-weight-bold"><u>Select a Subtopic</u></p>

  <div class="row">
    <div
      *ngFor="let subtopic of currentTopic.subtopics; let i = index"
      (click)="selectSubtopic(subtopic)"
      class="col-4"
      [ngClass]="{
        'font-weight-bold text-success font-italic':
          isSelected(subtopic) === true
      }"
      style="font-size: 12px; cursor: pointer"
    >
      {{ subtopic.name }}
    </div>
  </div>
  <div class="row">
    <div class="col-4 mx-auto text-center">
      <div class="dropdown h-100" class="statusDropdownContainer">
        <a
          class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="text-truncate"
            >Contest Type:
            {{
              selectedContestTypeValue
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}</span
          >
        </a>
        <div
          class="dropdown-menu w-100 pt-0"
          aria-labelledby="dropdownMenuButton"
        >
          <input
            type="text"
            class="w-100 p-2 searchInput"
            [ngModel]="searchContestTypeValue"
            (ngModelChange)="filterContestTypeDropdown($event)"
          />
          <a
            *ngFor="let option of filteredContestTypeList; let i = index"
            class="dropdown-item pointer text-contain"
            [ngClass]="{ alternateBackground: i % 2 == 0 }"
            (click)="selectContestTypeValue(option)"
          >
            {{ option | allCapsUnderscore | lowercase | titlecase }}
          </a>
          <div
            *ngIf="
              !filteredContestTypeList || filteredContestTypeList.length <= 0
            "
            class="text-center text-muted mt-1"
          >
            No Tag found
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 mx-auto text-center">
      <div class="dropdown h-100" class="statusDropdownContainer">
        <a
          class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="text-truncate"
            >Tags:
            {{
              selectedTagValue | allCapsUnderscore | lowercase | titlecase
            }}</span
          >
        </a>
        <div
          class="dropdown-menu w-100 pt-0"
          aria-labelledby="dropdownMenuButton"
        >
          <input
            type="text"
            class="w-100 p-2 searchInput"
            [ngModel]="searchTagValue"
            (ngModelChange)="filterTagDropdown($event)"
          />
          <a
            *ngFor="let option of filteredTagList; let i = index"
            class="dropdown-item pointer text-contain"
            [ngClass]="{ alternateBackground: i % 2 == 0 }"
            (click)="selectTagValue(option)"
          >
            {{ option.name | allCapsUnderscore | lowercase | titlecase }}
          </a>
          <div
            *ngIf="!filteredTagList || filteredTagList.length <= 0"
            class="text-center text-muted mt-1"
          >
            No Tag found
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 mx-auto text-center">
      <div class="dropdown h-100" class="statusDropdownContainer">
        <a
          class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="text-truncate"
            >Names:
            {{
              selectedGameNameValue | allCapsUnderscore | lowercase | titlecase
            }}</span
          >
        </a>
        <div
          class="dropdown-menu w-100 pt-0"
          aria-labelledby="dropdownMenuButton"
        >
          <input
            type="text"
            class="w-100 p-2 searchInput"
            [ngModel]="searchGameNameValue"
            (ngModelChange)="filterGameNameDropdown($event)"
          />
          <a
            *ngFor="let option of filteredGameNameList; let i = index"
            class="dropdown-item pointer text-contain"
            [ngClass]="{ alternateBackground: i % 2 == 0 }"
            (click)="selectGameNameValue(option)"
          >
            {{ option | allCapsUnderscore | lowercase | titlecase }}
          </a>
          <div
            *ngIf="!filteredGameNameList || filteredGameNameList.length <= 0"
            class="text-center text-muted mt-1"
          >
            No Name found
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 mx-auto text-center">
      <div class="dropdown h-100" class="statusDropdownContainer">
        <a
          class="btn btn-white dropdown-toggle mb-2 statusFilterDropdown h-100 flex-middle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="text-truncate"
            >Duration:
            {{
              selectedDurationTypeValue
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}</span
          >
        </a>
        <div
          class="dropdown-menu w-100 pt-0"
          aria-labelledby="dropdownMenuButton"
        >
          <input
            type="text"
            class="w-100 p-2 searchInput"
            [ngModel]="searchDurationTypeValue"
            (ngModelChange)="filterDurationTypeDropdown($event)"
          />
          <a
            *ngFor="let option of filteredDurationTypeList; let i = index"
            class="dropdown-item pointer text-contain"
            [ngClass]="{ alternateBackground: i % 2 == 0 }"
            (click)="selectDurationTypeValue(option)"
          >
            {{ option | allCapsUnderscore | lowercase | titlecase }}
          </a>
          <div
            *ngIf="
              !filteredDurationTypeList || filteredDurationTypeList.length <= 0
            "
            class="text-center text-muted mt-1"
          >
            No Tag found
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

  <div *ngIf="enterExistingContestFlag">
    <app-view-contests
      [contestType]="selectedContestType"
      [durationType]="selectedDurationType"
      [tag]="selectedTag"
      [userId]="userId"
      [paginatedContest]="paginatedContest"
      [enterButtonText]="enterButtonText"
      [enterButtonHeaderText]="enterButtonHeaderText"
      [globalContestStateForAPICall]="globalContestStateForAPICall"
    ></app-view-contests>
    <nav
      class="btn-group ml-4"
      role="group"
      aria-label="Basic example"
      *ngIf="!!pageData"
    >
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.FIRST)"
      >
        First
      </button>
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.PREVIOUS)"
      >
        Previous
      </button>
      <button type="button" class="btn-sm btn-secondary font-weight-light">
        {{ pageData.number + 1 }}
      </button>
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.NEXT)"
      >
        Next
      </button>
      <button
        type="button"
        class="btn-sm btn-secondary font-weight-light"
        (click)="onPaginate(PaginationCommand.LAST)"
      >
        Last &nbsp;[ {{ pageData.totalPages }}]
      </button>
    </nav>
  </div>
  <div class="row">
    <div class="col text-center mb-3"></div>
  </div>
</div>
<div *ngIf="!enterExistingContestFlag">
  <b><u>No Contests/Loading Contests</u></b>
</div>
