import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';

@Component({
  selector: 'app-username-for-password',
  templateUrl: './username-for-password.component.html',
  styleUrls: ['./username-for-password.component.css']
})
export class UsernameForPasswordComponent implements OnInit {

  message = '';
  error = '';

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  submitUsername(form: NgForm): void{
    const email = form.value.email_;
    const data = {username: email};

    this.httpClient.post(HOST_IP_INFO_MARKETS + '/forgotPasswordCreateUrl', data).subscribe((response: any) => {
      this.message = response.message;
    },
    error => {
      console.log(error);
      this.error = error;
    });
  }

}
