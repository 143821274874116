<div *ngIf="!isMobile" class="container-fluid">
  <div class="row">
    <div class="col-lg-8">
      <!-- <p class="font-weight-light">
          Make Predictions - Prove You're an Expert - Gain a following in Your
          Areas of Knowledge - Make Money by having others Subscribe to your
          content. To Learn More,
          <a routerLink="/about-us"><b>Click Here</b></a>
        </p> -->
      <app-view-contests-container
        (selectedASubtopic)="selectSubtopicTrigger($event)"
        (selectedATag)="selectTagTrigger($event)"
        [globalContestStateForAPICall]="'topicAndSubtopic'"
      ></app-view-contests-container>
    </div>
    <div class="col-lg-4">
      <p
        class="container text-center font-weight-bold mt-5 mr-3"
        style="width: 100%"
      >
        Top Apes in {{ currentSubtopic.name }}
      </p>
      <div class="row" *ngIf="!isTopicSelectedSports">
        <div class="col-6">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic':
                contestType === 'MARGIN_OF_ERROR'
            }"
            (click)="flipContestType('MARGIN_OF_ERROR')"
          >
            <p class="text-center">Margin of Error</p>
          </div>
        </div>
        <div class="col-6">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic':
                contestType === 'UNDER_OVER'
            }"
            (click)="flipContestType('UNDER_OVER')"
          >
            <p class="text-center">Under/Over</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isTopicSelectedSports">
        <div class="col-6">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic':
                contestType === 'MARGIN_OF_VICTORY'
            }"
            (click)="flipContestType('MARGIN_OF_VICTORY')"
          >
            <p class="text-center">Margin of Victory</p>
          </div>
        </div>
        <div class="col-6">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic':
                contestType === 'TOTAL_POINTS_SCORED'
            }"
            (click)="flipContestType('TOTAL_POINTS_SCORED')"
          >
            <p class="text-center">Total Points Scored</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic':
                periodType === 'MONTH'
            }"
            (click)="flipPeriodType('MONTH')"
          >
            <p class="text-center">Month</p>
          </div>
        </div>
        <div class="col-6">
          <div
            style="font-size: 10px; cursor: pointer"
            [ngClass]="{
              'font-weight-bold text-success font-italic': periodType === 'YEAR'
            }"
            (click)="flipPeriodType('YEAR')"
          >
            <p class="text-center">Year</p>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="mt-4 col-md-12" *ngIf="!!subtopicRankingsErrorMessage">
          <div
            class="d-flex justify-content-center font-italic font-weight-bold"
            style="font-size: 10px"
          >
            {{
              subtopicRankingsErrorMessage
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}
          </div>
        </div>

        <div *ngIf="subtopicSelected()">
          <div class="d-flex justify-content-center">
            <app-rankings-card
              [subtopicRankings]="subtopicRankings"
              [tag]="tag"
            ></app-rankings-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4" *ngIf="upVotedBlogs" style="height: auto">
      <app-article-holder
        [blogs]="mostViewedBlogs"
        [subtopic]="currentSubtopic"
        [title]="'Most viewed'"
      >
      </app-article-holder>
    </div>
    <div *ngIf="upVotedBlogs" class="col-4">
      <app-article-holder
        [blogs]="upVotedBlogs"
        [subtopic]="currentSubtopic"
        [title]="'Upvoted Content'"
      >
      </app-article-holder>
    </div>
    <div *ngIf="blogs" class="col-4">
      <!-- <app-article-holder
        [blogs]="newBlogs"
        [subtopic]="currentSubtopic"
        [title]="'New Articles'"
      >
      </app-article-holder> -->
      <app-article-holder
        [blogs]="blogs"
        [subtopic]="currentSubtopic"
        [title]="'Featured Articles'"
      >
      </app-article-holder>
    </div>
  </div>
</div>

<!-- 
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
##############
##############
####MOBILE####
##############
##############
-->
<div *ngIf="isMobile">
  <div class="d-flex justify-content-center">
    <div class="text-center my-2 mx-auto">
      <app-view-contests-container
        (selectedASubtopic)="selectSubtopicTrigger($event)"
        (selectedATag)="selectTagTrigger($event)"
        [globalContestStateForAPICall]="'topicAndSubtopic'"
      ></app-view-contests-container>
    </div>
  </div>

  <!-- <div class="row" style="padding-left: 0px">
    <div class="col text-center mb-3">
      <button
              class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
              (click)="createContest()"
            >
              Create Contest
            </button> 
      <button
              class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
              (click)="
                retreiveExistingContestsWithGlobalContestState(
                  GlobalContestState.UPCOMING
                )
              "
            >
              Enter Existing Contest
            </button> 
      <button
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="
          retreiveExistingContestsWithGlobalContestState(
            GlobalContestState.LIVE
          )
        "
      >
        View Live Contests
      </button>
      <button
        class="btn-sm btn-secondary ml-4 mt-1 font-weight-light"
        (click)="
          retreiveExistingContestsWithGlobalContestState(
            GlobalContestState.PAST
          )
        "
      >
        View Recently Completed Contests
      </button>
    </div>
  </div> -->
  <div class="row">
    <div class="col-sm-2"></div>
    <div class="col-sm-4">
      <div *ngIf="!!subtopicRankingsErrorMessage">
        <div class="mt-4 col-md-12" *ngIf="!!subtopicRankingsErrorMessage">
          <div
            class="d-flex justify-content-center font-italic font-weight-bold"
            style="font-size: 10px"
          >
            {{
              subtopicRankingsErrorMessage
                | allCapsUnderscore
                | lowercase
                | titlecase
            }}
          </div>
        </div>
      </div>
      <app-rankings-card [topicRankings]="topicRankings"></app-rankings-card>
    </div>

    <div class="col-sm-2"></div>
  </div>

  <!-- </div> -->
  <div>
    <p class="container text-center font-weight-bold mt-5 mr-3">
      Top Apes in {{ currentSubtopic.name }}
    </p>
    <div class="row" *ngIf="!isTopicSelectedSports">
      <div class="col-6">
        <div
          style="font-size: 10px; cursor: pointer"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              contestType === 'MARGIN_OF_ERROR'
          }"
          (click)="flipContestType('MARGIN_OF_ERROR')"
        >
          <p class="text-center">Margin of Error</p>
        </div>
      </div>
      <div class="col-6">
        <div
          style="font-size: 10px; cursor: pointer"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              contestType === 'UNDER_OVER'
          }"
          (click)="flipContestType('UNDER_OVER')"
        >
          <p class="text-center">Under/Over</p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isTopicSelectedSports">
      <div class="col-6">
        <div
          style="font-size: 10px; cursor: pointer"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              contestType === 'MARGIN_OF_VICTORY'
          }"
          (click)="flipContestType('MARGIN_OF_VICTORY')"
        >
          <p class="text-center">Margin of Victory</p>
        </div>
      </div>
      <div class="col-6">
        <div
          style="font-size: 10px; cursor: pointer"
          [ngClass]="{
            'font-weight-bold text-success font-italic':
              contestType === 'TOTAL_POINTS_SCORED'
          }"
          (click)="flipContestType('TOTAL_POINTS_SCORED')"
        >
          <p class="text-center">Total Points Scored</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div
          style="font-size: 10px; cursor: pointer"
          [ngClass]="{
            'font-weight-bold text-success font-italic': periodType === 'MONTH'
          }"
          (click)="flipPeriodType('MONTH')"
        >
          <p class="text-center">Month</p>
        </div>
      </div>
      <div class="col-6">
        <div
          style="font-size: 10px; cursor: pointer"
          [ngClass]="{
            'font-weight-bold text-success font-italic': periodType === 'YEAR'
          }"
          (click)="flipPeriodType('YEAR')"
        >
          <p class="text-center">Year</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="mt-4 col-md-12" *ngIf="!!subtopicRankingsErrorMessage">
        <div
          class="d-flex justify-content-center font-italic font-weight-bold"
          style="font-size: 10px"
        >
          {{
            subtopicRankingsErrorMessage
              | allCapsUnderscore
              | lowercase
              | titlecase
          }}
        </div>
      </div>
      <div class="mt-4 col-md-12" *ngIf="subtopicSelected()">
        <div class="d-flex justify-content-center">
          <app-rankings-card
            [subtopicRankings]="subtopicRankings"
            [tag]="tag"
          ></app-rankings-card>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <p class="container text-center font-weight-bold">
      Featured Articles in {{ currentSubtopic.name }}
    </p>
    <div class="overflow-scroll bigmike" style="max-height: 900px">
      <div *ngFor="let blog of blogs">
        <div>
          <div class="font-weight-light d-flex justify-content-center">
            <div class="card" style="width: 20rem">
              <div class="card-body">
                <div class="row">
                  <div
                    class="text-center col-12 d-flex justify-content-center font-weight-bold"
                    style="font-size: 16px"
                  >
                    <b
                      class="ql-editor text-center"
                      [innerHTML]="blog.title"
                    ></b>
                  </div>
                </div>
                <div class="row">
                  <span
                    class="col-12 d-flex justify-content-center my-2"
                    style="font-size: 10px; cursor: pointer"
                    (click)="viewUserProfile(blog._owner_id)"
                    ><b>{{ blog.name }}</b
                    >-/s/ {{ blog.subtopicName }} - {{ blog.date | date }}</span
                  >
                </div>
                <div class="row d-flex justify-content-center">
                  <div class="col-2"></div>
                  <div
                    class="col-8 d-flex justify-content-start align-items-start"
                  >
                    <span
                      class="material-icons col-1 px-o"
                      style="font-size: 12px; padding-right: 0; padding-left: 0"
                      >thumb_up_rounded
                    </span>

                    <span
                      class="col-3"
                      style="
                        font-size: 10px;
                        padding-right: 0;
                        padding-bottom: 2px;
                        padding-left: 0;
                      "
                      >{{ blog.upVotes }}</span
                    >

                    <span class="material-icons">favorite_border_rounded</span> -->

  <!-- <span
                      class="material-icons col-1 px-o"
                      style="
                        font-size: 12px;
                        padding-right: 2px;
                        padding-left: 2px;
                      "
                    >
                      remove_red_eye_outlined</span
                    ><span
                      class="col-3"
                      style="
                        padding-right: 2px;
                        padding-left: 2px;
                        font-size: 10px;
                        padding-bottom: 2px;
                      "
                      >{{ blog.viewCount }}</span
                    >

                    <span
                      class="material-icons col-1 px-o"
                      style="
                        font-size: 12px;
                        padding-right: 2px;
                        padding-left: 2px;
                        padding-top: 1px;
                      "
                      >chat_bubble_outline_rounded</span
                    ><span
                      class="col-3"
                      style="
                        padding-right: 0;
                        padding-left: 0px;

                        padding-bottom: 2px;
                        font-size: 10px;
                      "
                      >{{ blog.comments.length }}</span
                    >
                  </div>
                  <div class="col-1"></div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex justify-content-center mt-2">
                    <a
                      class="btn-sm btn-secondary mt-7 font-weight-light"
                      style="font-size: 9px; cursor: pointer"
                      (click)="selectCurrentBlog(blog)"
                      >View
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </div> -->

  <div class="container-fluid">
    <div *ngIf="upVotedBlogs">
      <p class="text-center">Featured Articles in {{ currentSubtopic.name }}</p>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <p class="text-center font-weight-bold"></p>
          <div>
            <div *ngFor="let blog of blogs">
              <div>
                <div class="font-weight-light d-flex justify-content-center">
                  <div class="card" style="width: 100%">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="text-center col-12 d-flex justify-content-center font-weight-bold"
                          style="font-size: 16px"
                        >
                          <b
                            class="ql-editor text-center m-3"
                            [innerHTML]="blog.title"
                          ></b>
                        </div>
                      </div>
                      <div class="row">
                        <span
                          class="col-12 d-flex justify-content-center my-2"
                          style="font-size: 10px; cursor: pointer"
                          (click)="viewUserProfile(blog._owner_id)"
                          ><b>{{ blog.name }}</b
                          >-/s/ {{ blog.subtopicName }} -
                          {{ blog.date | date }}</span
                        >
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-2"></div>
                        <div
                          class="col-10 d-flex justify-content-start align-items-start"
                        >
                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 0;
                              padding-left: 0;
                            "
                            >thumb_up_rounded
                          </span>

                          <span
                            class="col-2"
                            style="
                              font-size: 10px;
                              padding-right: 0;
                              padding-bottom: 2px;
                              padding-left: 0;
                            "
                            >{{ blog.upVotes }}</span
                          >

                          <!-- <span class="material-icons">favorite_border_rounded</span> -->

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                            "
                          >
                            remove_red_eye_outlined</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 2px;
                              padding-left: 2px;
                              font-size: 10px;
                              padding-bottom: 2px;
                            "
                            >{{ blog.pageVisits }}</span
                          >

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >chat_bubble_outline_rounded</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                          ></span>

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >pageview</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.loggedInUserViews }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-center mt-2">
                          <a
                            class="btn-sm btn-secondary mt-7 font-weight-light"
                            style="font-size: 9px; cursor: pointer"
                            (click)="selectCurrentBlog(blog)"
                            >View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="upVotedBlogs">
      <p class="text-center">Most Viewed in {{ currentSubtopic.name }}</p>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <p class="text-center font-weight-bold"></p>
          <div>
            <div *ngFor="let blog of mostViewedBlogs">
              <div>
                <div class="font-weight-light d-flex justify-content-center">
                  <div class="card" style="width: 100%">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="text-center col-12 d-flex justify-content-center font-weight-bold"
                          style="font-size: 16px"
                        >
                          <b
                            class="ql-editor text-center m-3"
                            [innerHTML]="blog.title"
                          ></b>
                        </div>
                      </div>
                      <div class="row">
                        <span
                          class="col-12 d-flex justify-content-center my-2"
                          style="font-size: 10px; cursor: pointer"
                          (click)="viewUserProfile(blog._owner_id)"
                          ><b>{{ blog.name }}</b
                          >-/s/ {{ blog.subtopicName }} -
                          {{ blog.date | date }}</span
                        >
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-2"></div>
                        <div
                          class="col-10 d-flex justify-content-start align-items-start"
                        >
                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 0;
                              padding-left: 0;
                            "
                            >thumb_up_rounded
                          </span>

                          <span
                            class="col-2"
                            style="
                              font-size: 10px;
                              padding-right: 0;
                              padding-bottom: 2px;
                              padding-left: 0;
                            "
                            >{{ blog.upVotes }}</span
                          >

                          <!-- <span class="material-icons">favorite_border_rounded</span> -->

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                            "
                          >
                            remove_red_eye_outlined</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 2px;
                              padding-left: 2px;
                              font-size: 10px;
                              padding-bottom: 2px;
                            "
                            >{{ blog.pageVisits }}
                          </span>

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >chat_bubble_outline_rounded</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.commentCount }}</span
                          >

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >pageview</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.loggedInUserViews }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-center mt-2">
                          <a
                            class="btn-sm btn-secondary mt-7 font-weight-light"
                            style="font-size: 9px; cursor: pointer"
                            (click)="selectCurrentBlog(blog)"
                            >View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="upVotedBlogs">
      <p class="text-center">Upvoted content in {{ currentSubtopic.name }}</p>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <p class="text-center font-weight-bold"></p>
          <div>
            <div *ngFor="let blog of upVotedBlogs">
              <div>
                <div class="font-weight-light d-flex justify-content-center">
                  <div class="card" style="width: 100%">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="text-center col-12 d-flex justify-content-center font-weight-bold"
                          style="font-size: 16px"
                        >
                          <b
                            class="ql-editor text-center m-3"
                            [innerHTML]="blog.title"
                          ></b>
                        </div>
                      </div>
                      <div class="row">
                        <span
                          class="col-12 d-flex justify-content-center my-2"
                          style="font-size: 10px; cursor: pointer"
                          (click)="viewUserProfile(blog._owner_id)"
                          ><b>{{ blog.name }}</b
                          >-/s/ {{ blog.subtopicName }} -
                          {{ blog.date | date }}</span
                        >
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-2"></div>
                        <div
                          class="col-10 d-flex justify-content-start align-items-start"
                        >
                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 0;
                              padding-left: 0;
                            "
                            >thumb_up_rounded
                          </span>

                          <span
                            class="col-2"
                            style="
                              font-size: 10px;
                              padding-right: 0;
                              padding-bottom: 2px;
                              padding-left: 0;
                            "
                            >{{ blog.upVotes }}</span
                          >

                          <!-- <span class="material-icons">favorite_border_rounded</span> -->

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                            "
                          >
                            remove_red_eye_outlined</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 2px;
                              padding-left: 2px;
                              font-size: 10px;
                              padding-bottom: 2px;
                            "
                            >{{ blog.pageVisits }}
                          </span>

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >chat_bubble_outline_rounded</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.commentCount }}</span
                          >

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >pageview</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.loggedInUserViews }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-center mt-2">
                          <a
                            class="btn-sm btn-secondary mt-7 font-weight-light"
                            style="font-size: 9px; cursor: pointer"
                            (click)="selectCurrentBlog(blog)"
                            >View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="newBlogs">
      <p class="text-center">New Articles in {{ currentSubtopic.name }}</p>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <p class="text-center font-weight-bold"></p>
          <div>
            <div *ngFor="let blog of newBlogs">
              <div>
                <div class="font-weight-light d-flex justify-content-center">
                  <div class="card" style="width: 100%">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="text-center col-12 d-flex justify-content-center font-weight-bold"
                          style="font-size: 16px"
                        >
                          <b
                            class="ql-editor text-center m-3"
                            [innerHTML]="blog.title"
                          ></b>
                        </div>
                      </div>
                      <div class="row">
                        <span
                          class="col-12 d-flex justify-content-center my-2"
                          style="font-size: 10px; cursor: pointer"
                          (click)="viewUserProfile(blog._owner_id)"
                          ><b>{{ blog.name }}</b
                          >-/s/ {{ blog.subtopicName }} -
                          {{ blog.date | date }}</span
                        >
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-2"></div>
                        <div
                          class="col-10 d-flex justify-content-start align-items-start"
                        >
                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 0;
                              padding-left: 0;
                            "
                            >thumb_up_rounded
                          </span>

                          <span
                            class="col-2"
                            style="
                              font-size: 10px;
                              padding-right: 0;
                              padding-bottom: 2px;
                              padding-left: 0;
                            "
                            >{{ blog.upVotes }}</span
                          >

                          <!-- <span class="material-icons">favorite_border_rounded</span> -->

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                            "
                          >
                            remove_red_eye_outlined</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 2px;
                              padding-left: 2px;
                              font-size: 10px;
                              padding-bottom: 2px;
                            "
                            >{{ blog.pageVisits }}
                          </span>

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >chat_bubble_outline_rounded</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.commentCount }}</span
                          >

                          <span
                            class="material-icons col-1 px-o"
                            style="
                              font-size: 12px;
                              padding-right: 2px;
                              padding-left: 2px;
                              padding-top: 1px;
                            "
                            >pageview</span
                          ><span
                            class="col-2"
                            style="
                              padding-right: 0;
                              padding-left: 0px;

                              padding-bottom: 2px;
                              font-size: 10px;
                            "
                            >{{ blog.loggedInUserViews }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-center mt-2">
                          <a
                            class="btn-sm btn-secondary mt-7 font-weight-light"
                            style="font-size: 9px; cursor: pointer"
                            (click)="selectCurrentBlog(blog)"
                            >View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
