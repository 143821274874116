<div
  class="font-weight-light"
  [ngClass]="{
    'container-fluid': !isMobile
  }"
>
  <div
    [ngClass]="{
      row: !isMobile
    }"
  >
    <div
      [ngClass]="{
        'col-lg-5': !isMobile,
        'd-flex justify-content-center text-center my-2 mx-auto': isMobile
      }"
    >
      <div
        *ngIf="
          !canSubmitContestEntry() &&
          currentContest.globalContestState === GlobalContestState.PAST
        "
      >
        <div *ngIf="numberOfUsers == 0" class="text-center">View Gurus</div>
        <div *ngIf="numberOfUsers > 0" class="text-center">
          View Gurus
          <!-- Entered in this Contest -->
        </div>
        <div class="row">
          <div class="col-6">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic':
                  timeAndGameDurationPeriodType ===
                  TimeAndGameDurationPeriodType.MONTH
              }"
              (click)="
                flipTimeframeTypeAndToggleShow(
                  TimeAndGameDurationPeriodType.MONTH
                )
              "
            >
              <p class="text-center">Month</p>
            </div>
          </div>
          <div class="col-6">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic':
                  timeAndGameDurationPeriodType ===
                  TimeAndGameDurationPeriodType.YEAR
              }"
              (click)="
                flipTimeframeTypeAndToggleShow(
                  TimeAndGameDurationPeriodType.YEAR
                )
              "
            >
              <p class="text-center">Year</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6" *ngFor="let tag of tags">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic': tag === selectedTag
              }"
              (click)="selectTagAndToggleShow(tag)"
            >
              <p class="text-center">{{ tag.name }}</p>
            </div>
          </div>
        </div>
        <app-rankings-card
          *ngIf="showRankings"
          [contestRankings]="rankings"
          [tag]="selectedTag"
          [timeframeType]="timeAndGameDurationPeriodType"
          [isRefresh]="isRefresh"
          [viewLimit]="viewLimit"
          (selectedContestOutcomes)="
            selectedContestOutcomesTrigger($event, target)
          "
        ></app-rankings-card>
        <button
          class="btn-xs btn-info text-center"
          style="font-size: 8.8px"
          *ngIf="!viewMoreRankingsToggle && isMobile"
          (click)="viewMoreRankings()"
        >
          View More Rankings!
        </button>
        <button
          class="btn-xs btn-info text-center"
          style="font-size: 8.8px"
          *ngIf="viewMoreRankingsToggle && isMobile"
          (click)="viewLessRankings()"
        >
          View Less Rankings!
        </button>
      </div>

      <div *ngIf="!contestOutcomesValid">
        <div *ngIf="numberOfUsers == 0" class="text-center">View Gurus</div>
        <div *ngIf="numberOfUsers > 0" class="text-center">
          View Gurus
          <!--  Entered in this Contest -->
        </div>
        <div class="row">
          <div class="col-6">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic':
                  timeAndGameDurationPeriodType ===
                  TimeAndGameDurationPeriodType.MONTH
              }"
              (click)="flipTimeframeType(TimeAndGameDurationPeriodType.MONTH)"
            >
              <p class="text-center">Month</p>
            </div>
          </div>
          <div class="col-6">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic':
                  timeAndGameDurationPeriodType ===
                  TimeAndGameDurationPeriodType.YEAR
              }"
              (click)="flipTimeframeType(TimeAndGameDurationPeriodType.YEAR)"
            >
              <p class="text-center">Year</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6" *ngFor="let tag of tags">
            <div
              style="font-size: 10px; cursor: pointer"
              [ngClass]="{
                'font-weight-bold text-success font-italic': tag === selectedTag
              }"
              (click)="selectTag(tag)"
            >
              <p class="text-center">{{ tag.name }}</p>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            'container-fluid': !isMobile
          }"
        >
          <div class="text-center my-2 mx-auto">
            <app-guru-ranking-timeframe
              (click)="scrollTo(target)"
              style="width: 100%"
              [timeframeType]="timeAndGameDurationPeriodType"
              [contestType]="contestType"
              [tagId]="tagId"
              [tagName]="tagName"
              [subtopicName]="subtopicName"
              [subtopicId]="subtopicId"
              [contestId]="contestId"
              [viewLimit]="viewLimit"
            >
            </app-guru-ranking-timeframe>
            <button
              class="btn-xs btn-info text-center"
              style="font-size: 8.8px"
              *ngIf="!viewMoreRankingsToggle && isMobile"
              (click)="viewMoreRankings()"
            >
              View More Rankings!
            </button>
            <button
              class="btn-xs btn-info text-center"
              style="font-size: 8.8px"
              *ngIf="viewMoreRankingsToggle && isMobile"
              (click)="viewLessRankings()"
            >
              View Less Rankings!
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="row">
        <div class="col-lg-3"></div>

        <div class="col-lg-6">
          <div
            *ngIf="
              currentContest.globalContestState ===
              GlobalContestState.NOT_ENOUGH_COMPETITORS
            "
            class="text-center bg-danger font-italic font-weight-bold"
            style="font-size: 15px"
          >
            <b>Contest has been cancelled. Not enough users have entered</b>
          </div>
          <div
            *ngIf="
              currentContest.globalContestState ===
              GlobalContestState.NOT_ENOUGH_COMPETITORS
            "
            class="text-center bg-danger font-italic font-weight-bold"
            style="font-size: 15px"
          >
            (3+ Users are needed per Contest Currently)
          </div>
          <div class="card card-margin table-xs table-hover table-condensed">
            <div class="card-header no-border">
              <h5
                *ngIf="isEditable"
                class="card-title text-center"
                contenteditable="true"
                [textContent]="currentContest.name | allCapsUnderscore"
                (keyup)="setContestName($event.target.textContent)"
              >
                {{ currentContest.name | allCapsUnderscore }}
              </h5>
              <h5 *ngIf="!isEditable" class="card-title text-center">
                {{ currentContest.name | allCapsUnderscore }}
              </h5>
            </div>
            <div class="card-body pt-0">
              <div class="widget-49">
                <div class="widget-49-title-wrapper">
                  <div class="widget-49-date-primary">
                    <span class="widget-49-date-day">{{
                      startDate | date : "d"
                    }}</span>
                    <span class="widget-49-date-month">{{
                      startDate | date : "MMM"
                    }}</span>
                  </div>
                  <div class="widget-49-meeting-info">
                    <span
                      *ngIf="!!betSlipUsername"
                      style="font-size: 12; cursor: pointer"
                      (click)="viewUserProfile(betSlipUserId)"
                      class="text-info font-weight-bold"
                      ><u>{{ betSlipUsername }}</u></span
                    >
                    <span class="widget-49-pro-title"
                      >{{ contestTopic.name }} -
                      {{ contestSubtopic.name }}</span
                    >
                    <span class="widget-49-pro-title">
                      Multiplier:
                      {{ currentContest.k_factor }}</span
                    >

                    <!-- <span
                      class="widget-49-meeting-time font-italic font-weight-bold"
                      style="color: black"
                      ><b
                        ><u
                          >{{ startTime | date : "medium" }} to
                          {{ endTime | date : "medium" }}</u
                        ></b
                      ></span
                    > -->
                    <!-- <span *ngIf="!isEditable" class="widget-49-meeting-time"
                      >Token Fee: {{ entryFee | number : "1.2-2" }}
                    </span>
                    <span *ngIf="!isEditable" class="widget-49-meeting-time"
                      >Current Token Pool: {{ prizePool | number : "1.2-2" }}
                    </span> -->
                    <span *ngIf="!isEditable" class="widget-49-meeting-time"
                      >Available Slots: {{ availableSlots }}
                    </span>
                    <span *ngIf="!isEditable" class="widget-49-meeting-time"
                      >Current entries: {{ numberOfUsers }}
                    </span>

                    <span *ngIf="isEditable" class="widget-49-meeting-time"
                      >Max Entries:
                      <span
                        contenteditable="true"
                        [textContent]="maxEntries"
                        (keyup)="setMaxEntries($event.target.textContent)"
                      ></span>
                    </span>
                  </div>
                </div>

                <app-enter-contest-modal></app-enter-contest-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>
      <div
        [ngClass]="{
          'container-fluid': !isMobile
        }"
      >
        <div class="row">
          <div class="col-md-12">
            <p
              class="widget-49-meeting-time font-weight-bold text-center"
              style="color: black"
            >
              <b
                ><u
                  >{{ startTime | date : "medium" }} to
                  {{ endTime | date : "medium" }}</u
                ></b
              >
            </p>
          </div>
        </div>
        <div *ngIf="!isSubscriptionOnly" class="row">
          <div class="col-md-12">
            <p
              class="widget-49-meeting-time font-weight-bold text-center"
              style="color: black"
            >
              <b> Open to All Logged In Users </b>
            </p>
          </div>
        </div>

        <div *ngIf="isSubscriptionOnly" class="row">
          <div class="col-md-12">
            <p
              class="widget-49-meeting-time font-weight-bold text-center"
              style="color: black"
            >
              <b> Open to Subscribers Only </b>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p
              *ngIf="!!betSlipUsername"
              style="font-size: 12; cursor: pointer"
              (click)="viewUserProfile(betSlipUserId)"
              class="text-info font-weight-bold text-center"
            >
              <u>{{ betSlipUsername }}</u>
            </p>
          </div>
        </div>
        <div
          *ngIf="
            currentContest.globalContestState ===
            GlobalContestState.NOT_ENOUGH_COMPETITORS
          "
          class="text-center bg-danger font-italic font-weight-bold"
          style="font-size: 15px"
        >
          <b>Contest has been cancelled. Not enough users have entered</b>
        </div>
        <div
          *ngIf="
            currentContest.globalContestState ===
            GlobalContestState.NOT_ENOUGH_COMPETITORS
          "
          class="text-center bg-danger font-italic font-weight-bold"
          style="font-size: 15px"
        >
          (3+ Users are needed per Contest Currently)
        </div>
        <div class="row">
          <div
            class="col-md-12"
            #contestOutcomeContainerPound
            id="contestOutcomeContainer"
          >
            <p class="text-center" *ngIf="activeContestOutcome">
              {{ activeContestOutcome.username }}'s Contest Outcome
            </p>
            <div
              *ngIf="activeContestOutcome"
              [ngClass]="{
                'd-flex justify-content-center text-center my-2 mx-auto':
                  isMobile
              }"
            >
              <table
                style="width: 100%"
                class="table table-striped table-dark table-xs table-hover table-condensed"
              >
                <thead>
                  <th>Username</th>
                  <th>Ranking</th>
                  <th
                    *ngIf="
                      currentContest.contestType === ContestType.MARGIN_OF_ERROR
                    "
                  >
                    Avg MOE
                  </th>
                  <th
                    *ngIf="
                      currentContest.contestType ===
                      ContestType.MARGIN_OF_VICTORY
                    "
                  >
                    Avg MOV
                  </th>
                  <th
                    *ngIf="
                      currentContest.contestType ===
                      ContestType.TOTAL_POINTS_SCORED
                    "
                  >
                    Avg TPS
                  </th>
                  <th
                    *ngIf="
                      currentContest.contestType ===
                        ContestType.MARGIN_OF_ERROR ||
                      currentContest.contestType ===
                        ContestType.MARGIN_OF_VICTORY ||
                      currentContest.contestType ===
                        ContestType.TOTAL_POINTS_SCORED
                    "
                  >
                    <!-- Total MOE -->
                    % Rank
                  </th>
                  <th
                    *ngIf="
                      currentContest.contestType === ContestType.UNDER_OVER
                    "
                  >
                    Game W/L
                  </th>
                  <th>Elo</th>
                  <th>H2H W/L/D</th>
                </thead>
                <tbody>
                  <td>
                    {{ activeContestOutcome.username }}
                  </td>
                  <td>
                    {{ activeContestOutcome.ranking }}
                  </td>
                  <td
                    *ngIf="
                      currentContest.contestType ===
                        ContestType.MARGIN_OF_ERROR ||
                      currentContest.contestType ===
                        ContestType.MARGIN_OF_VICTORY ||
                      currentContest.contestType ===
                        ContestType.TOTAL_POINTS_SCORED
                    "
                  >
                    {{
                      activeContestOutcome.avgMarginOfError | number : "1.2-4"
                    }}
                  </td>
                  <td
                    *ngIf="
                      currentContest.contestType ===
                        ContestType.MARGIN_OF_ERROR ||
                      currentContest.contestType ===
                        ContestType.MARGIN_OF_VICTORY ||
                      currentContest.contestType ===
                        ContestType.TOTAL_POINTS_SCORED
                    "
                  >
                    <!-- {{
                      activeContestOutcome.totalMarginOfError | number : "1.2-4"
                    }} -->
                    {{ activeContestOutcome.percentileRank | number : "1.2-4" }}
                  </td>
                  <td
                    *ngIf="
                      currentContest.contestType === ContestType.UNDER_OVER
                    "
                  >
                    {{ activeContestOutcome.totalGameWins }}/{{
                      activeContestOutcome.totalGameLosses
                    }}
                  </td>
                  <td>
                    {{ activeContestOutcome.eloValue | number : "1.2-4" }}
                  </td>
                  <td>
                    {{ activeContestOutcome.totalHeadToHeadWins }}/{{
                      activeContestOutcome.totalHeadToHeadLosses
                    }}/{{ activeContestOutcome.totalHeadToHeadDraws }}
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          [ngClass]="{
            row: !isMobile,
            'd-flex justify-content-center': isMobile
          }"
        >
          <div
            [ngClass]="{
              'col-md-12': !isMobile,
              'text-center my-2 mx-auto': isMobile
            }"
          >
            <div
              *ngIf="
                currentContest.globalContestState ===
                  GlobalContestState.UPCOMING &&
                currentContest.contestType === ContestType.TOTAL_POINTS_SCORED
              "
            >
              <app-total-points-scored-upcoming
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-total-points-scored-upcoming>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.LIVE &&
                currentContest.contestType === ContestType.TOTAL_POINTS_SCORED
              "
            >
              <app-total-points-scored-live
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-total-points-scored-live>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.PAST &&
                currentContest.contestType === ContestType.TOTAL_POINTS_SCORED
              "
            >
              <app-total-points-scored-past
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-total-points-scored-past>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState ===
                  GlobalContestState.UPCOMING &&
                currentContest.contestType === ContestType.MARGIN_OF_VICTORY
              "
            >
              <app-margin-of-victory-upcoming
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-margin-of-victory-upcoming>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.LIVE &&
                currentContest.contestType === ContestType.MARGIN_OF_VICTORY
              "
            >
              <app-margin-of-victory-live
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-margin-of-victory-live>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.PAST &&
                currentContest.contestType === ContestType.MARGIN_OF_VICTORY
              "
            >
              <app-margin-of-victory-past
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-margin-of-victory-past>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState ===
                  GlobalContestState.UPCOMING &&
                currentContest.contestType === ContestType.MARGIN_OF_ERROR
              "
            >
              <app-moe-upcoming
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-moe-upcoming>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.LIVE &&
                currentContest.contestType === ContestType.MARGIN_OF_ERROR
              "
            >
              <app-moe-live
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-moe-live>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.PAST &&
                currentContest.contestType === ContestType.MARGIN_OF_ERROR
              "
            >
              <app-moe-past
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-moe-past>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState ===
                  GlobalContestState.UPCOMING &&
                currentContest.contestType === ContestType.UNDER_OVER
              "
            >
              <app-under-over-upcoming
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-under-over-upcoming>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.LIVE &&
                currentContest.contestType === ContestType.UNDER_OVER
              "
            >
              <app-under-over-live
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-under-over-live>
            </div>
            <div
              *ngIf="
                currentContest.globalContestState === GlobalContestState.PAST &&
                currentContest.contestType === ContestType.UNDER_OVER
              "
            >
              <app-under-over-past
                [containsOtherUserBetslip]="containsOtherUserBetslip"
                [games]="games"
                [isMe]="isMe"
              ></app-under-over-past>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
