import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StockData } from '../create-game/create-financial-game/create-financial-game.component';
import { TopicService } from '../../topic/topic.service';
import { SubtopicService } from '../../topic/subtopic/subtopic.service';

@Injectable({
  providedIn: 'root'
})
export class CreateContestService {
  // todo
  /*
    // this.httpClient.get
   // ('https://api.polygon.io/v1/open-close/SPY/2020-10-13?adjusted=true&apiKey=satA4eyPu6b5RoLHOctuGa6yU8uFJk6b')
   // .subscribe((data: StockData) => {
   //   this.selectedStock = data;
   // });
   // this.selectedStock = stock1;
  */
  contestName: string;
  gameObject: GameObject;
  gameObjectName: string;
  availableStocks: StockData[] = [];
  betSlip: any;
  bettingValue = 0;
  fieldToBetOn = '';
  currentValueOfField = 0;
  searchText = '';
  currentGameType: any;
  currentDate = new Date();
  gameTypes = [ {type: 'StraightSelection', name: 'StraightSelectionGame',
  description: 'select a field to compete on. Then select under or over with a start and end time'},
  {type: 'MarginOfError', name: 'MarginOfErrorGame',
  description: 'select a field to compete on. Then guess a future value and a time when the game will finsish. Weight will always be 1'},
  {type: 'WeightedMarginOfError',  name: 'WeightedMarginOfErrorGame',
   description: 'Same as margin of error, but you can assign a ' +
  'weight of any value to this guess Ie make this game worth 2x or.5x?? should user be allowed to assign own weight in series of picks? I think so... add that in later'},
  {type: 'WeightedStraightSelection', name: 'WeightedStraightSelectionGame', description: 'self explanatory'}];
  endModel: NgbDateStruct;
  startModel: NgbDateStruct;
  endDate;
  startDate;
  today = this.calendar.getToday();
  tomorrow = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
  time = {hour: new Date().getHours() + 12, minute: 30};
  meridian = true;
  isDateSelection = false;
  isStockSelection = false;
  isNaming = false;

  contestCreationObject: ContestCreationObject;

  constructor(private calendar: NgbCalendar, private httpClient: HttpClient,
              private topicService: TopicService, private subtopicService: SubtopicService) { }

  loadFakeStockData(): void{
    const stock1 = {
      afterHours: 322.1,
      close: 325.12,
      high: 326.2,
      low: 322.3,
      open: 324.66,
      preMarket: 324.5,
      status: 'OK',
      symbol: 'AAPL',
      volume: 26122646
     };
    const stock2 = {
      afterHours: 26.93,
      close: 26.93,
      high: 26.93,
      low: 26.93,
      open: 26.93,
      preMarket: 26.93,
      status: 'OK',
      symbol: 'FSSNX',
      volume: 26122646
     };
    const stock3 = {
      afterHours: 467.90,
      close: 	467.61,
      high: 	472.87,
      low: 322.3,
      open: 	472.60,
      preMarket: 467.90,
      status: 'OK',
      symbol: 'SPY',
      volume: 2000000
     };
    const stock4 = {
      afterHours: 322.1,
      close: 325.96,
      high: 325.96,
      low: 322.3,
      open: 320.06,
      preMarket: 324.5,
      status: 'OK',
      symbol: 'MSFT',
      volume: 500000
     };
    this.availableStocks = [];
    this.availableStocks.push(stock1);
    this.availableStocks.push(stock2);
    this.availableStocks.push(stock3);
    this.availableStocks.push(stock4);
  }


  createDate(model: NgbDateStruct, isEndDate: boolean): Date{
    const date = new Date();
    date.setFullYear(model.year, model.month, model.day);
    if (isEndDate){
      if (this.fieldToBetOn === 'Open'){
        date.setHours(9, 30, 0, 0);
      }
      else{
        date.setHours(16, 0, 0, 0);
      }
    }
    else{
      date.setHours(this.time.hour, this.time.minute, 0, 0);
    }

    if (isEndDate){
      this.endDate = date;
    }
    else{
      this.startDate = date;
    }
    return date;
  }

  setEvents(gameType): void{
    if (gameType.type === 'StraightSelection'){
      this.betSlip = {over: false, under: false, bettingValue: this.bettingValue, weight: 0};
    }
    else  if (gameType.type === 'MarginOfError'){
      this.betSlip = {userGuess: 0, weight: 0};
    }
    else  if (gameType.type === 'WeightedMarginOfError'){
      this.betSlip = {userGuess: 0, weight: 0};
    }
    else  if (gameType.type === 'WeightedStraightSelection'){
      this.betSlip = {over: false, under: false, bettingValue: this.bettingValue, weight: 0};
    }
  }

  canCreateGame(): boolean{
    return !!(this.startModel && this.endModel
      && this.currentGameType.name && this.gameObjectName
              && this.betSlip && this.currentGameType);
  }


}

export interface GameObject {
  fieldValueMap: [Map<string, number>];
  name: string;
}

export interface ContestCreationObject {
  fieldsToBeBetOn: string[];
  gameTypes: string[];
  availableStartTimes: string[];
  availableEndTimes: string[];
}

export interface ContestCreationObject {
  gameObjectResponseList: GameObject[];
  contestCreationObject: ContestCreationObject;
}
