<div class="ml-1 mt-4 font-weight-light">
  <div class="container ml-1 mt-4 font-weight-light">
    <div class="row justify-content-center">
      <div class="col-auto">
        <div>
          Start Date and Time
          <form class="form-inline">
            <div class="form-group">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dp"
                  [(ngModel)]="startModel"
                  ngbDatepicker
                  #dStart="ngbDatepicker"
                />
                <div class="row">
                  <div class="input-group-append">
                    <button
                      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 calendar"
                      (click)="dStart.toggle()"
                      type="button"
                    >
                      Pick Start Date
                    </button>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="input-group">
                    <button
                      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 float-left"
                      (click)="startModel = today; dStart.close()"
                    >
                      Today
                    </button>
                    <button
                      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 text-center"
                      (click)="startModel = tomorrow; dStart.close()"
                    >
                      Tomorrow
                    </button>
                    <!-- <button class="btn-sm btn-secondary  ml-4 mt-1 font-weight-light m-2 float-right" (click)="dStart.close()">Close</button> -->
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          End Date
          <form class="form-inline">
            <div class="form-group">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dp"
                  [(ngModel)]="endModel"
                  ngbDatepicker
                  #d="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn-sm btn-secondary m-2 font-weight-light calendar"
                    (click)="d.toggle()"
                    type="button"
                  >
                    Pick End Date
                  </button>
                  <button
                    class="btn-sm btn-secondary m-2 float-left"
                    (click)="endModel = today; d.close()"
                  >
                    Today
                  </button>
                  <button
                    class="btn-sm btn-secondary m-2 text-center"
                    (click)="endModel = tomorrow; d.close()"
                  >
                    Tomorrow
                  </button>
                  <!-- <button class="btn btn-secondary m-2 float-right" (click)="d.close()">Close</button> -->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" (click)="previousPage()">Previous</a>
    </li>
    <li class="page-item" [class.disabled]="!endModel && !startModel">
      <a class="page-link" (click)="nextPage()">Next</a>
    </li>
    <li class="page-item">
      <a class="page-link" (click)="startPage()">Start</a>
    </li>
  </ul>
</div>
