import { Component, OnInit } from '@angular/core';
import { subtopic } from '../../../GlobalVars/routingGlobal';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from '../../../Aggregation/subtopic-ranking.service';
import { MeService } from '../../Me/me.service';
import { PaginatedSubtopicRanking, SubtopicRanking } from '../../../contest/contest.component';

@Component({
  selector: 'app-user-card-vertical',
  templateUrl: './user-card-vertical.component.html',
  styleUrls: ['./user-card-vertical.component.css']
})
export class UserCardVerticalComponent implements OnInit {

  constructor(private subtopicRankingService: SubtopicRankingService, private meService: MeService) { }

  ngOnInit(): void {
    this.subtopicRankingService.getAllSubtopicRankingsForUser(
      this.meService.id,
      TimeAndGameDurationPeriodType.YEAR).subscribe((paginatedSubtopicRanking: PaginatedSubtopicRanking) => {
        this.subtopicRankingService.currentPaginatedSubtopicRanking = paginatedSubtopicRanking;
      });
  }

  get currentSubtopicRanking(): SubtopicRanking{
    return this.subtopicRankingService.currentPaginatedSubtopicRanking._embedded.subtopicRankingDTOList[0];
  }

}
