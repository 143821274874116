<div class="modal-header">
  <!-- <div *ngIf="!isAlreadyEnteredInContest">
    <p>
      Entering this contest requires a fee of
      {{ contestEntryFee | number : "1.2-2" }}
    </p>
    <p>
      You currently have {{ currentAccountBalance | number : "1.2-2" }} in your
      competitor acount for {{ contestSubtopicName }}
    </p>
    <p>
      After you enter you will have
      {{ currentAccountBalanceDeducted | number : "1.2-2" }} in your account
    </p>
  </div> -->
  <!-- <div> -->
  <!-- <h4 *ngIf="!isAlreadyEnteredInContest" class="modal-title">
      Enter Contest
    </h4> -->
  <!-- </div> -->
  <div>
    <h4 *ngIf="isAlreadyEnteredInContest" class="modal-title">
      Update Contest
    </h4>
  </div>

  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div
  *ngIf="!!errorResponse"
  class="font-weight-bold font-italic"
  style="font-size: 15px; color: red"
>
  Error: {{ errorResponse.error }}
</div>
<div *ngIf="!isAlreadyEnteredInContest" class="modal-body">
  <p>Are you sure you want to enter?</p>
  <button
    *ngIf="!isAlreadyEnteredInContest"
    type="button"
    class="btn btn-outline-dark"
    (click)="enterContest()"
  >
    Enter Contest
  </button>
</div>

<div *ngIf="isAlreadyEnteredInContest" class="modal-body">
  <p>Are you sure you want to update your contest?</p>
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="updateContest()"
    *ngIf="isAlreadyEnteredInContest"
  >
    Update Entry for Contest
  </button>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>
