import { Component, OnInit } from '@angular/core';
import { MeService, MyUserDetails } from '../../profile/Me/me.service';
import { VerifyAccountService } from './verify-account.service';
import { Router } from '@angular/router';
import * as routingGlobals from '../../GlobalVars/routingGlobal';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})
export class VerifyAccountComponent implements OnInit {

  textMessageCode: string;
  errorMessage: string;
  emailCode: string;
  currentDatetime: number;
  canTriggerTime: number;
  constructor(private meService: MeService, private verifyAccountService: VerifyAccountService,
              private router: Router, private storageService: StorageService) { }

  ngOnInit(): void {
    this.canTriggerTime = Date.now() + 30000;
    setInterval(() => {
      this.currentDatetime = Date.now();
    }, 1000);
  }

  // submitTextMessageCode(value: any): void{
  //   this.textMessageCode = value;
  //   this.verifyAccountService.sendOTPForPhone(this.textMessageCode).subscribe((myUserDetails: MyUserDetails) => {
  //     this.storageService.saveUser(myUserDetails);
  //     this.meService.jwt = myUserDetails.jwt;
  //     this.meService.id = myUserDetails.id;
  //     this.meService.accountInfo = {
  //       displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
  //       email: myUserDetails.user.username,
  //       phoneNumber: myUserDetails.user.phoneNumber
  //     };
  //     this.meService.user = myUserDetails.user;
  //     if (this.meService.jwt == null || !!!this.meService.user.isVerifiedByOTP){
  //       this.errorMessage = 'Unable to verify';
  //     }
  //     else{
  //       this.router.navigate([routingGlobals.absRevenueShareSignup]);
  //     }
  //   }, error => {
  //     this.errorMessage = 'Unable to verify';
  //   });
  // }

  submitEmailCode(value: any): void{
     this.emailCode = value;
     this.verifyAccountService.sendOTPForEmail(this.emailCode).subscribe((myUserDetails: MyUserDetails) => {
      this.storageService.saveUser(myUserDetails);
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
      if (this.meService.jwt == null || !!!this.meService.user.isVerifiedByOTP){
        this.errorMessage = 'Unable to verify';
      }
      else{
        this.router.navigate([routingGlobals.absRevenueShareSignup]);
      }
     },
     error => {
      this.errorMessage = 'Unable to verify';
     });
  }

  // sendTextMessageCodeAgain(): void{
  //   this.canTriggerTime = Date.now() + 30000;
  //   // this.verifyAccountService.triggerOTPForPhone().subscribe(() => {
  //   //   console.log('Sent text message trigger again');

  //   // }, err => console.log('error in sending text message'));
  // }

  sendEmailCodeAgain(): void{
    this.verifyAccountService.triggerOTPForEmail().subscribe(() => {
      console.log('Sent email message trigger again');
      this.canTriggerTime = Date.now() + 30000;
    }, err => console.log('Error in sending email'));
  }

  get username(): string{
    return this.meService.user.username;
  }
  get email(): string{
    return this.meService.user.email;
  }
  get phone(): string{
    return this.meService.user.phoneNumber;
  }

  get userId(): number{
    return this.meService.id;
  }

  get triggerAllow(): boolean{
    if (this.currentDatetime > this.canTriggerTime){
      return false;
    }
    return true;
  }

}
