<p>create-financial-contest-modal-create-contest-or-another-game works!</p>

<p class="font-weight-light m-2">Current Games In Contest</p>

<ol
  class="widget-49-meeting-points font-weight-light m-2"
  *ngFor="let game of games"
>
  GameType:
  {{
    game.gameType.type
  }}
  <br />
  Stock Symbol:
  {{
    game.name
  }}
  <br />
  Field Being Bet On:
  {{
    game.fieldToBeBetOn
  }}
  <br />
  Value Being Bet On (if it exists... will not in margin of error):
  {{
    game.valueToBeBetOn
  }}
  <br />
  Start Time:
  {{
    game.startTime | date: "medium"
  }}
  <br />
  End Time:
  {{
    game.endTime | date: "medium"
  }}
  <br />
  Bet Slip ----
  <br />
  Over:
  {{
    game.betSlip.over
  }}
  <br />
  Undr:
  {{
    game.betSlip.under
  }}
  <br />
  Weight:
  {{
    game.betSlip.weight
  }}
</ol>
<ul class="pagination">
  <li class="page-item">
    <a class="page-link" (click)="submitContest()" data-dismiss="modal"
      >Submit Contest</a
    >
  </li>
  <li class="page-item">
    <a class="page-link" (click)="startPage()">Enter Another Game</a>
  </li>
</ul>
