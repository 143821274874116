<div class="ml-1">
  <div *ngIf="error" class="alert alert-danger">{{ error }}</div>

  <form (ngSubmit)="goToProfilePage(f)" #f="ngForm">
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input
        type="email"
        name="email_"
        ngModel
        #emailValid="ngModel"
        class="form-control"
        id="email_"
        aria-describedby="emailHelp"
        email
        placeholder="Enter email"
      />
      <small id="emailHelp" class="form-text text-muted"></small>
    </div>
    <div
      *ngIf="
        emailValid.invalid &&
        emailValid.errors.email &&
        (emailValid.dirty || emailValid.touched)
      "
      class="alert alert-danger"
    >
      Not a valid email
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Username</label>
      <input
        type="username"
        name="username"
        ngModel
        #usernameValid="ngModel"
        class="form-control"
        id="username"
        aria-describedby="emailHelp"
        placeholder="Enter username"
      />
      <small id="usernameHelp" class="form-text text-muted"></small>
    </div>
    <div
      *ngIf="
        usernameValid.invalid &&
        usernameValid.errors &&
        (usernameValid.dirty || usernameValid.touched)
      "
      class="alert alert-danger"
    >
      not a valid username
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input
        type="password"
        name="password"
        minlength="8"
        ngModel
        #passwordValid="ngModel"
        class="form-control"
        id="exampleInputPassword1"
        required
        placeholder="Password"
      />
    </div>
    <div
      *ngIf="
        passwordValid.invalid && (passwordValid.dirty || passwordValid.touched)
      "
      class="alert alert-danger"
    >
      <div *ngIf="passwordValid.errors.required">password is required.</div>
      <div *ngIf="passwordValid.errors.minlength">
        Name must be at least 8 characters long.
      </div>
    </div>

    <!-- <div class="form-group">
      <label for="phoneNumber">Phone Number</label>
      <input
        type="tel"
        placeholder="888 888 8888"
        pattern="^\(\d{3}\)\s\d{3}-\d{4}"
        id="phoneNumber"
        required
        minlength="2"
        maxlength="20"
        size="10"
        class="form-control"
        ngModel
        #phoneValid="ngModel"
        [ngModel]="phoneNumber | phone"
        name="phoneNumber"
        (ngModelChange)="phoneNumber = $event"
        name="phoneNumber"
        type="text"
      />
      <div
        *ngIf="
          phoneValid.invalid &&
          phoneValid.errors &&
          (phoneValid.dirty || phoneValid.touched)
        "
        class="alert alert-danger"
      >
        not a valid phone number - format: (999) 999-0000
      </div>
    </div> -->
    <div class="form-group">
      <input
        required
        type="checkbox"
        id="vehicle1"
        name="vehicle1"
        value="Privacy Policy"
        (change)="acceptPolicy()"
      />
      <label for="privacyPolicy">Accept Privacy Policy</label><br />
    </div>
    <a
      class="nav-item nav-link font-weight-light"
      routerLinkActive="active"
      routerLink="/privacy-policy"
    >
      View Privacy Policy Here</a
    >
    <div class="col-sm-12 controls">
      <button
        class="btn btn-secondary"
        type="submit"
        [disabled]="!f.valid || !acceptedPolicy()"
      >
        Sign up
      </button>
    </div>
  </form>
</div>
<div *ngIf="!!error">
  {{ error }}
</div>
<div class="container">
  <div class="row d-flex justify-content-center">
    <button class="btn btn-secondary" (click)="linkToLogin()">
      Already Signed Up? Log In Here or Click Sigsouk on the top left
    </button>
  </div>
</div>
