<div class="col text-center mb-3">
  <p>Verify your account by either submitting the email code we sent you</p>
  <p>UserId: {{ userId }}</p>
  <p>Email: {{ email }}</p>
  <p>Username: {{ username }}</p>
  <!-- <p>Phone: {{ phone }}</p>
  <p>Enter the 6 digit code that you recieved from the text message</p>
  <p>
    <input
      type="text"
      id="event"
      name="textMessageCode"
      #textMessageCodeInput
    />
  </p>
  <button
    class="btn-sm btn-success ml-4 mt-1 font-weight-light"
    type="button"
    (click)="submitTextMessageCode(textMessageCodeInput.value)"
  >
    Submit Text message code
  </button>

  <p>{{ textMessageCode }}</p> -->

  <p>Enter the 6 digit code that you recieved from the email we sent you</p>
  <p>
    <input type="text" id="event" name="emailCodeInput" #emailCodeInput />
  </p>
  <button
    class="btn-sm btn-success ml-4 mt-1 font-weight-light"
    type="button"
    (click)="submitEmailCode(emailCodeInput.value)"
  >
    Submit Email code
  </button>

  <p>{{ emailCode }}</p>

  <!-- <button
    class="btn-sm btn-warning ml-4 mt-1 font-weight-light"
    type="button"
    (click)="sendTextMessageCodeAgain()"
  >
    Send Text message code again
  </button> -->
  <button
    class="btn-sm btn-warning ml-4 mt-1 font-weight-light"
    type="button"
    (click)="sendEmailCodeAgain()"
    [disabled]="triggerAllow"
  >
    Send Email code Again
  </button>

  <div>
    {{ canTriggerTime | timeUntilNextTrigger : currentDatetime }}
  </div>
</div>
