import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BetSlipService } from 'src/app/contest/bet-slip.service';
import { ContestStateService } from 'src/app/contest/contest-state.service';
import { ContestService } from 'src/app/contest/contest.service';
import { Contest, ContestType, DurationType } from 'src/app/contest/game/game.service';
import { PaginatedContest, UserService } from '../user.service';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { PaginationCommand } from '../../Me/your-blogs/your-blogs.component';
import { PaginatedContestOutcomeRanking } from '../../../contest/contest.component';

@Component({
  selector: 'app-user-contests',
  templateUrl: './user-contests.component.html',
  styleUrls: ['./user-contests.component.css']
})
export class UserContestsComponent implements OnInit {

  endOfContests = false;
  private _isMobile: boolean;
  ContestType = ContestType;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    // this.setYourRankInContest();
    if (window.screen.width < 520) { // 768px portrait
      this._isMobile = true;
    }
    else{
      this._isMobile = false;
    }
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

    get userId(): number{
      if (this.userService.user && this.userService.user.id){
        return this.userService.user.id;
      }
    }
}
