import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Game, UnderOverOutcome, BetSlip, Contest, ContestType, TagReference } from '../game/game.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnterContestModalContentComponent } from './enter-contest-modal-content/enter-contest-modal-content.component';
import { ContestStateService, UnderOverOutcomeRanking } from '../contest-state.service';
import { BetSlipService } from '../bet-slip.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { GlobalContestState } from '../../GlobalVars/Enums/ContestEnums';
import { ContestOutcomeRanking, PaginatedContestOutcomeRanking } from '../contest.component';
import { SubTopicsEntityOrOwnedSubtopicsEntity, UserService } from '../../profile/User/user.service';
import { Me } from 'src/app/profile/Me/me.service';
import * as routingGlobals from '../../GlobalVars/routingGlobal';
import { MeService } from '../../profile/Me/me.service';
import { StorageService } from '../../shared/storage.service';
import { UserContestOutcomesService } from '../../profile/User/user-contest-outcomes/user-contest-outcomes.service';
import { TimeAndGameDurationPeriodType } from 'src/app/Aggregation/subtopic-ranking.service';
import { TimeUntilPipe } from '../../shared/custom-pipes/time-until.pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { ContestService } from '../contest.service';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-view-contest',
  templateUrl: './view-contest.component.html',
  styleUrls: ['./view-contest.component.css']
})
export class ViewContestComponent implements OnInit {

  @ViewChild('contestOutcomeContainerPound')
  contestOutcomeContainerPound: ElementRef;

  @Input()
  isEditable: boolean;

  @Input()
  isNewContest: boolean;

  @Input()
  userContestStateInput: UserContestState;  // = UserContestState.ENTERED_PAST;

  rankings: PaginatedContestOutcomeRanking;
  UserContestState = UserContestState;
  ContestType = ContestType;
  TimeAndGameDurationPeriodType = TimeAndGameDurationPeriodType;
  GlobalContestState = GlobalContestState;
  UnderOverOutcome = UnderOverOutcome;
  _selectedAccounts: any[];
  _tagId: number;
  showRankings = true;
  _tagName: string;
  containsOtherUserBetslip = false;
  activeUserId = null;
  isMobile: boolean;
  isMe: boolean;
  contestOutcomesValid = false;
  selectedTag: TagReference;
  timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType;
  viewLimit = 150;
  viewMoreRankingsToggle: boolean;
  isRefresh = false;

  constructor(public contestStateService: ContestStateService, private modalService: NgbModal,
              private betSlipService: BetSlipService, private route: ActivatedRoute,
              private userService: UserService, private router: Router,
              private userContestOutcomesService: UserContestOutcomesService,
              private meService: MeService, private storageService: StorageService,
              private contestService: ContestService) {
                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
               }

  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    this.betSlipService.isEnteredAndHasBetSlips = false;
    this.timeAndGameDurationPeriodType = TimeAndGameDurationPeriodType.YEAR;
    this.activeUserId = null;
    this.contestStateService.activeContestOutcome = null;
    this.betSlipService.betSlipcurrentUsername = null;
    if (this.meService.isAuthenticated() && ((!!this.route.snapshot.queryParamMap.get('user')
    && +this.route.snapshot.queryParamMap.get('user') === this.meService.id) || (!!!this.route.snapshot.queryParamMap.get('user')))){
      this.isMe = true;
      this.route.data.subscribe((results: any) => {
        if (this.meService.isAuthenticated()){
          this.contestStateService.setContestFromAPI(results.data[0]);
          this.contestStateService.currentContest.tagReferences.sort((a, b) => a.id - b.id);
          this.selectTag(this.contestStateService.currentContest.tagReferences[0]);
          this.userContestOutcomesService.
          getContestOutcomesForUserInSingleContest(this.meService.id, this.contestStateService.currentContest.id)
          .subscribe(contestOutcome => {
            this.contestStateService.activeContestOutcome = contestOutcome;
          });
        }
        if (results.data[3] && results.data[3]._embedded && results.data[3]._embedded.betSlipFinalList){
          this.betSlipService.isEnteredAndHasBetSlips = true;
          results.data[3]._embedded.betSlipFinalList.forEach((betSlip: BetSlip) => {
            this.contestStateService.currentContest.games.forEach((game: Game) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          if (this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_PAST ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.rankings =  results.data[1];
            // this.rankings = this.route.snapshot.data.rankingData;
            }
          this.userContestStateInput = this.contestStateService.currentContest.userContestState;
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_UPCOMING){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
          }
        }
        else if (results.data[2] && results.data[2]._embedded && results.data[2]._embedded.betSlipList){
          this.betSlipService.isEnteredAndHasBetSlips = true;
          results.data[2]._embedded.betSlipList.forEach((betSlip: BetSlip) => {
            this.contestStateService.currentContest.games.forEach((game: Game) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          if (this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_PAST ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.rankings =  results.data[1];
            // this.rankings = this.route.snapshot.data.rankingData;
            }
          this.userContestStateInput = this.contestStateService.currentContest.userContestState;
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_UPCOMING){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
          }
        }
        else{
          this.contestStateService.createEmptyBetSlipsForContest();
          if (this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_PAST ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.rankings =  results.data[1];
            // this.rankings = this.route.snapshot.data.rankingData;
            }
          this.userContestStateInput = this.contestStateService.currentContest.userContestState;
        }
      });
      this.viewEnteredContest(+this.route.snapshot.queryParamMap.get('user'), '');
      if (this.betSlipUsername === ''){
        if (this.rankings && this.rankings._embedded &&
            this.rankings._embedded.contestOutcomeRankingDTOList && this.route.snapshot.queryParamMap.get('user')){
            this.rankings._embedded.contestOutcomeRankingDTOList.forEach(item => {
              if (item.owner_id === +this.route.snapshot.queryParamMap.get('user')){
                this.betSlipService.betSlipcurrentUsername = item.username;
                return;
              }
            });
        }
      }
    }
    else if ((!!this.route.snapshot.queryParamMap.get('user')
    && +this.route.snapshot.queryParamMap.get('user') !== this.meService.id)){
      this.isMe = false;
      this.route.data.subscribe((results: any) => {
        this.contestStateService.setContestFromAPI(results.data[0], +this.route.snapshot.queryParamMap.get('user'));
        this.contestStateService.currentContest.tagReferences.sort((a, b) => a.id - b.id);
        this.selectTag(this.contestStateService.currentContest.tagReferences[0]);
        this.userContestOutcomesService.
        getContestOutcomesForUserInSingleContest(+this.route.snapshot.queryParamMap.get('user'),
        this.contestStateService.currentContest.id)
        .subscribe(contestOutcome => {
          this.contestStateService.activeContestOutcome  = contestOutcome;
        });
        if (results.data[3] && results.data[3]._embedded && results.data[3]._embedded.betSlipFinalList){
          this.betSlipService.isEnteredAndHasBetSlips = true;
          results.data[3]._embedded.betSlipFinalList.forEach((betSlip: BetSlip) => {
            this.contestStateService.currentContest.games.forEach((game: Game) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          // this.isEnteredAndHasBetSlips = true;
          if (this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_PAST ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.rankings =  results.data[1];
            // this.rankings = this.route.snapshot.data.rankingData;
            }
          this.userContestStateInput = this.contestStateService.currentContest.userContestState;
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_UPCOMING){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
          }
        }
        else if (results.data[2] && results.data[2]._embedded && results.data[2]._embedded.betSlipList){
          this.betSlipService.isEnteredAndHasBetSlips = true;
          results.data[2]._embedded.betSlipList.forEach((betSlip: BetSlip) => {
            this.contestStateService.currentContest.games.forEach((game: Game) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
          if (this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_PAST ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.rankings =  results.data[1];
            // this.rankings = this.route.snapshot.data.rankingData;
            }
          this.userContestStateInput = this.contestStateService.currentContest.userContestState;
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_UPCOMING){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_UPCOMING_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED;
          }
          if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
          }
        }
        else{
          this.contestStateService.createEmptyBetSlipsForContest();
          if (this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.ENTERED_PAST ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_LIVE ||
            this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
            this.rankings =  results.data[1];
            // this.rankings = this.route.snapshot.data.rankingData;
            }
          this.userContestStateInput = this.contestStateService.currentContest.userContestState;
        }
      });
      this.viewEnteredContest(+this.route.snapshot.queryParamMap.get('user'), '');
      if (this.betSlipUsername === ''){
        if (this.rankings && this.rankings._embedded &&
            this.rankings._embedded.contestOutcomeRankingDTOList && this.route.snapshot.queryParamMap.get('user')){
            this.rankings._embedded.contestOutcomeRankingDTOList.forEach(item => {
              if (item.owner_id === +this.route.snapshot.queryParamMap.get('user')){
                this.betSlipService.betSlipcurrentUsername = item.username;
                return;
              }
            });
        }
      }

    }
    else{
      this.isMe = false;
      this.route.data.subscribe((results: any) => {
        const contest: Contest = results.data[0];
        this.rankings =  results.data[1];

        if (contest.globalContestState === GlobalContestState.UPCOMING){
          this.contestStateService.viewNonEnteredUpcomingContest(contest);
        }
        else if (contest.globalContestState === GlobalContestState.LIVE){
          this.contestStateService.viewNonEnteredLiveContest(contest);
        }
        if (contest.globalContestState === GlobalContestState.PAST){
          this.contestStateService.viewNonEnteredPastContest(contest);
        }
        if (results.data[3] && results.data[3]._embedded && results.data[3]._embedded.betSlipFinalList){
          results.data[3]._embedded.betSlipFinalList.forEach((betSlip: BetSlip) => {
            this.betSlipService.isEnteredAndHasBetSlips = true;
            this.containsOtherUserBetslip = true;
            this.activeUserId = this.route.snapshot.queryParamMap.get('user');
            this.contestStateService.currentContest.games.forEach((game: Game) => {
              if (betSlip.gameId === game.id){
                game.betSlip = betSlip;
                return;
              }
            });
          });
         }
        else if (results.data[2] && results.data[2]._embedded && results.data[2]._embedded.betSlipList){
        results.data[2]._embedded.betSlipList.forEach((betSlip: BetSlip) => {
          this.betSlipService.isEnteredAndHasBetSlips = true;
          this.containsOtherUserBetslip = true;
          this.activeUserId = this.route.snapshot.queryParamMap.get('user');
          this.contestStateService.currentContest.games.forEach((game: Game) => {
            if (betSlip.gameId === game.id){
              game.betSlip = betSlip;
              return;
            }
          });
        });
       }
      });
    }

  }

  get isLoggedIn(): boolean {
    return this.storageService.isLoggedIn();
  }

  rankingsForContest(): ContestOutcomeRanking[] {
    if (this.contestStateService.rankingsForContest){
      this.contestStateService.activeContestOutcome  = this.contestStateService.rankingsForContest;
      return this.contestStateService.rankingsForContest;
    }
  }

  get currentContest(): any {
    return this.contestStateService.currentContest;
  }

  enterContest(): void{
    const modalRef = this.modalService.open(EnterContestModalContentComponent, { centered: true,  size: 'lg'});
    modalRef.componentInstance.name = 'World';
  }

  linkToSignUpPage(): void{
    this.router.navigate([routingGlobals.absSignup]);
  }

  selectUnderOver(selection: string, game: Game): void{

    const betSlip = game.betSlip;
    if (selection === 'over'){
      betSlip.over = true;
      betSlip.under = false;
      game.betSlip = betSlip;
    }
    else{
      betSlip.over = false;
      betSlip.under = true;
      game.betSlip = betSlip;
    }
  }


  get numberOfUsers(): number{
    return this.contestStateService.currentContest.numberOfCompetitors;
  }

  get maxEntries(): number{
    return this.contestStateService.currentContest.maxUsers;
  }

  setMaxEntries(maxEntries: number): void{
    this.contestStateService.currentContest.maxUsers = maxEntries;
  }

  setContestName(contestName: string): void{
    this.contestStateService.currentContest.name = contestName;
  }

  get availableSlots(): number{
    return this.contestStateService.currentContest.maxUsers - this.contestStateService.currentContest.numberOfCompetitors;
  }

  get games(): Game[]{
    return this.contestStateService.currentContest.games;
  }

  get startDate(): any{
    return this.contestStateService.currentContest.startTime;
  }

  get userContestState(): UserContestState{
    if (this.contestStateService && this.contestStateService.currentContest && this.contestStateService.currentContest.userContestState){
      return this.contestStateService.currentContest.userContestState;
    }

  }


  get contestTopic(): any{
    return this.contestStateService.currentContest.topicReference;
  }
  get contestSubtopic(): any{
    return this.contestStateService.currentContest.subtopicReference;
  }

  get startTime(): any {
    return this.contestStateService.currentContest.startTime;
  }

  get endTime(): any {
    return this.contestStateService.currentContest.endTime;
  }

  get betSlipUsername(): any{
    return this.betSlipService.betSlipcurrentUsername;
  }

  get betSlipUserId(): number{
    return this.betSlipService.betslipCurrentUserId;
  }

  viewUserProfile(userId: number): void {
    this.userService.getUser(userId).subscribe((user: Me) => {
      this.userService.user = user;
      this.userService.id = user.id;
      this.router.navigate([routingGlobals.absUser, userId]);
    });
  }

  updateNameOfContest(prediction: number, game: Game ): void{
    if (!prediction){
      prediction = -1000;
    }
    const betSlip = game.betSlip;

    betSlip.predictedValue = prediction;
    game.betSlip = betSlip;

  }


  viewEnteredContest(userId: number, username: string): void{
    // this.contestService.currentViewState(ContestOwnershipState.isEntered, ContestActiveState.LIVE, contest);
    // this.contestStateService.viewOtherUsersContestBetSlips(this.contestStateService.currentContest, userId, username);
      this.activeUserId = userId;
      this.userService.id = userId;
      // this.userService.user.id = userId;
      // this.router.navigate([routingGlobals.absViewContest, this.contestStateService.currentContest.id],
      //   { queryParams: { user: userId }});
      this.betSlipService.setBetSlipsForContest(this.contestStateService.currentContest, userId, username);

      this.contestStateService.currentContest.userContestState = this.contestStateService.currentContest.userContestState;
      if (this.contestStateService.currentContest.userContestState === UserContestState.NON_ENTERED_PAST){
        this.contestStateService.currentContest.userContestState = UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED;
      }
  }

  get activeContestOutcome(): any{
    return this.contestStateService.activeContestOutcome;
  }

  get contestType(): any{
    return this.contestStateService.currentContest.contestType;
  }
  get tagId(): any{
    if (this.contestStateService.currentContest &&
      this.contestStateService.currentContest.tagReferences &&
      this.contestStateService.currentContest.tagReferences[0] &&
      this.contestStateService.currentContest.tagReferences[0].id){
        if (!!!this._tagId) {
          this.contestStateService.currentContest.tagReferences.sort( (a, b) => a.id - b.id);
          this._tagId = this.contestStateService.currentContest.tagReferences[0].id;
          this.contestStateService.currentContest.tagReferences.sort((a, b) => a.id - b.id);
          this.selectTag(this.contestStateService.currentContest.tagReferences[0]);
        }
        return this._tagId;
    }
  }
  get tagName(): any{
    if (this.contestStateService.currentContest &&
      this.contestStateService.currentContest.tagReferences &&
      this.contestStateService.currentContest.tagReferences[0] &&
      this.contestStateService.currentContest.tagReferences[0].name){
        if (!!!this._tagName) {
          this._tagName = this.contestStateService.currentContest.tagReferences[0].name;
        }
        return this._tagName;
      }
  }

  get contestId(): number{
    if (this.contestStateService.currentContest && this.contestStateService.currentContest.id){
      return this.contestStateService.currentContest.id;
    }
  }

  get isSubscriptionOnly(): boolean{
    if (this.contestStateService.currentContest && this.contestStateService.currentContest.subscriptionOnly      ){
      return this.contestStateService.currentContest.subscriptionOnly;
    }
  }

  get subtopicId(): any{
    return this.contestStateService.currentContest.subtopicReference.id;
  }
  get subtopicName(): any{
    return this.contestStateService.currentContest.subtopicReference.name;
  }

  selectedContestOutcomesTrigger($event, el: HTMLElement): void{
    console.log('contest outcomes have been retreived');
    this.contestOutcomesValid = true;
    this.contestOutcomeContainerPound.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

  }

  // scrollTo( el: HTMLElement): void{
  //   el.scrollIntoView();
  // }

  // get errorResponse(): HttpErrorResponse{
  //   return this.contestService.errorResponse?.error;
  // }

  flipTimeframeType(timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType): void {
    this.timeAndGameDurationPeriodType = timeAndGameDurationPeriodType;
  }


  flipTimeframeTypeAndToggleShow(timeAndGameDurationPeriodType: TimeAndGameDurationPeriodType): void {
    this.timeAndGameDurationPeriodType = timeAndGameDurationPeriodType;
    this.showRankings = false;
    this.isRefresh = true;
    this.betSlipService.getContestRankingsByTagIdAndTimeframeType(this.contestId, this.timeAndGameDurationPeriodType, this._tagId)
    .subscribe(result => {
      this.rankings = result;
      this.showRankings = true;
    },
    err => this.showRankings = true);
  }

  selectTag(tag: TagReference): void {
    this.selectedTag = tag;
    this._tagId = this.selectedTag.id;
    this._tagName = this.selectedTag.name;
  }

  selectTagAndToggleShow(tag: TagReference): void {
    this.selectedTag = tag;
    this._tagId = this.selectedTag.id;
    this._tagName = this.selectedTag.name;
    this.showRankings = false;
    this.isRefresh = true;
    this.betSlipService.getContestRankingsByTagIdAndTimeframeType(this.contestId, this.timeAndGameDurationPeriodType, this._tagId)
    .subscribe(result => {
      this.rankings = result;
      this.showRankings = true;
    },
    err => this.showRankings = true);

  }
  get tags(): TagReference[]{
    return this.contestStateService.currentContest?.tagReferences;
  }

  viewMoreRankings(): void{
    this.viewMoreRankingsToggle = true;
    this.viewLimit = 150;
  }

  viewLessRankings(): void{
    this.viewMoreRankingsToggle = false;
    this.viewLimit = 10;
  }

  canSubmitContestEntry(): boolean {
    if (this.currentContest.contestType === ContestType.UNDER_OVER){
      let canSubmitEntry = true;
      this.games.forEach((game: Game) => {
        if (game.betSlip.under === false && game.betSlip.over === false){
          canSubmitEntry = false;
          console.log('likely had bet slip error uncaught in api');
          return;
        }
      });
      if (this.contestStateService.currentContest.globalContestState !== GlobalContestState.UPCOMING){
        return false;
      }
      const contestStartInMs = new Date(this.contestStateService.currentContest.startTime).getTime();
      const currentTimeInMs = new Date().getTime();
      const ms = contestStartInMs - currentTimeInMs ;
      if (ms < 0){
        // contest has started
        return false;
      }
      if (!this.isMe){
        return false;
      }
      return canSubmitEntry;
    }
    else if (this.currentContest.contestType === ContestType.MARGIN_OF_ERROR){
      let canSubmitEntry = true;
      this.games.forEach((game: Game) => {
        if (game.betSlip.predictedValue < 0){
          canSubmitEntry = false;
          return;
        }
      });
      if (this.contestStateService.currentContest.globalContestState !== GlobalContestState.UPCOMING){
        return false;
      }
      const contestStartInMs = new Date(this.contestStateService.currentContest.startTime).getTime();
      const currentTimeInMs = new Date().getTime();
      const ms = contestStartInMs - currentTimeInMs ;
      if (ms < 0){
        // contest has started
        return false;
      }
      return canSubmitEntry;
    }
  }
}
