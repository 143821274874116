<p class="font-italic font-weight-light">
  Enter the Value that you think the item will be at the end of the contest
</p>
<table
  class="table table-striped table-dark table-xs table-hover table-condensed font-weight-light"
  style="width: 100%"
  #target
>
  <thead>
    <tr>
      <th scope="col">Name</th>

      <th scope="col">Locked Start Value</th>
      <th scope="col">Current</th>

      <th
        scope="col"
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED ||
          currentContest.userContestState === UserContestState.ENTERED_LIVE ||
          userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED
        "
      >
        Predicted Value
      </th>

      <th
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_LIVE ||
          userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED
        "
      >
        Projected MOE
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
      <td
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'stocks'.toUpperCase()"
          href="{{ 'https://www.bing.com/search?q=' + game.name + '+Stock' }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'stocks'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
        <a
          *ngIf="
            contestSubtopic.name.toUpperCase() === 'currency'.toUpperCase()
          "
          href="{{ 'https://www.bing.com/search?q=USD+to+' + game.name }}"
          target="_blank"
          >{{ game.name }}</a
        >

        <div
          *ngIf="
            contestSubtopic.name.toUpperCase() === 'currency'.toUpperCase()
          "
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'crypto'.toUpperCase()"
          href="{{ 'https://www.bing.com/search?q=' + game.name + '+Price' }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'crypto'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
      </td>
      <td
        [ngClass]="{
          'bg-secondary': currentContest.contestType === ContestType.UNDER_OVER
        }"
      >
        {{ game.startAtom.value | number : "1.2-4" }} s
      </td>

      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED) &&
          game.betSlip.over
        "
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true,
          'bg-secondary': currentContest.contestType === ContestType.UNDER_OVER
        }"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>
      <td
        *ngIf="
          (userContestState === UserContestState.ENTERED_PAST ||
            userContestState ===
              UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED) &&
          game.betSlip.under
        "
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true,
          'bg-secondary': currentContest.contestType === ContestType.UNDER_OVER
        }"
      >
        {{ game.endAtom.value | number : "1.2-4" }}
      </td>

      <td class="bg-secondary">
        {{ game.liveAtom.value | number : "1.2-4" }} -
        {{ game.liveAtom.updateDate | date : "shortTime" }}
      </td>

      <td
        *ngIf="
          currentContest.userContestState === UserContestState.ENTERED_LIVE ||
          currentContest.userContestState ===
            UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED ||
          currentContest.userContestState === UserContestState.ENTERED_PAST ||
          userContestState ===
            UserContestState.NON_ENTERED_PAST_OTHER_USER_IS_ENTERED
        "
        class="bg-secondary"
        [ngClass]="{
          scratched: game.endAtom.scratchGame === true
        }"
      >
        {{ game.betSlip.predictedValue | number : "1.2-4" }}
      </td>
      <td
        *ngIf="
          !game.endAtom.valueLocked &&
          game.liveAtom.value <= game.betSlip.predictedValue &&
          (currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            userContestState ===
              UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED)
        "
        class="bg-info"
      >
        {{
          1 - game.liveAtom.value / game.betSlip.predictedValue
            | number : "1.2-4"
        }}
        - Based off Live data
      </td>
      <td
        *ngIf="
          game.endAtom.valueLocked &&
          game.endAtom.value <= game.betSlip.predictedValue &&
          (currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            userContestState ===
              UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED)
        "
        class="bg-info"
      >
        {{
          1 - game.endAtom.value / game.betSlip.predictedValue
            | number : "1.2-4"
        }}
        - Unnofficial Result - Locked final value is
        {{ game.endAtom.value }}
      </td>
      <td
        *ngIf="
          !game.endAtom.valueLocked &&
          game.liveAtom.value > game.betSlip.predictedValue &&
          (currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            userContestState ===
              UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED)
        "
        class="bg-info"
      >
        {{
          1 - game.betSlip.predictedValue / game.liveAtom.value
            | number : "1.2-4"
        }}
        - Based off live data
      </td>

      <td
        *ngIf="
          game.endAtom.valueLocked &&
          game.endAtom.value > game.betSlip.predictedValue &&
          (currentContest.userContestState === UserContestState.ENTERED_LIVE ||
            userContestState ===
              UserContestState.NON_ENTERED_LIVE_OTHER_USER_IS_ENTERED)
        "
        class="bg-info"
      >
        {{
          1 - game.betSlip.predictedValue / game.endAtom.value
            | number : "1.2-4"
        }}
        - Unnofficial Result - Locked final value is
        {{ game.endAtom.value }}
      </td>
    </tr>
  </tbody>
</table>
<div class="widget-49-meeting-action">
  <button
    *ngIf="isLoggedIn"
    [ngClass]="{
      'btn-sm btn-secondary text-center': canSubmitContestEntry() === false,
      'btn-sm btn-success text-center': canSubmitContestEntry() === true,
      scratched: !canSubmitContestEntry()
    }"
    style="font-size: 8.8px"
    [disabled]="!canSubmitContestEntry()"
    (click)="enterContest()"
  >
    Enter or Update Entry
  </button>

  <button
    *ngIf="!isLoggedIn"
    style="font-size: 8.8px"
    (click)="linkToSignUpPage()"
    class="btn btn-success text-center"
  >
    Sign up/in to Play!
  </button>
</div>

<div
  class="widget-49-meeting-action"
  *ngIf="
    !canSubmitContestEntry() &&
    currentContest.globalContestState === GlobalContestState.LIVE
  "
>
  <div class="btn btn-sm btn-flash-border-primary">Picks are Locked</div>
</div>
