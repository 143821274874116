import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FootballGameService {

  sportsGames: SportsGame[];

  constructor(private httpClient: HttpClient) { }


  public getSportsGamesForContest(contestId: number): Observable<any> {
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/sports-game/${contestId}`);
  }

}

export interface SportsGame {
  pid?: number;
  id?: string;
  sport_key?: string;
  sport_title?: string;
  date_event?: Date;
  moneyline?: number;
  totalPointsProjection?: number;
  line?: number;
  home_team_record?: string;
  away_team_record?: string;
  home_team_ranking?: number;
  away_team_ranking?: number;
  event_id?: string;
  event_uuid?: string;
  completed?: boolean;
  home_team?: string;
  home_team_id?: number;
  away_team?: string;
  away_team_id?: number;
  home_score?: number;
  away_score?: number;
  status?: string;
}
