import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MeService } from '../profile/Me/me.service';
import { StorageService } from '../shared/storage.service';
import * as routingGlobals from '../GlobalVars/routingGlobal';

@Injectable({
  providedIn: 'root'
})
export class OtpGuard implements CanActivate {
  constructor(private meService: MeService, private router: Router, private storageService: StorageService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /*
    if no user is logged in -> return true
    if user is logged in but is not verified -> false and reroute to verification page
    */
   if (!this.storageService.isLoggedIn()){
    return true;
   }
   if (this.storageService.isLoggedIn() && !!this.meService.user &&

    this.meService.user.isVerifiedByOTP !== null &&
    this.meService.user.isVerifiedByOTP !== undefined && !!!this.meService.user.isVerifiedByOTP){
    this.router.navigate([routingGlobals.absVerifyAccount]);
    return false;
   }
   if (this.storageService.isLoggedIn() && !!this.meService.user  &&
   this.meService.user.isVerifiedByOTP !== null &&
     this.meService.user.isVerifiedByOTP !== undefined && !!this.meService.user.isVerifiedByOTP){
    return true;
   }
   return true;
  }

}
