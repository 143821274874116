<div class="ml-1 mt-4 font-weight-light">
  <div class="row">
    <div class="col text-center mb-3 container ml-1 mt-4 font-weight-light">
      <div class="md-form">
        <input
          type="search"
          [(ngModel)]="searchText"
          placeholder="Search text"
        />
      </div>
      <ul *ngFor="let data of availableStocks | search: searchText">
        <li style="list-style-type: none" (click)="selectCurrentStock(data)">
          {{ data.symbol }}
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="selectedStock" class="container ml-1 mt-4 font-weight-light">
    <div class="row">
      <div class="col-sm-4">
        <table
          class="table table-striped mr-3 table-xs table-hover table-condensed"
        >
          <thead>
            <tr>
              <th scope="col">Field to Bet on</th>
              <th scope="col">Current Value of Field</th>
              <th scope="col">Create this type of Game</th>
            </tr>
          </thead>
          <tbody>
            <tr (click)="selectFieldToBetOn('Close', selectedStock.close)">
              <td>Close</td>
              <td>{{ selectedStock.close }}</td>
              <td>Create Close Game by clicking on row</td>
            </tr>
            <tr (click)="selectFieldToBetOn('High', selectedStock.high)">
              <td>Daily High</td>
              <td>{{ selectedStock.high }}</td>
              <td>Create Daily High Game by clicking on row</td>
            </tr>
            <tr (click)="selectFieldToBetOn('Low', selectedStock.low)">
              <td>Daily High</td>
              <td>{{ selectedStock.low }}</td>
              <td>Create Daily Low Game by clicking on row</td>
            </tr>
            <tr (click)="selectFieldToBetOn('Open', selectedStock.open)">
              <td>Open</td>
              <td>{{ selectedStock.open }}</td>
              <td>Create Daily Low Game by clicking on row</td>
            </tr>
            <tr (click)="selectFieldToBetOn('volume', selectedStock.volume)">
              <td>Volume</td>
              <td>{{ selectedStock.volume }}</td>
              <td>Create Volume Game by clicking on row</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="fieldToBeBetOn" class="col-sm-4 text-center mb-3">
        <div>
          <div ngbDropdown class="d-inline-block">
            <button
              class="btn-sm btn-secondary ml-1 mt-4 font-weight-light"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              Choose Game Type
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div
                *ngFor="let gameType of gameTypes"
                (click)="selectGameType(gameType)"
              >
                <button
                  class="btn-sm btn-secondary ml-1 mt-4 font-weight-light"
                  ngbDropdownItem
                >
                  {{ gameType.type }} <br />
                </button>
              </div>
            </div>
          </div>
          <p *ngIf="currentGameType">
            {{ currentGameType.type }}: {{ currentGameType.description }}
          </p>
        </div>
        <div *ngIf="currentGameType">
          Start Date and Time
          <form class="form-inline">
            <div class="form-group">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dp"
                  [(ngModel)]="startModel"
                  ngbDatepicker
                  [footerTemplate]="footerTemplate"
                  #dStart="ngbDatepicker"
                />
                <div class="row">
                  <div class="input-group-append">
                    <button
                      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 calendar"
                      (click)="dStart.toggle()"
                      type="button"
                    >
                      Pick Start Date
                    </button>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="input-group">
                    <button
                      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 float-left"
                      (click)="startModel = today; dStart.close()"
                    >
                      Today
                    </button>
                    <button
                      class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 text-center"
                      (click)="startModel = tomorrow; dStart.close()"
                    >
                      Tomorrow
                    </button>
                    <!-- <button class="btn-sm btn-secondary  ml-4 mt-1 font-weight-light m-2 float-right" (click)="dStart.close()">Close</button> -->
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="currentGameType" class="col-sm-4">
      <!-- All Time is in Eastern Standard Time
  <ngb-timepicker [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
    <button class="btn-sm btn-secondary  ml-4 mt-1 font-weight-light btn-outline-{{meridian ? 'success' : 'danger'}}" (click)="toggleMeridian()">
         Meridian - {{meridian ? "ON" : "OFF"}}
                </button>
            <hr>
        <pre>Selected time: {{time | json}}</pre> -->
      End Date
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              [(ngModel)]="endModel"
              ngbDatepicker
              [footerTemplate]="footerTemplate"
              #d="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="btn-sm btn-secondary m-2 font-weight-light calendar"
                (click)="d.toggle()"
                type="button"
              >
                Pick End Date
              </button>
              <button
                class="btn-sm btn-secondary m-2 float-left"
                (click)="endModel = today; d.close()"
              >
                Today
              </button>
              <button
                class="btn-sm btn-secondary m-2 text-center"
                (click)="endModel = tomorrow; d.close()"
              >
                Tomorrow
              </button>
              <!-- <button class="btn btn-secondary m-2 float-right" (click)="d.close()">Close</button> -->
            </div>
          </div>
        </div>
      </form>
      {{ fieldToBeBetOn }} - selected field -- {{ fieldToBeBetOnDescription }}
    </div>
    <div>
      <button
        class="btn-sm btn-secondary m-2 text-center font-weight-light"
        *ngIf="canCreateGame()"
        (click)="createGame()"
      >
        Enter game
      </button>

      <p class="font-weight-light m-2">Current Games In Contest</p>

      <ol
        class="widget-49-meeting-points font-weight-light m-2"
        *ngFor="let game of games"
      >
        GameType:
        {{
          game.gameType.type
        }}
        <br />
        Stock Symbol:
        {{
          game.name
        }}
        <br />
        Field Being Bet On:
        {{
          game.fieldToBeBetOn
        }}
        <br />
        Value Being Bet On (if it exists... will not in margin of error):
        {{
          game.valueToBeBetOn
        }}
        <br />
        Start Time:
        {{
          game.startTime | date: "medium"
        }}
        <br />
        End Time:
        {{
          game.endTime | date: "medium"
        }}
        <br />
        Bet Slip ----
        <br />
        Over:
        {{
          game.betSlip.over
        }}
        <br />
        Undr:
        {{
          game.betSlip.under
        }}
        <br />
        Weight:
        {{
          game.betSlip.weight
        }}
      </ol>
    </div>

    <div
      *ngIf="selectedStock && false"
      class="container ml-1 mt-4 font-weight-light"
    >
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <div class="card card-margin">
            <div class="card-header no-border">
              <h5 class="card-title text-center">
                Symbol {{ selectedStock.symbol }}
              </h5>
            </div>
            <div class="card-body pt-0">
              <div class="widget-49">
                <div class="widget-49-title-wrapper">
                  <p>{{ currentDate | date }}</p>
                  <div>
                    <p>After Hours: {{ selectedStock.afterHours }}</p>
                    <p
                      (click)="selectFieldToBetOn('Close', selectedStock.close)"
                    >
                      Close: {{ selectedStock.close }} | Create Close Game
                    </p>
                    <p (click)="selectFieldToBetOn('High', selectedStock.high)">
                      Daily High {{ selectedStock.high }} | Create Daily High
                      Game
                    </p>
                    <p (click)="selectFieldToBetOn('Low', selectedStock.low)">
                      Daily Low {{ selectedStock.low }} | | Create Daily Low
                      Game
                    </p>
                    <p (click)="selectFieldToBetOn('Open', selectedStock.open)">
                      Open {{ selectedStock.open }} | Create Open Game
                    </p>
                    <p
                      (click)="
                        selectFieldToBetOn('volume', selectedStock.volume)
                      "
                    >
                      Volume {{ selectedStock.volume }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="fieldToBeBetOn">
              <div class="col text-center mb-3">
                <div ngbDropdown class="d-inline-block">
                  <button
                    class="btn btn-secondary"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                  >
                    Choose Game Type
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <div
                      *ngFor="let gameType of gameTypes"
                      (click)="selectGameType(gameType)"
                    >
                      <button ngbDropdownItem>
                        {{ gameType.type }} <br />
                      </button>
                    </div>
                  </div>
                </div>
                <p *ngIf="currentGameType">
                  {{ currentGameType.type }}: {{ currentGameType.description }}
                </p>
              </div>
              <div *ngIf="currentGameType">
                Start Date and Time
                <form class="form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="startModel"
                        ngbDatepicker
                        [footerTemplate]="footerTemplate"
                        #dStart="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light btn-outline-secondary calendar"
                          (click)="dStart.toggle()"
                          type="button"
                        >
                          Pick Start Date
                        </button>
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 float-left"
                          (click)="startModel = today; dStart.close()"
                        >
                          Today
                        </button>
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 text-center"
                          (click)="startModel = tomorrow; dStart.close()"
                        >
                          Tomorrow
                        </button>
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light m-2 float-right"
                          (click)="dStart.close()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                All Time is in Eastern Standard Time
                <ngb-timepicker
                  [(ngModel)]="time"
                  [meridian]="meridian"
                ></ngb-timepicker>
                <button
                  class="btn-sm btn-secondary  ml-4 mt-1 font-weight-light btn-outline-{{
                    meridian ? 'success' : 'danger'
                  }}"
                  (click)="toggleMeridian()"
                >
                  Meridian - {{ meridian ? "ON" : "OFF" }}
                </button>
                <hr />
                <pre>Selected time: {{ time | json }}</pre>
                End Date
                <form class="form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="endModel"
                        ngbDatepicker
                        [footerTemplate]="footerTemplate"
                        #d="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn-sm btn-secondary ml-4 mt-1 font-weight-light calendar"
                          (click)="d.toggle()"
                          type="button"
                        >
                          Pick End Date
                        </button>
                        <button
                          class="btn btn-primary btn-sm m-2 float-left"
                          (click)="endModel = today; d.close()"
                        >
                          Today
                        </button>
                        <button
                          class="btn btn-primary btn-sm m-2 text-center"
                          (click)="endModel = tomorrow; d.close()"
                        >
                          Tomorrow
                        </button>
                        <button
                          class="btn btn-secondary btn-sm m-2 float-right"
                          (click)="d.close()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {{ fieldToBeBetOn }} - selected field --
                {{ fieldToBeBetOnDescription }}
              </div>

              <button class="btn btn-primary" (click)="createGame()">
                Enter game
              </button>

              <p>Current Games In Contest</p>

              <ol class="widget-49-meeting-points" *ngFor="let game of games">
                GameType:
                {{
                  game.gameType.type
                }}
                <br />
                Stock Symbol:
                {{
                  game.name
                }}
                <br />
                Field Being Bet On:
                {{
                  game.fieldToBeBetOn
                }}
                <br />
                Value Being Bet On (if it exists... will not in margin of
                error):
                {{
                  game.valueToBeBetOn
                }}
                <br />
                Start Time:
                {{
                  game.startTime | date: "medium"
                }}
                <br />
                End Time:
                {{
                  game.endTime | date: "medium"
                }}
                <br />
                Bet Slip ----
                <br />
                Over:
                {{
                  game.betSlip.over
                }}
                <br />
                Undr:
                {{
                  game.betSlip.under
                }}
                <br />
                Weight:
                {{
                  game.betSlip.weight
                }}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: center">
    <h1>Begin creating contest</h1>
  </div>
  <nav style="text-align: center">
    <button routerLink="create-financial-contest-modal-date-selection">
      select dates
    </button>
    <button routerLink="create-financial-contest-modal-stock-selection">
      select stocks
    </button>
    <!-- <button routerLink="/withdraw">Withdraw some money</button> -->
  </nav>
  here is start of router outlet
  <router-outlet></router-outlet>
  here is end of router outlet
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item"><a class="page-link" href="#">Previous</a></li>
      <li class="page-item"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" href="#">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item"><a class="page-link" href="#">Next</a></li>
    </ul>
  </nav>
</div>
