import { httpInterceptorProviders } from './shared/http-interceptors/index';
import { TokenInterceptorService } from './shared/token-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EmailExistsInListDirective } from './shared/Validators/email-exists-in-list.directive';
import { EmailValidDirective } from './shared/Validators/email-valid.directive';
import { PasswordConfirmDirective } from './shared/Validators/password-confirm.directive';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { SignupPaymentInformationComponent } from './login/SignupProcess/signup-payment-information/signup-payment-information.component';
import { AuthGuardGuard } from './shared/auth-guard.guard';
import { SignupComponent } from './login/SignupProcess/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AccountSettingsComponent } from './profile/Me/account-settings/account-settings.component';
import { PhoneNumberPipe } from './shared/custom-pipes/phone-number.pipe';
import { StripeModule } from 'stripe-angular';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { UsernameForPasswordComponent } from './login/username-for-password/username-for-password.component';
import { AboutUsComponent } from './profile/Me/about-us/about-us.component';
import { ProfileComponent } from './profile/profile.component';
import { ContestComponent } from './contest/contest.component';
import { GameComponent } from './contest/game/game.component';
import { CreateContestComponent } from './contest/create-contest/create-contest.component';
import { CreateGameComponent } from './contest/create-game/create-game.component';
import { ViewContestComponent } from './contest/view-contest/view-contest.component';
import { TopicComponent } from './topic/topic.component';
import { SubtopicComponent } from './topic/subtopic/subtopic.component';
import { CreateSportsGameComponent } from './contest/create-game/create-sports-game/create-sports-game.component';
import { CreateFinancialGameComponent } from './contest/create-game/create-financial-game/create-financial-game.component';
import { ViewStockContestComponent } from './contest/view-contest/featured/view-stock-contest/view-stock-contest.component';
import { SearchPipe } from './shared/custom-pipes/search.pipe';
import { EnterContestModalComponent } from './contest/view-contest/enter-contest-modal/enter-contest-modal.component';
import { ViewContestsComponent } from './contest/view-contests/view-contests.component';
import { YourContestsComponent } from './profile/Me/your-contests/your-contests.component';
import { HistoryComponent } from './profile/history/history.component';
import { RankingsCardComponent } from './contest/view-contest/rankings-card/rankings-card.component';
import { CommonModule, DatePipe } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { CreateContestModalComponent } from './contest/create-contest/create-contest-modal/create-contest-modal.component';
import { CreateFinancialContestModalStockSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-stock-selection/create-financial-contest-modal-stock-selection.component';
import { CreateFinancialContestModalDateSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-date-selection/create-financial-contest-modal-date-selection.component';
import { CreateContestModalBlankComponent } from './contest/create-contest/create-contest-modal/create-contest-modal-blank/create-contest-modal-blank.component';
import { CreateFinancialContestModalCreateContestOrAnotherGameComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-create-contest-or-another-game/create-financial-contest-modal-create-contest-or-another-game.component';
import { CreateFinancialContestModalReviewAndSubmitComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-review-and-submit/create-financial-contest-modal-review-and-submit.component';
import { CreateFinancialContestModalStockGameTypeSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-stock-game-type-selection/create-financial-contest-modal-stock-game-type-selection.component';
import { CreateFinancialContestModalStockFieldSelectionComponent } from './contest/create-contest/create-contest-modal/create-financial-contest-modal-stock-field-selection/create-financial-contest-modal-stock-field-selection.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YourVirtualAccountComponent } from './profile/Me/your-virtual-account/your-virtual-account.component';
import { SafeHtmlPipe } from './media/blog/safe-html.pipe';
import { MediaComponent } from './media/media.component';

import { ViewBlogComponent } from './media/blog/view-blog/view-blog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { YourBlogsComponent } from './profile/Me/your-blogs/your-blogs.component';
import { UserVirtualAccountComponent } from './profile/User/user-virtual-account/user-virtual-account.component';
import { UserBlogsComponent } from './profile/User/user-blogs/user-blogs.component';
import { UserProfileComponent } from './profile/User/user-profile/user-profile.component';
import { UserContestsComponent } from './profile/User/user-contests/user-contests.component';
import { TimeUntilPipe } from './shared/custom-pipes/time-until.pipe';
import { MyContestOutcomesComponent } from './profile/Me/my-contest-outcomes/my-contest-outcomes.component';
import { UserContestOutcomesComponent } from './profile/User/user-contest-outcomes/user-contest-outcomes.component';
// tslint:disable-next-line:max-line-length
import { EnterContestModalContentComponent } from './contest/view-contest/enter-contest-modal-content/enter-contest-modal-content.component';
import { UserCardHorizontalComponent } from './profile/userCards/user-card-horizontal/user-card-horizontal.component';
import { UserCardVerticalComponent } from './profile/userCards/user-card-vertical/user-card-vertical.component';
import { PrivacyPolicyComponent } from './privacyPolicy/privacy-policy/privacy-policy.component';
import { ConnectedAccountComponent } from './login/SignupProcess/connected-account/connected-account.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { GuruComponent } from './guru/guru.component';
import { SkinnyArticlesComponent } from './soukComponents/skinny-articles/skinny-articles.component';
import { UserContestTableComponent } from './soukComponents/user-contest-table/user-contest-table.component';
import { GuruRankingTimeframeComponent } from './guru/guru-ranking-timeframe/guru-ranking-timeframe.component';
import { ViewContestsContainerComponent } from './contest/view-contests-container/view-contests-container.component';
import { DropdownSearchComponent } from './contest/dropdown-search/dropdown-search.component';
import { ArticleHolderComponent } from './contest/article-holder/article-holder.component';
import { AllCapsUnderscorePipe } from './shared/custom-pipes/all-caps-underscore.pipe';
import { ShareableLinkPageComponent } from './shareable-link-page/shareable-link-page.component';
import { UserSocialLinksComponent } from './profile/User/user-social-links/user-social-links.component';
import { YourSocialLinksComponent } from './profile/Me/your-social-links/your-social-links.component';
import { CreateBlogComponent } from './profile/Me/your-blogs/create-blog/create-blog.component';
import { LoginModalComponent } from './login/login-modal/login-modal.component';
import { LoginModalContentComponent } from './login/login-modal-content/login-modal-content.component';
import { VerifyAccountComponent } from './login/verify-account/verify-account.component';
import { RevenueShareSignupComponent } from './login/SignupProcess/revenue-share-signup/revenue-share-signup.component';
import { MoeLiveComponent } from './contest/view-contest/moe-live/moe-live.component';
import { MoePastComponent } from './contest/view-contest/moe-past/moe-past.component';
import { MoeUpcomingComponent } from './contest/view-contest/moe-upcoming/moe-upcoming.component';
import { UnderOverLiveComponent } from './contest/view-contest/under-over-live/under-over-live.component';
import { UnderOverPastComponent } from './contest/view-contest/under-over-past/under-over-past.component';
import { UnderOverUpcomingComponent } from './contest/view-contest/under-over-upcoming/under-over-upcoming.component';
import { MarginOfVictoryLiveComponent } from './contest/view-contest/margin-of-victory-live/margin-of-victory-live.component';
import { MarginOfVictoryPastComponent } from './contest/view-contest/margin-of-victory-past/margin-of-victory-past.component';
import { MarginOfVictoryUpcomingComponent } from './contest/view-contest/margin-of-victory-upcoming/margin-of-victory-upcoming.component';
import { TotalPointsScoredLiveComponent } from './contest/view-contest/total-points-scored-live/total-points-scored-live.component';
import { TotalPointsScoredPastComponent } from './contest/view-contest/total-points-scored-past/total-points-scored-past.component';
import { TotalPointsScoredUpcomingComponent } from './contest/view-contest/total-points-scored-upcoming/total-points-scored-upcoming.component';
import { TimeUntilNextTriggerPipe } from './shared/custom-pipes/time-until-next-trigger.pipe';
import { STRIPE_PK } from './GlobalVars/api';
import { environment } from 'src/environments/environment';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    PageNotFoundComponent,
    EmailExistsInListDirective,
    EmailValidDirective,
    PasswordConfirmDirective,
    SignupComponent,
    SignupPaymentInformationComponent,
    AccountSettingsComponent,
    PhoneNumberPipe,
    ForgotPasswordComponent,
    UsernameForPasswordComponent,
    AboutUsComponent,
    ProfileComponent,
    ContestComponent,
    GameComponent,
    CreateContestComponent,
    CreateGameComponent,
    ViewContestComponent,
    TopicComponent,
    SubtopicComponent,
    CreateSportsGameComponent,
    CreateFinancialGameComponent,
    ViewStockContestComponent,
    SearchPipe,
    TimeUntilPipe,
    TimeUntilNextTriggerPipe,
    SafeHtmlPipe,
    AllCapsUnderscorePipe,
    MediaComponent,
    ViewBlogComponent,
    EnterContestModalComponent,
    EnterContestModalContentComponent,
    ViewContestsComponent,
    YourContestsComponent,
    HistoryComponent,
    RankingsCardComponent,
    YourBlogsComponent,
    CreateContestModalComponent,
    CreateFinancialContestModalStockSelectionComponent,
    CreateFinancialContestModalDateSelectionComponent,
    CreateContestModalBlankComponent,
    CreateFinancialContestModalCreateContestOrAnotherGameComponent,
    CreateFinancialContestModalReviewAndSubmitComponent,
    CreateFinancialContestModalStockGameTypeSelectionComponent,
    CreateFinancialContestModalStockFieldSelectionComponent,
    YourVirtualAccountComponent,
    YourBlogsComponent,
    UserVirtualAccountComponent,
    UserBlogsComponent,
    UserProfileComponent,
    UserContestsComponent,
    MyContestOutcomesComponent,
    UserContestOutcomesComponent,
    UserCardHorizontalComponent,
    UserCardVerticalComponent,
    PrivacyPolicyComponent,
    ConnectedAccountComponent,
    GuruComponent,
    SkinnyArticlesComponent,
    UserContestTableComponent,
    GuruRankingTimeframeComponent,
    ViewContestsContainerComponent,
    DropdownSearchComponent,
    ArticleHolderComponent,
    ShareableLinkPageComponent,
    UserSocialLinksComponent,
    YourSocialLinksComponent,
    CreateBlogComponent,
    LoginModalComponent,
    LoginModalContentComponent,
    VerifyAccountComponent,
    RevenueShareSignupComponent,
    MoeLiveComponent,
    MoePastComponent,
    MoeUpcomingComponent,
    UnderOverLiveComponent,
    UnderOverPastComponent,
    UnderOverUpcomingComponent,
    MarginOfVictoryLiveComponent,
    MarginOfVictoryPastComponent,
    MarginOfVictoryUpcomingComponent,
    TotalPointsScoredLiveComponent,
    TotalPointsScoredPastComponent,
    TotalPointsScoredUpcomingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    AppRoutingModule,
    // GoogleChartsModule,
    Ng2GoogleChartsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    StripeModule.forRoot(environment.STRIPE_PUBLIC_KEY),
    CommonModule,
    QuillModule.forRoot(),
    InfiniteScrollModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    // ShareButtonsModule

  ],
  providers: [
    AuthGuardGuard,
    TokenInterceptorService,
    httpInterceptorProviders,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
