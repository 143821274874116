import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { ContestService } from '../contest/contest.service';
import { contest, user } from '../GlobalVars/routingGlobal';
import { BetSlipService } from '../contest/bet-slip.service';
import { ContestStateService } from '../contest/contest-state.service';
import { catchError } from 'rxjs/operators';
import { MeService } from '../profile/Me/me.service';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:ban-types
export class ContestResolverService implements Resolve<Object> {

  constructor(private contestService: ContestService, private betSlipService: BetSlipService,
              private contestStateService: ContestStateService, private meService: MeService) { }
  // tslint:disable-next-line:ban-types
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    const contestId = +route.paramMap.get('contestId');
    const userId = +route.queryParamMap.get('user');

    // tslint:disable-next-line:no-shadowed-variable
    const contest = this.contestService.getContest(contestId);
    const contestRankings = this.betSlipService.getContestRankings(contestId).pipe(catchError(e => of('Oops!')));
    let myBetSlips = null;
    let myFinalBetSlips = null;
    if (this.meService.isAuthenticated() && ((!!route.queryParamMap.get('user')
    && +route.queryParamMap.get('user') === this.meService.id) || (!!!route.queryParamMap.get('user')))){
      myBetSlips = this.betSlipService.getMyBetSlips(contestId).pipe(catchError(e => of('Oops!')));
      myFinalBetSlips = this.betSlipService.getMyFinalBetSlips(contestId).pipe(catchError(e => of('Oops!')));
    }
    else{
      myBetSlips = this.betSlipService.getUserBetSlips(contestId, userId).pipe(catchError(e => of('Oops!')));
      myFinalBetSlips = this.betSlipService.getUserFinalBetSlips(contestId, userId).pipe(catchError(e => of('Oops!')));
    }

    console.log('in contest resolver');
    return forkJoin([contest, contestRankings, myBetSlips, myFinalBetSlips]);
  }
}
