<div *ngIf="!isMobile && !!userId" class="container">
  <div class="mt-4 font-weight-light">
    <div class="text-center mb-3 my-1 mx-auto">
      <p class="text-center mb-3">Upcoming</p>
      <app-view-contests-container
        [globalContestStateForAPICall]="'userCompetitorUpcoming'"
        [userId]="userId"
      ></app-view-contests-container>
    </div>

    <div class="text-center mb-3 my-1 mx-auto">
      <p class="text-center mb-3 col-sm-6 my-1 mx-auto table-wrapper-scroll-y">
        Active/Live
      </p>
      <app-view-contests-container
        [globalContestStateForAPICall]="'userCompetitorLive'"
        [userId]="userId"
      ></app-view-contests-container>
    </div>
    <div class="text-center mb-3 my-1 mx-auto">
      <p class="text-center mb-3">Past</p>
      <app-view-contests-container
        [globalContestStateForAPICall]="'userCompetitorPast'"
        [userId]="userId"
      ></app-view-contests-container>
    </div>
  </div>
</div>

<div *ngIf="isMobile && !!userId">
  <p class="text-center mb-3">Upcoming</p>

  <div class="d-flex justify-content-center">
    <div class="text-center mb-3 my-1 mx-auto">
      <app-view-contests-container
        [globalContestStateForAPICall]="'userCompetitorUpcoming'"
        [userId]="userId"
      ></app-view-contests-container>
    </div>
  </div>

  <p class="text-center my-3">Active/Live</p>

  <div class="d-flex justify-content-center">
    <app-view-contests-container
      [globalContestStateForAPICall]="'userCompetitorLive'"
      [userId]="userId"
    ></app-view-contests-container>
  </div>

  <p class="text-center my-3">Past</p>

  <div class="d-flex justify-content-center">
    <app-view-contests-container
      [globalContestStateForAPICall]="'userCompetitorPast'"
      [userId]="userId"
    ></app-view-contests-container>
  </div>
</div>
