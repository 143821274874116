import { Component, OnInit } from '@angular/core';
import { BlogService } from '../blog.service';
import { DatePipe, Location, Time } from '@angular/common';
import { SafeHtml } from '@angular/platform-browser';
import Quill from 'quill';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { Comment, Blog, PaginatedComment } from '../../media.component';
import { CommentService } from '../comment.service';
import { Me, MeService } from 'src/app/profile/Me/me.service';
import { UserService } from '../../../profile/User/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { user } from '../../../GlobalVars/routingGlobal';
import { SubtopicRanking, PaginatedSubtopicRanking, SubtopicAggregationItem } from '../../../contest/contest.component';
import { SubtopicRankingService, TimeAndGameDurationPeriodType } from '../../../Aggregation/subtopic-ranking.service';
import { SubtopicAggregateItemService } from '../../../Aggregation/subtopic-aggregate-item.service';
import { ViewService } from '../../../Aggregation/view.service';
import { UpvoteService } from '../../../Aggregation/upvote.service';
import cloneDeep from 'lodash/cloneDeep';
import { StorageService } from '../../../shared/storage.service';
import { ContestType, TagReference } from '../../../contest/game/game.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.css']
})
export class ViewBlogComponent implements OnInit {
  copy: Blog;
  isMobile: boolean;
  _errorMessage: string;
  contestType: ContestType;
  ContestTypeEnum = ContestType;
  periodType: TimeAndGameDurationPeriodType;
  selectedTag: any;
  tagReferenceList: TagReference[];
  contestTypesFinance = [ContestType.MARGIN_OF_ERROR, ContestType.UNDER_OVER];
  contestTypesSports = [ContestType.MARGIN_OF_VICTORY, ContestType.TOTAL_POINTS_SCORED];
  contestTypes: ContestType[] = [];
  subtopicRankingsYearError: any;
  subtopicRankingsMonthError: any;
  throttle = 0;
  distance = 2;
  page = 1;

  throttleMobile = 0;
  distanceMobile = 1;
  endOfComments = false;
  viewableComments: any;

  constructor(public blogService: BlogService, private _location: Location,
              private datePipe: DatePipe, private meService: MeService,
              private commentService: CommentService, private userService: UserService,
              private router: Router, private route: ActivatedRoute,
              private subtopicRankingService: SubtopicRankingService,
              private subtopicAggregateItemService: SubtopicAggregateItemService,
              private viewService: ViewService, private upvoteService: UpvoteService,
              private storageService: StorageService) { }
  canViewBlog = false;
  title: string;
  transformedDate;
  topOfArticle: SafeHtml;
  ContestType = ContestType;
  quil: any; // Quill;
  focused;
  blurred;
  comments: SafeHtml[] = [];
  subtopicRankingCurrentMonth: SubtopicRanking;
  subtopicRankingPreviousMonth: SubtopicRanking;
  subtopicRankingYear: SubtopicRanking;
  subtopicAggregateItemCurrentMonth: SubtopicAggregationItem;
  subtopicAggregateItemPreviousMonth: SubtopicAggregationItem;
  subtopicAggregateItemYear: SubtopicAggregationItem;

  commentsSafe: SafeHtml[] = [];
  ngOnInit(): void {
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    if (this.router.url.includes('/author')){
      this.route.data.subscribe((results: any) => {
        if (results.data[1] instanceof HttpErrorResponse ){
          this.userService.user = results.data[0];
          this._errorMessage = results.data[1].error;
          this.blogService.blogSafeHtml = this.toHTML(this._errorMessage);
          this.commentService.paginatedComments = results.data[2];
        }
        else{
          this.userService.user = results.data[0];
          this.blogService.currentBlog = results.data[1];
          this.blogService.currentBlog.tagReferenceList.sort((a, b) => a.id - b.id);
          this.commentService.paginatedComments = results.data[2];
        }
      });
    }

    if (this.tags){
      this.selectedTag = this.tags[0];
    }
    if (this.blogService.currentBlog){
      if (this.blogService.currentBlog.topicName.toUpperCase() === 'FINANCE'){
        this.contestTypes = this.contestTypesFinance;
        this.contestType = this.contestTypes[0];
      }
      else if (this.blogService.currentBlog.topicName.toUpperCase() === 'SPORTS'){
        this.contestTypes = this.contestTypesSports;
        this.contestType = this.contestTypes[0];
      }
    }


    this.subtopicRankingService.getSubtopicRankingsForUser(
      this.blogService.currentBlog.subtopicId,
      this.blogService.currentBlog._owner_id,
      TimeAndGameDurationPeriodType.MONTH, this.contestType, this.selectedTag.id).subscribe((subtopicRanking) => {
        this.subtopicRankingCurrentMonth = subtopicRanking;
        this.subtopicRankingPreviousMonth = subtopicRanking; // TODO REMOVE
      },
      err => {
        this.subtopicRankingsMonthError = err.error;
        this.subtopicRankingCurrentMonth  = null;
        this.subtopicRankingPreviousMonth = null;
      });
    this.subtopicRankingService.getSubtopicRankingsForUser(
      this.blogService.currentBlog.subtopicId,
      this.blogService.currentBlog._owner_id,
      TimeAndGameDurationPeriodType.YEAR, this.contestType, this.selectedTag.id).subscribe((subtopicRanking) => {
        this.subtopicRankingYear = subtopicRanking;
      },
      err => {
        this.subtopicRankingsYearError = err.error;
        this.subtopicRankingYear = null;
       });

    this.subtopicAggregateItemService.getSubtopicAggregationItem(this.blogService.currentBlog.subtopicId,
      TimeAndGameDurationPeriodType.YEAR).subscribe((subtopicAggregateItem) => {
        this.subtopicAggregateItemYear = subtopicAggregateItem;
      });
    this.canViewBlog = true;
    if (!!!this._errorMessage){
      let data = this.blogService.currentBlog.contentString;
      // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
      data = this.toHTML(data);
      this.blogService.blogSafeHtml = data;
    }

    let title = this.blogService.currentBlog.title;
    // this.data = this._sanitizer.bypassSecurityTrustHtml(blog.contentString);
    title = this.toHTML(title);
    this.blogService.blogSafeHtmlTitle = title;
    this.transformedDate = this.datePipe.transform(this.blogService.currentBlog.date, 'medium');
    this.topOfArticle = this.toHTML(`T/${this.blogService.currentBlog.topicName}&amp;nbsp;&amp;nbsp; S/${this.blogService.currentBlog.subtopicName}&amp;nbsp;&amp;nbsp; Written by U/ li &lt;b&gt;${ this.blogService.currentBlog.author_fullname}&lt;/b&gt;${this.transformedDate} `);
    // this.blogService.currentBlog.comments.forEach(comment => {
    //   comment.contentStringSafeHtml = this.toHTML(comment.contentString);
    // });

    const x = 1;
    this.pageVisit(this.blogService.currentBlog);
    this.viewedByUser(this.blogService.currentBlog);

    this.copy = cloneDeep(this.blogService.currentBlog);
    this.copy.contentStringSafeHtml = this.toHTML(this.blogService.currentBlog.contentString);
    if (this.commentService.paginatedComments
      && this.commentService.paginatedComments._embedded
      && this.commentService.paginatedComments._embedded.commentDTOList){
        this.commentService.paginatedComments._embedded.commentDTOList.forEach(comment => {
          comment.contentStringSafeHtml = this.toHTML(comment.contentString);
        });
      }

    this.viewableComments = [];
    const nextPage = 0;

    this.commentService
      .getCommentsForBlog(this.blogService.currentBlog.id, nextPage)
      .subscribe((newComments: PaginatedComment) => {
          this.commentService.paginatedComments = newComments;
          newComments._embedded.commentDTOList.forEach(post => {
            const copy = post;
            copy.contentStringSafeHtml = this.toHTML(post.contentString);
            this.viewableComments.push(copy);
          });
      });
  }

  goBackToPreviousPage(): void{
    this._location.back();
  }

  toHTML(input): string {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
}

get errorMessage(): string{
  return this._errorMessage;
}

public addComment(quil: any): void {
  const comment: Comment = {
    contentString: quil as string,
    date: new Date(),
    downVotes: 0,
    upVotes: 0,
    username: this.meService.user.username,
    _blog_id: this.blogService.currentBlog.id,
    _owner_id: this.meService.user.id
  };
  this.commentService.postCommentsForBlog(comment).subscribe((res: Comment) => {
    console.log('comment added');
    // this.blogService.currentBlog.comments.unshift(res);
    // this.blogService.currentBlog.comments[0].contentStringSafeHtml = this.toHTML(this.blogService.currentBlog.comments[0].contentString);
    this.commentService.paginatedComments._embedded.commentDTOList.unshift(res);
    // tslint:disable-next-line:max-line-length
    this.commentService.paginatedComments._embedded.commentDTOList[0].contentStringSafeHtml = this.toHTML(this.commentService.paginatedComments._embedded.commentDTOList[0].contentString);
    this.viewableComments.unshift(res);
    this.viewableComments[0].contentStringSafeHtml = this.toHTML(this.viewableComments[0].contentString);
  });
}

public updateComment(quil: any, commentId: number): void {
  const comment: Comment = {
    contentString: quil as string,
    _blog_id: this.blogService.currentBlog.id,
    _owner_id: this.meService.user.id,
    id: commentId
  };
  // this.commentService.updateComment(comment).subscribe((res: Comment) => {
  //   console.log('comment update');
  //   // this.blogService.currentBlog.comments.unshift(res);
  //   // this.blogService.currentBlog.comments[0].contentStringSafeHtml =
  // this.toHTML(this.blogService.currentBlog.comments[0].contentString);
  //   this.commentService.paginatedComments._embedded.commentList.unshift(res);
  //   // tslint:disable-next-line:max-line-length
  //   this.commentService.paginatedComments._embedded.commentList[0].contentStringSafeHtml =
  // this.toHTML(this.commentService.paginatedComments._embedded.commentList[0].contentString);
  // });
}

public deleteComment(commentId: number): void {

  this.commentService.deleteComment(commentId).subscribe((res: Comment) => {
    console.log('comment deleted');
    const currentPage =  this.commentService.paginatedComments.page.number;
    this.commentService
      .getCommentsForBlog(this.blogService.currentBlog.id, currentPage)
      .subscribe((newComments: PaginatedComment) => {
        if (newComments?._embedded?.commentDTOList?.length > 0 &&
          this.commentService.paginatedComments?._embedded?.commentDTOList?.length > 0){
          this.commentService.paginatedComments._embedded.commentDTOList.push(...newComments._embedded.commentDTOList);
          this.commentService.paginatedComments._links = newComments._links;
          this.commentService.paginatedComments.page = newComments.page;
          newComments._embedded.commentDTOList.forEach(post => {
            const copy = post;
            copy.contentStringSafeHtml = this.toHTML(post.contentString);
            this.viewableComments.push(copy);
            this.ngOnInit();
          });
        }
        else{
          this.endOfComments = true;
          this.ngOnInit();
        }
      });
  });
}

public replyToComment(parentComment: Comment, quil: any): void {
  const comment: Comment = {
    contentString: quil as string,
    date: new Date(),
    downVotes: 0,
    upVotes: 0,
    username: this.meService.user.username,
    _blog_id: this.blogService.currentBlog.id,
    _owner_id: this.meService.user.id,
    _original_comment_id: parentComment.id
  };
  this.commentService.postCommentsForBlog(comment).subscribe((res: Comment) => {
    console.log('comment added');
    this.blogService.currentBlog.comments.unshift(res);
    this.blogService.currentBlog.comments[0].contentStringSafeHtml = this.toHTML(this.blogService.currentBlog.comments[0].contentString);
  });
}

get blog_owner_username(): string{
  return this.blogService.currentBlog.author_fullname;
}

get blog_owner_id(): number{
  return this.blogService.currentBlog._owner_id;
}


get date_written(): Date{
  return this.blogService.currentBlog.createDate;
}


get upvotes(): number{
  return this.blogService.currentBlog.upVotes;
}

get downvotes(): number{
  return this.blogService.currentBlog.downVotes;
}

get views(): number{
  return this.blogService.currentBlog.viewCount;
}

get pageVisits(): number{
  return this.blogService.currentBlog.pageVisits;
}

get loggedInUserViews(): number{
  return this.blogService.currentBlog.loggedInUserViews;
}

upvoteBlog(currentBlog: Blog): void{
    this.upvoteService.upvoteBlog(currentBlog.id).subscribe(
      result => {
        console.log('Successfully sent upvote');
        if (result.message === 'upvoteNew'){
          console.log('Adding to upvote locally');
          this.blogService.currentBlog.upVotes += 1;
        }
        else if (result.message === 'exists'){
          console.log('Already upvoted this blog');
        }
        else if (result.message === 'upvoteSwap'){
          this.blogService.currentBlog.upVotes += 1;
          this.blogService.currentBlog.downVotes -= 1;
          console.log('Already upvoted this blog');
        }
      },
      err => {
        console.log('Error with upvote');
      }
    );
}
get commentsFromCommentService(): Comment[]{
  if (this.commentService.paginatedComments
    && this.commentService.paginatedComments._embedded
    && this.commentService.paginatedComments._embedded.commentDTOList){
      return this.commentService.paginatedComments._embedded.commentDTOList;
    }
    else{
      return null;
    }

}

downvoteBlog(currentBlog: Blog): void{
  this.upvoteService.downvoteBlog(currentBlog.id).subscribe(
    result => {
      console.log('Successfully sent downvote');
      if (result.message === 'downvoteNew'){
        console.log('Adding to downvote locally');
        this.blogService.currentBlog.downVotes += 1;
      }
      else if (result.message === 'exists'){
        console.log('Already downvoted this blog');
      }
      else if (result.message === 'downvoteSwap'){
        this.blogService.currentBlog.downVotes += 1;
        this.blogService.currentBlog.upVotes -= 1;
        console.log('Already downvoted this blog');
      }
    },
    err => {
      console.log('Error with downvote');
    }
  );
}

get currentBlogData(): Blog{
  if (this.blogService.currentBlog){
    return this.blogService.currentBlog;
  }
  else{
    return null;
  }
}

pageVisit(currentBlog: Blog): void{
  this.viewService.pageVisit(currentBlog.id).subscribe(
    result => {
      console.log('Successfully sent page visit');
    },
    err => {
      console.log('Error with page visit');
    }
  );
}

viewedByUser(currentBlog: Blog): void{
  if (this.storageService.isLoggedIn()){
    this.viewService.viewBlogAsSignedInUser(currentBlog.id).subscribe(
      result => {
        console.log('Successfully viewed page as user');
      },
      err => {
        console.log('Error with viewed page as user');
      }
    );
  }

}


upvoteComment(comment: Comment): void{
  this.upvoteService.upvoteComment(comment.id).subscribe(
    result => {
      console.log('Successfully sent upvote');
      if (result.message === 'upvoteNew'){
        console.log('Adding to upvote locally');
        comment.upVotes += 1;
        comment = cloneDeep(comment);
      }
      else if (result.message === 'exists'){
        console.log('Already upvoted this blog');
      }
      else if (result.message === 'upvoteSwap'){
        console.log('Already upvoted this blog');
        comment.upVotes += 1;
        comment.downVotes -= 1;
        comment = cloneDeep(comment);
      }
    },
    err => {
      console.log('Error with upvote');
    }
  );
}

downvoteComment(comment: Comment): void{
  this.upvoteService.downvoteComment(comment.id).subscribe(
    result => {
      console.log('Successfully sent upvote');
      if (result.message === 'downvoteNew'){
        console.log('Adding to upvote locally');
        comment.downVotes += 1;
      }
      else if (result.message === 'exists'){
        console.log('Already upvoted this blog');
      }
      else if (result.message === 'downvoteSwap'){
        comment.downVotes += 1;
        comment.upVotes -= 1;
        console.log('Already upvoted this blog');
        comment = cloneDeep(comment);
      }
    },
    err => {
      console.log('Error with upvote');
    }
  );
}


public saveBlog(): void{}


viewUserProfile(userId: number): void {
  // tslint:disable-next-line:no-shadowed-variable
  this.userService.getUser(userId).subscribe((user: Me) => {
    this.userService.user = user;
    this.userService.id = user.id;
    this.router.navigate([routingGlobals.absUser, userId]);
  });
}

created(event: Quill): void {
  // tslint:disable-next-line:no-console
  console.log('editor-created', event);
}

changedEditor(event: EditorChangeContent | EditorChangeSelection): void {
  // tslint:disable-next-line:no-console
  console.log('editor-change', event);
}

focus($event): void {
  // tslint:disable-next-line:no-console
  console.log('focus', $event);
  this.focused = true;
  this.blurred = false;
}

blur($event): void {
  // tslint:disable-next-line:no-console
  console.log('blur', $event);
  this.focused = false;
  this.blurred = true;
}

get commentPlaceholderValue(): string{
  if (this.meService.isAuthenticated()){
    return 'Write your comment here';
  }
  else{
    return 'Must be logged in to submit comment';
  }
}

isAuthenticated(): boolean{
    return this.meService.isAuthenticated();
}

isAuthenticatedAndIsAuthorOfBlog(): boolean{
  return this.meService.isAuthenticated() && this.meService.id === this.blogService.currentBlog._owner_id ;
}

isAuthenticatedAndIsAuthorOfComment(commentAuthorId: number): boolean{
  return this.meService.isAuthenticated() && this.meService.id === commentAuthorId ;
}

editBlog(): void{
  this.router.navigate([routingGlobals.absProfile + '/' + routingGlobals.absCreateBlog],
   { queryParams: { blogId: this.blogService.currentBlog.id }});
}

deleteBlog(): void{
  this.router.navigate([routingGlobals.absProfile, this.meService.id]);
}

selectContestType(contestType: ContestType): void {
  this.contestType = contestType;
  this.refreshRankings();
}

refreshRankings(): void {

    this.subtopicRankingService.getSubtopicRankingsForUser(
      this.blogService.currentBlog.subtopicId,
      this.blogService.currentBlog._owner_id,
      TimeAndGameDurationPeriodType.MONTH, this.contestType, this.selectedTag.id).subscribe((subtopicRanking) => {
        this.subtopicRankingCurrentMonth = subtopicRanking;
        this.subtopicRankingPreviousMonth = subtopicRanking; // TODO REMOVE
      },
      err => {
        this.subtopicRankingsMonthError = err.error;
        this.subtopicRankingCurrentMonth  = null;
        this.subtopicRankingPreviousMonth = null;
      });
    this.subtopicRankingService.getSubtopicRankingsForUser(
      this.blogService.currentBlog.subtopicId,
      this.blogService.currentBlog._owner_id,
      TimeAndGameDurationPeriodType.YEAR, this.contestType, this.selectedTag.id).subscribe((subtopicRanking) => {
        this.subtopicRankingYear = subtopicRanking;
      },
      err => {
        this.subtopicRankingsYearError = err.error;
        this.subtopicRankingYear = null;
       });

  }

selectTag(tag: any): void {

  this.selectedTag = tag;
  this.subtopicRankingService.getSubtopicRankingsForUser(
    this.blogService.currentBlog.subtopicId,
    this.blogService.currentBlog._owner_id,
    TimeAndGameDurationPeriodType.MONTH, this.contestType, this.selectedTag.id).subscribe((subtopicRanking) => {
      this.subtopicRankingCurrentMonth = subtopicRanking;
      this.subtopicRankingPreviousMonth = subtopicRanking; // TODO REMOVE
    },
    err => {
      this.subtopicRankingsMonthError = err.error;
      this.subtopicRankingCurrentMonth  = null;
      this.subtopicRankingPreviousMonth = null;
    });

  this.subtopicRankingService.getSubtopicRankingsForUser(
    this.blogService.currentBlog.subtopicId,
    this.blogService.currentBlog._owner_id,
    TimeAndGameDurationPeriodType.YEAR, this.contestType, this.selectedTag.id).subscribe((subtopicRanking) => {
      this.subtopicRankingYear = subtopicRanking;
    },
    err => {
      this.subtopicRankingsYearError = err.error;
      this.subtopicRankingYear = null;
     });
}

get tags(): TagReference[]{
  return this.blogService.currentBlog?.tagReferenceList;
}


isSelectedContestType(contestType: string): boolean {
  return this.contestType === contestType;
}

onScroll(): void {
  const nextPage =  this.commentService.paginatedComments.page.number + 1;
  this.commentService
    .getCommentsForBlog(this.blogService.currentBlog.id, nextPage)
    .subscribe((newComments: PaginatedComment) => {
      if (newComments?._embedded?.commentDTOList?.length > 0 &&
        this.commentService.paginatedComments?._embedded?.commentDTOList?.length > 0){
        this.commentService.paginatedComments._embedded.commentDTOList.push(...newComments._embedded.commentDTOList);
        this.commentService.paginatedComments._links = newComments._links;
        this.commentService.paginatedComments.page = newComments.page;

        newComments._embedded.commentDTOList.forEach(post => {
          const copy = post;
          copy.contentStringSafeHtml = this.toHTML(post.contentString);
          this.viewableComments.push(copy);
        });
      }
      else{
        this.endOfComments = true;
      }
    });
}
}
