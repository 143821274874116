import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Game, GameService } from 'src/app/contest/game/game.service';
import * as routingGlobals from '../../../../GlobalVars/routingGlobal';
import { createFinancialContestModalCreateContestOrAnotherGame } from '../../../../GlobalVars/routingGlobal';
import { CreateContestService } from '../../create-contest.service';
import { ContestStateService } from '../../../contest-state.service';
@Component({
  selector: 'app-create-financial-contest-modal-review-and-submit',
  templateUrl: './create-financial-contest-modal-review-and-submit.component.html',
  styleUrls: ['./create-financial-contest-modal-review-and-submit.component.css']
})
export class CreateFinancialContestModalReviewAndSubmitComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router,
              private createContestService: CreateContestService, private gameService: GameService,
              private contestStateService: ContestStateService) {}

  nextPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalCreateContestOrAnotherGame], {relativeTo: this.route});
  }
  previousPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalDateSelection], {relativeTo: this.route});
  }
  startPage(): void {
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalStockSelection], {relativeTo: this.route});
  }

  ngOnInit(): void {
    this.createGame();
  }

  get games(): Game[]{
    return this.gameService.games;
  }

  get currentGame(): Game{
    return this.currentGame;
  }

  canCreateGame(): boolean{
    return this.createContestService.canCreateGame();
  }

  createGame(): void {
    const game: Game = {betSlip: this.createContestService.betSlip,
      stockSymbol: this.createContestService.gameObject.name,
      name: this.createContestService.currentGameType.name, // todo need this for java atm
      field: this.createContestService.fieldToBetOn,
      startTime: this.createContestService.startDate,
      endTime: this.createContestService.endDate,
      gameType: this.createContestService.currentGameType,
      gameTypeName: this.createContestService.currentGameType.name,
      gameState: 'UPCOMING'
      };
    this.gameService.games.push(game);
    this.gameService.currentGame = game;
    this.createContestService.setEvents(this.createContestService.currentGameType);
    this.contestStateService.currentContest.games = [];
    this.contestStateService.currentContest.games = this.gameService.games;
    this.router.navigate(['../' + routingGlobals.createFinancialContestModalCreateContestOrAnotherGame], {relativeTo: this.route});
  }

}
