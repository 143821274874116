import { Component, OnInit } from '@angular/core';
import {  SubtopicRankingService } from 'src/app/Aggregation/subtopic-ranking.service';
import { PaginatedSubtopicRanking, SubtopicRanking } from 'src/app/contest/contest.component';
import { MeService } from '../../Me/me.service';
import { TimeAndGameDurationPeriodType } from '../../../Aggregation/subtopic-ranking.service';

@Component({
  selector: 'app-user-card-horizontal',
  templateUrl: './user-card-horizontal.component.html',
  styleUrls: ['./user-card-horizontal.component.css']
})
export class UserCardHorizontalComponent implements OnInit {
  constructor(private subtopicRankingService: SubtopicRankingService, private meService: MeService) { }

  ngOnInit(): void {
    this.subtopicRankingService.getAllSubtopicRankingsForUser(
      this.meService.id,
      TimeAndGameDurationPeriodType.YEAR).subscribe((paginatedSubtopicRanking: PaginatedSubtopicRanking) => {
        this.subtopicRankingService.currentPaginatedSubtopicRanking = paginatedSubtopicRanking;
      });
  }

  get currentSubtopicRanking(): SubtopicRanking{

    if (this.subtopicRankingService.currentPaginatedSubtopicRanking
       && this.subtopicRankingService.currentPaginatedSubtopicRanking._embedded
      && this.subtopicRankingService.currentPaginatedSubtopicRanking._embedded.subtopicRankingDTOList){
        return this.subtopicRankingService.currentPaginatedSubtopicRanking._embedded.subtopicRankingDTOList[0];
      }
    return null;

  }
}
