import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MeService } from '../profile/Me/me.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return true; // this.canActivate(childRoute, state);
  }
  constructor(private meService: MeService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    let isAuthenticated: boolean = this.meService.isAuthenticated();
    isAuthenticated = true;
    if (isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
