<!-- <div class="row">
  <div class="col-lg-12">
    <p>
      Would you like to sign up for our subscription revenue share contract
      model which allows you to potentially earn money from our site?
    </p>
    <p>More information about this program is linked here:</p>
    <a routerLink="/about-us" target="_blank"
      ><b>Click Here For More Info</b></a
    >

    <div class="col-md-12">
      <div id="dropin-container"></div>
    </div>
    <button
      id="submit-button"
      type="button"
      class="approve-btn approve-btn-text btn-sm btn-success text-center m-3"
    >
      Activate Subscription
    </button>
    <button
      class="btn-sm btn-danger text-center m-3"
      (click)="continueToHomePage()"
    >
      No, Im not interested
    </button>
    <div
      *ngIf="!!errorMessage"
      class="bg-danger d-flex justify-content-center font-italic font-weight-bold"
      style="font-size: 14px"
    >
      {{ errorMessage }}
    </div>
  </div>
</div> -->
<p>Earn Money from writing articles in our revenue share program!</p>
<p>Only available to premium subscribers!</p>
<div class="row">
  <div class="col-12">
    <head>
      <title>Checkout</title>
      <script src="https://js.stripe.com/v3/"></script>
    </head>
    <!-- <div> Current Special - 3 day trial period - If you dont like our product you can cancel any subscription at any time within the first 3 days in the Account Settings Tab and you will not be charged</div> -->
    <div class="container ml-1 mt-4">
      <div class="row">
        <div class="col-sm">
          <div class="card" style="width: 28rem" (click)="checkout('daily')">
            <div class="card-body">
              <h5 class="card-title">Premium Plan</h5>
              <p class="card-text">Monthly Subscription: $10</p>
              <button (click)="checkout('Basic')" class="btn btn-primary">
                Click to subscribe now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p>Whats available in our Premium Plan?</p>
<ul>
  <li>
    Ability to be selected for our revenue share program where we give
    subscribers Monthly writing contracts for creating premium content for the
    site. More about that here: Link
  </li>
  <li>Access to subscriber only contests</li>
  <li>Ablity to write in premium only tags</li>
  <li>Ablity to read in premium only tagged content</li>
  <li>
    Raised Article limits for writing content. Non Subscribers can only write
    once per day. Subscribers can write 15 Articles per day
  </li>
  <li>
    Access to premium content written by our paid writers who we offer contracts
    in our revenue share program
  </li>
  <li>
    Ability to cancel any time easily in our account settings page. No Hoops to
    jump through if our site isnt for you and you want to cancel
  </li>
</ul>
