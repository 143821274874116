<div class="ml-1 mt-4 font-weight-light">
  <div class="row">
    <div class="col text-center mb-3 container ml-1 mt-4 font-weight-light">
      <div class="md-form">
        <input
          type="search"
          [(ngModel)]="searchText"
          placeholder="Search text"
        />
      </div>
      <ul *ngFor="let data of availableStocks | search: searchText">
        <li style="list-style-type: none" (click)="selectCurrentStock(data)">
          {{ data.name }}
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="!!selectedGameObjectName" class="text-center">
    You have selected {{ selectedGameObjectName }} for your game
  </div>
</div>
<div class="ml-1 mt-4 font-weight-light">
  <ul class="pagination">
    <li class="page-item" [class.disabled]="!selectedGameObjectName">
      <a class="page-link" (click)="nextPage()">Next</a>
    </li>
  </ul>
</div>
