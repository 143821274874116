import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeUntilNextTrigger'
})
export class TimeUntilNextTriggerPipe implements PipeTransform {

    transform(date: Date, currentDateTime: number): string {
      // console.log('update time ' + currentDateTime);
      const contestStartInMs = new Date(date).getTime();
      const currentTimeInMs = new Date().getTime();
      const ms = contestStartInMs - currentTimeInMs ;
      if (ms < 0){
        return 'Can now retrigger one time password';
      }
      const days = Math.floor( ms / (24 * 60 * 60 * 1000));
      const daysms = ms % (24 * 60 * 60 * 1000);
      const hours = Math.floor(daysms / (60 * 60 * 1000));
      const hoursms = ms % (60 * 60 * 1000);
      const minutes = Math.floor(hoursms / (60 * 1000));
      const minutesms = ms % (60 * 1000);
      const sec = Math.floor(minutesms / 1000);
      if (days > 0){
        return days + ' Days';
      }
      else{
        return 'Can retrigger one time password in: ' + sec + ' seconds';
      }
    }
}
