<div class="row">
  <table class="table table-striped mr-3 table-xs table-hover table-condensed">
    <thead>
      <tr>
        <th scope="col">Attribute</th>
        <th scope="col">This Month</th>
        <th scope="col">Last Month</th>
        <th scope="col">All Time</th>
        <!-- <th scope="col">Coin Value</th> -->
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>H2H Wins:</td>
        <td>{{ currentSubtopicRanking.totalH2HWins }}</td>
        <td>{{ currentSubtopicRanking.totalH2HWins }}</td>
        <td>{{ currentSubtopicRanking.totalH2HWins }}</td>
      </tr>
      <tr>
        <td>H2H Losses:</td>
        <td>{{ currentSubtopicRanking.totalH2HLosses }}</td>
        <td>{{ currentSubtopicRanking.totalH2HLosses }}</td>
        <td>{{ currentSubtopicRanking.totalH2HLosses }}</td>
      </tr>
      <tr>
        <td>H2H Draws:</td>
        <td>{{ currentSubtopicRanking.totalH2HDraws }}</td>
        <td>{{ currentSubtopicRanking.totalH2HDraws }}</td>
        <td>{{ currentSubtopicRanking.totalH2HDraws }}</td>
      </tr>
      <tr>
        <td>Game Wins:</td>
        <td>{{ currentSubtopicRanking.totalGameWins }}</td>
        <td>{{ currentSubtopicRanking.totalGameWins }}</td>
        <td>{{ currentSubtopicRanking.totalGameWins }}</td>
      </tr>
      <tr>
        <td>Game Losses:</td>
        <td>{{ currentSubtopicRanking.totalGameLosses }}</td>
        <td>{{ currentSubtopicRanking.totalGameLosses }}</td>
        <td>{{ currentSubtopicRanking.totalGameLosses }}</td>
      </tr>
      <tr>
        <td>Elo:</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
      </tr>
      <tr>
        <td>Avg Contest Finish %:</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
      </tr>
      <tr>
        <td># of Contests Played:</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
      </tr>
      <tr>
        <td># of Articles Written:</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
      </tr>
      <tr>
        <td>Avg ^ per article:</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
        <td>{{ currentSubtopicRanking.eloValue | number : "1.2-2" }}</td>
      </tr>
    </tbody>
  </table>
</div>
