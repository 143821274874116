import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidatorFn,
  FormControl,
  EmailValidator,
} from '@angular/forms';

@Directive({
  selector: '[appEmailValid]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true },
  ],
})
export class EmailValidDirective implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.emailValidator();
  }
  // tslint:disable-next-line:typedef
  validate(c: FormControl) {
    return this.validator(c);
  }
  emailValidator(): ValidatorFn {
    return (c: FormControl) => {
      const isValid = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(
        c.value
      );
      if (isValid) {
        return null;
      } else {
        return { emailValidator: { valid: false } };
      }
    };
  }
}
