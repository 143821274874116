<div
  *ngIf="!!viewableContests && viewableContests.length > 0"
  class="text-center my-2"
>
  <table
    class="table table-striped table-dark table-xs table-hover table-condensed"
    style="width: 100%; word-wrap: break-word"
  >
    <thead>
      <tr>
        <!-- <th *ngIf="!isMobile" scope="col">Subtopic</th> -->
        <th *ngIf="!isMobile" scope="col">Subtopic</th>
        <th scope="col">Contest</th>
        <th scope="col">Type</th>
        <!-- <th scope="col">Style</th> -->
        <!-- <th *ngIf="!isMobile" scope="col"># Entries</th> -->
        <th scope="col"># Entries</th>
        <th scope="col">{{ dateFieldHeaderText }}</th>
        <th scope="col">{{ enterButtonHeaderText }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let contest of viewableContests"
        [ngClass]="{
          processed: contest.payoutType === 'Halving'
        }"
      >
        <!-- <td *ngIf="!isMobile">
          {{ contest.subtopicReference.name }}
        </td> -->
        <td *ngIf="!isMobile">
          {{ contest.subtopicReference.name }}
        </td>
        <td>{{ contest.name | allCapsUnderscore }}</td>
        <td *ngIf="contest.contestType === ContestType.MARGIN_OF_ERROR">
          Margin Of Error
        </td>
        <td *ngIf="contest.contestType === ContestType.UNDER_OVER">
          Under/ Over
        </td>
        <td *ngIf="contest.contestType === ContestType.MARGIN_OF_VICTORY">
          Margin Of Victory
        </td>
        <td *ngIf="contest.contestType === ContestType.TOTAL_POINTS_SCORED">
          Total Points Scored
        </td>
        <!-- <td>Under/ Over</td> -->
        <!-- <td>{{ contest.entryFee | number : "1.2-2" }}</td>
        <td>{{ contest.prizePool | number : "1.2-2" }}</td> -->
        <!-- <td *ngIf="!isMobile">
          {{ contest.numberOfCompetitors }}/{{ contest.maxUsers }}
        </td> -->
        <td>{{ contest.numberOfCompetitors }}/ {{ contest.maxUsers }}</td>
        <td *ngIf="startingIn">
          {{ contest.startTime | timeUntil : currentDatetime }}
          <!-- {{ contest.endTime | date: "medium" }} -->
        </td>
        <td *ngIf="!startingIn">
          {{ contest.startTime | date : "medium" }}/
          {{ contest.endTime | date : "medium" }}
          <!-- {{ contest.endTime | date: "medium" }} -->
        </td>
        <td>
          <button
            class="btn-sm btn-success text-center"
            style="font-size: 8.8px"
            (click)="viewNonEnteredContest(contest)"
          >
            {{ enterButtonText }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!!!viewableContests || viewableContests.length < 1">
  <p>No contests available for current selections</p>
</div>
