<div class="row justify-content-center">
  <div class="col-auto text-center">
    <div>
      <div ngbDropdown class="d-inline-block">
        <button
          class="btn-sm btn-secondary ml-1 mt-4 font-weight-light"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          Choose Game Type
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div
            *ngFor="let gameType of gameTypes"
            (click)="selectGameType(gameType)"
          >
            <button
              class="btn-sm btn-secondary ml-1 mt-4 font-weight-light"
              ngbDropdownItem
            >
              {{ gameType.type }} <br />
            </button>
          </div>
        </div>
      </div>
      <p *ngIf="currentGameType">
        {{ currentGameType.type }}: {{ currentGameType.description }}
      </p>
    </div>
  </div>
</div>
<ul class="pagination">
  <li class="page-item">
    <a class="page-link" (click)="previousPage()">Previous</a>
  </li>
  <li class="page-item" [class.disabled]="!currentGameType">
    <a class="page-link" (click)="nextPage()">Next</a>
  </li>
  <li class="page-item">
    <a class="page-link" (click)="startPage()">Start</a>
  </li>
</ul>
