import { Component, Input, OnInit } from '@angular/core';
import { TimeAndGameDurationPeriodType } from 'src/app/Aggregation/subtopic-ranking.service';
import { UserContestState, GlobalContestState } from 'src/app/GlobalVars/Enums/ContestEnums';
import { Game, ContestType, UnderOverOutcome } from '../../game/game.service';
import { ContestStateService } from '../../contest-state.service';
import { StorageService } from 'src/app/shared/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnterContestModalContentComponent } from '../enter-contest-modal-content/enter-contest-modal-content.component';
import { Router } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';

@Component({
  selector: 'app-under-over-upcoming',
  templateUrl: './under-over-upcoming.component.html',
  styleUrls: ['./under-over-upcoming.component.css']
})
export class UnderOverUpcomingComponent implements OnInit {
  @Input()
  games: Game[];
  @Input()
  containsOtherUserBetslip = false;
  @Input()
  isMe: boolean;

  UserContestState = UserContestState;

  UnderOverOutcome = UnderOverOutcome;

  ContestType = ContestType;

  TimeAndGameDurationPeriodType = TimeAndGameDurationPeriodType;

  GlobalContestState = GlobalContestState;
  constructor(public contestStateService: ContestStateService, private storageService: StorageService,
              private modalService: NgbModal,  private router: Router ) { }

  ngOnInit(): void {
  }

  get currentContest(): any {
    return this.contestStateService.currentContest;
  }
  get userContestState(): UserContestState{
    if (this.contestStateService && this.contestStateService.currentContest && this.contestStateService.currentContest.userContestState){
      return this.contestStateService.currentContest.userContestState;
    }

  }
  get isLoggedIn(): boolean {
    return this.storageService.isLoggedIn();
  }
  get contestSubtopic(): any{
    return this.contestStateService.currentContest.subtopicReference;
  }
  canSubmitContestEntry(): boolean {
    if (this.currentContest.contestType === ContestType.UNDER_OVER){
      let canSubmitEntry = true;
      this.games.forEach((game: Game) => {
        if (game.betSlip.under === false && game.betSlip.over === false){
          canSubmitEntry = false;
          console.log('likely had bet slip error uncaught in api');
          return;
        }
      });
      if (this.contestStateService.currentContest.globalContestState !== GlobalContestState.UPCOMING){
        return false;
      }
      const contestStartInMs = new Date(this.contestStateService.currentContest.startTime).getTime();
      const currentTimeInMs = new Date().getTime();
      const ms = contestStartInMs - currentTimeInMs ;
      if (ms < 0){
        // contest has started
        return false;
      }
      if (!this.isMe){
        return false;
      }
      return canSubmitEntry;
    }
    else if (this.currentContest.contestType === ContestType.MARGIN_OF_ERROR){
      let canSubmitEntry = true;
      this.games.forEach((game: Game) => {
        if (game.betSlip.predictedValue < 0){
          canSubmitEntry = false;
          return;
        }
      });
      if (this.contestStateService.currentContest.globalContestState !== GlobalContestState.UPCOMING){
        return false;
      }
      const contestStartInMs = new Date(this.contestStateService.currentContest.startTime).getTime();
      const currentTimeInMs = new Date().getTime();
      const ms = contestStartInMs - currentTimeInMs ;
      if (ms < 0){
        // contest has started
        return false;
      }
      return canSubmitEntry;
    }
  }

  selectUnderOver(selection: string, game: Game): void{

    const betSlip = game.betSlip;
    if (selection === 'over'){
      betSlip.over = true;
      betSlip.under = false;
      game.betSlip = betSlip;
    }
    else{
      betSlip.over = false;
      betSlip.under = true;
      game.betSlip = betSlip;
    }
  }

  enterContest(): void{
    const modalRef = this.modalService.open(EnterContestModalContentComponent, { centered: true,  size: 'lg'});
    modalRef.componentInstance.name = 'World';
  }

  linkToSignUpPage(): void{
    this.router.navigate([routingGlobals.absSignup]);
  }
  updateNameOfContest(prediction: number, game: Game ): void{
    if (!prediction){
      prediction = -1000;
    }
    const betSlip = game.betSlip;

    betSlip.predictedValue = prediction;
    game.betSlip = betSlip;

  }
}
