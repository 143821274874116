import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { BlogService } from '../media/blog/blog.service';
import { CommentService } from '../media/blog/comment.service';
import { UserService } from '../profile/User/user.service';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:ban-types
export class EditBlogResolverService implements Resolve<Object>{


  constructor(private blogService: BlogService,
              private commentService: CommentService) { }
// tslint:disable-next-line:ban-types
resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
// const userId = +route.paramMap.get('userId');
// const blogId = +route.paramMap.get('blogId');
// const blogId = +params.blogId;

const blogId = +route.queryParamMap.get('blogId');
if (!!!blogId){
  return;
}

const blog = this.blogService.getBlog(blogId);
const comments = this.commentService.getCommentsForBlog(blogId);

return forkJoin([blog, comments]);
}

collectRouteParams(stack: ActivatedRouteSnapshot[]): any {
let params = {};
// const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
while (stack.length > 0) {
// tslint:disable-next-line:no-non-null-assertion
const route = stack.pop()!;
params = {...params, ...route.params};
stack.push(...route.children);
}
return params;
}
}
