import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GameService} from '../game/game.service';
import { ContestService } from '../contest.service';
import { TopicService } from '../../topic/topic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContestStateService } from '../contest-state.service';

@Component({
  selector: 'app-create-contest',
  templateUrl: './create-contest.component.html',
  styleUrls: ['./create-contest.component.css']
})
export class CreateContestComponent implements OnInit {
  topics = []; // [{name: 'Sports', subtopics: [{name: 'Football'}, {name: 'Basketball'}, {name: 'Baseball'}]},
  // {name: 'Economics', subtopics: [{name: 'Macro Trends'}, {name: 'Inflation'}, {name: 'Price Changes'}]},
  // {name: 'Stocks', subtopics: [{name: 'Dow Jones'}, {name: 'Asian Markets'}, {name: 'Daily Changes'}]},
  // {name: 'Politics', subtopics: [{name: 'Global'}, {name: 'US'}, {name: 'State'}]},
  // {name: 'Social Media', subtopics: [{name: 'Instagram'}, {name: 'Tik Tok'}, {name: 'SnapChat'}]}];


  @ViewChild('nameOfContestInput') nameOfContestInput; // accessing the reference element

  nameOfContest = '';
  games = [];
  currentContesName;
  isCreateGames = false;
  availableSlots = 0;
  numberOfUsers = 0;
  maxEntries = 0;
  startDate = new Date();
  constructor(
              private modalService: NgbModal,
              private gameService: GameService,
              private contestStateService: ContestStateService,
              private contestService: ContestService,
              private topicService: TopicService,
              private activatedRoute: ActivatedRoute,
              private router: Router
    ) { }

ngOnInit(): void {
}

createGames(): void{
  this.isCreateGames = true;
}
addContestName(contestName): void{
  this.currentContesName = contestName;
  this.contestStateService.currentContest.name = contestName;
  console.log('currname ' + this.currentContesName);
  }

contestHasGames(): boolean {
  return this.gameService.games.length > 0;
}

updateNameOfContest(nameOfContest: string): void{
  this.nameOfContest = nameOfContest;
}

get subtopicName(): string{
  return this.contestStateService.currentContest.subtopicReference.name;
}

get topicName(): string{
  return this.contestStateService.currentContest.topicReference.name;
}

isSportsTopic(): boolean{
  if ( this.contestStateService.currentContest.topicReference.name === 'Stock Market'){
    return true;
  }
  return false;
}
isStockMarketTopic(): boolean{
  if ( this.contestStateService.currentContest.topicReference.name === 'Stock Market' ){
    return true;
  }
  return false;
}
isEconomicTopic(): boolean{
  if ( this.contestStateService.currentContest.topicReference.name === 'Stock Market' ){
    return true;
  }
  return false;
}

}
