import { Component, OnInit } from '@angular/core';
import { TopicsEntity, UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContestService } from 'src/app/contest/contest.service';
import { ContestStateService } from 'src/app/contest/contest-state.service';
import { Contest, ContestType, DurationType } from 'src/app/contest/game/game.service';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { TopicService } from '../../../topic/topic.service';
import { GlobalContestState, UserContestState } from 'src/app/GlobalVars/Enums/ContestEnums';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  show = true;

  showText = 'collapse navbar-collapse show';
  dontShowTest = 'collapse navbar-collapse';
  currentText = this.showText;

  profilePictureUrl = 'https://infomarketz-content.s3.amazonaws.com/profilePictures/5.jpg';
  isMobile: boolean;


  constructor(public userService: UserService,
              private router: Router, private contestService: ContestService,
              private topicService: TopicService, private contestStateService: ContestStateService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.viewUserPosts();
    if (window.screen.width < 520) { // 768px portrait
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    const contest: Contest = {
      topicReference: null,
      subtopicReference: null,
      name: '',
      games: [],
      maxUsers: 100000,
      globalContestState: GlobalContestState.NONE,
      userContestState: UserContestState.NONE,
      durationType: DurationType.ALL,
      contestType: ContestType.ALL
    };
    this.contestStateService.viewNonEnteredUpcomingContest(contest);
    this.route.data.subscribe((response: any) => {
      this.topicService.allTopics = response.topicData;
      this.userService.user.paginatedTopics = response;
    });
  }

  viewUserContests(): void{
    this.router.navigate(['./' + routingGlobals.userContests], {relativeTo: this.route});
  }

  viewUserBlogs(): void{
    this.router.navigate(['./' + routingGlobals.userBlogs], {relativeTo: this.route});
  }
  viewSocialLinks(): void {
    this.router.navigate(['./' + routingGlobals.userSocialLinks], {relativeTo: this.route});
  }

  viewUserPosts(): void{
    this.router.navigate(['./' + routingGlobals.userPosts], {relativeTo: this.route});
  }

  viewUserSubscriptions(): void{
    this.router.navigate(['./' + routingGlobals.userSubscriptions], {relativeTo: this.route});
  }

  viewAccounts(): void{
    this.router.navigate(['./' + routingGlobals.userAccount], {relativeTo: this.route});
  }

  viewAccountSettings(): void{
    this.router.navigate(['./' + routingGlobals.userAccountSettings], {relativeTo: this.route});
  }

  get username(): any{
    if (this.userService.user && this.userService.user.username){
      return this.userService.user.username;
    }
  }

  get followers(): any{
    return null;
  }

  get following(): any{
    return null;
  }

  isAuthenticated(): boolean{
    return false;
  }

  toggleShowDropDown(): void {
    if (this.show) {
      this.show = !this.show;
      this.currentText = this.dontShowTest;
    } else {
      this.show = !this.show;
      this.currentText = this.showText;
    }
  }


}
