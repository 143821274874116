import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { RevenueShareSignupService } from './revenue-share-signup.service';
import { MeService } from 'src/app/profile/Me/me.service';
import { HttpClient } from '@angular/common/http';
import { HOST_IP_INFO_MARKETS, STRIPE_PK, STRIPE_STANDARD_SUB } from 'src/app/GlobalVars/api';

declare var braintree: any;
declare var Stripe;

@Component({
  selector: 'app-revenue-share-signup',
  templateUrl: './revenue-share-signup.component.html',
  styleUrls: ['./revenue-share-signup.component.css']
})
export class RevenueShareSignupComponent implements OnInit {
  showBraintree: boolean;
  errorMessage: string;

  stripe;

  constructor(private httpClient: HttpClient, private router: Router, private revenueShareSignupService: RevenueShareSignupService,
              private elementRef: ElementRef, private meService: MeService) { }

  ngOnInit(): void {
    this.stripe = Stripe(STRIPE_PK);
    const submitButton = this.elementRef.nativeElement.querySelector('#submit-button');
    // this getClientToken() service method (GET request) calls the spring boot backend and get the client token that
    // needs to initialise the Braintree SDK
    this.meService.getClientToken().subscribe(token => {
      braintree.dropin.create({
        authorization: token.message,
        selector: '#dropin-container',
        paypal: {
          flow: 'vault'
        },
        }, (err, dropinInstance) => {
          if (err) {
            // Errors when creating Drop-in
            console.error(err);
            return;
          }
          // this listnes for any click by the user to initiate the subscription
          submitButton.addEventListener('click', () => {
            // this requestPaymentMethod() gets the user specified payment method (Card or Paypal)
            // and return a nonce that should be sent to our spring boot server
            // tslint:disable-next-line:no-shadowed-variable
            dropinInstance.requestPaymentMethod((err, payload) => {
              if (err) {
                this.errorMessage = err.message + ' You must fill out above card information correctly. Please retry filling out your card info';
                console.error('err', err);
                return;
              }
              const user = {name: this.meService.user.email , username: this.meService.user.username,  nonce: payload.nonce };
              // this doSubscribe() service method (POST request) calls the spring boot backend with the nonce
              // tslint:disable-next-line:no-shadowed-variable
              this.meService.doSubscribe(user).subscribe(() => {
                // can show a successful or error message
                this.router.navigate([routingGlobals.absContest]);

              },
              error => this.errorMessage = error.error);
            });
          });
        });
    });
  }


  continueToHomePage(): void {
    this.showBraintree = false;
    this.router.navigate([routingGlobals.absContest]);
  }



  checkout(product: string): any{
    this.checkoutCall(product).subscribe(response => {
      console.log(response);

      this.stripe.
      redirectToCheckout({
        sessionId: response.sessionId
      })
      .then((res) => {
        console.log('finsihed');
      });
    });
  }

  checkoutCall(product: string): any{
    let _priceid = '';
    if (product === 'Basic') {
      _priceid = STRIPE_STANDARD_SUB;
    }
    const data = { priceId: _priceid, id: this.meService.id };

    // todo make successful redirect link carry jwt
    return this.httpClient.post(HOST_IP_INFO_MARKETS + '/create-checkout-session', data);
  }

}

