import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContestService} from '../../contest.service';
import { Contest } from '../../game/game.service';
import { ContestStateService } from '../../contest-state.service';
import { Router } from '@angular/router';
import * as routingGlobals from '../../../GlobalVars/routingGlobal';
import { MeService } from 'src/app/profile/Me/me.service';
import { BetSlipService } from '../../bet-slip.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-enter-contest-modal-content',
  templateUrl: './enter-contest-modal-content.component.html',
  styleUrls: ['./enter-contest-modal-content.component.css']
})
export class EnterContestModalContentComponent implements OnInit {

  @Input() name;
  _errorResponse: any;

  constructor(public activeModal: NgbActiveModal, private contestService: ContestService,
              private contestStateService: ContestStateService, private meService: MeService,
              private router: Router, private betSlipService: BetSlipService) {}

  ngOnInit(): void {}

  get isAlreadyEnteredInContest(): boolean{
    return this.betSlipService.isEnteredAndHasBetSlips;
  }

  get contestSubtopicName(): string{
    return this.contestStateService.currentContest.subtopicReference.name;
  }

  enterContest(): void{
    this.contestService.updateContest('enterContest', this.contestStateService.currentContest)
        .subscribe((result: Contest) => {
          console.log('success... take back to user contest page');
          this.router.navigate([routingGlobals.absProfile]);
          this.activeModal.close('Close click');
        },
        error => {
          console.log('Error is ' + error);
          this._errorResponse = error;
          // this.activeModal.close('Close click');
        }
        );
  }

  get errorResponse(): string {
    return this._errorResponse;
  }

  updateContest(): void{
    this.contestService.updateContest('updateContest', this.contestStateService.currentContest)
        .subscribe((result: Contest) => {
          console.log('success... take back to user contest page');
          this.router.navigate([routingGlobals.absProfile]);
          this.activeModal.close('Close click');
        },
        error => {
          console.log('Error is ' + error);
          this._errorResponse = error;
          // this.activeModal.close('Close click');
        }
        );
  }

}
