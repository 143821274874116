import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedSubtopicRanking } from 'src/app/contest/contest.component';
import { HOST_IP_STATS } from 'src/app/GlobalVars/api';
import { Links, Page } from 'src/app/media/media.component';

@Injectable({
  providedIn: 'root'
})
export class MyContestOutcomeService {

  paginatedContestOutcome: PaginatedContestOutcome;
  currentContestOutcome: ContestOutcome;
  paginatedSubtopicRankingActiveForTimeframeType: PaginatedSubtopicRanking;
  paginatedSubtopicRankingAllForTimeframe: PaginatedSubtopicRanking;
  constructor(private httpClient: HttpClient) { }

  getContestOutcomesForContest(userId: number, page: number = 0): Observable<PaginatedContestOutcome>{
    return this.httpClient.get<PaginatedContestOutcome>(HOST_IP_STATS + `/contest-outcome/contest/${userId}?page=${page}&size=1000`);
}

getContestOutcomesForMe(userId: number, subtopicId: number,
                        tagId: number, contestType: string, timeframe: string, page: number = 0): Observable<PaginatedContestOutcome>{
    return this.httpClient.get<PaginatedContestOutcome>(HOST_IP_STATS + `/contest-outcome/user/${userId}/subtopic/${subtopicId}?page=${page}&size=1000&tagId=${tagId}&contestType=${contestType}&timeAndGameDurationPeriod=${timeframe}`);
}
}

export interface ContestOutcome{
  totalGameWins: number;
  totalGameLosses: number;
  avgWinningElo: number;
  avgLosingElo: number;
  startingEloValue: number;
  endingEloValue: number;
  rankingWithinSingleContest: number;
  timestamp_: number;
  eloValue: number;
  processed: number; // means included in current ranking entity table
  sizeOfContest: number; // use this in weighted elo avg for avg win loss total
  userId: number;
  contestId: number;
  topicId: number;
  subtopicId: number;
  username: string;
  subtopicName: string;
  processedDate: Date;
  startOfContestDate: Date;
  endOfContestDate: Date;
  sizeOfProcessedContestOutcomes: number;
  totalHeadToHeadWins: number;
  totalHeadToHeadLosses: number;
  totalHeadToHeadDraws: number;
  avgMarginOfError: number;
  totalMarginOfError: number;
}

export interface PaginatedContestOutcome {
  _embedded: EmbeddedContestOutcome;
  _links: Links;
  page: Page;
}

export interface EmbeddedContestOutcome {
  contestOutcomeDTOList: ContestOutcome[];
}
