import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MeService } from '../profile/Me/me.service';
import { StorageService } from './storage.service';
import { LoaderService } from './loader.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService  implements HttpInterceptor {

  private count = 0;

  constructor(private meService: MeService, private storageService: StorageService,
              private loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    this.count++;
    if (this.storageService.isLoggedIn()){
      const myUserDetails =  this.storageService.getUser();
      this.meService.jwt = myUserDetails.jwt;
      this.meService.id = myUserDetails.id;
      this.meService.accountInfo = {
        displayName: myUserDetails.user.firstname + ' ' + myUserDetails.user.lastname ,
        email: myUserDetails.user.username,
        phoneNumber: myUserDetails.user.phoneNumber
      };
      this.meService.user = myUserDetails.user;
    }
    let authReq;
    console.log('request before anything ' + req);
    if (this.meService.jwt){
      authReq = req.clone({
        // withCredentials: true,
        setHeaders:
        { Authorization: 'Bearer ' + this.meService.jwt
      }});
      if (this.meService.id){
        authReq = req.clone({
          // withCredentials: true,
          setHeaders:
          { Authorization: 'Bearer ' + this.meService.jwt,
          id: this.meService.id + ''
        }});
      }
      return next.handle(authReq).pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0){
            if (req.body === 'Expired token'){
              this.meService.logout();
          }
            this.loaderService.isLoading.next(false);
          }
        })
      );
    }
    else if (!!this.meService.id){
      authReq = req.clone({
        // withCredentials: true,
        setHeaders:
        {id: this.meService.id + ''
      }});
      return next.handle(authReq).pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0){
            if (req.body === 'Expired token'){
              this.meService.logout();
          }
            this.loaderService.isLoading.next(false);
          }
        })
      );
    }
    else{
      return next.handle(req).pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0){
            this.loaderService.isLoading.next(false);
          }
        })
      );
    }
    }

}
