<div>
  <div *ngIf="isContestRanking">
    <p class="text-center">Contest Outcome Results</p>
    <p style="font-size: 10px" class="font-weight-bold text-center">
      {{ underOverOutcomeRankingsVO[0].timeAndGameDurationPeriod }}
      <span
        *ngIf="!!tag"
        style="font-size: 10px"
        class="font-weight-bold text-center font-italic"
      >
        [{{ tag.name }}]</span
      >
    </p>

    <!-- style="border: 1px solid #000; overflow: hidden; white-space: wrap" -->
    <table
      class="table table-striped mr-3 table-xs table-hover table-condensed"
      style="width: 100%; word-wrap: break-word"
    >
      <thead>
        <tr>
          <th scope="col">/C Rank</th>
          <th scope="col">Username</th>
          <th
            *ngIf="
              contestStateService.currentContest.contestType ===
              ContestType.UNDER_OVER
            "
            scope="col"
          >
            Game Wins
          </th>
          <th
            *ngIf="
              contestStateService.currentContest.contestType ===
              ContestType.UNDER_OVER
            "
            scope="col"
          >
            Game Losses
          </th>
          <th
            *ngIf="
              contestStateService.currentContest.contestType ===
              ContestType.MARGIN_OF_ERROR
            "
            scope="col"
          >
            Avg MOE
          </th>
          <th
            *ngIf="
              contestStateService.currentContest.contestType ===
              ContestType.MARGIN_OF_VICTORY
            "
            scope="col"
          >
            Avg MOV
          </th>
          <th
            *ngIf="
              contestStateService.currentContest.contestType ===
              ContestType.TOTAL_POINTS_SCORED
            "
            scope="col"
          >
            Avg TPS
          </th>
          <th
            *ngIf="
              contestStateService.currentContest.contestType ===
                ContestType.MARGIN_OF_ERROR ||
              contestStateService.currentContest.contestType ===
                ContestType.MARGIN_OF_VICTORY ||
              contestStateService.currentContest.contestType ===
                ContestType.TOTAL_POINTS_SCORED
            "
            scope="col"
          >
            <!-- Total MOE -->
            % Rank
          </th>
          <th scope="col">/CD Elo</th>
          <!-- <th scope="col">Coin Value</th> -->
        </tr>
      </thead>
      <tbody
        *ngIf="
          contestStateService.currentContest.contestType ===
          ContestType.UNDER_OVER
        "
      >
        <tr
          *ngFor="let ranking of underOverOutcomeRankingsVO; let i = index"
          (click)="viewEnteredContest(ranking.owner_id, ranking.username)"
          style="cursor: pointer"
          [ngClass]="{
            'bg-secondary text-white': ranking.owner_id === meId,
            'bg-dark text-white': ranking.owner_id === userId
          }"
        >
          <th *ngIf="ranking.owner_id === meId" scope="row">
            {{ ranking.ranking }}
          </th>
          <td *ngIf="ranking.owner_id === meId">{{ ranking.username }}</td>
          <td *ngIf="ranking.owner_id === meId">{{ ranking.totalGameWins }}</td>
          <td *ngIf="ranking.owner_id === meId">
            {{ ranking.totalGameLosses }}
          </td>
          <td *ngIf="ranking.owner_id === meId">
            {{ ranking.startingElo | number : "1.3-3" }} -->
            {{ ranking.eloValue | number : "1.3-3" }}
          </td>

          <th *ngIf="ranking.owner_id === userId" scope="row">
            {{ ranking.ranking }}
          </th>
          <td *ngIf="ranking.owner_id === userId">{{ ranking.username }}</td>
          <td *ngIf="ranking.owner_id === userId">
            {{ ranking.totalGameWins }}
          </td>
          <td *ngIf="ranking.owner_id === userId">
            {{ ranking.totalGameLosses }}
          </td>
          <td *ngIf="ranking.owner_id === userId">
            {{ ranking.startingElo | number : "1.3-3" }} -->
            {{ ranking.eloValue | number : "1.3-3" }}
          </td>

          <th
            *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId"
            scope="row"
          >
            {{ ranking.ranking }}
          </th>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.username }}
          </td>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.totalGameWins }}
          </td>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.totalGameLosses }}
          </td>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.startingElo | number : "1.3-3" }} -->
            {{ ranking.eloValue | number : "1.3-3" }}
          </td>
        </tr>
      </tbody>

      <tbody
        *ngIf="
          contestStateService.currentContest.contestType ===
            ContestType.MARGIN_OF_ERROR ||
          contestStateService.currentContest.contestType ===
            ContestType.MARGIN_OF_VICTORY ||
          contestStateService.currentContest.contestType ===
            ContestType.TOTAL_POINTS_SCORED
        "
      >
        <tr
          *ngFor="let ranking of underOverOutcomeRankingsVO; let i = index"
          (click)="viewEnteredContest(ranking.owner_id, ranking.username)"
          style="cursor: pointer"
          [ngClass]="{
            'bg-secondary text-white': ranking.owner_id === meId,
            'bg-dark text-white': ranking.owner_id === userId
          }"
        >
          <th *ngIf="ranking.owner_id === meId" scope="row">
            {{ ranking.ranking }}
          </th>
          <td *ngIf="ranking.owner_id === meId">{{ ranking.username }}</td>
          <td *ngIf="ranking.owner_id === meId">
            {{ ranking.avgMarginOfError | number : "1.2-4" }}
          </td>
          <td *ngIf="ranking.owner_id === meId">
            <!-- {{ ranking.totalMarginOfError | number : "1.2-4" }} -->
            {{ ranking.percentileRank | number : "1.2-4" }}
          </td>
          <td *ngIf="ranking.owner_id === meId">
            {{ ranking.startingElo | number : "1.3-3" }} -->
            {{ ranking.eloValue | number : "1.3-3" }}
          </td>

          <th *ngIf="ranking.owner_id === userId" scope="row">
            {{ ranking.ranking }}
          </th>
          <td *ngIf="ranking.owner_id === userId">{{ ranking.username }}</td>
          <td *ngIf="ranking.owner_id === userId">
            {{ ranking.avgMarginOfError | number : "1.2-4" }}
          </td>
          <td *ngIf="ranking.owner_id === userId">
            <!-- {{ ranking.totalMarginOfError | number : "1.2-4" }} -->
            {{ ranking.percentileRank | number : "1.2-4" }}
          </td>
          <td *ngIf="ranking.owner_id === userId">
            {{ ranking.startingElo | number : "1.3-3" }} -->
            {{ ranking.eloValue | number : "1.3-3" }}
          </td>

          <th
            *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId"
            scope="row"
          >
            {{ ranking.ranking }}
          </th>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.username }}
          </td>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.avgMarginOfError | number : "1.2-4" }}
          </td>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            <!-- {{ ranking.totalMarginOfError | number : "1.2-4" }} -->
            {{ ranking.percentileRank | number : "1.2-4" }}
          </td>
          <td *ngIf="ranking.owner_id !== userId && ranking.owner_id !== meId">
            {{ ranking.startingElo | number : "1.3-3" }} -->
            {{ ranking.eloValue | number : "1.3-3" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="isTopicRanking" class="row">
    <div *ngIf="!topicRankingsVO || topicRankingsVO.length === 0">
      <p>Rankings currently unavailable</p>
    </div>
    <div *ngIf="topicRankingsVO && topicRankingsVO.length > 0">
      <p style="font-size: 10px" class="font-weight-bold text-center">
        {{ topicRankingsVO[0].timeAndGameDurationPeriod }} --
        <span
          *ngIf="!!tag"
          style="font-size: 10px"
          class="font-weight-bold text-center font-italic"
        >
          [{{ tag.name }}]</span
        >
      </p>

      <!-- style="border: 1px solid #000; overflow: hidden; white-space: wrap" -->
      <table
        class="table table-striped mr-3 table-xs table-hover table-condensed"
      >
        <thead>
          <tr>
            <th scope="col">/S Rank</th>
            <th scope="col">Username</th>
            <th scope="col">H2H Wins</th>
            <th scope="col">H2H Loss</th>
            <th scope="col">H2H Draw</th>
            <th scope="col">ELO</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let ranking of topicRankingsVO; let i = index"
            style="cursor: pointer"
            (click)="viewUserProfile(ranking.owner_id)"
          >
            <th scope="row" *ngIf="ranking.ranking !== -100">
              {{ ranking.ranking }}
            </th>
            <th scope="row" *ngIf="ranking.ranking === -100">Unranked</th>
            <td>{{ ranking.username }}</td>
            <td>{{ ranking.totalH2HWins }}</td>
            <td>{{ ranking.totalH2HLosses }}</td>
            <td>{{ ranking.totalH2HDraws }}</td>
            <td>{{ ranking.eloValue | number : "1.2-2" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
