<div *ngIf="!isMobile">
  <link
    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
    rel="stylesheet"
  />
  <!-- <app-user-card-horizontal></app-user-card-horizontal> -->
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="content" class="content content-full-width">
          <!-- begin profile -->
          <div class="profile">
            <div class="profile-header">
              <!-- BEGIN profile-header-cover -->
              <div class="profile-header-cover"></div>
              <!-- END profile-header-cover -->
              <!-- BEGIN profile-header-content -->
              <div class="profile-header-content">
                <!-- BEGIN profile-header-img -->
                <!-- <div class="profile-header-img">
                <img src="/assets/me.jpg" alt="" />
              </div> -->
                <!-- END profile-header-img -->
                <!-- BEGIN profile-header-info -->
                <div class="profile-header-info">
                  <h4 class="m-t-10 m-b-5">{{ username }}</h4>
                  <!-- <a href="#" class="btn btn-sm btn-info mb-2">Edit Profile</a> -->
                </div>
                <!-- END profile-header-info -->
              </div>
              <!-- END profile-header-content -->
              <!-- BEGIN profile-header-tab -->
              <ul class="profile-header-tab nav nav-tabs">
                <!-- <li class="nav-item">
                <a
                  class="nav-link active show"
                  (click)="viewYourPosts()"
                  data-toggle="tab"
                  >Jabs/Yaps</a
                >
              </li> -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    (click)="viewYourContests()"
                    data-toggle="tab"
                    style="cursor: pointer"
                    >Your Contests</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    (click)="viewYourBlogs()"
                    data-toggle="tab"
                    style="cursor: pointer"
                    >Your Blogs</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    (click)="viewCreateBlog()"
                    data-toggle="tab"
                    style="cursor: pointer"
                    >Create Blog</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    (click)="viewYourSocialLinks()"
                    data-toggle="tab"
                    style="cursor: pointer"
                    >Your Social Links</a
                  >
                </li>

                <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  (click)="viewYourSubscriptions()"
                  data-toggle="tab"
                  >Subscriptions</a
                >
              </li> -->
                <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  (click)="currentTab('history')"
                  data-toggle="tab"
                  >History</a
                >
              </li> -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    (click)="viewAccounts()"
                    data-toggle="tab"
                    style="cursor: pointer"
                    >Your Rankings and Outcomes</a
                  >
                </li>
                <!-- <li class="nav-item"><a  class="nav-link" data-toggle="tab">Account Settings</a></li> -->
                <li class="nav-item">
                  <ng-template [ngIf]="meService.isAuthenticated()"
                    ><a
                      class="nav-link"
                      (click)="viewAccountSettings()"
                      data-toggle="tab"
                      style="cursor: pointer"
                      >Account Settings</a
                    >
                  </ng-template>
                </li>
              </ul>
              <!-- END profile-header-tab -->
            </div>
          </div>
          <!-- end profile -->
          <!-- begin profile-content -->
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<div *ngIf="isMobile">
  <p class="text-center" style="font-size: 12px">
    <b
      ><u>{{ username }}</u></b
    >
  </p>
  <ul class="nav nav-tabs">
    <!-- <li class="nav-item">
          <a
            class="nav-link active show"
            (click)="viewYourPosts()"
            data-toggle="tab"
            >Jabs/Yaps</a
          >
        </li> -->
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="viewYourContests()"
        data-toggle="tab"
        style="cursor: pointer"
        >Your Contests</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="viewYourBlogs()"
        data-toggle="tab"
        style="cursor: pointer"
        >Your Posts</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="viewCreateBlog()"
        data-toggle="tab"
        style="cursor: pointer"
        >Create Blog</a
      >
    </li>
    <!-- <li class="nav-item">
          <a
            class="nav-link"
            (click)="viewYourSubscriptions()"
            data-toggle="tab"
            >Subscriptions</a
          >
        </li> -->
    <!-- <li class="nav-item">
          <a
            class="nav-link"
            (click)="currentTab('history')"
            data-toggle="tab"
            >History</a
          >
        </li> -->
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="viewAccounts()"
        data-toggle="tab"
        style="cursor: pointer"
        >Your Rankings and Outcomes</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="viewYourSocialLinks()"
        data-toggle="tab"
        style="cursor: pointer"
        >Your Social Links</a
      >
    </li>
    <!-- <li class="nav-item"><a  class="nav-link" data-toggle="tab">Account Settings</a></li> -->
    <li class="nav-item">
      <ng-template [ngIf]="meService.isAuthenticated()"
        ><a
          class="nav-link"
          (click)="viewAccountSettings()"
          data-toggle="tab"
          style="cursor: pointer"
          >Account Settings</a
        >
      </ng-template>
    </li>
  </ul>

  <router-outlet></router-outlet>
</div>
