import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';

@Injectable({
  providedIn: 'root'
})
export class VerifyAccountService {

  constructor(private httpClient: HttpClient) { }

  sendOTPForEmail(otp: string): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/otp-email/${otp}`);
  }

  sendOTPForPhone(otp: string): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/otp-phone/${otp}`);
  }

  triggerOTPForEmail(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/otp-email`);
  }

  triggerOTPForPhone(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/otp-phone`);
  }

}
