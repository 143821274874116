<div class="row">
  <div class="col-2"></div>
  <div class="col-8">
    <p style="word-break: break-all; white-space: normal"><b>Your Link:</b></p>
    <p style="word-break: break-all; white-space: normal">
      <u>{{ yourLink }}</u>
    </p>
    <button class="btn btn-sm btn-success" (click)="copyLink()">
      Copy Link!
    </button>
  </div>
  <div class="col-2"></div>
</div>
<p class="mt-2 text-center" *ngIf="!!invitedBy">
  You were invited by {{ invitedBy.inviteeUsername }} --{{ invitedBy.id }}
</p>
<p *ngIf="!!invitees" class="text-center">Sign ups in past 30 days from you</p>
<div class="row my-3">
  <div class="col-2"></div>
  <div class="col-8">
    <table
      *ngIf="!!invitees"
      class="table table-striped mr-3 table-xs table-hover table-condensed text-center"
    >
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Inivitee</th>
          <th scope="col">Date of Sign up</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invitee of invitees; let i = index">
          <td>
            {{ i }}
          </td>
          <td>
            {{ invitee.inviteeId }}
            -{{ invitee.inviteeUsername }}
          </td>
          <td>
            {{ invitee.addedOn | date }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-2"></div>
</div>
<div class="d-flex justify-content-center"></div>
