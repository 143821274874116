import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';
import { Comment, PaginatedComment } from '../media.component';
import { BlogService } from './blog.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  paginatedComments: PaginatedComment;
  currentComment: Comment;

  constructor(private httpClient: HttpClient, private blogService: BlogService) { }

  getCommentsForBlog(blogId: number, page: number = 0): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/comment/blog/${blogId}?page=${page}&size=10`);
  }

  postCommentsForBlog(comment: Comment): Observable<any>{
    return this.httpClient.post(HOST_IP_INFO_MARKETS + `/me/comment`, comment);
  }

  // updateComment(comment: Comment): Observable<any>{
  //   return this.httpClient.put(HOST_IP_INFO_MARKETS + `/me/comment`, comment);
  // }

  deleteComment(commentId: number): Observable<any>{
    return this.httpClient.delete(HOST_IP_INFO_MARKETS + `/me/comment/delete-comment/${commentId}`);
  }
}
