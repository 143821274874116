<p class="font-italic font-weight-light">
  Enter the Value that you think the item will be at the end of the contest
</p>
<table
  class="table table-striped table-dark table-xs table-hover table-condensed font-weight-light"
  style="width: 100%"
  #target
>
  <thead>
    <tr>
      <th scope="col">Game</th>

      <th scope="col" *ngIf="isLoggedIn">Update Selection</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
      <td
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
          href="{{
            'https://www.bing.com/search?q=' +
              game.name +
              '+Upcoming College Football Game'
          }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.startTime | date : "medium" }}
        </div>
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
          style="font-size: 10px"
        >
          Home/Away
        </div>
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'stocks'.toUpperCase()"
          href="{{ 'https://www.bing.com/search?q=' + game.name + '+Stock' }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'stocks'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
        <a
          *ngIf="
            contestSubtopic.name.toUpperCase() === 'currency'.toUpperCase()
          "
          href="{{ 'https://www.bing.com/search?q=USD+to+' + game.name }}"
          target="_blank"
          >{{ game.name }}</a
        >

        <div
          *ngIf="
            contestSubtopic.name.toUpperCase() === 'currency'.toUpperCase()
          "
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
        <a
          *ngIf="contestSubtopic.name.toUpperCase() === 'crypto'.toUpperCase()"
          href="{{ 'https://www.bing.com/search?q=' + game.name + '+Price' }}"
          target="_blank"
          >{{ game.name }}</a
        >
        <div
          *ngIf="contestSubtopic.name.toUpperCase() === 'crypto'.toUpperCase()"
          style="font-size: 10px"
        >
          {{ game.description }}
        </div>
      </td>

      <td
        *ngIf="
          contestSubtopic.name.toUpperCase() !== 'NCAAF'.toUpperCase() &&
          contestSubtopic.name.toUpperCase() !== 'NFL'.toUpperCase()
        "
        class="bg-secondary"
      >
        {{ game.liveAtom.value | number : "1.2-4" }} -
        {{ game.liveAtom.updateDate | date : "shortTime" }}
      </td>

      <div
        *ngIf="
          userContestState === UserContestState.ENTERED_UPCOMING ||
          userContestState === UserContestState.CREATING ||
          userContestState === UserContestState.NON_ENTERED_UPCOMING
        "
      >
        <td
          *ngIf="
            currentContest.userContestState ===
              UserContestState.ENTERED_UPCOMING ||
            currentContest.userContestState ===
              UserContestState.NON_ENTERED_UPCOMING ||
            currentContest.userContestState === UserContestState.ENTERED_PAST
          "
          (click)="updateNameOfContest(nameOfContestInput.value, game)"
        >
          <button
            *ngIf="game.betSlip.predictedValue < 0"
            class="btn-xs btn-danger text-center"
            style="font-size: 8.8px"
          >
            Enter Prediction
          </button>
          <button
            *ngIf="game.betSlip.predictedValue >= 0"
            class="btn-xs btn-info text-center"
            style="font-size: 8.8px"
          >
            Update Prediction
          </button>
          <div>
            <p></p>
            <p
              *ngIf="game.betSlip.predictedValue >= 0"
              style="font-size: 10.8px"
              class="text-success font-weight-bold font-italic"
            >
              Current Prediction
            </p>
          </div>
        </td>
        <td>
          <p>
            <input
              type="number"
              id="event"
              name="nameOfContestInput"
              style="font-size: 16px; width: 85px"
              #nameOfContestInput
              [value]="
                game.betSlip.predictedValue >= 0
                  ? game.betSlip.predictedValue
                  : game.liveAtom.value.toFixed(4)
              "
            />
          </p>

          <p
            *ngIf="game.betSlip.predictedValue >= 0"
            style="font-size: 10.8px"
            class="text-success font-weight-bold font-italic"
          >
            {{ game.betSlip.predictedValue | number : "1.2-4" }}
          </p>
        </td>
      </div>
    </tr>
  </tbody>
</table>
<div class="widget-49-meeting-action">
  <button
    *ngIf="isLoggedIn"
    [ngClass]="{
      'btn-sm btn-secondary text-center': canSubmitContestEntry() === false,
      'btn-sm btn-success text-center': canSubmitContestEntry() === true
    }"
    style="font-size: 8.8px"
    [disabled]="!canSubmitContestEntry()"
    (click)="enterContest()"
  >
    Enter or Update Entry
  </button>

  <button
    *ngIf="!isLoggedIn"
    style="font-size: 8.8px"
    (click)="linkToSignUpPage()"
    class="btn btn-success text-center"
  >
    Sign up/in to Play!
  </button>
</div>
<div class="widget-49-meeting-action" *ngIf="!canSubmitContestEntry()">
  <p
    class="btn btn-sm btn-flash-border-primary"
    style="font-size: 10.8px; color: red"
  >
    Please enter predictions (all values must be larger than 0)
  </p>
</div>
