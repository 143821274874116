<p class="font-italic font-weight-light">
  How much will the <u>(H:) Home Team </u> beat the <u>(A:) Away Team.</u> If
  the Home team will win by 7 points, enter 7 (Positive 7). If the home team
  will lose by 3 points, enter -3 (Negative 3).
</p>
<table
  class="table table-striped table-dark table-xs table-hover table-condensed font-weight-light"
  style="width: 100%"
  #target
  *ngIf="loadedGames"
>
  <thead>
    <tr>
      <th scope="col">Game</th>

      <th scope="col" *ngIf="isLoggedIn">Update Selection</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let game of games">
      <td
        *ngIf="contestSubtopic.name.toUpperCase() === 'NCAAF'.toUpperCase()"
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
      >
        <a
          href="{{
            'https://www.bing.com/search?q=' +
              game.name +
              '+Upcoming College Football Game'
          }}"
          target="_blank"
          >H: {{ game.sportsGame.home_team }}
          <br />
          A: {{ game.sportsGame.away_team }}</a
        >
        <div style="font-size: 10px">
          {{ game.startTime | date : "medium" }}
        </div>
      </td>
      <td
        [ngClass]="{
          scratched: game.scratchGame === true
        }"
        *ngIf="contestSubtopic.name.toUpperCase() === 'NFL'.toUpperCase()"
      >
        <a
          href="{{
            'https://www.bing.com/search?q=' + game.name + '+Upcoming NFL Game'
          }}"
          target="_blank"
          >H: {{ game.sportsGame.home_team }}
          <br />
          A: {{ game.sportsGame.away_team }}</a
        >
        <div style="font-size: 10px">
          {{ game.startTime | date : "medium" }}
        </div>
      </td>

      <div
        *ngIf="
          userContestState === UserContestState.ENTERED_UPCOMING ||
          userContestState === UserContestState.CREATING ||
          userContestState === UserContestState.NON_ENTERED_UPCOMING
        "
      >
        <td
          *ngIf="
            currentContest.userContestState ===
              UserContestState.ENTERED_UPCOMING ||
            currentContest.userContestState ===
              UserContestState.NON_ENTERED_UPCOMING ||
            currentContest.userContestState === UserContestState.ENTERED_PAST
          "
          (click)="enterPrediciton(predictionInput.value, game)"
        >
          <button
            *ngIf="
              !!game.betSlip.predictedValue &&
              game.betSlip.predictedValue < -999
            "
            class="btn-xs btn-danger text-center"
            style="font-size: 8.8px"
          >
            Enter Prediction
          </button>
          <button
            *ngIf="
              (!!game.betSlip.predictedValue &&
                game.betSlip.predictedValue >= -999) ||
              game.betSlip.predictedValue == 0
            "
            class="btn-xs btn-info text-center"
            style="font-size: 8.8px"
          >
            Update Prediction
          </button>
          <div>
            <p></p>
            <p
              *ngIf="
                (!!game.betSlip.predictedValue &&
                  game.betSlip.predictedValue >= -999) ||
                game.betSlip.predictedValue == 0
              "
              style="font-size: 10.8px"
              class="text-success font-weight-bold font-italic"
            >
              Current Prediction
            </p>
          </div>
        </td>
        <td>
          <p>
            <input
              type="number"
              id="event"
              name="predictionInput"
              style="font-size: 16px; width: 85px"
              #predictionInput
              [value]="
                !!game.betSlip.predictedValue &&
                game.betSlip.predictedValue >= -999
                  ? game.betSlip.predictedValue
                  : game.sportsGame.line.toFixed(4)
              "
            />
          </p>

          <p
            *ngIf="
              !!game.betSlip.predictedValue &&
              game.betSlip.predictedValue >= -999
            "
            style="font-size: 10.8px"
            class="text-success font-weight-bold font-italic"
          >
            {{ game.betSlip.predictedValue | number : "1.2-4" }}
          </p>
        </td>
      </div>
    </tr>
  </tbody>
</table>
<div class="widget-49-meeting-action">
  <button
    *ngIf="isLoggedIn"
    [ngClass]="{
      'btn-sm btn-secondary text-center': canSubmitContestEntry() === false,
      'btn-sm btn-success text-center': canSubmitContestEntry() === true
    }"
    style="font-size: 8.8px"
    [disabled]="!canSubmitContestEntry()"
    (click)="enterContest()"
  >
    Enter or Update Entry
  </button>

  <button
    *ngIf="!isLoggedIn"
    style="font-size: 8.8px"
    (click)="linkToSignUpPage()"
    class="btn btn-success text-center"
  >
    Sign up/in to Play!
  </button>
</div>
<div class="widget-49-meeting-action" *ngIf="!canSubmitContestEntry()">
  <p
    class="btn btn-sm btn-flash-border-primary"
    style="font-size: 10.8px; color: red"
  >
    Please enter predictions (all values must be larger than -999)
  </p>
</div>
