import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HOST_IP_INFO_MARKETS } from 'src/app/GlobalVars/api';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpClient: HttpClient) { }

  getClientToken(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/braintree/client-token`);
  }

  doSubscribe(userDTO: any): Observable<any>{
    return this.httpClient.post(HOST_IP_INFO_MARKETS + `/braintree/create-subscription`, userDTO);
  }

  getSubscription(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/braintree/get-subscription`);
  }

  cancelSubscription(): Observable<any>{
    return this.httpClient.get(HOST_IP_INFO_MARKETS + `/braintree/cancel-subscription`);
  }
}
